import Button from "../../generic/Button";
import styles from "./Listas.module.css";
import { useEffect, useState, useContext, useRef } from "react";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import $ from "jquery";
import Loader from "../../generic/Loader";
import MensajeCentro from "../../generic/MensajeCentro";
import Input from "../../generic/Input";
import { objValidadoInicial, validarForm } from "../../../helpers/ValidarT";
import ConfirmContext from "../../../context/ConfirmContext";
import { ValidarT } from "../../../helpers/ValidarT";
import BarraBusqueda from "../../generic/BarraBusqueda";
import Modal from "../../generic/Modal";
import CreatableSelect from "react-select/creatable";
import { IMagnitud } from "./Magnitudes";
import { motion } from "framer-motion";

const initialFormData = { item: "", idmagnitud: "0" };

interface ITipoEquipo {
  idtipoequipo: number;
  tipoequipo: string;
  idmagnitud: number;
  estado: number;
}

function TiposDeEquipo() {
  const { mostrarConfirm } = useContext(ConfirmContext);
  const { mostrarPopup } = useContext(PopupContext);

  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [loadingCreation, setLoadingCreation] = useState<[boolean, number]>([
    false,
    0,
  ]);
  const [listData, setListData] = useState<ITipoEquipo[]>([]);
  const [filteredListData, setFilteredListData] = useState<ITipoEquipo[]>([]);
  const [formData, setFormData] = useState(initialFormData);
  const [modal, setModal] = useState(false);
  const [magnitudes, setMagnitudes] = useState<IMagnitud[]>([]);

  const formValidacion = useRef({
    item: objValidadoInicial,
    idmagnitud: objValidadoInicial,
  });

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    obtenerLista();
    getMagnitud();
    setMounted(true);
  }, []);

  const obtenerLista = () => {
    $.ajax({
      url: `${host}:${port}/api/tipoequipo/all/`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        if (!mounted) setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setListData(data);
      },
      error: function (data) {
        setCargando(false);
        setListData([]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const borrarItem = async (id: number) => {
    if (await mostrarConfirm("¿Borrar el ítem de la lista?")) {
      $.ajax({
        url: `${host}:${port}/api/tipoequipo/delete/`,
        type: "delete",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ id }),
        beforeSend: function () {
          setLoadingCreation([true, id]);
        },
        success: function (data) {
          mostrarPopup(1, data.msg);
          setLoadingCreation([false, 0]);
          obtenerLista();
          setFormData(initialFormData);
        },
        error: function (data) {
          setLoadingCreation([false, 0]);
          setListData([]);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  const anadirItem = () => {
    $.ajax({
      url: `${host}:${port}/api/tipoequipo/create`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        tipoequipo: formData.item.toUpperCase(),
        idmagnitud: formData.idmagnitud,
      }),
      beforeSend: function () {
        setCargando2(true);
      },
      success: function (data) {
        mostrarPopup(1, data.msg);
        setCargando2(false);
        obtenerLista();
        setModal(false);
        setFormData(initialFormData);
      },
      error: function (data) {
        setCargando2(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const updateItem = (idtipoequipo: number, idmagnitud: number) => {
    $.ajax({
      url: `${host}:${port}/api/tipoequipo/update`,
      type: "put",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        idtipoequipo,
        idmagnitud,
      }),
      beforeSend: function () {
        setLoadingCreation([true, idtipoequipo || 0]);
      },
      success: function (data) {
        setLoadingCreation([false, 0]);
        mostrarPopup(1, data.msg);
        obtenerLista();
      },
      error: function (data) {
        setLoadingCreation([false, 0]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const handleGuardar = () => {
    if (validarForm(formValidacion.current)) {
      anadirItem();
    } else {
      mostrarPopup(2, "Debes llenar todos los campos");
    }
  };

  const setMagnitud = (idtipoequipo: number, idmagnitud: number) => {
    updateItem(idtipoequipo, idmagnitud);
    setListData((lD) =>
      lD.map((item) => {
        if (item.idtipoequipo === idtipoequipo) item.idmagnitud = idmagnitud;
        return item;
      })
    );
  };

  // magnitudes
  const getMagnitud = () => {
    $.ajax({
      url: `${host}:${port}/api/magnitud/all/`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setCargando(true);
      },
      success: function (data) {
        // setCargando(false);
        setMagnitudes(data);
      },
      error: function (data) {
        // setCargando(false);
        setListData([]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const addMagnitud = (magnitud: string, idtipoequipo?: number) => {
    $.ajax({
      url: `${host}:${port}/api/magnitud/create`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        magnitud: magnitud.toUpperCase(),
      }),
      beforeSend: function () {
        setLoadingCreation([true, idtipoequipo || 0]);
      },
      success: function (data) {
        setLoadingCreation([false, 0]);
        mostrarPopup(1, data.msg);
        handleChange("idmagnitud", data.idmagnitud.toString());
        if (idtipoequipo) {
          setMagnitud(idtipoequipo, data.idmagnitud);
        }
        getMagnitud();
      },
      error: function (data) {
        setMagnitud(idtipoequipo || 0, 0);
        setLoadingCreation([false, 0]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    handleValidacion(name, value);
  };

  const handleValidacion = (name: string, value: string) => {
    formValidacion.current = {
      ...formValidacion.current,
      [name]: ValidarT.general(value),
    };
  };

  return (
    <>
      <div className={styles.contListas}>
        <div
          className="encabezado-nombre-barra-buscar"
          style={{ marginBottom: "10px", marginTop: "3px" }}
        >
          <h4>Ítems</h4>
          <BarraBusqueda
            data={listData.sort((a, b) =>
              a.tipoequipo.localeCompare(b.tipoequipo)
            )}
            keysToFilter={["tipoequipo"]}
            setFilteredData={setFilteredListData}
          />
          <Button icono="ico-anadir" onClick={() => setModal(true)} />
        </div>
        {cargando ? (
          <Loader />
        ) : (
          <div className={"flex flex-col gap-2"}>
            {filteredListData.map((el, i) => {
              return (
                <motion.div
                  layout
                  key={el.idtipoequipo}
                  className={`grid grid-cols-[1fr_250px_max-content] gap-2 bg-white rounded-xl p-3 items-center`}
                >
                  <p>{el.tipoequipo}</p>
                  <CreatableSelect
                    options={magnitudes
                      .sort((a, b) => a.magnitud.localeCompare(b.magnitud))
                      .map((el) => ({
                        value: el.idmagnitud,
                        label: el.magnitud,
                      }))}
                    onChange={(e) =>
                      setMagnitud(el.idtipoequipo, e?.value || 0)
                    }
                    onCreateOption={(e) => addMagnitud(e, el.idtipoequipo)}
                    isLoading={
                      loadingCreation[0] &&
                      loadingCreation[1] === el.idtipoequipo
                    }
                    value={{
                      label: magnitudes.find(
                        (magnitud) => magnitud.idmagnitud === el.idmagnitud
                      )?.magnitud,
                      value: el.idmagnitud,
                    }}
                    placeholder="Magnitud"
                  />
                  <div
                    className="ico-eliminar format-ico-row-table row-accion"
                    onClick={() => borrarItem(el.idtipoequipo)}
                    style={{ opacity: "0.2" }}
                  />
                </motion.div>
              );
            })}
            {listData.length <= 0 && (
              <div className="grid-full-width">
                <MensajeCentro mensaje="No hay ítems" />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal activo={modal} cerrar={() => setModal(false)}>
        <div className={`flex flex-col gap-4 w-80`}>
          <h3>Nuevo tipo de equipo</h3>
          <Input
            label="Tipo de equipo"
            name="item"
            onChange={handleChange}
            value={formData.item}
            autoFocus
          />
          <CreatableSelect
            options={magnitudes
              .sort((a, b) => a.magnitud.localeCompare(b.magnitud))
              .map((el) => ({
                value: String(el.idmagnitud),
                label: el.magnitud,
              }))}
            onChange={(e) =>
              handleChange("idmagnitud", e?.value.toString() || "0")
            }
            onCreateOption={(e) => addMagnitud(e)}
            isLoading={loadingCreation[0]}
            value={{
              label: magnitudes.find(
                (magnitud) =>
                  magnitud.idmagnitud === Number(formData.idmagnitud)
              )?.magnitud,
              value: formData.idmagnitud,
            }}
            placeholder="Magnitud"
          />
          <div>
            <Button
              icono="ico-guardar"
              onClick={handleGuardar}
              label="Guardar"
              cargando={cargando2}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TiposDeEquipo;
