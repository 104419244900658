import { useContext, useEffect, useState } from "react";
import EquipoHistorialCertificado from "./EquipoHistorialCertificado";
import "./HistorialEquipo.css";
import { host, port } from "../../../../../helpers/DbData";
import $ from "jquery";
import PopupContext from "../../../../../context/PopupContext";
import Loader from "../../../../generic/Loader";
import MensajeCentro from "../../../../generic/MensajeCentro";

function HistorialEquipo({ activo, idequipo, idtablaequipo }) {
  const [cargando, setCargando] = useState(false);
  const [historial, setHistorial] = useState([]);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    if (activo && idequipo !== 0) {
      obtenerHistorialEquipo();
    }
  }, [activo, idequipo]);

  const obtenerHistorialEquipo = () => {
    $.ajax({
      url:
        idtablaequipo === 2
          ? `${host}:${port}/api/ordenTrabajo/certificadosPesas`
          : `${host}:${port}/api/ordenTrabajo/seriemarcaequipo`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ idequipo }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setHistorial(data);
      },
      error: function (data) {
        setCargando(false);
        setHistorial([]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };
  return (
    <div className="cont-historial-equipo">
      <div className="cont-card cont-historial-equipo-equipos">
        {cargando ? (
          <Loader />
        ) : historial.length > 0 ? (
          <>
            {historial
              .sort((a, b) =>
                a.idordentrabajo > b.idordentrabajo
                  ? -1
                  : a.idordentrabajo === b.idordentrabajo
                  ? 0
                  : 1
              )
              .map((el, i) => (
                <EquipoHistorialCertificado
                  key={i}
                  data={el}
                  idotactual={0}
                  idtablaequipo={idtablaequipo}
                />
              ))}
          </>
        ) : (
          <MensajeCentro mensaje="No existen certificados emitidos para este equipo" />
        )}
      </div>
    </div>
  );
}

export default HistorialEquipo;
