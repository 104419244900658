import LabelText from "../../../../generic/LabelText";
import LabelState from "../../../../generic/LabelState";
import { INumDecimales } from "./IdentificacionEquipo";
import DataHandler from "../../../../../helpers/DataHandler";

export interface IVerificacionesPrevias {
  aptaproceso: number;
  ceroinicial: number;
  estado: string;
  lecturacargaprueba: number;
  motivonoapta: string;
  nivelada: number;
  otroestadoequipo: string;
  retornocero: number;
  idnumdecimales: number;
}

interface Props {
  mantenimiento?: boolean;
  data: IVerificacionesPrevias;
  numdecimales: INumDecimales;
  unidadmedida: string;
}

function VerificacionesPrevias({
  mantenimiento = false,
  data,
  numdecimales,
  unidadmedida,
}: Props) {
  const renderND = DataHandler.renderND(numdecimales);
  return (
    <div className="cont-card">
      <h3>Verificaciones previas</h3>
      <div className="calibracion-2-colum">
        <>
          {!mantenimiento && (
            <div className="table-row">
              <LabelText label={"Estado"} text={data.estado} />
              <LabelText
                text={`${renderND(
                  data.lecturacargaprueba,
                  data.idnumdecimales
                )} ${unidadmedida}`}
                label={"Lectura de carga de prueba"}
              />
            </div>
          )}
          <div className="table-row">
            <LabelState label={"Cero inicial"} state={!!data.ceroinicial} />
            <LabelState label={"Retorno a cero"} state={!!data.retornocero} />
          </div>
          {!mantenimiento && (
            <>
              <div className="table-row">
                <LabelState label={"Nivelada"} state={!!data.nivelada} />
                <LabelState
                  label={"Apta para calibración"}
                  state={!!data.aptaproceso}
                />
              </div>
              <div className="table-row">
                {data.aptaproceso === 0 && (
                  <LabelText
                    label={"Motivo no apta"}
                    text={data.motivonoapta}
                  />
                )}
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default VerificacionesPrevias;
