import { useContext, useEffect, useState, useRef } from "react";
import Button from "../generic/Button";
import PopupContext from "../../context/PopupContext";
import ConfirmContext from "../../context/ConfirmContext";
import $ from "jquery";
import { host, port } from "../../helpers/DbData";
import MensajesUI from "../../helpers/MensajesUI";
import Loader from "../generic/Loader";
import CiudadesEC from "../../helpers/CiudadesEC.json";
import _ from "lodash";
import useForm, { IConfigValidacion } from "../../hooks/useForm";
import Input from "../generic/Input";
import Select from "../generic/Select";
import { IOrdenAfectada } from "./FormOrdenesAfectadas";
import { IDatosCliente } from "./CardCliente";
import DataHandler from "../../helpers/DataHandler";

let initialDatosForm = {
  identificacion: "",
  empresa: "",
  idtipoindustria: 0,
  nombres: "",
  ciudad: "",
  direccion: "",
  email: "",
  telefono: "",
  celular: "",
};

let initialFormValidado: IConfigValidacion = {
  identificacion: { validar: "cedula", opcional: false },
  empresa: { validar: "general", opcional: false },
  idtipoindustria: { validar: "noCero", opcional: false },
  nombres: { validar: "general", opcional: false },
  ciudad: { validar: "texto", opcional: false },
  direccion: { validar: "general", opcional: false },
  email: { validar: "email", opcional: false },
  telefono: { validar: "telefono", opcional: true },
  celular: { validar: "telefono", opcional: true },
};

interface Props {
  idCliente: number;
  cerrar: () => void;
  clientes: IDatosCliente[];
  seleccionarOrdenesAfectadas: (
    ordenesAfectadas: IOrdenAfectada[],
    idCliente: number
  ) => void;
}

function FormNuevoCliente({
  idCliente,
  cerrar,
  clientes,
  seleccionarOrdenesAfectadas,
}: Props) {
  const { mostrarConfirm } = useContext(ConfirmContext);

  // states
  const [tiposIndustria, setTiposIndustria] = useState<
    { idtipoindustria: number; tipoindustria: string }[]
  >([]);
  const { mostrarPopup } = useContext(PopupContext);
  const [cargandoGuardar, setCargandoGuardar] = useState(false);
  const [cargandoEliminar, setCargandoEliminar] = useState(false);
  const [modoActualizar, setModoActualizar] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const {
    formData,
    handleChange,
    setFormData,
    resetForm,
    formValidacion,
    formValidado,
  } = useForm(initialDatosForm, initialFormValidado);

  // órdenes afectadas

  // refs
  const originalFormData = useRef(initialDatosForm);

  useEffect(() => {
    cargarCombos();
    return () => setTiposIndustria([]);
  }, []);

  useEffect(() => {
    if (idCliente > 0) {
      setModoActualizar(true);
      cargarDatosCliente();
    } else {
      inicializarForm();
    }
  }, [idCliente]);

  const inicializarForm = () => {
    resetForm();
    setModoActualizar(false);
    originalFormData.current = initialDatosForm;
  };

  const handleGuardarCliente = () => {
    if (formValidado) {
      guardarCliente();
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  const eliminarCliente = async () => {
    if (
      (await mostrarConfirm(
        `¿Seguro que deseas eliminar el cliente ${formData.empresa}?`
      )) &&
      !cargandoEliminar
    ) {
      $.ajax({
        url: `${host}:${port}/api/cliente/delete`,
        type: "delete",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          idcliente: idCliente,
        }),
        beforeSend: function () {
          setCargandoEliminar(true);
        },
        success: function (data) {
          setCargandoEliminar(false);
          mostrarPopup(1, data.data);
          cerrar();
          inicializarForm();
        },
        error: function (data) {
          setCargandoEliminar(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  const guardarCliente = () => {
    const editado = !_.isEqual(originalFormData.current, formData);
    if (!editado && modoActualizar) {
      mostrarPopup(2, "No se ha realizado ningún cambio");
      return;
    }
    if (!cargandoGuardar)
      $.ajax({
        url: `${host}:${port}/api/cliente/edit`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          // ...DataHandler.nullEmpty(formData),
          ...formData,
          editado,
          idcliente: idCliente,
        }),
        beforeSend: function () {
          setCargandoGuardar(true);
        },
        success: function (data) {
          if (data.ordenesAfectadas && data.ordenesAfectadas.length > 0)
            seleccionarOrdenesAfectadas(data.ordenesAfectadas, idCliente);
          setCargandoGuardar(false);
          mostrarPopup(
            data.ordenesAfectadas && data.ordenesAfectadas.length > 0 ? 2 : 1,
            data.data
          );
          cerrar();
          inicializarForm();
        },
        error: function (data) {
          setCargandoGuardar(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const cargarDatosCliente = () => {
    $.ajax({
      url: `${host}:${port}/api/cliente/${idCliente}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",

      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        const tempData = {
          identificacion: data[0].identificacion,
          empresa: data[0].empresa,
          idtipoindustria: data[0].idtipoindustria,
          nombres: data[0].nombres,
          ciudad: data[0].ciudad,
          direccion: data[0].direccion,
          email: data[0].email,
          telefono: data[0].telefono,
          celular: data[0].celular,
        };
        setFormData(tempData);
        originalFormData.current = DataHandler.emptyNull(tempData);
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const cargarCombos = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/tipoIndustria`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        // console.log(data);
        // setNumPag(Math.ceil(parseInt(data) / clientesPP));
        // setClientes(data);
        setTiposIndustria(data);
        // if (data.length === 1) {
        //   if ("cedulausuario" in data[0]) {
        //     sessionStorage.setItem("datosUsuario", JSON.stringify(data[0]));
        //     setDatosUsuario(data[0]);
        //   } else {
        //     mostrarPopup(2, "No se ha encontrado el usuario");
        //   }
        // } else {
        //   mostrarPopup(2, "No se ha encontrado el usuario");
        // }
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  useEffect(() => {
    if (
      clientes.find((el) => el.identificacion === formData.identificacion) &&
      !(idCliente > 0)
    ) {
      setMensajeError(
        "El cliente ya existe, verifícalo en la lista de clientes"
      );
    } else {
      setMensajeError("");
    }
  }, [formData.identificacion, clientes, mostrarPopup]);

  return (
    <>
      <form
        className="cont-2-columnas grid-gap-form"
        style={{ width: "500px" }}
        autoComplete={"off"}
      >
        <h3 className="grid-full-width">
          {formData.empresa.length <= 0 ? "Nuevo cliente" : formData.empresa}
        </h3>
        <Input
          label={"Cédula o RUC"}
          placeholder="1700000001"
          tipo="number"
          name="identificacion"
          value={formData.identificacion}
          onChange={handleChange}
          validacion={formValidacion.identificacion}
        />
        <Select
          label={"Tipo de industria"}
          name="idtipoindustria"
          value={formData.idtipoindustria}
          onChange={handleChange}
          validacion={formValidacion.idtipoindustria}
          options={tiposIndustria.map((el, i) => ({
            label: el.tipoindustria,
            value: el.idtipoindustria,
          }))}
          initialLabel="Seleccione un tipo de industria"
        />
        <div className="grid-full-width">
          <Input
            label={"Empresa"}
            placeholder="Pinprexat"
            name="empresa"
            value={formData.empresa}
            onChange={handleChange}
            validacion={formValidacion.empresa}
          />
        </div>
        <Input
          label={"Nombre del encargado"}
          placeholder="Juan Pérez"
          tipo="text"
          name="nombres"
          value={formData.nombres}
          onChange={handleChange}
          validacion={formValidacion.nombres}
        />
        <Input
          label={"Ciudad"}
          placeholder="Quito"
          tipo="text"
          name="ciudad"
          value={formData.ciudad}
          onChange={handleChange}
          validacion={formValidacion.ciudad}
          datalist={CiudadesEC.sort((a, b) => a.city.localeCompare(b.city)).map(
            (el) => ({ label: el.city, value: el.city })
          )}
        />
        <div className="grid-full-width">
          <Input
            label={"Dirección"}
            placeholder="El Telégrafo OE-00"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            validacion={formValidacion.direccion}
          />
        </div>
        <Input
          label={"E-mail"}
          placeholder="me@pinprexat.com"
          tipo="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          validacion={formValidacion.email}
        />
        <Input
          label={"Teléfono"}
          placeholder="2000001"
          tipo="tel"
          name="telefono"
          value={formData.telefono}
          onChange={handleChange}
          validacion={formValidacion.telefono}
        />
        <Input
          label={"Celular"}
          placeholder="0990000001"
          tipo="tel"
          name="celular"
          value={formData.celular}
          onChange={handleChange}
          validacion={formValidacion.celular}
        />
        <div className="cont-btns grid-full-width">
          {cargandoEliminar || cargandoGuardar ? (
            <Loader />
          ) : (
            <>
              <Button
                label={"Guardar"}
                icono={"ico-guardar"}
                onClick={handleGuardarCliente}
                cargando={cargandoGuardar}
              />
              {modoActualizar ? (
                <Button
                  label={"Eliminar"}
                  icono={"ico-eliminar"}
                  onClick={eliminarCliente}
                  color="ROJO"
                  cargando={cargandoEliminar}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {mensajeError !== "" && (
          <p className="texto-validacion">{mensajeError}</p>
        )}
      </form>
    </>
  );
}

export default FormNuevoCliente;
