import Modal from "../../../generic/Modal";
import styles from "./AyudaEstado.module.css";
import { maxEstadosOT } from "./OrdenTrabajo";
import { useState } from "react";

// función que muestra qué significa cada color de estado de las órdenes de trabajo
function AyudaEstados() {
  const [active, setActive] = useState(false);

  // const refCont = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const checkIfClickedOutside = (e: MouseEvent) => {
  //     if (refCont.current && !refCont.current.contains(e.target as any)) {
  //       setActive(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   return () => {
  //     // Cleanup the event listener
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [refCont]);

  const renderEstado = (estado: number) => {
    let htmlEstados = [];
    for (let i = 1; i < maxEstadosOT; i++) {
      // if (parseInt(orden.estado) == maxEstadosOT + 1) {
      if (estado === maxEstadosOT) {
        htmlEstados[i] = <div key={i} className="color-estado-completo"></div>;
      } else {
        //   if (i < parseInt(orden.estado)) {
        if (i < estado) {
          htmlEstados[i] = <div key={i} className={"color-estado-" + i}></div>;
        } else {
          htmlEstados[i] = <div key={i} className="sin-color"></div>;
        }
      }
    }
    return htmlEstados;
  };

  return (
    <div>
      <div
        className={`animar-hover ${styles.contIcoAyuda}`}
        onClick={() => setActive(true)}
      >
        <div className={`ico-ayuda ${styles.icoAyuda}`} />
      </div>
      <Modal activo={active} cerrar={() => setActive(false)}>
        <h3 className={styles.titulo}>Estados de las órdenes</h3>
        <div className={`cont-card ${styles.contFlotante}`}>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(1)}
            </div>
            <p>Creada</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(2)}
            </div>
            <p>Aprobada por Ventas</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(3)}
            </div>
            <p>Aprobada por Calidad</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(4)}
            </div>
            <p>Aprobada por Cliente</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(5)}
            </div>
            <p>Servicio completado por Técnicos</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(6)}
            </div>
            <p>Revisado por Calidad</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(7)}
            </div>
            <p>Revisado por Gerencia Técnica</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(8)}
            </div>
            <p>Certificados emitidos</p>
          </div>
          <div>
            <div className={`cont-barra-estados ${styles.contBarraEstados}`}>
              {renderEstado(9)}
            </div>
            <p>Descargado por cliente</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AyudaEstados;
