import React, { ChangeEvent, useCallback, useMemo } from "react";
import isHotkey from "is-hotkey";
import styles from "./RichTextInput.module.css";
import {
  Editable,
  withReact,
  // useSlate
  // ,
  Slate,
  ReactEditor,
} from "slate-react";
import {
  Node,
  Editor,
  // Transforms,
  createEditor,
  Descendant,
  // Element as SlateElement,
} from "slate";

import { BaseEditor } from "slate";
// import ToolBar from "./ToolBar";
// import ToggleButton from "./ToggleButton";
import { useState, useEffect } from "react";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

// const LIST_TYPES = ["numbered-list", "bulleted-list"];
// const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const initialValue: Descendant[] = [
  { type: "paragraph", children: [{ text: "" }] },
];

interface Props {
  onChange: (name: any, text: string) => void;
  name: any;
  value: string;
  label?: string;
}

const RichTextInput = ({ onChange, name, value, label }: Props) => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const [helper, setHelper] = useState(0);
  // const texto=useRef('')

  const serialize = (value: Descendant[]) => {
    return (
      value
        // Return the string content of each paragraph in the value's children.
        .map((n) => Node.string(n))
        // Join them all with line breaks denoting paragraphs.
        .join("\n")
    );
  };

  useEffect(() => {
    setHelper((h) => 1 + h);
  }, [value]);

  const handleChange = (value: Descendant[]) => {
    if (onChange && name) {
      onChange(name, serialize(value));
    }
  };
  const deserialize = (string: string): Descendant[] => {
    // Return a value array of children derived by splitting the string.
    return string.split("\n").map((line) => {
      return {
        type: "paragraph",
        children: [{ text: line }],
      };
    });
  };

  // const initialValue = useMemo(
  //   deserialize(localStorage.getItem('content')) || '',
  //   []
  // )

  // useEffect(() => {
  //   if(value!==serialize(text1))
  // }, [value]);
  return (
    <>
      {label && label !== "" && <p>{label}</p>}
      <div className={styles.contRichTextInput}>
        <Slate
          editor={editor}
          value={deserialize(value)}
          onChange={handleChange}
          // key={helper}
        >
          {/* <ToolBar> */}
          {/* <MarkButton format="bold" /> */}
          {/* <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" /> */}
          {/* <MarkButton format="code" icon="code" /> */}
          {/* <BlockButton format="heading-one" icono="looks_one" />
        <BlockButton format="heading-two" icono="looks_two" /> */}
          {/* <BlockButton format="block-quote" icono="format_quote" />
        <BlockButton format="numbered-list" icono="format_list_numbered" />
        <BlockButton format="bulleted-list" icono="format_list_bulleted" />
        <BlockButton format="left" icono="format_align_left" />
        <BlockButton format="center" icono="format_align_center" />
        <BlockButton format="right" icono="format_align_right" />
        <BlockButton format="justify" icono="format_align_justify" /> */}
          {/* </ToolBar> */}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={"..."}
            spellCheck
            autoFocus
            onKeyDown={(event) => {
              let hotkey: keyof typeof HOTKEYS;
              for (hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </Slate>
      </div>
    </>
  );
};

// const toggleBlock = (editor: any, format) => {
//   const isActive = isBlockActive(
//     editor,
//     format,
//     TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
//   );
//   const isList = LIST_TYPES.includes(format);

//   Transforms.unwrapNodes(editor, {
//     match: (n) =>
//       !Editor.isEditor(n) &&
//       SlateElement.isElement(n) &&
//       LIST_TYPES.includes(n.type) &&
//       !TEXT_ALIGN_TYPES.includes(format),
//     split: true,
//   });
//   let newProperties: Partial<SlateElement>;
//   if (TEXT_ALIGN_TYPES.includes(format)) {
//     newProperties = {
//       align: isActive ? undefined : format,
//     };
//   } else {
//     newProperties = {
//       type: isActive ? "paragraph" : isList ? "list-item" : format,
//     };
//   }
//   Transforms.setNodes<SlateElement>(editor, newProperties);

//   if (!isActive && isList) {
//     const block = { type: format, children: [] };
//     Transforms.wrapNodes(editor, block);
//   }
// };

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

// const isBlockActive = (editor: any, format: any, blockType = "type") => {
//   const { selection } = editor;
//   if (!selection) return false;

//   const [match] = Array.from(
//     Editor.nodes(editor, {
//       at: Editor.unhangRange(editor, selection),
//       match: (n) =>
//         !Editor.isEditor(n) &&
//         SlateElement.isElement(n) &&
//         n[blockType] === format,
//     })
//   );

//   return !!match;
// };

const isMarkActive = (editor: BaseEditor & ReactEditor, format: any) => {
  const marks = Editor.marks(editor);
  // @ts-ignore: Unreachable code error
  return marks ? marks[format] === true : false;
};

const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: any;
  children: React.ReactNode;
  element: any;
}) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

interface Ileaf {
  bold: boolean;
  code: boolean;
  italic: boolean;
  underline: boolean;
}
interface ILeaf {
  leaf: Ileaf;
  attributes: object;
  children: React.ReactNode;
}

const Leaf = ({ attributes, children, leaf }: ILeaf) => {
  if (leaf.bold) {
    children = <strong className={styles.strong}>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

// const BlockButton = ({ format, icono }: { icono: TypeIconToolBar }) => {
//   const editor = useSlate();
//   return (
//     <Button
//       //   active={isBlockActive(
//       //     editor,
//       //     format,
//       //     TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
//       //   )}
//       icono={icono}
//       onClick={() => {
//         toggleBlock(editor, format);
//       }}
//     />
//   );
// };

// const MarkButton = ({ format }: { format: any }) => {
//   const editor = useSlate();
//   return (
//     <ToggleButton
//       label="B"
//       onClick={() => {
//         toggleMark(editor, format);
//       }}
//       active={isMarkActive(editor, format)}
//     />
//   );
// };

// const initialValue: Descendant[] = [
//   {
//     type: "paragraph",
//     children: [{ text: "" }],
//   },
// ];

export default RichTextInput;
