import { INumDecimales } from "./IdentificacionEquipo";
import DataHandler from "../../../../../helpers/DataHandler";
export interface IAprobacionProceso {
  numpunto: number;
  punto: number;
  lectura: number;
  errorpunto: number;
  errorlectura: number;
  diferror: number;
  difmax: number;
  aprobado: number;
  idnumdecimales: number;
}

interface Props {
  data: IAprobacionProceso[];
  numdecimales: INumDecimales;
  unidadmedida: string;
}

function AprobacionProceso({ data, numdecimales, unidadmedida }: Props) {
  const renderND = DataHandler.renderND(numdecimales);

  return (
    <div className="cont-card">
      <h3>Aprobación o rechazo del proceso de calibración</h3>
      <div>
        <div className="table-row table-row-title center-hd">
          <span>Punto ({unidadmedida})</span>
          <span>Lectura 2da cal. ({unidadmedida})</span>
          <span>Error 1ra cal. ({unidadmedida})</span>
          <span>Error 2da cal. ({unidadmedida})</span>
          <span>Dif. abs. 1ra y 2da cal. (A) ({unidadmedida})</span>
          <span>Dif. máx repetibilidad (B) ({unidadmedida})</span>
          <span>{"Aprobado (A<B)"}</span>
        </div>
        {data.map((el, i) => {
          return (
            <div key={i} className="table-row center-hd">
              <span>{renderND(el.punto, el.idnumdecimales)}</span>
              <span>{renderND(el.lectura, el.idnumdecimales)}</span>
              <span>{renderND(el.errorpunto, el.idnumdecimales)}</span>
              <span>{renderND(el.errorlectura, el.idnumdecimales)}</span>
              <span>{renderND(el.diferror, el.idnumdecimales)}</span>
              <span>{renderND(el.difmax, el.idnumdecimales)}</span>
              <div
                className={
                  "format-ico-label-state " +
                  (el.aprobado === 0 ? "ico-aprobado" : "ico-error")
                }
                style={{ justifySelf: "center" }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AprobacionProceso;
