import { useState, useEffect, useRef } from "react";
import ContInput from "./ContInput";
import TypeIcon from "../../types/TypeIcon";
import "./SelectMultiple.css";
import {
  objValidadoInicial,
  objValidado,
  IValidacion,
} from "../../helpers/ValidarT";

interface Props {
  name?: string;
  icono?: TypeIcon;
  label?: string;
  value?: number[];
  options: { value: number; label: string }[];
  setValue: (name: string, value: number[]) => void;
  setValidacion?: (name: string, value: IValidacion) => void;
}

function SelectMultiple({
  name,
  icono,
  label,
  value,
  options,
  setValue,
  setValidacion,
}: Props) {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [validacionInput, setValidacionInput] = useState(objValidadoInicial);

  const handleClick = (value: number) => {
    let tempSelected = [...selectedOptions];
    let i = tempSelected.findIndex((el) => el === value);
    if (i > -1) {
      tempSelected.splice(i, 1);
    } else {
      tempSelected.push(value);
    }
    tempSelected.sort((a, b) => a - b);
    setSelectedOptions(tempSelected);
  };

  const refCont = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (refCont.current && !refCont.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [refCont]);

  useEffect(() => {
    if (name && value && !compareArrays(value, selectedOptions))
      setValue(name, selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      setValidacionInput(objValidado);
    } else {
      setValidacionInput({
        mensaje: "Debes seleccionar al menos una opción",
        validado: false,
      });
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (value && !compareArrays(value, selectedOptions)) {
      setSelectedOptions(value);
    }
  }, [value]);

  const compareArrays = (array1: number[], array2: number[]) => {
    array1.sort((a, b) => a - b);
    array2.sort((a, b) => a - b);
    if (array1.length !== array2.length) {
      return false; //diferentes
    }
    for (let i = 0; i < array1.length; i++) {
      const el1 = array1[i];
      const el2 = array1[i];
      if (el1 !== el2) {
        return false; // diferentes
      }
    }
    return true; // iguales
  };

  useEffect(() => {
    if (setValidacion && name) {
      setValidacion(name, validacionInput);
    }
  }, [validacionInput]);

  return (
    <div ref={refCont} className="external-cont-select-multiple">
      <ContInput icono={icono} label={label}>
        <div className="cont-select-multiple">
          <div
            className="select-multiple-selected-options"
            onClick={() => setOpen(!open)}
          >
            {selectedOptions.length} seleccionado(s)
          </div>
          {open && (
            <div className="cont-select-multiple-floating">
              <p className="label">{label}</p>
              {options &&
                options.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className={"select-multiple-item"}
                      onClick={() => handleClick(el.value)}
                    >
                      <span>{el.label}</span>
                      {selectedOptions.find((el1) => el1 === el.value) && (
                        <div className="ico-aprobado format-ico-select-multiple animar-zoom-min-to-max" />
                      )}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </ContInput>
    </div>
  );
}

export default SelectMultiple;
