import { useContext, useState } from "react";
import UserContext from "../../../context/UserContext";
import Button from "../../generic/Button";
import ContInput from "../../generic/ContInput";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import { Validar } from "../../../helpers/Validar";

let initialDatosForm = { anterior: "", nueva: "", verificacion: "" };

let initialFormValidado = {
  anterior: [false, ""],
  nueva: [false, ""],
  verificacion: [false, ""],
};

function FormContrasena({ cerrar }) {
  const [datosForm, setDatosForm] = useState(initialDatosForm);
  const [formValidado, setFormValidado] = useState(initialFormValidado);
  const { userData } = useContext(UserContext);
  const { mostrarPopup } = useContext(PopupContext);
  const [cargando, setCargando] = useState(false);

  const guardarContrasena = () => {
    if (!cargando)
      $.ajax({
        url: `${host}:${port}/api/usuario/cambioPas`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ id: userData.idusuario, ...datosForm }),
        beforeSend: function () {
          // setCargando(true);
        },
        success: function (data) {
          // setCargando(false);
          // console.log(data);
          mostrarPopup(1, data + " La sesión se cerrará");
          cerrar();
        },
        error: function (data) {
          // setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const handleGuardarContrasena = () => {
    if (validarForm()) {
      guardarContrasena();
    } else {
      mostrarPopup(
        2,
        "Revisa que todos los campos se hayan llenado correctamente"
      );
    }
  };

  const validarForm = () => {
    for (const key in formValidado) {
      if (Object.hasOwnProperty.call(formValidado, key)) {
        const el = formValidado[key];
        if (!el[0]) return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    setDatosForm({
      ...datosForm,
      [e.target.name]: e.target.value,
    });
    actualizarValidacion(e);
  };

  const actualizarValidacion = (e) => {
    let tempCampo = {};
    switch (e.target.name) {
      case "anterior":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "nueva":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "verificacion":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      default:
        break;
    }
    setFormValidado({
      ...formValidado,
      ...tempCampo,
    });
  };

  return (
    <div style={{ width: "300px" }}>
      <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <h3>Cambiar contraseña</h3>
        <div>
          <ContInput label={"Contraseña anterior"}>
            <input
              type="password"
              name="anterior"
              placeholder="1234"
              value={datosForm.anterior}
              onChange={handleChange}
            />
            {!formValidado.anterior[0] && (
              <div className="ico-advertencia  format-ico-form-validacion"></div>
            )}
          </ContInput>
          {!formValidado.anterior[0] && (
            <p className="texto-validacion">{formValidado.anterior[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Nueva contraseña"}>
            <input
              type="password"
              name="nueva"
              placeholder="1234"
              value={datosForm.nueva}
              onChange={handleChange}
            />
            {!formValidado.nueva[0] && (
              <div className="ico-advertencia  format-ico-form-validacion"></div>
            )}
          </ContInput>
          {!formValidado.nueva[0] && (
            <p className="texto-validacion">{formValidado.nueva[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Verifica la nueva contraseña"}>
            <input
              type="password"
              name="verificacion"
              placeholder="1234"
              value={datosForm.verificacion}
              onChange={handleChange}
            />
            {!formValidado.verificacion[0] && (
              <div className="ico-advertencia  format-ico-form-validacion"></div>
            )}
          </ContInput>
          {!formValidado.verificacion[0] && (
            <p className="texto-validacion">{formValidado.verificacion[1]}</p>
          )}
        </div>
        <div className="max-content" style={{ alignSelf: "end" }}>
          <Button
            label={"Guardar"}
            icono={"ico-guardar"}
            onClick={handleGuardarContrasena}
          />
        </div>
      </form>
    </div>
  );
}

export default FormContrasena;
