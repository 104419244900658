import { useEffect, useState, useCallback } from "react";
function useFocus() {
  const [isFocused, setIsFocused] = useState(true);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [handleBlur, handleFocus]);

  return { isFocused };
}

export default useFocus;
