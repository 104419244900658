function EquipoTermometro({ datosEquipo = {}, editar, detalles }) {
  return (
    <div className="table-row row-equipo animar-entrada">
      <span>{datosEquipo.marca}</span>
      <span>{datosEquipo.modelo}</span>
      <span>{datosEquipo.serie}</span>
      <span>{datosEquipo.codigo}</span>
      <span>
        {datosEquipo.liminferior} <b>{datosEquipo.abreviatura}</b> /{" "}
        {datosEquipo.limsuperior} <b>{datosEquipo.abreviatura}</b>
      </span>
      <span>
        {datosEquipo.resolucion} <b>{datosEquipo.abreviatura}</b>
      </span>
      <div className="row-cont-acciones">
        <div
          className="ico-documento format-ico-row-table row-accion"
          onClick={() => detalles(datosEquipo.idequipo)}
          title="Ver detalles del equipo"
        ></div>
        <div
          className="ico-lapiz format-ico-row-table row-accion"
          onClick={() => editar(datosEquipo.idequipo)}
        ></div>
      </div>
    </div>
  );
}

export default EquipoTermometro;

// titulo de las filas de la tabla de equipos de peso patron
function EquipoTermometroTitulo() {
  return (
    <div className="table-row table-row-title">
      <span>Marca</span>
      <span>Modelo</span>
      <span>Serie</span>
      <span>Código</span>
      <span>Rango</span>
      <span>Resolución</span>
      <span style={{ textAlign: "center" }}>Acciones</span>
    </div>
  );
}

export { EquipoTermometroTitulo };
