import Button from "../../../../generic/Button";
import { useState, useEffect } from "react";
import ContInput from "../../../../generic/ContInput";

export interface IVerificacionNorma {
  excentricidad: boolean;
  linealidad: boolean;
  repetibilidad: boolean;
}

interface Props {
  data: { observacion: string };
  setData: (observaciones: string) => void;
  permitirEdicion: boolean;
  setEditando: (editando: boolean) => void;
}

function Observaciones({ data, permitirEdicion, setData, setEditando }: Props) {
  const [editar, setEditar] = useState(false);
  const [formData, setFormData] = useState({
    observacion: data?.observacion ?? "",
  });

  useEffect(() => {
    setFormData({ observacion: data?.observacion ?? "" });
  }, [data]);

  useEffect(() => {
    setEditando(editar);
  }, [editar]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const cancelar = () => {
    setEditar(false);
    setFormData({ observacion: data.observacion });
  };

  const guardar = () => {
    setEditar(false);
    setData(formData.observacion);
  };

  return (
    <div className="cont-card">
      <div className="calibracion-titulo-btn">
        <h3>Observaciones</h3>
        {permitirEdicion && (
          <>
            {editar ? (
              <div className="cont-flex-horizontal-gap">
                <Button label="Guardar" onClick={guardar} nivel={2} />
                <Button
                  label="Cancelar"
                  onClick={cancelar}
                  color="ROJO"
                  nivel={2}
                />
              </div>
            ) : (
              <Button
                label="Editar"
                onClick={() => setEditar(true)}
                nivel={2}
              />
            )}
          </>
        )}
      </div>
      <div>
        {editar ? (
          <>
            <div>
              <ContInput>
                <input
                  type="text"
                  pattern="[0-9]*"
                  name="observacion"
                  value={formData.observacion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </ContInput>
            </div>
          </>
        ) : (
          <p>
            {!data?.observacion || data?.observacion === ""
              ? "-"
              : data.observacion}
          </p>
        )}
      </div>
    </div>
  );
}

export default Observaciones;
