import "./ContInput.css";
import TypeIcon from "../../types/TypeIcon";

interface Props {
  label?: string;
  children: React.ReactNode | JSX.Element | JSX.Element[];
  icono?: TypeIcon;
  disabled?: boolean;
  textarea?: boolean;
  style?: React.CSSProperties;
}

function ContInput({
  label = "",
  children,
  icono,
  disabled = false,
  textarea = false,
  style,
}: Props) {
  return (
    <div className={`cont-input ${textarea ? "textArea" : ""}`}>
      {label && label !== "" && <p>{label}</p>}
      <div
        style={style}
        className={"cont-input-ico border " + (disabled ? "disabled" : "")}
      >
        {icono ? (
          <div className={icono + " format-ico-form"}></div>
        ) : (
          <span className="relleno-input-no-ico"></span>
        )}
        <div className={`input-content`}>{children}</div>
      </div>
    </div>
  );
}

export default ContInput;
