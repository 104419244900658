import { useState, useEffect, useContext, useRef } from "react";
import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";
import ContInput from "../../../../generic/ContInput";
import SelectMultiple from "../../../../generic/SelectMultiple";
import PopupContext from "../../../../../context/PopupContext";
import Button from "../../../../generic/Button";
import styles from "./FormDatosServicio.module.css";
import {
  objValidadoInicial,
  objValidado,
  ValidarT,
  validarForm,
} from "../../../../../helpers/ValidarT";
import MensajesUI from "../../../../../helpers/MensajesUI";
import { IValidacion } from "../../../../../helpers/ValidarT";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserContext from "../../../../../context/UserContext";

interface ITecnico {
  nombre: string;
  idusuario: number;
}

interface IData {
  fechaserviciodesde: Date | null;
  fechaserviciohasta: Date | null;
  tecnicosorden: { idusuario: number }[];
}

const initialFormData: IData = {
  fechaserviciodesde: null,
  fechaserviciohasta: null,
  tecnicosorden: [],
};

const initialFormValidacion = {
  fechaserviciodesde: objValidado,
  fechaserviciohasta: objValidado,
  tecnicosorden: objValidadoInicial,
};

interface Props {
  data: IData;
  cerrar: () => void;
  idordentrabajo: number;
  setHuboCambio?: (value: boolean) => void;
  tecnicos: ITecnico[];
  setEditar: (data: boolean) => void;
}

// formulario para editar los datos del servicio como la fecha de inicio y fin, así como los técnicos asignados
function FormDatosServicio({
  data,
  cerrar,
  idordentrabajo,
  setHuboCambio,
  tecnicos,
  setEditar,
}: Props) {
  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);
  const [formData, setFormData] = useState(initialFormData);
  const [cargando, setCargando] = useState(false);
  const formValidacion = useRef(initialFormValidacion);

  useEffect(() => {
    // valida los datos antes de asignarlos al formulario
    setFormData({
      fechaserviciodesde: data.fechaserviciodesde
        ? new Date(
            new Date(data.fechaserviciodesde).setHours(
              new Date(data.fechaserviciodesde).getHours() + 5
            )
          )
        : null,
      fechaserviciohasta: data.fechaserviciohasta
        ? new Date(
            new Date(data.fechaserviciohasta).setHours(
              new Date(data.fechaserviciohasta).getHours() + 5
            )
          )
        : null,
      tecnicosorden: data.tecnicosorden,
    });
  }, [data]);

  const handleChangeDate = (name: string, value: Date | null) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    actualizarValidacion(name, value ? value.toLocaleDateString() : "");
  };

  const handleChangeSM = (name: string, value: number[]) => {
    console.log(name, value);
    setFormData({ ...formData, [name]: value });
    if (value.length > 0) {
      formValidacion.current = {
        ...formValidacion.current,
        tecnicosorden: objValidado,
      };
    } else {
      formValidacion.current = {
        ...formValidacion.current,
        tecnicosorden: {
          mensaje: "Selecciona al menos un técnico",
          validado: false,
        },
      };
    }
  };

  const setValidacionSM = (name: string, value: IValidacion) => {
    formValidacion.current = { ...formValidacion.current, [name]: value };
  };

  const actualizarValidacion = (name: string, value: string) => {
    let tempCampo = {};
    switch (name) {
      case "fechaserviciodesde":
        tempCampo = {
          [name]: ValidarT.general(value),
        };
        break;
      case "fechaserviciohasta":
        tempCampo = {
          [name]: ValidarT.general(value),
        };
        break;
      default:
        break;
    }
    formValidacion.current = {
      ...formValidacion.current,
      ...tempCampo,
    };
  };

  const handleGuardar = () => {
    if (validarForm(formValidacion.current)) {
      guardar();
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  const guardar = () => {
    if (setHuboCambio) {
      setHuboCambio(true);
    }
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/tecnicosfechas`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        idordentrabajo,
        fechaserviciodesde: new Date(
          new Date(formData.fechaserviciodesde ?? 0).setHours(5)
        ).toLocaleString("sv"),
        fechaserviciohasta: new Date(
          new Date(formData.fechaserviciohasta ?? 0).setHours(5)
        ).toLocaleString("sv"),
        usuarios: formData.tecnicosorden,
        idmodificador: userData.idusuario,
      }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        mostrarPopup(1, data.data);
        cerrar();
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <div className={styles.contFormDatosServicio + " animar-entrada"}>
      <div className="titulo-accion">
        <h4 className="titulo-seccion-nueva-orden">Datos del servicio</h4>
        <Button
          label="Cancelar"
          icono="ico-cerrar"
          onClick={() => {
            setEditar(false);
          }}
          color="ROJO"
          nivel={2}
        />
      </div>
      <div className="cont-detalle-fechas">
        <ContInput label={"Desde"}>
          <DatePicker
            selected={
              formData.fechaserviciodesde
                ? // añado 5 horas para que se muestre la fecha correcta de acuerdo al timezone de Ecuador
                  new Date(new Date(formData.fechaserviciodesde).setHours(5))
                : null
            }
            todayButton
            onChange={(date) => handleChangeDate("fechaserviciodesde", date)}
          />
        </ContInput>
        <ContInput label={"Hasta"}>
          <DatePicker
            selected={
              formData.fechaserviciohasta
                ? new Date(new Date(formData.fechaserviciohasta).setHours(5))
                : null
            }
            todayButton
            onChange={(date) => handleChangeDate("fechaserviciohasta", date)}
          />
        </ContInput>
        <SelectMultiple
          name="tecnicosorden"
          icono={"ico-usuario"}
          label={"Técnicos asignados"}
          value={formData.tecnicosorden.map((el) => el.idusuario)}
          options={tecnicos.map((el) => {
            return { label: el.nombre, value: el.idusuario };
          })}
          setValue={handleChangeSM}
          setValidacion={setValidacionSM}
        />
      </div>
      <Button
        label="Guardar"
        icono="ico-guardar"
        onClick={handleGuardar}
        cargando={cargando}
      />
    </div>
  );
}

export default FormDatosServicio;
