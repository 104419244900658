import styles from "./Hub.module.css";
import { useRef, useEffect, useContext, useState } from "react";
import Item from "./hub/Item";
import { SocketContext } from "../../context/SocketContext.js";
import Tabs from "../generic/Tabs";
import MensajeCentro from "../generic/MensajeCentro";
import TypeNotificacion from "../../types/TypeNotificacion";

interface Props {
  active: boolean;
  cerrar: () => void;
}

function Hub({ active, cerrar }: Props) {
  const refCont = useRef<HTMLDivElement>(null);
  const {
    notificaciones,
  }: {
    notificaciones: TypeNotificacion[];
  } = useContext(SocketContext);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (refCont.current && !refCont.current.contains(e.target as Node)) {
        cerrar();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [refCont]);

  return (
    <div
      className={`${styles.contHub} ${
        active && styles.active
      } animar-entrada-right-to-left`}
      ref={refCont}
    >
      <div className={styles.titulo}>
        <h4>Notificaciones</h4>
        <Tabs
          lista={["Pendientes", "Atendidas"]}
          selected={tab}
          setSelected={setTab}
        />
      </div>
      <div
        className={`${styles.contItems} ${
          tab === 0 &&
          notificaciones.filter((el) => el.estado === tab).length > 0 &&
          styles.notificacionesPendientes
        }`}
      >
        {notificaciones.filter((el) => el.estado === tab).length > 0 ? (
          <>
            {notificaciones
              .filter((el) => el.estado === tab)
              .map((el, i) => (
                <Item key={i} data={el} cerrar={cerrar} />
              ))}
          </>
        ) : (
          <div className={styles.mensajeCentroHub}>
            <MensajeCentro
              mensaje={`No hay notificaciones ${
                tab === 0 ? "pendientes" : "atendidas"
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Hub;
