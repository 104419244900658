import inicialPinprexat from "../media/ico/inicialPinprexat.svg";
import notificationAudio from "../media/sounds/notification.wav";

interface Props {
  titulo: string;
  mensaje: string;
  ruta: string;
  onClick?: () => void;
}

const BrowserNotification = ({ titulo, mensaje, ruta, onClick }: Props) => {
  if (ruta !== "/monitoreo") {
    const audio = new Audio(notificationAudio);
    audio.play();
  }
  let notification = new Notification(titulo, {
    icon: inicialPinprexat,
    body: mensaje,
  });
  notification.onclick = function () {
    window.parent.parent.focus();
    window.location.href = `/#${ruta}`;
    if (onClick) onClick();
  };
};

export default BrowserNotification;
