import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Button from "../../generic/Button";
import Modal from "../../generic/Modal";
import FormNuevaOrdenTrabajo from "./ordenestrabajo/FormNuevaOrdenTrabajo";
import "./OrdenesDeTrabajo.css";
import OrdenTrabajo from "./ordenestrabajo/OrdenTrabajo";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import { useParams } from "react-router-dom";
import DetalleOrdenTrabajo from "./ordenestrabajo/DetalleOrdenTrabajo";
import PopupContext from "../../../context/PopupContext";
import useItemsPP from "../../../hooks/useItemsPP";
import Loader from "../../generic/Loader";
import UserContext from "../../../context/UserContext";
import useFocus from "../../../hooks/useFocus";
import FSModal from "../../generic/FSModal";
import Paginador from "../../generic/Paginador";
import MensajeCentro from "../../generic/MensajeCentro";
import Calibraciones from "./Calibraciones";
import AyudaEstados from "./ordenestrabajo/AyudaEstados";
import { motion } from "framer-motion";

function OrdenesDeTrabajo() {
  const { idcliente } = useParams();

  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);
  const itemsPP = useItemsPP(320);
  const { isFocused } = useFocus();

  const [mounted, setMounted] = useState(true);
  const [activoModal, setActivoModal] = useState(false);
  const [activoDetalleOrden, setActivoDetalleOrden] = useState(false);
  const [ordenesTrabajo, setOrdenesTrabajo] = useState([]);
  const [idOrdenTrabajo, setIdOrdenTrabajo] = useState(0);
  const [estadoCotizacion, setEstadoCotizacion] = useState(0);
  const [idOrdenDuplicar, setIdOrdenDuplicar] = useState(0);
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [huboCambio, setHuboCambio] = useState(false);
  const [idotCert, setIdotCert] = useState(0);
  const [modalCert, setModalCert] = useState(false);

  const firstLoad = useRef(true);

  useEffect(() => {
    if (isFocused) obtenerOrdenes();
  }, [isFocused, idcliente]);

  useEffect(() => {
    if (huboCambio) {
      setHuboCambio(false);
      obtenerOrdenes();
    }
  }, [huboCambio]);

  const obtenerOrdenes = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/ordenes/${idcliente}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        if (firstLoad.current) setMostrarCargando(true);
      },
      success: function (data) {
        setMostrarCargando(false);
        if (mounted)
          setOrdenesTrabajo(
            data.sort((a, b) =>
              a.idordentrabajo > b.idordentrabajo
                ? -1
                : b.idordentrabajo > a.idordentrabajo
                ? 1
                : 0
            )
          );
      },
      error: function (data) {
        setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
    firstLoad.current = false;
  }, [idcliente, mostrarPopup, mounted]);

  const verDetalleOrden = (id) => {
    setIdOrdenTrabajo(id);
    setActivoDetalleOrden(true);
  };
  const editarOrden = (id, estado) => {
    setIdOrdenTrabajo(id);
    setEstadoCotizacion(estado);
    setActivoModal(true);
  };
  const duplicarOrden = (id) => {
    setIdOrdenTrabajo(0);
    setEstadoCotizacion(0);
    setIdOrdenDuplicar(id);
    setActivoModal(true);
  };

  const verCertificados = (id) => {
    setIdotCert(id);
    setModalCert(true);
  };

  const cerrarCertificados = () => {
    setIdotCert(0);
    setModalCert(false);
    obtenerOrdenes();
  };

  const cerrarDOT = useCallback(() => {
    obtenerOrdenes();
    setActivoDetalleOrden(false);
    setIdOrdenTrabajo(0);
    setEstadoCotizacion(0);
  }, [obtenerOrdenes]);

  return (
    <>
      <div>
        {userData.idperfil === 4 ? (
          <div className="encabezado-nombre-barra-buscar">
            <Button
              label={"Nueva orden"}
              icono={"ico-anadir"}
              onClick={() => {
                setActivoModal(true);
              }}
            />
            <span></span>
            {/* <div>Filtros</div> */}
          </div>
        ) : (
          <></>
        )}
        {mostrarCargando ? (
          <Loader fullScreen />
        ) : (
          <>
            <Paginador
              pages={Array.from(
                {
                  length: Math.ceil(
                    (Array.isArray(ordenesTrabajo)
                      ? ordenesTrabajo.length
                      : 0) / (itemsPP > 0 ? itemsPP : 1)
                  ),
                },
                (_, i) => i + 1
              )}
              selected={pagina}
              setSelected={setPagina}
            />
            <div className="cont-contenido-ordenes">
              {ordenesTrabajo ? (
                ordenesTrabajo
                  .slice(pagina * itemsPP, (pagina + 1) * itemsPP)
                  .map((el) => {
                    return (
                      <motion.div key={el.idordentrabajo} layout>
                        <OrdenTrabajo
                          datosOrden={el}
                          verDetalle={verDetalleOrden}
                          editar={editarOrden}
                          duplicar={duplicarOrden}
                          actualizarOrdenes={obtenerOrdenes}
                          verCertificados={verCertificados}
                        />
                      </motion.div>
                    );
                  })
              ) : (
                <></>
              )}
              {(Array.isArray(ordenesTrabajo) ? ordenesTrabajo.length : 0) <=
              0 ? (
                <MensajeCentro mensaje="No existen órdenes de trabajo" />
              ) : (
                <></>
              )}
            </div>
            {/* <div className="cont-contenido-ordenes animar-zoom-min-to-max">
              {ordenesTrabajo.map((el, i) => {
                return (
                  <OrdenTrabajo
                    key={i}
                    datosOrden={el}
                    verDetalle={verDetalleOrden}
                    editar={editarOrden}
                    duplicar={duplicarOrden}
                  />
                );
              })}
            </div> */}
          </>
        )}
      </div>
      <AyudaEstados />
      {(userData.idperfil === 2 || userData.idperfil === 5) && (
        <FSModal
          activo={modalCert}
          cerrar={cerrarCertificados}
          titulo="Revisión de certificados"
        >
          <Calibraciones idordentrabajo={idotCert} />
        </FSModal>
      )}
      {userData.idperfil === 4 && (
        <FSModal
          activo={activoModal}
          cerrar={() => {
            setActivoModal(false);
            setIdOrdenTrabajo(0);
            setIdOrdenDuplicar(0);
            setEstadoCotizacion(0);
          }}
          titulo={
            idOrdenTrabajo > 0 && (estadoCotizacion === 0 || !estadoCotizacion)
              ? "Editando cotización"
              : idOrdenTrabajo > 0 && estadoCotizacion > 0
              ? "Actualizando cotización"
              : "Nueva cotización"
          }
        >
          <FormNuevaOrdenTrabajo
            activo={activoModal}
            cerrar={() => {
              obtenerOrdenes();
              setActivoModal(false);
              setIdOrdenTrabajo(0);
              setIdOrdenDuplicar(0);
              setEstadoCotizacion(0);
            }}
            idOrdenTrabajo={idOrdenTrabajo}
            idOrdenDuplicar={idOrdenDuplicar}
            estadoCotizacion={estadoCotizacion}
          />
        </FSModal>
      )}
      <Modal
        activo={activoDetalleOrden}
        cerrar={() => {
          setActivoDetalleOrden(false);
          setIdOrdenTrabajo(0);
          setEstadoCotizacion(0);
        }}
      >
        <DetalleOrdenTrabajo
          activo={activoDetalleOrden}
          cerrar={cerrarDOT}
          idOrdenTrabajo={idOrdenTrabajo}
          setHuboCambio={setHuboCambio}
        />
      </Modal>
    </>
  );
}

export default OrdenesDeTrabajo;
