import "./Usuario.css";

function Usuario({ datos, abrirForm }) {
  const textEstado = () => {
    return datos.estado === 1 ? "Activo" : "Inactivo";
  };

  const colorEstado = () => {
    switch (datos.estado) {
      case 0:
        return "color-estado-rojo";
      case 1:
        return "color-estado-verde";
      default:
        return "color-estado-default";
    }
  };

  return (
    <div
      className={"cont-card cont-usuario animar-hover"}
      onClick={() => abrirForm(datos.idusuario)}
    >
      {/* <p className="usuario-inicial">{datos.nombre[0].toUpperCase()}</p> */}
      <div
        className={
          "cont-ico-usuario-card-config borde-usuario-" + datos.idperfil
        }
      >
        <div className="ico-usuario format-ico-usuario-config"></div>
      </div>
      <div className="usuario-textos">
        <p>{datos.nombre}</p>
        {/* <p>
          {datos.perfil.toUpperCase()[0] +
            datos.perfil.toLowerCase().substring(1)}
        </p> */}
        <div className="usuario-estado">
          <div className={"cont-circulo-estado " + colorEstado()}></div>
          <p>{textEstado()}</p>
        </div>
      </div>
    </div>
  );
}

export default Usuario;
