import styles from "./GrupoClientes.module.css";

export interface IDatosGrupo {
  idgrupo: number;
  empresa: string;
}

interface Props {
  grupo: IDatosGrupo;
  abrirGrupo: (idgrupo: number) => void;
}

function GrupoClientes({ grupo, abrirGrupo }: Props) {
  return (
    <div
      className={`animar-hover cont-card ${styles.contPrincipal}`}
      onClick={() => abrirGrupo(grupo.idgrupo)}
    >
      <h4>{grupo.empresa}</h4>
      <div className={`${styles.clientes}`}>
        <div className={`${styles.cliente}`} />
        <div className={`${styles.cliente}`} />
        <div className={`${styles.cliente}`} />
        <div className={`${styles.cliente}`} />
        <div className={`${styles.cliente}`} />
      </div>
    </div>
  );
}

export default GrupoClientes;
