import { useState, useEffect, useRef } from "react";
import { IValidacion, IValidar, ValidarT } from "../helpers/ValidarT";

interface IOptions {
  minimo: number;
  maximo: number;
}

export interface IConfigValidacion {
  [key: string]: {
    validar: keyof IValidar;
    opcional: boolean;
    options?: IOptions;
  };
}

// interface IFormValidado {
//   [key: string]: IValidacion;
// }

function useForm<
  T extends Object,
  //   U extends { [k in keyof T]: { validar: keyof IValidar; opcional: boolean } }
  U extends {
    [key in keyof T | string]: {
      validar: keyof IValidar;
      opcional: boolean;
      options?: IOptions;
    };
  },
  IFormValidado extends { [key in keyof T]: IValidacion }
>(
  // function useForm<T extends Object, U extends T & IConfigValidacion>(
  initialFormData: T,
  tipoValidacion: U
) {
  const inicializarValidacion = (tipoValidacionA: U) => {
    let tempValidacion = {} as IFormValidado;
    for (const key in tipoValidacionA) {
      if (Object.prototype.hasOwnProperty.call(tipoValidacionA, key)) {
        const el = tipoValidacionA[key];
        tempValidacion = {
          ...tempValidacion,
          [key]: { validado: el.opcional, mensaje: "" },
        };
      }
    }
    return tempValidacion;
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formValidacion, setFormValidacion] = useState<IFormValidado>(() =>
    inicializarValidacion(tipoValidacion)
  );
  const [formValidado, setFormValidado] = useState(false);
  const tipoValidacion1 = useRef(tipoValidacion);
  // const oldTipoValidacion1 = useRef(tipoValidacion);

  const handleChange = (name: keyof T, value: string | number) => {
    setFormData((fD) => ({
      ...fD,
      [name]: value,
    }));
    handleValidacion(name as keyof U, value);
  };

  const handleValidacion = (name: keyof U, value: string | number) => {
    const validacion = ValidarT[tipoValidacion1.current[name].validar](
      value.toString(),
      tipoValidacion1.current[name].opcional
    );
    setFormValidacion((fV) => ({ ...fV, [name]: validacion }));
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setFormValidacion(inicializarValidacion(tipoValidacion1.current));
  };

  useEffect(() => {
    let tempValidado = true;
    for (const key in formValidacion) {
      if (Object.prototype.hasOwnProperty.call(formValidacion, key)) {
        const el = formValidacion[key];
        if (el.validado === false) {
          tempValidado = false;
          break;
        }
      }
    }
    setFormValidado(tempValidado);
  }, [formValidacion]);

  const setFormData1 = (data: T) => {
    const data1 = formatearData(data);
    setFormData(data1);
    validarTodo(data1);
  };

  const formatearData = (data: T): T => {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const el = data[key];
        if (typeof initialFormData[key] === "number") {
          data = { ...data, [key]: el ?? 0 };
        } else {
          data = { ...data, [key]: el ?? "" };
        }
      }
    }
    return data;
  };

  const validarTodo = (data: T) => {
    let tempFormValidacion: any = {};
    let key: keyof T & keyof U;
    for (key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const el = data[key] as any;
        if (tipoValidacion1.current[key]) {
          tempFormValidacion[key] = {
            mensaje: "",
            validado: ValidarT[tipoValidacion1.current[key].validar](
              el.toString(),
              tipoValidacion1.current[key].opcional
            ).validado,
          };
          // tempFormValidacion[key] = Validar[tipoValidacion1[key].validar](
          //   el.toString(),
          //   tipoValidacion1[key].opcional
          // )
        }
      }
    }
    setFormValidacion(tempFormValidacion);
  };

  // useEffect(() => {
  //   let validar = false;
  //   for (const key in oldTipoValidacion1.current) {
  //     if (
  //       Object.prototype.hasOwnProperty.call(oldTipoValidacion1.current, key)
  //     ) {
  //       const el = oldTipoValidacion1.current[key];
  //       const el1 = tipoValidacion1.current[key];
  //       if (el1.opcional !== el.opcional) {
  //         validar = true;
  //         break;
  //       }
  //     }
  //   }
  //   console.log("uef", validar);
  //   if (validar) {
  //     console.log(146, tipoValidacion1);
  //     validarTodo(formData);
  //   }
  // }, [tipoValidacion1]);

  const setOptional = (data: { key: keyof T; opcional: boolean }[]) => {
    let object: IConfigValidacion = {};
    for (const el of data) {
      object[el.key as keyof IConfigValidacion] = {
        validar: tipoValidacion1.current[el.key as keyof U].validar,
        opcional: el.opcional,
      };
    }
    // let tempTV = { ...tipoValidacion1.current };
    // for (const key in object) {
    //   if (Object.prototype.hasOwnProperty.call(object, key)) {
    //     delete tempTV[key];
    //   }
    // }
    // oldTipoValidacion1.current = { ...tipoValidacion1.current };
    // console.log(179, "a;sdlkfja;sldkjf", { ...tempTV, ...object });
    tipoValidacion1.current = { ...tipoValidacion1.current, ...object };
    validarTodo(formData);
  };

  return {
    formData,
    handleChange,
    setFormData: setFormData1,
    formValidacion,
    formValidado,
    resetForm,
    setOptional,
  };
}

export default useForm;
