export interface IVerificacionNorma {
  excentricidad: 0 | 1;
  linealidad: 0 | 1;
  repetibilidad: 0 | 1;
}

interface Props {
  data: IVerificacionNorma;
}

function VerificacionNorma({ data }: Props) {
  return (
    <div className="cont-card">
      <h3>Verificación respecto a la norma</h3>
      <div>
        <div className="table-row table-row-title center-hd">
          <span>Pruebas</span>
          <span>Excentricidad</span>
          <span>Linealidad e histéresis</span>
          <span>Repetibilidad</span>
        </div>
        <div className="table-row center-hd">
          <span>e.m.p.</span>
          <span>{data.excentricidad === 1 ? "<=" : ">"}</span>
          <span>{data.linealidad === 1 ? "<=" : ">"}</span>
          <span>{data.repetibilidad === 1 ? "<=" : ">"}</span>
        </div>
      </div>
    </div>
  );
}

export default VerificacionNorma;
