import LabelText from "../../../../../generic/LabelText";
import { ICostos } from "../../FormNuevaOrdenTrabajo";

interface Props {
  costos: ICostos;
}

function IndicadorPrecios({ costos }: Props) {
  return (
    <div>
      <LabelText
        label={"Visita técnica"}
        text={"$" + costos.visitatecnica.toFixed(2)}
      />
      <LabelText label={"Equipos"} text={"$" + costos.equipos.toFixed(2)} />
      {costos.repuestos > 0 && (
        <LabelText
          label={"Repuestos"}
          text={"$" + costos.repuestos.toFixed(2)}
        />
      )}
      <LabelText
        label={"Serv. Ext."}
        text={"$" + costos.serviciosexternos.toFixed(2)}
      />
      <LabelText label={"Subtotal"} text={"$" + costos.subtotal.toFixed(2)} />
      <LabelText
        label={`Descuento`}
        text={`${costos.descuento ? "$" + costos.descuento.toFixed(2) : "-"} ${
          costos.descuentoP ? `(${costos.descuentoP.toFixed()}%)` : ""
        }`}
      />
      <LabelText
        label={"Subtotal 2"}
        text={
          costos.subtotal2 !== null && costos.subtotal2 !== undefined
            ? "$" + costos.subtotal2.toFixed(2)
            : "-"
        }
      />
      <LabelText label={"IVA"} text={"$" + costos.iva.toFixed(2)} />

      <LabelText
        label={"Total"}
        text={"$" + costos.total.toFixed(2)}
        color={costos.total <= 0 || isNaN(costos.total) ? "red" : ""}
      />
    </div>
  );
}

export default IndicadorPrecios;
