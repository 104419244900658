import $ from "jquery";
import { host, port } from "../../helpers/DbData";
import "./Pesos.css";
import { useContext, useEffect, useState } from "react";
import PopupContext from "../../context/PopupContext";
import Loader from "../../components/generic/Loader";
import Acreditacion from "../../components/configuracion/pesos/Acreditacion";
import Modal from "../../components/generic/Modal";
import FormNuevoJuego from "../../components/configuracion/pesos/FormNuevoJuego";
import JuegoPesas from "../../components/configuracion/pesos/JuegoPesas";
import Button from "../../components/generic/Button";

function Pesos() {
  const [activoModal, setActivoModal] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [juegosPesas, setJuegosPesas] = useState([]);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    obtenerJuegosPesas();
  }, []);

  const obtenerJuegosPesas = () => {
    $.ajax({
      url: `${host}:${port}/api/pesas/juegoPesas`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setJuegosPesas(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data.responseJSON.data);
        const mensaje = data.responseJSON.data;
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(2, mensaje);
      },
    });
  };

  return (
    <>
      <div className="animar-zoom-min-to-max cont-pesos">
        {cargando ? (
          <Loader />
        ) : (
          <>
            <div className="encabezado-nombre-barra-buscar">
              <h3 className="titulo-pagina">Trazabilidad</h3>
              <span></span>{" "}
              <div style={{ width: "max-content", justifySelf: "flex-end" }}>
                <Button
                  label="Nuevo juego"
                  icono="ico-anadir"
                  onClick={() => {
                    setActivoModal(true);
                  }}
                />
              </div>
            </div>

            <div className="cont-contenido-pesos animar-entrada">
              {juegosPesas.map((el, i) => {
                return (
                  <JuegoPesas
                    key={i}
                    datosJuego={el}
                    actualizar={obtenerJuegosPesas}
                  />
                );
              })}
              <Acreditacion />
            </div>
          </>
        )}
      </div>
      <Modal
        activo={activoModal}
        cerrar={() => {
          setActivoModal(false);
        }}
      >
        <FormNuevoJuego
          cerrar={() => {
            setActivoModal(false);
            obtenerJuegosPesas();
          }}
        />
      </Modal>
    </>
  );
}

export default Pesos;
