import "./LabelState.css";

interface Props {
  label: string;
  state: boolean;
}

function LabelState({ label, state }: Props) {
  return (
    <div className="cont-label-state">
      <p className={"label"}>{label}</p>
      <div
        className={
          "state format-ico-label-state " +
          (state ? "ico-aprobado" : "ico-error")
        }
      ></div>
    </div>
  );
}

export default LabelState;
