import { useEffect, useState } from "react";
import "./Modal.css";

interface Props {
  activo?: boolean;
  cerrar?: () => void;
  children: React.ReactNode;
  zIndex?: number;
  temblar?: boolean;
}

function Modal({
  activo = false,
  cerrar,
  children,
  zIndex = 100,
  temblar = false,
}: Props) {
  const [temblarManual, setTemblarManual] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activo]);

  useEffect(() => {
    if (temblarManual) {
      setTemblarManual(false);
    }
  }, [temblarManual]);

  return (
    <>
      <div
        style={zIndex ? { zIndex } : {}}
        className={
          "cont-modal " +
          (activo ? " active " : "") +
          (activo && temblar && !temblarManual ? "temblar" : "")
        }
      >
        {children}
      </div>
      <div
        style={zIndex ? { zIndex: zIndex - 10 } : {}}
        className={"modal-fondo " + (activo ? "active" : "")}
        onClick={cerrar ? cerrar : () => setTemblarManual(true)}
      />
    </>
  );
}

export default Modal;
