import { useState } from "react";
import CardCliente from "../../components/clientes/CardCliente";
import GrupoClientes from "../../components/clientes/GrupoClientes";
import NuevoGrupo from "../../components/clientes/NuevoGrupo";
import Button from "../../components/generic/Button";
import Modal from "../../components/generic/Modal";

function Grupos() {
  const [modal0, setModal0] = useState(false);

  const abrirGrupo = (idgrupo: number) => {};

  return (
    <>
      <div className="encabezado-nombre-barra-buscar">
        <Button
          label={"Nuevo grupo"}
          icono={"ico-anadir"}
          onClick={() => setModal0(true)}
        />
        {/* <Paginador
          pages={
            numPag !== 0 ? Array.from({ length: numPag }, (_, i) => i + 1) : []
          }
          selected={pagina}
          setSelected={setPagina}
        /> */}
      </div>
      <div className="cont-contenido-clientes">
        <GrupoClientes
          grupo={{ idgrupo: 1, empresa: "Pronaca" }}
          abrirGrupo={abrirGrupo}
        />
      </div>
      <Modal activo={modal0} cerrar={() => setModal0(false)}>
        <NuevoGrupo />
      </Modal>
    </>
  );
}

export default Grupos;
