import { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../generic/Button";
import ContInput from "../../generic/ContInput";
import Modal from "../../generic/Modal";
import Tabs from "../../generic/Tabs";
import FormNuevoEquipo from "./equipos/FormNuevoEquipo";
import "./Equipos.css";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import EquipoBalanza from "./equipos/filas/EquipoBalanza";
import EquipoPesoPatron from "./equipos/filas/EquipoPesoPatron";
import EquipoTermometro from "./equipos/filas/EquipoTermometro";
import { EquipoBalanzaTitulo } from "./equipos/filas/EquipoBalanza";
import { EquipoPesoPatronTitulo } from "./equipos/filas/EquipoPesoPatron";
import { EquipoTermometroTitulo } from "./equipos/filas/EquipoTermometro";
import PopupContext from "../../../context/PopupContext";
import DetalleEquipo from "./equipos/DetalleEquipo";
import Loader from "../../generic/Loader";
import BarraBusqueda from "../../generic/BarraBusqueda";
import MensajeCentro from "../../generic/MensajeCentro";
import ConfirmContext from "../../../context/ConfirmContext";
import FormOrdenesAfectadas, { IOrdenAfectada } from "../FormOrdenesAfectadas";

// let tabs = ["Balanzas", "Pesos patrón", "Termómetros"];
// let tabs = ["Balanzas"];

let initialEquipos = {
  balanzas: [],
  pesospatron: [],
  termometros: [],
};

function Equipos() {
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);
  const { mostrarConfirm } = useContext(ConfirmContext);

  const [pantallaActiva, setPantallaActiva] = useState(0);
  const [activoNuevoEquipo, setActivoNuevoEquipo] = useState(false);
  const [activoDetallesEquipo, setActivoDetallesEquipo] = useState(false);
  const [equiposCliente, setEquiposCliente] = useState(initialEquipos);
  const [idEquipo, setIdEquipo] = useState(0);
  const [duplicar, setDuplicar] = useState(false);
  const { idcliente } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [tabs, setTabs] = useState(["Balanzas", "Pesos patrón"]);

  const [cargando3, setCargando3] = useState(false);

  useEffect(() => {
    const cantBalanzas = equiposCliente["balanzas"].length;
    const cantPesosPatron = equiposCliente["pesospatron"].length;
    setTabs([
      `Balanzas (${cantBalanzas})`,
      `Pesos patrón (${cantPesosPatron})`,
    ]);
  }, [equiposCliente]);

  useEffect(() => {
    obtenerEquipos();
  }, []);

  useEffect(() => {
    if (!activoNuevoEquipo) setIdEquipo(0);
  }, [activoNuevoEquipo]);

  const obtenerEquipos = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/getEquipos`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        idcliente,
      }),
      beforeSend: function () {
        setMostrarCargando(true);
      },
      success: function (data) {
        setMostrarCargando(false);
        setEquiposCliente(data);
      },
      error: function (data) {
        setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const editarEquipo = (idequipo) => {
    setIdEquipo(idequipo);
    setActivoNuevoEquipo(true);
  };

  const duplicarEquipo = (idequipo) => {
    setDuplicar(true);
    editarEquipo(idequipo);
  };

  const handleEliminar = async (idequipo) => {
    if (await mostrarConfirm("¿Deseas eliminar el equipo?")) {
      eliminar(idequipo);
    }
  };

  const eliminar = (idequipo) => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/equipo`,
      // userData.idperfil === 5
      //   ? `${host}:${port}/api/certificado/revisioncert`
      //   : `${host}:${port}/api/ordenTrabajo/ordenesclienterevision`,
      type: "delete",
      dataType: "json",
      contentType: "application/json",
      // data: JSON.stringify({ idcliente, idusuario: userData.idusuario }),
      data: JSON.stringify({ idequipo }),
      beforeSend: function () {
        setCargando3(true);
      },
      success: function (data) {
        setCargando3(false);
        mostrarPopup(1, data.message);
      },
      error: function (data) {
        setCargando3(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const detallesEquipo = (idequipo) => {
    setIdEquipo(idequipo);
    setActivoDetallesEquipo(true);
  };

  const [ordenesAfectadas, setOrdenesAfectadas] = useState([]);
  const [modalOrdenesAfectadas, setModalOrdenesAfectadas] = useState(false);
  const seleccionarOrdenesAfectadas = useCallback((ordenesAfectadas) => {
    setModalOrdenesAfectadas(true);
    setOrdenesAfectadas(ordenesAfectadas);
  }, []);

  const renderSwitchTitulo = () => {
    switch (pantallaActiva) {
      case 0:
        return <EquipoBalanzaTitulo />;
      case 1:
        return <EquipoPesoPatronTitulo />;
      case 2:
        return <EquipoTermometroTitulo />;
      default:
        return <p>Sin datos</p>;
    }
  };

  return (
    <div className="cont-contenido-equipos">
      <div className="encabezado-nombre-barra-buscar">
        <Button
          label={"Nuevo equipo"}
          icono={"ico-anadir"}
          onClick={() => {
            setActivoNuevoEquipo(true);
          }}
        />
        <BarraBusqueda
          data={
            equiposCliente[
              pantallaActiva === 0
                ? "balanzas"
                : pantallaActiva === 1
                ? "pesospatron"
                : "termometros"
            ] ?? []
          }
          keysToFilter={
            pantallaActiva === 0
              ? [
                  "marca",
                  "serie",
                  "codigo",
                  "modelo",
                  "clase",
                  "capacidad",
                  "divescalareal",
                  "tipo",
                ]
              : pantallaActiva === 1
              ? [
                  "marca",
                  "serie",
                  "codigo",
                  "clase",
                  "valornominal",
                  "forma",
                  "material",
                ]
              : []
          }
          setFilteredData={setFilteredData}
        />
        <div className="justify-self-end">
          <Tabs
            lista={tabs}
            selected={pantallaActiva}
            setSelected={setPantallaActiva}
            nivel={2}
          />
        </div>
      </div>
      <div>
        {mostrarCargando ? (
          <Loader fullScreen />
        ) : (
          <div
            className={
              "tabla-equipos table " +
              (!mostrarCargando ? " animar-entrada" : "")
            }
          >
            {renderSwitchTitulo()}
            {equiposCliente[
              pantallaActiva === 0
                ? "balanzas"
                : pantallaActiva === 1
                ? "pesospatron"
                : "termometros"
            ].length === 0 ? (
              <MensajeCentro mensaje="No existen equipos" />
            ) : filteredData.length === 0 ? (
              <MensajeCentro mensaje="La búsqueda no arrojó resultados" />
            ) : (
              filteredData.map((el) => {
                switch (pantallaActiva) {
                  case 0:
                    return (
                      <EquipoBalanza
                        datosEquipo={el}
                        key={el.idequipo}
                        detalles={detallesEquipo}
                        editar={editarEquipo}
                        duplicar={duplicarEquipo}
                        eliminar={handleEliminar}
                      />
                    );
                  case 1:
                    return (
                      <EquipoPesoPatron
                        datosEquipo={el}
                        key={el.idequipo}
                        detalles={detallesEquipo}
                        editar={editarEquipo}
                        duplicar={duplicarEquipo}
                        eliminar={handleEliminar}
                      />
                    );
                  case 2:
                    return (
                      <EquipoTermometro
                        datosEquipo={el}
                        key={el.idequipo}
                        editar={editarEquipo}
                        detalles={detallesEquipo}
                      />
                    );
                  default:
                    return <MensajeCentro mensaje="No existen equipos" />;
                }
              })
            )}
          </div>
        )}
      </div>
      <Modal
        activo={activoNuevoEquipo}
        cerrar={() => {
          setActivoNuevoEquipo(false);
          setIdEquipo(0);
          setDuplicar(false);
        }}
      >
        <FormNuevoEquipo
          idcliente={idcliente}
          idequipo={idEquipo}
          duplicar={duplicar}
          idtablaequipo={pantallaActiva}
          cerrar={() => {
            setActivoNuevoEquipo(false);
            obtenerEquipos();
            setIdEquipo(0);
            setDuplicar(false);
          }}
          equipos={equiposCliente}
          activo={activoNuevoEquipo}
          seleccionarOrdenesAfectadas={seleccionarOrdenesAfectadas}
        />
      </Modal>
      <Modal
        activo={activoDetallesEquipo}
        cerrar={() => {
          setActivoDetallesEquipo(false);
          setIdEquipo(0);
        }}
      >
        <DetalleEquipo
          activo={activoDetallesEquipo}
          idcliente={idcliente}
          datosEquipo={[
            ...equiposCliente.balanzas,
            ...equiposCliente.pesospatron,
            ...equiposCliente.termometros,
          ].find((el) => el.idequipo === idEquipo)}
          idtablaequipo={pantallaActiva + 1}
          cerrar={() => {
            setActivoDetallesEquipo(false);
            setIdEquipo(0);
          }}
        />
      </Modal>
      <Modal activo={modalOrdenesAfectadas} temblar>
        <FormOrdenesAfectadas
          ordenesAfectadas={ordenesAfectadas}
          cerrar={() => {
            setOrdenesAfectadas([]);
            setModalOrdenesAfectadas(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default Equipos;
