import { useEffect, useState, useContext } from "react";
import "./HojaDatosBalanza.css";
import { host, port } from "../../../../helpers/DbData";
import $ from "jquery";
import Button from "../../../generic/Button";
import PopupContext from "../../../../context/PopupContext";
import UserContext from "../../../../context/UserContext";
import Loader from "../../../generic/Loader";
import MensajeCentro from "../../../generic/MensajeCentro";
import { IEquipoActivo } from "../Calibraciones";
import IdentificacionClientePP from "./hojaDatosPP/IdentificacionClientePP";
import { IIdentificacionClientePP } from "./hojaDatosPP/IdentificacionClientePP";
import IdentificacionPesasPP, {
  IIdentificacionPesasPP,
} from "./hojaDatosPP/IdentificacionPesasPP";
import MedicionesPP from "./hojaDatosPP/MedicionesPP";
import { IMedicionesPP } from "./hojaDatosPP/MedicionesPP";
import CondicionesAmbientalesPP from "./hojaDatosPP/CondicionesAmbientalesPP";
import { ICondicionesAmbientalesPP } from "./hojaDatosPP/CondicionesAmbientalesPP";

interface IDatosCertificado {
  identificacioncliente: IIdentificacionClientePP;
  pesas: IIdentificacionPesasPP[];
  resultados: IMedicionesPP[];
  condicionesambientales: ICondicionesAmbientalesPP;
  balanzas: string[];
}

interface Props {
  data: IEquipoActivo;
  cerrar: () => void;
}

function HojaDatosPesosPatron({ data, cerrar }: Props) {
  const [datosCertificado, setDatosCertificado] = useState<IDatosCertificado>();
  const [cargando, setCargando] = useState(false);
  const [cargando1, setCargando1] = useState(false);
  const [cambios, setCambios] = useState(0);
  const [editando, setEditando] = useState({
    identificacioncliente: false,
    identificacionequipo: false,
    observaciones: false,
  });

  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (data.idordentrabajo !== 0) {
      $.ajax({
        url:
          userData.idperfil === 5
            ? `${host}:${port}/api/pesopatron/revisionfinal`
            : `${host}:${port}/api/pesopatron/calcular`,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          idordentrabajo: data.idordentrabajo,
          idusuario: userData.idusuario,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          delete data.mensaje;
          data.identificacioncliente.fecharecepcion = new Date(
            data.identificacioncliente.fecharecepcion
          ).toLocaleDateString("sv");
          setDatosCertificado(data);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  }, [data.idordentrabajo, userData.idperfil, mostrarPopup]);

  const guardarAprobar = (revisado: number) => {
    $.ajax({
      url:
        userData.idperfil === 5
          ? `${host}:${port}/api/pesopatron/revisionfinal`
          : `${host}:${port}/api/pesopatron/calcular`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        // identificacioncliente: datosCertificado?.identificacioncliente,
        ...datosCertificado?.identificacioncliente,
        // identificacionequipo: datosCertificado.identificacionequipo,
        // cambio: datosCertificado.cambio,
        revisioncambios: cambios,
        idordentrabajo: data.idordentrabajo,
        idusuarioRevisa: userData.idusuario,
        revisado,
      }),
      beforeSend: function () {
        setCargando1(true);
      },
      success: function (data) {
        setCambios(0);
        setCargando1(false);
        mostrarPopup(1, data.mensaje);
        cerrar();
      },
      error: function (data) {
        cerrar();
        setCargando1(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  return (
    <>
      {cargando ? (
        <Loader />
      ) : (
        <div className="cont-hoja-datos animar-entrada">
          {!data || data.idordentrabajo === 0 ? (
            <MensajeCentro mensaje="Selecciona un equipo" />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {cargando1 ? (
                  <Loader />
                ) : (
                  <>
                    {userData.idperfil === 5 ? (
                      <Button
                        label={"Rechazar"}
                        icono={"ico-eliminar"}
                        color="ROJO"
                        onClick={() => guardarAprobar(99)}
                        disabled={
                          editando.identificacioncliente ||
                          editando.identificacionequipo ||
                          editando.observaciones
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <Button
                      label={"Guardar y aprobar"}
                      icono={"ico-guardar"}
                      onClick={() => guardarAprobar(1)}
                      disabled={
                        editando.identificacioncliente ||
                        editando.identificacionequipo ||
                        editando.observaciones
                      }
                    />
                  </>
                )}
              </div>
              <div className="cont-hoja1 cont-hoja-datos1 animar-entrada">
                {datosCertificado ? (
                  <>
                    <IdentificacionClientePP
                      data={datosCertificado.identificacioncliente}
                      permitirEdicion={userData.idperfil === 2}
                      setData={(data: IIdentificacionClientePP) => {
                        setCambios(1);
                        setDatosCertificado({
                          ...datosCertificado,
                          identificacioncliente: {
                            ...datosCertificado.identificacioncliente,
                            ...data,
                          },
                        });
                      }}
                      setEditando={(editando1) =>
                        setEditando({
                          ...editando,
                          identificacioncliente: editando1,
                        })
                      }
                    />
                    <IdentificacionPesasPP data={datosCertificado.pesas} />
                    <CondicionesAmbientalesPP
                      data={datosCertificado.condicionesambientales}
                    />
                    <MedicionesPP
                      data={datosCertificado.resultados}
                      balanzas={datosCertificado.balanzas}
                    />
                  </>
                ) : (
                  <MensajeCentro mensaje="No hay datos de calibración para esta orden" />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default HojaDatosPesosPatron;
