import Loader from "./Loader";
import TypeIcon from "../../types/TypeIcon";
import styles from "./Button.module.css";

interface Props {
  id?: string;
  "data-tooltip-id"?: string;
  label?: string;
  icono?: TypeIcon;
  onClick: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  cargando?: boolean;
  color?: "PRINCIPAL" | "ROJO" | "TRULLO" | "BLANCO" | "GRIS";
  nivel?: 1 | 2 | 3;
}

function Button({
  id,
  label,
  icono,
  onClick,
  disabled = false,
  type = "button",
  cargando = false,
  color = "PRINCIPAL",
  nivel = 1,
  "data-tooltip-id": dataTooltipId,
}: Props) {
  const handleClick = (e: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick();
      e.preventDefault();
    }
  };

  return (
    <>
      {!cargando && (
        <button
          id={id}
          data-tooltip-id={dataTooltipId}
          className={`${styles.contBtn} ${disabled && styles.disabled} ${
            nivel === 1
              ? styles[color]
              : nivel === 2
              ? styles.GRIS
              : styles.TRANSPARENT
          }`}
          onClick={handleClick}
          type={type}
        >
          {icono && (
            <div
              className={`${styles.ico} ${icono} ${
                disabled && styles.disabled
              } ${
                nivel === 1 && color !== "BLANCO"
                  ? styles.BLANCO
                  : color === "BLANCO"
                  ? styles.PRINCIPAL
                  : styles[color]
              } ${disabled && styles.disabled}`}
            />
          )}
          {label && (
            <span
              className={`${styles.label} ${
                nivel === 1 && color !== "BLANCO"
                  ? styles.BLANCO
                  : color === "BLANCO"
                  ? styles.PRINCIPAL
                  : styles[color]
              } ${disabled && styles.disabled} ${icono && styles.paddingRight}`}
            >
              {label}
            </span>
          )}
        </button>
      )}
      {cargando && <Loader />}
    </>
  );
}

export default Button;
