import Button from "../../generic/Button";
import styles from "./Listas.module.css";
import { useEffect, useState, useContext, useRef } from "react";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import $ from "jquery";
import Loader from "../../generic/Loader";
import MensajeCentro from "../../generic/MensajeCentro";
import Input from "../../generic/Input";
import { objValidadoInicial, validarForm } from "../../../helpers/ValidarT";
import ConfirmContext from "../../../context/ConfirmContext";
import { ValidarT } from "../../../helpers/ValidarT";
import BarraBusqueda from "../../generic/BarraBusqueda";
import Modal from "../../generic/Modal";

export interface IMagnitud {
  idmagnitud: number;
  magnitud: string;
  estado: number;
}

function Magnitudes() {
  const { mostrarConfirm } = useContext(ConfirmContext);
  const { mostrarPopup } = useContext(PopupContext);

  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [listData, setListData] = useState<IMagnitud[]>([]);
  const [filteredListData, setFilteredListData] = useState<IMagnitud[]>([]);
  const [formData, setFormData] = useState({ item: "" });
  const [modal, setModal] = useState(false);

  const formValidacion = useRef({ item: objValidadoInicial });

  useEffect(() => {
    obtenerLista();
  }, []);

  const obtenerLista = () => {
    $.ajax({
      url: `${host}:${port}/api/magnitud/all/`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setListData(data);
      },
      error: function (data) {
        setCargando(false);
        setListData([]);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const borrarItem = async (id: number) => {
    if (await mostrarConfirm("¿Borrar el ítem de la lista?")) {
      $.ajax({
        url: `${host}:${port}/api/magnitud/delete/`,
        type: "delete",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ id }),
        beforeSend: function () {
          setCargando2(true);
        },
        success: function (data) {
          mostrarPopup(1, data.msg);
          setCargando2(false);
          obtenerLista();
          setFormData({ item: "" });
        },
        error: function (data) {
          setCargando2(false);
          setListData([]);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  const handleGuardar = () => {
    if (validarForm(formValidacion.current)) {
      anadirItem();
    } else {
      mostrarPopup(2, "Debes llenar el campo");
    }
  };

  const anadirItem = () => {
    $.ajax({
      url: `${host}:${port}/api/magnitud/create`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        tipoindustria: formData.item,
      }),
      beforeSend: function () {
        setCargando2(true);
      },
      success: function (data) {
        mostrarPopup(1, data.msg);
        setCargando2(false);
        obtenerLista();
        setModal(false);
        setFormData({ item: "" });
      },
      error: function (data) {
        setCargando2(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    handleValidacion(name, value);
  };

  const handleValidacion = (name: string, value: string) => {
    formValidacion.current = {
      ...formValidacion.current,
      [name]: ValidarT.texto(value),
    };
  };

  return (
    <>
      <div className={styles.contListas}>
        <div
          className="encabezado-nombre-barra-buscar"
          style={{ marginBottom: "10px", marginTop: "3px" }}
        >
          <h4>Ítems</h4>
          <BarraBusqueda
            data={listData}
            keysToFilter={["magnitud"]}
            setFilteredData={setFilteredListData}
          />
          <Button icono="ico-anadir" onClick={() => setModal(true)} />
        </div>
        {cargando ? (
          <Loader />
        ) : (
          <div className={styles.contLista}>
            {filteredListData.map((el, i) => {
              return (
                <div key={i} className={`${styles.itemLista}`}>
                  <p>{el.magnitud}</p>
                  <div
                    className="ico-eliminar format-ico-row-table row-accion"
                    onClick={() => borrarItem(el.idmagnitud)}
                    style={{ opacity: "0.2" }}
                  />
                </div>
              );
            })}
            {listData.length <= 0 && (
              <div className="grid-full-width">
                <MensajeCentro mensaje="No hay ítems" />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal activo={modal} cerrar={() => setModal(false)}>
        <div className={`flex flex-col gap-4 w-80`}>
          <h3>Nueva magnitud</h3>
          <Input
            name="item"
            onChange={handleChange}
            value={formData.item}
            autoFocus
          />
          <div>
            <Button
              icono="ico-guardar"
              onClick={handleGuardar}
              label="Guardar"
              cargando={cargando2}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Magnitudes;
