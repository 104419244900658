import MensajeCentro from "../../../generic/MensajeCentro";
import { IEquipoActivo } from "../Calibraciones";
import { IOrdenRevisionPP } from "./ListaPesosPatron";

export interface IEquipoRevisionBalanzas {
  idordentrabajo: number;
  iddetalleordentrabajo: number | undefined;
  marca: string;
  modelo: string;
  serie: string;
  capacidad: number;
  unidadmedida: string;
  idtipoequipo: number;
  idservicio: number;
  fechacalibracion: string;
  fechacalibracionfin?: string;
}

interface Props {
  equiposRevision: IEquipoRevisionBalanzas[];
  setEquipoActivo: (
    data: IEquipoRevisionBalanzas | IOrdenRevisionPP | undefined
  ) => void;
  equipoActivo: IEquipoActivo | undefined;
}

const ListaBalanzas = ({
  equiposRevision,
  setEquipoActivo,
  equipoActivo,
}: Props) => {
  return (
    <div>
      {equiposRevision.length > 0 ? (
        <>
          {equiposRevision.map((el, i) => {
            return (
              <p
                key={el.iddetalleordentrabajo}
                className={`calibraciones-equipo-lista animar-entrada 
                  ${
                    equipoActivo &&
                    equipoActivo.iddetalleordentrabajo ===
                      el.iddetalleordentrabajo
                      ? "active"
                      : ""
                  } ${
                  el.idservicio === 1
                    ? "servicio-calibracion"
                    : el.idservicio === 2
                    ? "servicio-mantenimiento"
                    : ""
                }`}
                onClick={() => {
                  setEquipoActivo(el);
                }}
              >
                {el.marca} {el.modelo} {el.serie}
              </p>
            );
          })}
        </>
      ) : (
        <div style={{ marginTop: "30px" }}>
          <MensajeCentro mensaje="No hay certificados por revisar" />
        </div>
      )}
    </div>
  );
};

export default ListaBalanzas;
