import OrdenTrabajo from "../../components/clientes/seccionesDetalle/ordenestrabajo/OrdenTrabajo";
import styles from "./OrdenesPA.module.css";
import { useState, useContext, useCallback, useEffect } from "react";
import MensajeCentro from "../../components/generic/MensajeCentro";
import DetalleOrdenTrabajo from "../../components/clientes/seccionesDetalle/ordenestrabajo/DetalleOrdenTrabajo";
import Modal from "../../components/generic/Modal";
import $ from "jquery";
import { host, port, portPDF } from "../../helpers/DbData";
import PopupContext from "../../context/PopupContext";
import UserContext from "../../context/UserContext";
import Loader from "../../components/generic/Loader";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Button from "../../components/generic/Button";
import ContInput from "../../components/generic/ContInput";

export interface IOrdenTrabajo {
  idordentrabajo: number;
  fechaserviciodesde: string;
  fechaserviciohasta: string;
  version: number;
  estado: number;
  tecnicos: string;
  estadocotizacion: number | null;
  codigocotizacion: string | null;
  idservicio: number;
  servicio: string;
  equipos: string;
  cantequipos: number;
}

export interface IOrdenes {
  idcliente: number;
  empresa: string;
  nombres: string;
  ciudad: string;
  ordenes: IOrdenTrabajo[];
}

interface IFiltros {
  estados: number[];
  fechaservicioasignada: 0 | 1 | 2; // 'Cualquiera'|'Si'|'No'
  fechadesde: Date | null;
  fechahasta: Date | null;
}

// ordenes pendientes de aprobacion por calidad
function OrdenesPA() {
  const { userData } = useContext(UserContext);

  const [ordenes, setOrdenes] = useState<IOrdenes[]>([]);
  const [idOrdenActiva, setIdOrdenActiva] = useState(0);
  const [modalActivo, setModalActivo] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);
  const [combos, setCombos] = useState<{
    estados: { estado: number; descripcion: string }[];
  }>({ estados: [] });
  const [filtros, setFiltros] = useState<IFiltros>({
    estados: [],
    fechaservicioasignada: 0,
    fechadesde: null,
    fechahasta: null,
  });

  useEffect(() => {
    if (idOrdenActiva !== 0) setModalActivo(true);
  }, [idOrdenActiva]);

  const cerrarModal1 = () => {
    setModalActivo(false);
    setIdOrdenActiva(0);
  };

  const cerrarModal2 = () => {
    cerrarModal1();
    getOrdenes();
  };

  const getCombos = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/combosFiltros`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setCombos(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  const getOrdenes = useCallback(
    (excel?: boolean) => {
      // validar los filtros
      let fechadesde = filtros.fechadesde;

      // si se seleccionó una fecha, se le suma un día para que la búsqueda sea hasta las 23:59:59 del día seleccionado
      let fechahasta = filtros.fechahasta
        ? new Date(filtros.fechahasta.getTime() + 86400000)
        : null;

      let estados =
        userData.idperfil === 2 ? [2] : userData.idperfil === 4 ? 4 : [];

      if (userData.idperfil === 4) {
        // solo el perfil de ventas puede filtrar por estados
        if (filtros.estados.length === 0)
          return mostrarPopup(0, "Debes seleccionar al menos un estado");

        if (!fechadesde || !fechahasta)
          return mostrarPopup(0, "Debes seleccionar un rango de fechas");

        if (fechadesde > fechahasta)
          return mostrarPopup(
            0,
            "La fecha 'desde' no puede ser mayor a la fecha 'hasta'"
          );
      }

      if (userData.idperfil === 4) estados = filtros.estados;

      $.ajax({
        url: `${host}:${port}/api/ordenTrabajo/byFilter`,
        type: "get",
        // dataType: "json",
        // contentType: "application/json",
        data: {
          estados,
          fechadesde: userData.idperfil === 4 ? fechadesde : null,
          fechahasta: userData.idperfil === 4 ? fechahasta : null,
          fechaservicioasignada:
            userData.idperfil === 4 ? filtros.fechaservicioasignada : null,
          excel,
        },
        xhrFields: excel
          ? {
              responseType: "blob",
            }
          : undefined,
        beforeSend: function () {
          setCargando2(true);
        },
        success: function (data, textStatus, xhr) {
          setCargando2(false);
          if (excel) {
            mostrarPopup(1, "Descargando excel");
            const contentDispositionHeader = xhr.getResponseHeader(
              "Content-Disposition"
            );
            const match =
              contentDispositionHeader &&
              contentDispositionHeader.match(
                /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              );

            let filename = "excel.xlsx";
            if (match && match[1]) filename = match[1];
            const blob = new Blob([data], { type: "application/vnd.ms-excel" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            setOrdenes(data);
          }
        },
        error: function (data) {
          setCargando2(false);
          if (excel)
            return mostrarPopup(0, "No se encontraron órdenes para exportar");
          console.error(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    },
    [mostrarPopup, filtros, userData.idperfil]
  );

  useEffect(() => {
    if (userData.idperfil !== 4) {
      getOrdenes();
    }
  }, [getOrdenes, userData.idperfil]);

  useEffect(() => {
    getCombos();
  }, [getCombos]);

  return (
    <>
      <div className={styles.contPrincipal}>
        {/* cuando el perfil es de calidad (2) debería mostrar directamente las órdenes aprobadas por ventas */}
        {userData.idperfil === 4 && (
          <div className="cont-card my-2 justify-center items-end grid grid-cols-[1fr_1fr_1fr_100px_100px] gap-4 w-full">
            <div className="col-span-full">
              <ContInput label="Estados">
                <Select
                  isMulti
                  name="estado"
                  onChange={(e) =>
                    setFiltros((f) => ({
                      ...f,
                      estados: e.map((el) => el.value),
                    }))
                  }
                  options={combos.estados.map((el) => ({
                    label: el.descripcion,
                    value: el.estado,
                  }))}
                  placeholder="Selecciona los estados"
                  unstyled
                  classNames={{
                    control: () =>
                      "h-8 !min-h-0 w-full max-w-[calc(100vw_-_130px)]",
                    valueContainer: () =>
                      "gap-1 !flex-nowrap flex-row overflow-y-hidden !overflow-x-auto",
                    multiValue: () => "bg-gray-200 py-[1px] pl-2 rounded",
                    // multiValueLabel: () => "max-w-[40px]",
                    multiValueRemove: () =>
                      "w-5 flex justify-center items-center",
                    placeholder: () => "text-zinc-300",
                    clearIndicator: () =>
                      "cursor-pointer invert-[0.6] hover:bg-gray-200 hover:invert-0 w-7 h-7 items-center justify-center flex rounded-full",
                    dropdownIndicator: () =>
                      "cursor-pointer invert-[0.6] hover:bg-gray-200 hover:invert-0 w-7 h-7 items-center justify-center flex rounded-full",
                    menu: () => "bg-white shadow-lg rounded-lg p-2",
                    option: () => "p-2 cursor-pointer hover:bg-gray-200",
                  }}
                  closeMenuOnSelect={false}
                />
              </ContInput>
            </div>
            <ContInput label="Fecha de servicio asignada">
              <Select
                name="fechaservicioasignada"
                onChange={(e) =>
                  setFiltros((f) => ({
                    ...f,
                    fechaservicioasignada: Number(e?.value ?? 0) as 0 | 1 | 2,
                  }))
                }
                options={[
                  { label: "Cualquiera", value: 0 },
                  { label: "Sí", value: 1 },
                  { label: "No", value: 2 },
                ]}
                defaultValue={{ label: "Cualquiera", value: 0 }}
                unstyled
                classNames={{
                  control: () =>
                    "h-8 !min-h-0 w-full max-w-[calc(100vw_-_130px)]",
                  valueContainer: () =>
                    "gap-1 !flex-nowrap flex-row overflow-y-hidden !overflow-x-auto",
                  multiValue: () => "bg-gray-200 py-[1px] pl-2 rounded",
                  // multiValueLabel: () => "max-w-[40px]",
                  multiValueRemove: () =>
                    "w-5 flex justify-center items-center",
                  placeholder: () => "text-zinc-300",
                  clearIndicator: () =>
                    "cursor-pointer invert-[0.6] hover:bg-gray-200 hover:invert-0 w-7 h-7 items-center justify-center flex rounded-full",
                  dropdownIndicator: () =>
                    "cursor-pointer invert-[0.6] hover:bg-gray-200 hover:invert-0 w-7 h-7 items-center justify-center flex rounded-full",
                  menu: () => "bg-white shadow-lg rounded-lg p-2",
                  option: () => "p-2 cursor-pointer hover:bg-gray-200",
                }}
              />
            </ContInput>
            <ContInput label="Fechas de creación">
              <ReactDatePicker
                selected={filtros.fechadesde}
                startDate={filtros.fechadesde}
                endDate={filtros.fechahasta}
                onChange={(e) =>
                  setFiltros((f) => ({
                    ...f,
                    fechadesde: e,
                  }))
                }
                selectsStart
                placeholderText="Desde"
                isClearable
                dateFormat={"yyyy-MM-dd"}
                todayButton="Hoy"
              />
            </ContInput>
            <ContInput>
              <ReactDatePicker
                selected={filtros.fechahasta}
                startDate={filtros.fechadesde}
                endDate={filtros.fechahasta}
                onChange={(e) =>
                  setFiltros((f) => ({
                    ...filtros,
                    fechahasta: e,
                  }))
                }
                selectsEnd
                placeholderText="Hasta"
                isClearable
                dateFormat={"yyyy-MM-dd"}
                todayButton="Hoy"
                minDate={filtros.fechadesde}
              />
            </ContInput>
            <Button onClick={getOrdenes} label="Buscar" nivel={2} />
            <Button
              onClick={() => getOrdenes(true)}
              label="Excel"
              icono="ico-descargar"
              color="TRULLO"
              // disabled={ordenes.length === 0}
            />
          </div>
        )}
        {cargando || cargando2 ? (
          <Loader fullScreen />
        ) : (
          <>
            {ordenes.length > 0 ? (
              <>
                <h3 className="ml-3">
                  {ordenes.reduce(
                    (total, el) => (total += el.ordenes.length),
                    0
                  )}{" "}
                  resultados
                </h3>
                {ordenes.map((el, i) => (
                  <div key={i}>
                    <div className={styles.empresa}>
                      <h4>{el.empresa}</h4>
                    </div>
                    <div className={styles.contOrdenes}>
                      {el.ordenes.map((el1, i1) => (
                        <OrdenTrabajo
                          key={i1}
                          datosOrden={el1}
                          verDetalle={(idordentrabajo) =>
                            setIdOrdenActiva(idordentrabajo)
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <MensajeCentro mensaje="No hay órdenes pendientes" />
            )}
          </>
        )}
      </div>
      <Modal activo={modalActivo} cerrar={cerrarModal1}>
        <DetalleOrdenTrabajo
          idOrdenTrabajo={idOrdenActiva}
          activo={modalActivo}
          cerrar={cerrarModal2}
        />
      </Modal>
    </>
  );
}

export default OrdenesPA;
