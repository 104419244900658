import useForm from "../../../hooks/useForm";
import { IConfigValidacion } from "../../../hooks/useForm";
import Input from "../../generic/Input";
import styles from "./LimitesCondicionesLab.module.css";
import { useCallback, useEffect, useState, useContext } from "react";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import Button from "../../generic/Button";
import Loader from "../../generic/Loader";

interface IFormData {
  temperaturamin: string;
  temperaturamax: string;
  humedadmin: string;
  humedadmax: string;
  presionmin: string;
  presionmax: string;
}

const initialFormData: IFormData = {
  temperaturamin: "",
  temperaturamax: "",
  humedadmin: "",
  humedadmax: "",
  presionmin: "",
  presionmax: "",
};

const initialValidacion: IConfigValidacion = {
  temperaturamin: { validar: "numerosPositivos", opcional: false },
  temperaturamax: { validar: "numerosPositivos", opcional: false },
  humedadmin: { validar: "numerosPositivos", opcional: false },
  humedadmax: { validar: "numerosPositivos", opcional: false },
  presionmin: { validar: "numerosPositivos", opcional: false },
  presionmax: { validar: "numerosPositivos", opcional: false },
};

function LimitesCondicionesLab() {
  const { formData, formValidacion, formValidado, handleChange, setFormData } =
    useForm(initialFormData, initialValidacion);
  const [cargando, setCargando] = useState(false);
  const [cargando1, setCargando1] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const getLimites = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/limitescondicioneslab`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      // data: JSON.stringify({ idcliente, idusuario: userData.idusuario }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        // borro los campos que no se usan en el formulario
        delete data.idlimitescondicioneslab;
        delete data.fechacreacion;
        delete data.fechamodificacion;
        setFormData(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  useEffect(() => {
    getLimites();
  }, [getLimites]);

  const guardar = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/limitescondicioneslab`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(formData),
      beforeSend: function () {
        setCargando1(true);
      },
      success: function (data) {
        setCargando1(false);
        mostrarPopup(1, data.mensaje);
      },
      error: function (data) {
        setCargando1(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [formData, mostrarPopup]);

  return (
    <div className={styles.cont}>
      <div>
        <h4>Límites de las condiciones ambientales</h4>
        <Button
          label="Guardar"
          disabled={!formValidado}
          onClick={guardar}
          icono="ico-guardar"
          cargando={cargando1}
        />
      </div>
      {cargando ? (
        <Loader />
      ) : (
        <form
          className={`cont-card ${styles.form}`}
          onSubmit={(e) => e.preventDefault()}
        >
          <div>
            <span />
            <p>Mínima</p>
            <p>Máxima</p>
          </div>
          <div>
            <span>Temperatura (°C)</span>
            <Input
              name="temperaturamin"
              onChange={handleChange}
              value={formData.temperaturamin}
              placeholder="20"
              validacion={formValidacion.temperaturamin}
            />
            <Input
              name="temperaturamax"
              onChange={handleChange}
              value={formData.temperaturamax}
              placeholder="25"
              validacion={formValidacion.temperaturamax}
            />
          </div>
          <div>
            <span>Humedad (%HR)</span>
            <Input
              name="humedadmin"
              onChange={handleChange}
              value={formData.humedadmin}
              placeholder="40"
              validacion={formValidacion.humedadmin}
            />
            <Input
              name="humedadmax"
              onChange={handleChange}
              value={formData.humedadmax}
              placeholder="60"
              validacion={formValidacion.humedadmax}
            />
          </div>
          <div>
            <span>Presión (hPa)</span>
            <Input
              name="presionmin"
              onChange={handleChange}
              value={formData.presionmin}
              placeholder="740"
              validacion={formValidacion.presionmin}
            />
            <Input
              name="presionmax"
              onChange={handleChange}
              value={formData.presionmax}
              placeholder="760"
              validacion={formValidacion.presionmax}
            />
          </div>
        </form>
      )}
    </div>
  );
}

export default LimitesCondicionesLab;
