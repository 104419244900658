import LimitesCondicionesLab from "../../components/configuracion/condicionesambientales/LimitesCondicionesLab";
import FCTermohigrometros from "../../components/configuracion/condicionesambientales/FCTermohigrometros";

function CondicionesAmbientales() {
  return (
    <div className="animar-zoom-min-to-max cont-flex-vertical-gap">
      <h3 className="titulo-pagina">Condiciones ambientales</h3>
      <LimitesCondicionesLab />
      <FCTermohigrometros />
    </div>
  );
}

export default CondicionesAmbientales;
