import Input from "../../../../../generic/Input";
import ContInput from "../../../../../generic/ContInput";
import Switch from "../../../../../generic/Switch";
import { useState, useEffect, useCallback } from "react";
import { ICotizacionData, ICostos } from "../../FormNuevaOrdenTrabajo";
import Select from "../../../../../generic/Select";

interface IFormaPago {
  idformapago: number;
  formapago: string;
}

interface Props {
  formCotizacionData: ICotizacionData;
  handleChangeCostoExtra: (name: string, value: string) => void;
  handleChangeContrato: (name: string, value: string) => void;
  handleChangeDatosCliente: (name: string, value: string) => void;
  formasPago: IFormaPago[];
  visible?: boolean;
  costos: ICostos;
}

function DatosGenerales({
  formCotizacionData,
  handleChangeCostoExtra,
  handleChangeContrato,
  handleChangeDatosCliente,
  formasPago,
  visible,
  costos,
}: Props) {
  const [tipoDescuento, setTipoDescuento] = useState(false); // true:porcentaje, false:$
  const [descuento, setDescuento] = useState("");

  useEffect(() => {
    handleDescuento("descuento", descuento);
  }, [tipoDescuento]);

  const calcularDescuentoPorcentual = useCallback(
    (value: string): number => Number((Number(value) / 100) * costos.subtotal),
    [costos.subtotal]
  );

  useEffect(() => {
    if (costos.subtotal === 0) {
      setDescuento("");
      return;
    }
  }, [tipoDescuento, costos, descuento, calcularDescuentoPorcentual]);

  const handleDescuento = (name: string, value: string) => {
    setDescuento(value);
    if (tipoDescuento) {
      handleChangeContrato(name, calcularDescuentoPorcentual(value).toFixed(2));
    } else {
      handleChangeContrato(name, value);
    }
  };

  useEffect(() => {
    if (
      Number(formCotizacionData.contrato.descuento) !== Number(descuento) &&
      tipoDescuento === false &&
      visible
    ) {
      setDescuento(formCotizacionData.contrato.descuento);
    }
  }, [formCotizacionData.contrato.descuento, visible]);

  useEffect(() => {
    // if (!visible) {
    if (!(Number(formCotizacionData.contrato.descuento) > 0)) {
      setTipoDescuento(false);
    }
  }, [visible]);

  return (
    <div
      className="orden-datos-generales animar-entrada"
      style={{ display: visible ? "grid" : "none" }}
    >
      <div>
        <div>
          <h4 className="titulo-seccion-nueva-orden">Visita técnica</h4>
          <div>
            <div className="orden-visita-tecnica">
              <Input
                label="Cant."
                name="cantidad"
                value={formCotizacionData.costoextra.cantidad.toString()}
                onChange={handleChangeCostoExtra}
                validacion={{
                  mensaje: "",
                  validado:
                    formCotizacionData.costoextra.cantidad.toString().length >
                    0,
                }}
              />
              <Input
                label={"C/unitario ($)"}
                name="costounitario"
                value={formCotizacionData.costoextra.costounitario}
                onChange={handleChangeCostoExtra}
                validacion={{
                  mensaje: "",
                  validado:
                    formCotizacionData.costoextra.costounitario.toString()
                      .length > 0,
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="titulo-seccion-nueva-orden">Contrato</h4>
          <Select
            label={"Forma de pago"}
            name="idformapago"
            onChange={handleChangeContrato}
            value={formCotizacionData.contrato.idformapago}
            options={formasPago.map((el) => ({
              label: el.formapago,
              value: el.idformapago,
            }))}
          />
          <Input
            label={"Días de crédito"}
            name="diascredito"
            onChange={handleChangeContrato}
            value={formCotizacionData.contrato.diascredito.toString()}
          />
          <Input
            label={"Vigencia de la cotización"}
            name="vigencia"
            onChange={handleChangeContrato}
            value={formCotizacionData.contrato.vigencia.toString()}
          />
          <Input
            label={"Proveedor del servicio"}
            name="proveedor"
            onChange={handleChangeContrato}
            value={formCotizacionData.contrato.proveedor}
          />
        </div>
        <div>
          <h4 className="titulo-seccion-nueva-orden">
            Descuento ({tipoDescuento ? "%" : "$"})
          </h4>
          <div className="cont-inputs-descuento">
            <Input
              name="descuento"
              onChange={handleDescuento}
              value={descuento}
              validacion={{ validado: Number(descuento) >= 0, mensaje: "" }}
              disabled={costos.subtotal === 0}
            />
            <Switch
              name="tipodescuento"
              onChange={() => {
                setTipoDescuento(!tipoDescuento);
              }}
              value={tipoDescuento ? 1 : 0}
              stateLabels={{ off: "$", on: "%" }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "2px",
          display: visible ? "grid" : "none",
          gap: "10px",
        }}
        className={"animar-entrada"}
      >
        <div>
          <ContInput label={"Observaciones"} textarea>
            <textarea
              name="observaciones"
              onChange={(e) =>
                handleChangeContrato(e.target.name, e.target.value)
              }
              value={formCotizacionData.contrato.observaciones}
              style={{ height: "280px" }}
            />
          </ContInput>
          <Input
            label="Ciudad del servicio"
            name="informe_ciudad"
            onChange={handleChangeDatosCliente}
            value={formCotizacionData.datoscliente.informe_ciudad}
          />
          <Input
            label="Dirección del servicio"
            name="informe_direccion"
            onChange={handleChangeDatosCliente}
            value={formCotizacionData.datoscliente.informe_direccion}
          />
        </div>
      </div>
    </div>
  );
}

export default DatosGenerales;
