import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";
import { useState, useContext, useEffect } from "react";
import PopupContext from "../../../../../context/PopupContext";
import Loader from "../../../../generic/Loader";

interface IHistorial {
  id: number;
  usuario: string;
  accion: string;
  fecha: Date;
}

interface Props {
  idordentrabajo: number;
}

const HistorialCambios = ({ idordentrabajo }: Props) => {
  const { mostrarPopup } = useContext(PopupContext);

  const [cargando, setCargando] = useState(false);
  const [historial, setHistorial] = useState<IHistorial[]>([]);

  useEffect(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/historial`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idordentrabajo },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setHistorial(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  }, [idordentrabajo, mostrarPopup]);

  if (cargando) return <Loader />;

  return (
    <div className="bg-white rounded-xl overflow-hidden">
      <div className="overflow-auto max-h-96">
        <table className="table-auto w-full">
          <thead className="text-left">
            <tr>
              <th className="pl-2 py-3 text-[var(--color-trullo)] font-bold">
                Fecha
              </th>
              <th className="pl-2 py-3 text-[var(--color-trullo)] font-bold">
                Usuario
              </th>
              <th className="pl-2 py-3 text-[var(--color-trullo)] font-bold">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            {historial.map((el, i) => (
              <tr key={i} className="border-t hover:bg-gray-100">
                <td className="pl-2 py-3">
                  {new Date(el.fecha).toLocaleString("sv")}
                </td>
                <td className="pl-2 py-3">{el.usuario}</td>
                <td className="pl-2 py-3">{el.accion}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistorialCambios;
