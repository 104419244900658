import Button from "../../../../../../generic/Button";
import { IServicioExterno } from "../../../FormNuevaOrdenTrabajo";
import useForm from "../../../../../../../hooks/useForm";
import { IConfigValidacion } from "../../../../../../../hooks/useForm";
import Select from "../../../../../../generic/Select";
import Input from "../../../../../../generic/Input";
import styles from "./ServicioExterno.module.css";
import LabelNumeroUnidad from "../../../../../../generic/LabelNumeroUnidad";
import { useState, useCallback, useContext, useEffect, useRef } from "react";
import ConfirmContext from "../../../../../../../context/ConfirmContext";
import PopupContext from "../../../../../../../context/PopupContext";
import MensajesUI from "../../../../../../../helpers/MensajesUI";
import { ICombosServicioExterno } from "../ServiciosExternos";
import SelectSearch from "../../../../../../generic/SelectSearch";
import Switch from "../../../../../../generic/Switch";

interface INuevoServicio {
  nuevo: boolean;
  cancelarNuevo: () => void;
}

interface Props {
  servicio?: IServicioExterno;
  nuevoServicio?: INuevoServicio;
  guardar: (data: IServicioExterno) => void;
  eliminar?: () => void;
  combos: ICombosServicioExterno;
}

const initialFormData: IServicioExterno = {
  idtipoequipo: 0,
  idservicio: 0,
  idproveedor: 0,
  cantidad: "",
  costounitario: "",
  acreditado: 0,
  descripcion: "",
  marca: "",
  modelo: "",
  identificacion: "",
  capacidad: "",
  resolucion: "",
};

const initialFormValidacion: IConfigValidacion = {
  idtipoequipo: { validar: "noCero", opcional: false },
  idservicio: { validar: "noCero", opcional: false },
  idproveedor: { validar: "noCero", opcional: false },
  cantidad: { validar: "numerosPositivos", opcional: false },
  costounitario: { validar: "numerosPositivos", opcional: false },
  acreditado: { validar: "general", opcional: true },
  descripcion: { validar: "general", opcional: true },
  marca: { validar: "general", opcional: true },
  modelo: { validar: "general", opcional: true },
  identificacion: { validar: "general", opcional: true },
  capacidad: { validar: "general", opcional: true },
  resolucion: { validar: "general", opcional: true },
};

// un servicio externalizado
function ServicioExterno({
  servicio,
  nuevoServicio,
  guardar,
  eliminar,
  combos,
}: Props) {
  const {
    formData,
    formValidacion,
    formValidado,
    handleChange,
    resetForm,
    setFormData,
  } = useForm(initialFormData, initialFormValidacion);
  const { mostrarConfirm } = useContext(ConfirmContext);
  const { mostrarPopup } = useContext(PopupContext);

  const [editando, setEditando] = useState(
    nuevoServicio ? nuevoServicio.nuevo : false
  );
  const formDataAnterior = useRef(initialFormData);

  useEffect(() => {
    if (servicio) setFormData(servicio);
  }, [servicio]);

  const guardar0 = () => {
    if (formValidado) {
      guardar(formData);
      formDataAnterior.current = formData;
      setEditando(false);
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.Incompleto);
    }
  };

  const cancelar = useCallback(() => {
    setEditando(false);
    setFormData(formDataAnterior.current);
  }, []);

  const cancelarNuevo = () => {
    nuevoServicio?.cancelarNuevo();
  };

  const eliminar0 = async () => {
    if ((await mostrarConfirm("¿Eliminar el servicio?")) && eliminar) {
      eliminar();
    }
  };

  const editar = useCallback(() => {
    setEditando(true);
    formDataAnterior.current = formData;
  }, [formData]);

  return (
    <div
      className={`${
        nuevoServicio?.nuevo || editando ? styles.contEditando : ""
      } ${styles.contPrincipal} animar-entrada`}
    >
      <div className={`${styles.contBtns}`}>
        {nuevoServicio?.nuevo ? (
          <h3>Nuevo servicio</h3>
        ) : editando ? (
          <h3>Editando servicio</h3>
        ) : (
          <span />
        )}
        <div>
          {nuevoServicio?.nuevo ? (
            <>
              <Button label="Guardar" onClick={guardar0} nivel={2} />
              <Button
                label="Cancelar"
                onClick={cancelarNuevo}
                color="ROJO"
                nivel={2}
              />
            </>
          ) : (
            <>
              {editando ? (
                <>
                  <Button
                    label="Eliminar"
                    onClick={eliminar0}
                    color="ROJO"
                    nivel={2}
                  />
                  <Button label="Guardar" onClick={guardar0} nivel={2} />
                  <Button
                    icono="ico-cerrar"
                    onClick={cancelar}
                    color="ROJO"
                    nivel={2}
                  />
                </>
              ) : (
                <>
                  <Button icono="ico-lapiz" onClick={editar} nivel={2} />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={`${styles.contForm}`}>
        <div>
          <div className={`table-row ${styles.fila}`}>
            <SelectSearch
              label="Tipo de equipo"
              name="idtipoequipo"
              onChange={handleChange}
              options={combos.tipoequipo
                .sort((a, b) => a.tipoequipo.localeCompare(b.tipoequipo))
                .map((el) => ({
                  label: el.tipoequipo,
                  value: el.idtipoequipo,
                }))}
              value={formData.idtipoequipo}
              validacion={formValidacion.idtipoequipo}
              disabled={!editando}
            />
            <Select
              label="Servicio"
              name="idservicio"
              onChange={handleChange}
              options={combos.servicio.map((el) => ({
                label: el.servicio,
                value: el.idservicio,
              }))}
              value={formData.idservicio}
              validacion={formValidacion.idservicio}
              disabled={!editando}
            />
            <Select
              label="Proveedor"
              name="idproveedor"
              onChange={handleChange}
              options={combos.proveedores.map((el) => ({
                label: el.proveedor,
                value: el.idproveedor,
              }))}
              value={formData.idproveedor}
              validacion={formValidacion.idproveedor}
              disabled={!editando}
            />
            <Switch
              label="Acreditado"
              name="acreditado"
              onChange={handleChange}
              value={formData.acreditado}
              validacion={formValidacion.acreditado}
              disabled={!editando}
            />
          </div>
          <div className={`table-row ${styles.fila}`}>
            <Input
              label="Marca"
              name="marca"
              onChange={handleChange}
              value={formData.marca}
              validacion={formValidacion.marca}
              disabled={!editando}
            />
            <Input
              label="Modelo"
              name="modelo"
              onChange={handleChange}
              value={formData.modelo}
              validacion={formValidacion.modelo}
              disabled={!editando}
            />
            <Input
              label="Identificación"
              name="identificacion"
              onChange={handleChange}
              value={formData.identificacion}
              validacion={formValidacion.identificacion}
              disabled={!editando}
            />
            <Input
              label="Capacidad"
              name="capacidad"
              onChange={handleChange}
              value={formData.capacidad}
              validacion={formValidacion.capacidad}
              disabled={!editando}
            />
            <Input
              label="Resolución"
              name="resolucion"
              onChange={handleChange}
              value={formData.resolucion}
              validacion={formValidacion.resolucion}
              disabled={!editando}
            />
          </div>
        </div>
        <div>
          <div className={`table-row ${styles.fila}`}>
            <Input
              label="Cantidad"
              name="cantidad"
              onChange={handleChange}
              value={formData.cantidad}
              validacion={formValidacion.cantidad}
              tipo="number"
              disabled={!editando}
            />
            <Input
              label="Precio unitario"
              name="costounitario"
              onChange={handleChange}
              value={formData.costounitario}
              validacion={formValidacion.costounitario}
              tipo="number"
              disabled={!editando}
            />
            <LabelNumeroUnidad
              label="Precio total"
              numero={
                "$" + Number(formData.cantidad) * Number(formData.costounitario)
              }
              derecha
            />
          </div>
          <div className={`table-row ${styles.fila}`}>
            <Input
              label="Descripción"
              name="descripcion"
              onChange={handleChange}
              value={formData.descripcion}
              validacion={formValidacion.descripcion}
              disabled={!editando}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicioExterno;
