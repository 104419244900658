import { useContext, useEffect, useRef, useState } from "react";
import PopupContext from "../../../../../context/PopupContext";
import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";
import Input from "../../../../generic/Input";
import useForm from "../../../../../hooks/useForm";
import { IConfigValidacion } from "../../../../../hooks/useForm";
import Select from "../../../../generic/Select";
import {
  TypeComboMarca,
  TypeComboUnidadMedida,
  TypeComboClasePesoPatron,
  TypeComboFormaPesoPatron,
  TypeComboMaterialPesoPatron,
} from "../../../../../types/TypeCombos";
import Button from "../../../../generic/Button";
import Switch from "../../../../generic/Switch";
import Loader from "../../../../generic/Loader";
import _ from "lodash";
import { IOrdenAfectada } from "../../../FormOrdenesAfectadas";

interface ICombos {
  clase: TypeComboClasePesoPatron[];
  marca: TypeComboMarca[];
  formaPesoPatron: TypeComboFormaPesoPatron[];
  materialPesoPatron: TypeComboMaterialPesoPatron[];
  unidadMedida: TypeComboUnidadMedida[];
}

let initialCombos: ICombos = {
  clase: [],
  marca: [],
  formaPesoPatron: [],
  materialPesoPatron: [],
  unidadMedida: [],
};

const initialAcreditado = {
  cumplecapacidad: false,
};

const initialFormData: IEquipoPesoPatron = {
  idmarca: 0,
  serie: "",
  codigo: "",
  valornominal: "",
  idunidadmedida: 0,
  idformapesopatron: 0,
  ubicacion: "",
  responsable: "",
  idmaterial: 0,
  idclase: 0,
  cumpleoiml: 0,
};

const initialFormValidacion: IConfigValidacion = {
  idmarca: { validar: "numerosPositivos", opcional: false },
  serie: { validar: "general", opcional: true },
  codigo: { validar: "general", opcional: true },
  valornominal: { validar: "numerosPositivos", opcional: false },
  idunidadmedida: { validar: "numerosPositivos", opcional: false },
  idformapesopatron: { validar: "numerosPositivos", opcional: true },
  ubicacion: { validar: "general", opcional: true },
  responsable: { validar: "general", opcional: true },
  idmaterial: { validar: "numerosPositivos", opcional: true },
  idclase: { validar: "numerosPositivos", opcional: false },
  cumpleoiml: { validar: "numerosPositivos", opcional: true },
};

interface IEquipoPesoPatron {
  idmarca: number;
  serie: string;
  codigo: string;
  valornominal: string;
  idunidadmedida: number;
  idformapesopatron: number;
  ubicacion: string;
  responsable: string;
  idmaterial: number;
  idclase: number;
  modelo?: string;
  cumpleoiml: 0 | 1;
}

interface IEquipoComparacion {
  abreviatura: string;
  acreditado: 0 | 1;
  clase: string;
  codigo?: string;
  cumpleoiml: 0 | 1;
  forma?: string;
  idclase: number;
  idequipo: number;
  idformapesopatron?: number;
  idmaterial?: number;
  marca: string;
  material?: string;
  modelo?: string;
  serie?: string;
  ubicacion?: string;
  valornominal: string;
  responsable?: string;
}

interface Props {
  activo: boolean;
  idcliente: number;
  idequipo: number;
  cerrar: () => void;
  equipos: IEquipoComparacion[];
  seleccionarOrdenesAfectadas: (ordenesAfectadas: IOrdenAfectada[]) => void;
  duplicar: boolean;
}

function FormPesoPatron({
  activo,
  idcliente,
  idequipo,
  cerrar,
  equipos,
  seleccionarOrdenesAfectadas,
  duplicar,
}: Props) {
  const [mounted, setMounted] = useState(true);
  const { mostrarPopup } = useContext(PopupContext);

  const {
    formData,
    formValidacion,
    formValidado,
    handleChange,
    resetForm,
    setFormData,
  } = useForm(initialFormData, initialFormValidacion);
  const [combos, setCombos] = useState(initialCombos);
  const [idTipoEquipo, setIdTipoEquipo] = useState(0);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [equipoYaExiste, setEquipoYaExiste] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [cargando3, setCargando3] = useState(false);
  const [acreditado, setAcreditado] = useState(initialAcreditado);

  const originalFormData = useRef<IEquipoPesoPatron>(initialFormData);

  useEffect(() => {
    obtenerCombos();
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    // si es diferente de 0 es porque se esta editando
    if (idequipo !== 0) {
      obtenerEquipo();
      if (!duplicar) setModoEdicion(true);
    }
    // if (idequipo === 0) inicializarForm();
    // return () => {
    //   setDatosForm(initialDatosForm);
    // };
  }, [idequipo]);

  useEffect(() => {
    if (!activo) inicializarForm();
  }, [activo]);

  useEffect(() => {
    // valido si el equipo ya existe
    if (
      equipos.filter((el) => {
        if (el.serie)
          return el.serie.toLowerCase() === formData.serie?.toLowerCase();
        else return undefined;
      }).length > 0 &&
      !modoEdicion
    ) {
      setEquipoYaExiste(true);
    } else {
      setEquipoYaExiste(false);
    }
  }, [formData]);

  const inicializarForm = () => {
    resetForm();
    setEquipoYaExiste(false);
    setModoEdicion(false);
  };

  const obtenerCombos = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/comboId`,
      type: "put",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ id: 2 }),
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        if (mounted) {
          setCombos(data);
          setIdTipoEquipo(data.tipoEquipo[0].idtipoequipo);
        }
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const obtenerEquipo = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/equiposId/${idequipo}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        let tempData = data[0] as IEquipoPesoPatron;
        delete tempData.modelo;
        setFormData(tempData);
        originalFormData.current = tempData;
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const guardarEquipo = () => {
    const editado = !_.isEqual(originalFormData.current, formData);
    if (!editado && modoEdicion) {
      mostrarPopup(2, "No se ha realizado ningún cambio");
      return;
    }
    if (!cargando)
      $.ajax({
        url: `${host}:${port}/api/nuevaCotizacion/edit`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          editado,
          id: duplicar ? 0 : idequipo,
          idtablaequipo: 2,
          idtipoequipo: idTipoEquipo,
          idcliente,
          ...formData,
          modelo: undefined,
          acreditado: acreditado.cumplecapacidad ? 1 : 0,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data.data);
          resetForm();
          originalFormData.current = initialFormData;
          cerrar();
          if (data.affectedOrders && data.affectedOrders.length > 0)
            seleccionarOrdenesAfectadas(data.affectedOrders);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const handleGuardarEquipo = () => {
    if (formValidado) {
      guardarEquipo();
    } else {
      mostrarPopup(
        2,
        "Revisa que todos los campos se hayan llenado correctamente"
      );
    }
  };

  const timeoutAcreditacion = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    // valido si el equipo cumple con la acreditacion
    if (
      Number(formData.valornominal) > 0 &&
      Number(idTipoEquipo) > 0 &&
      Number(formData.idclase) > 0 &&
      Number(formData.idunidadmedida) > 0 &&
      activo
    ) {
      setCargando2(true);
      clearTimeout(timeoutAcreditacion.current);
      timeoutAcreditacion.current = setTimeout(() => {
        cumpleAcreditacion();
      }, 500);
    } else {
      clearTimeout(timeoutAcreditacion.current);
      setCargando2(false);
    }
    return () => clearTimeout(timeoutAcreditacion.current);
  }, [
    formData.valornominal,
    idTipoEquipo,
    formData.idclase,
    formData.idunidadmedida,
  ]);

  const cumpleAcreditacion = async () => {
    if (
      Number(formData.valornominal) > 0 &&
      Number(idTipoEquipo) > 0 &&
      Number(formData.idclase) > 0 &&
      Number(formData.idunidadmedida) > 0
    ) {
      await $.ajax({
        url: `${host}:${port}/api/acreditacion/cumpleacreditacion`,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          valornominal: formData.valornominal,
          idtipoequipo: idTipoEquipo,
          idunidadmedida: formData.idunidadmedida,
          idclase: formData.idclase,
        }),
        beforeSend: function () {
          // setMostrarCargando(true);
        },
        success: function (data) {
          setAcreditado(data);
          setCargando2(false);
          // // console.log(data);
          // if (data.idclase)
          //   setDatosForm({ ...datosForm, idclase: data.idclase });
          // else setDatosForm({ ...datosForm, idclase: 1 });
        },
        error: function (data) {
          setCargando2(false);
          setAcreditado({ cumplecapacidad: false });
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  return (
    <form
      className="cont-2-columnas grid-gap-form animar-entrada"
      autoComplete={"off"}
    >
      <Select
        label="Marca"
        name="idmarca"
        options={combos.marca
          .sort((a, b) =>
            a.marca > b.marca ? 1 : a.marca === b.marca ? 0 : -1
          )
          .map((el) => ({
            value: el.idmarca,
            label: el.marca,
          }))}
        onChange={handleChange}
        value={formData.idmarca}
        validacion={formValidacion.idmarca}
      />
      <Input
        label="Valor nominal"
        name="valornominal"
        placeholder="100"
        onChange={handleChange}
        value={formData.valornominal}
        validacion={formValidacion.valornominal}
      />
      <Select
        label="Unidad de medida"
        name="idunidadmedida"
        options={combos.unidadMedida.map((el) => ({
          value: el.idunidadmedida,
          label: el.abreviatura,
        }))}
        onChange={handleChange}
        value={formData.idunidadmedida}
        validacion={formValidacion.idunidadmedida}
      />
      <Select
        label="Clase"
        name="idclase"
        options={combos.clase.map((el) => ({
          value: el.idclase,
          label: el.clase,
        }))}
        onChange={handleChange}
        value={formData.idclase}
        validacion={formValidacion.idclase}
      />
      <Input
        label="Serie"
        name="serie"
        placeholder="S001"
        onChange={handleChange}
        value={formData.serie}
        validacion={formValidacion.serie}
      />
      <Input
        label="Código"
        name="codigo"
        placeholder="PCT-001"
        onChange={handleChange}
        value={formData.codigo}
        validacion={formValidacion.codigo}
      />
      <Select
        label="Forma"
        name="idformapesopatron"
        options={combos.formaPesoPatron.map((el) => ({
          value: el.idformapesopatron,
          label: el.formapesopatron,
        }))}
        onChange={handleChange}
        value={formData.idformapesopatron}
        validacion={formValidacion.idformapesopatron}
      />
      <Input
        label="Ubicación"
        name="ubicacion"
        placeholder="Planta"
        onChange={handleChange}
        value={formData.ubicacion}
        validacion={formValidacion.ubicacion}
      />
      <Input
        label="Responsable"
        name="responsable"
        placeholder="Juan Pérez"
        onChange={handleChange}
        value={formData.responsable}
        validacion={formValidacion.responsable}
      />
      <Select
        label="Material"
        name="idmaterial"
        options={combos.materialPesoPatron.map((el) => ({
          value: el.idmaterialpesopatron,
          label: el.materialpesopatron,
        }))}
        onChange={handleChange}
        value={formData.idmaterial}
        validacion={formValidacion.idmaterial}
      />
      <div className="grid-full-width">
        <Switch
          label="Cumple OIML"
          name="cumpleoiml"
          value={formData.cumpleoiml}
          onChange={handleChange}
        />
      </div>
      <div className="cont-btns grid-full-width">
        {cargando || cargando2 || cargando3 ? (
          <Loader />
        ) : (
          <>
            {!modoEdicion && equipoYaExiste && (
              <p className="texto-validacion">
                El equipo ya existe, verifica que quieres guardarlo
              </p>
            )}
            {Number(formData.valornominal) > 0 &&
              Number(idTipoEquipo) > 0 &&
              Number(formData.idclase) > 0 &&
              Number(formData.idunidadmedida) > 0 &&
              !cargando2 &&
              !acreditado.cumplecapacidad && (
                <p className="texto-validacion">
                  {!acreditado.cumplecapacidad ? "Valor nominal " : ""}
                  fuera del alcance de acreditación
                </p>
              )}
            <Button
              label={"Guardar"}
              icono={"ico-guardar"}
              onClick={() => {
                handleGuardarEquipo();
              }}
              disabled={!formValidado}
            />
          </>
        )}
      </div>
    </form>
  );
}

export default FormPesoPatron;
