import styles from "./Loader.module.css";

interface Props {
  fullScreen?: boolean;
}

function Loader({ fullScreen = false }: Props) {
  return (
    <div
      className={`${styles.contLoader} ${fullScreen ? styles.fullScreen : ""}`}
    >
      <div className={styles.loader} />
    </div>
  );
}

export default Loader;
