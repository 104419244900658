import styles from "../Hub.module.css";
import { useContext, useState } from "react";
import { SocketContext } from "../../../context/SocketContext.js";
import { NavLink } from "react-router-dom";
import TypeNotificacion from "../../../types/TypeNotificacion";
import moment from "moment";
moment.locale("es", {
  months:
    "enero_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort:
    "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "unos segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

interface Props {
  data: TypeNotificacion;
  cerrar: () => void;
}

function Item({ data, cerrar }: Props) {
  const { aprobarNotificacion } = useContext(SocketContext);
  const [fechaCompleta, setFechaCompleta] = useState(false);

  const handleClick = () => {
    aprobarNotificacion(data.idnotificacion);
    cerrar();
  };

  return (
    <NavLink to={data.ruta} replace>
      <div
        className={`${styles.contItem}`}
        onClick={handleClick}
        onMouseEnter={() => setFechaCompleta(true)}
        onMouseLeave={() => setFechaCompleta(false)}
      >
        <div
          className={`${styles.inicialUsuario} ${
            styles["tn" + data.idtiponotificacion]
          }`}
        >
          {data.emisor[0]}
        </div>
        <div>
          <p className={styles.nombreUsuario}>{data.emisor}</p>
          <p className={`${styles.texto}`}>{data.mensaje}</p>
          <p className={`${styles.time}`}>
            {fechaCompleta ? (
              <span>
                {new Date(data.fechacreacion).toLocaleString().replace(",", "")}
              </span>
            ) : (
              <span>{moment(data.fechacreacion).fromNow()}</span>
            )}
          </p>
        </div>
      </div>
    </NavLink>
  );
}

export default Item;
