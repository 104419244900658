import { Outlet, useNavigate } from "react-router-dom";
import Tabs from "../components/generic/Tabs";
import { useState, useEffect, useContext } from "react";
import UserContext from "../context/UserContext";

// órdenes de trabajo sin estar agrupadas por cliente, útiles para visualizar órdenes según estados o para buscarlas en la pestaña "buscar"
// para calidad se renderizan órdenes de trabajo con estado 2 (pendiente de aprobación)
// para ventas se renderizan órdenes de trabajo con estado 1 (creada) y 3 (aprobada)
// los detalles de los significados de los estados de la base de datos se detallan en la documentación del software
function Ordenes() {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    switch (tab) {
      case 0:
        navigate("");
        break;
      case 1:
        navigate("buscar");
        break;
      default:
        break;
    }
  }, [tab, navigate]);

  return (
    <div>
      <div className="encabezado-nombre-barra-buscar">
        <h3 className="titulo-pagina">Órdenes</h3>
        <span />
        <Tabs
          selected={tab}
          setSelected={setTab}
          lista={[
            userData.idperfil === 2 ? "Pendientes de aprobación" : "Por estado",
            "Buscar",
          ]}
        />
      </div>
      <Outlet />
    </div>
  );
}

export default Ordenes;
