interface Props {
  data: { frecuencia: string };
}

function Frecuencia({ data }: Props) {
  return (
    <div className="cont-card">
      <div className="cont-frecuencia">
        <h3>Frecuencia de mantenimiento</h3>
        <p>{data.frecuencia}</p>
      </div>
    </div>
  );
}

export default Frecuencia;
