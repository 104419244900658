import ContInput from "./ContInput";
import { useEffect, useState } from "react";
import styles from "./BarraBusqueda.module.css";

interface IData extends Object {
  [key: string]: any;
}

function BarraBusqueda<T extends IData>({
  data,
  keysToFilter,
  setFilteredData,
  style,
}: {
  data: T[];
  keysToFilter: (keyof T)[];
  setFilteredData: (filteredData: T[]) => void;
  style?: React.CSSProperties;
}) {
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [dataAux, setDataAux] = useState<T[]>([]);
  const [cantResultados, setCantResultados] = useState(data.length);

  useEffect(() => {
    setDataAux(data);
  }, [data]);

  useEffect(() => {
    if (terminoBusqueda === "") {
      setFilteredData(dataAux);
      setCantResultados(data.length);
    } else buscar();
  }, [terminoBusqueda, dataAux]);

  const buscar = () => {
    if (data.length > 0) {
      const filteredData = data.filter((el) => {
        let key: keyof T;
        for (key of keysToFilter) {
          if (
            el[key] !== null &&
            el[key] !== undefined &&
            el[key]
              .toString()
              .toLowerCase()
              .indexOf(terminoBusqueda.toLowerCase()) > -1
          ) {
            return true;
          }
        }
        return false;
      });
      setFilteredData(filteredData);
      setCantResultados(filteredData.length);
    }
  };

  const borrarBusqueda = () => {
    setTerminoBusqueda("");
  };

  return (
    <div className={styles.cont} style={style}>
      <ContInput
        icono={"ico-lupa"}
        style={{ backgroundColor: "rgb(235,235,235)" }}
      >
        <input
          placeholder="Buscar"
          onChange={(e) => {
            setTerminoBusqueda(e.target.value);
          }}
          value={terminoBusqueda}
        />
        {(cantResultados !== data.length || terminoBusqueda !== "") && (
          <p className={`${styles.resultados} animar-entrada`}>
            ({cantResultados}{" "}
            {cantResultados === 1 ? "resultado" : "resultados"})
          </p>
        )}
        {terminoBusqueda.length > 0 && (
          <div
            className={`ico-cerrar ${styles.btnBorrar}`}
            onClick={borrarBusqueda}
          />
        )}
      </ContInput>
    </div>
  );
}

export default BarraBusqueda;
