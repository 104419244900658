import MensajeCentro from "../../../../generic/MensajeCentro";
interface Props {
  data: { falla: string }[];
}

function Fallas({ data }: Props) {
  return (
    <div className="cont-card">
      <h3>Prevención de fallas</h3>
      {data.length > 0 ? (
        <>
          <div className="table-row table-row-title">
            <span>Fallas</span>
          </div>
          {data.map((el, i) => (
            <div key={i} className="table-row">
              <span>{el.falla}</span>
            </div>
          ))}
        </>
      ) : (
        <MensajeCentro mensaje="No se ha reportado" />
      )}
    </div>
  );
}

export default Fallas;
