import Button from "../../generic/Button";
import $ from "jquery";
import { useContext, useRef, useState } from "react";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";

function Acreditacion() {
  const refInput = useRef<HTMLInputElement>(null);
  const [cargando, setCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const descargarExcel = () => {
    mostrarPopup(1, "Descargando excel");
    window.open(`${host}:${port}/api/excel/download/acreditacion`);
  };

  const cargarExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!cargando && e.target.files && e.target.files.length > 0) {
      var file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      e.target.value = "";
      $.ajax({
        url: `${host}:${port}/api/excel/upload/acreditacion`,
        type: "POST",
        contentType: false,
        processData: false,
        data: formData,
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    } else {
      mostrarPopup(2, "No se ha seleccionado un archivo");
    }
  };

  return (
    <>
      <div
        className="cont-card cont-flex-vertical-gap"
        style={{ borderRadius: "15px", background: "rgb(255,240,0)" }}
      >
        <p className="titulo-card-trazabilidad">Alcance de acreditación</p>
        <div className="cont-grid-equal-gap">
          <span />
          <div className="max-content justify-self-end cont-flex-vertical-gap align-items-end">
            <Button
              icono="ico-cargar"
              onClick={() => refInput.current?.click()}
              cargando={cargando}
              nivel={2}
            />
            <Button
              icono="ico-descargar"
              onClick={() => {
                descargarExcel();
              }}
              nivel={2}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <input ref={refInput} type={"file"} onChange={cargarExcel} />
      </div>
    </>
  );
}

export default Acreditacion;
