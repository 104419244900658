import { useState, useEffect, useContext, useCallback } from "react";
import CardCliente, {
  IDatosCliente,
} from "../../components/clientes/CardCliente";
import FormNuevoCliente from "../../components/clientes/FormNuevoCliente";
import Button from "../../components/generic/Button";
import Modal from "../../components/generic/Modal";
import $ from "jquery";
import { host, port } from "../../helpers/DbData";
import "./Clientes.css";
import PopupContext from "../../context/PopupContext";
import useItemsPP from "../../hooks/useItemsPP";
import Loader from "../../components/generic/Loader";
import Paginador from "../../components/generic/Paginador";
import BarraBusqueda from "../../components/generic/BarraBusqueda";
import FormOrdenesAfectadas, {
  IOrdenAfectada,
} from "../../components/clientes/FormOrdenesAfectadas";
import { motion } from "framer-motion";

function Clientes() {
  const { mostrarPopup } = useContext(PopupContext);
  const clientesPP = useItemsPP(340);

  const [clientes, setClientes] = useState<IDatosCliente[]>([]);
  const [clientesAux, setClientesAux] = useState<IDatosCliente[]>([]);

  const [activoNuevoCliente, setActivoNuevoCliente] = useState(false);
  const [numPag, setNumPag] = useState(0);
  const [pagina, setPagina] = useState(0);

  const [cargando, setMostrarCargando] = useState(false);
  const [idClienteEditar, setIdClienteEditar] = useState(0);

  const [modalOrdenesAfectadas, setModalOrdenesAfectadas] = useState(false);
  const [ordenesAfectadas, setOrdenesAfectadas] = useState<IOrdenAfectada[]>(
    []
  );

  const [moreData, setMoreData] = useState(["", ""]);

  const obtenerClientes = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/cliente`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setMostrarCargando(true);
      },
      success: function (data: IDatosCliente[]) {
        setMostrarCargando(false);
        setClientes(data.sort((b, a) => a.idcliente - b.idcliente));
      },
      error: function (data) {
        setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  const editarCliente = (idCliente: number) => {
    setActivoNuevoCliente(true);
    setIdClienteEditar(idCliente);
  };

  const cerrarModalCliente = () => {
    setActivoNuevoCliente(false);
    setIdClienteEditar(0);
  };

  useEffect(() => {
    obtenerClientes();
  }, [obtenerClientes]);

  useEffect(() => {
    setNumPag(Math.ceil(clientesAux.length / clientesPP));
  }, [clientesAux, clientesPP]);

  // cliente ordenes afectadas
  const [clienteOA, setClienteOA] = useState(0);
  const seleccionarOrdenesAfectadas = useCallback(
    (ordenesAfectadas: IOrdenAfectada[], idClienteOA: number) => {
      setModalOrdenesAfectadas(true);
      setOrdenesAfectadas(ordenesAfectadas);
      setClienteOA(idClienteOA);
    },
    []
  );

  return (
    <>
      <div className="cont-clientes animar-zoom-max-to-min">
        <div>
          {cargando ? (
            <Loader fullScreen />
          ) : (
            <div className="encabezado-nombre-barra-buscar">
              <h3 className="titulo-pagina">Clientes</h3>
              <BarraBusqueda
                data={clientes ?? []}
                keysToFilter={["empresa", "nombres", "identificacion"]}
                setFilteredData={setClientesAux}
                style={{ width: "300px" }}
              />
              <Button
                label={"Nuevo Cliente"}
                icono={"ico-anadir"}
                onClick={() => {
                  setActivoNuevoCliente(true);
                }}
              />
            </div>
          )}
        </div>
        <Paginador
          pages={
            numPag !== 0 ? Array.from({ length: numPag }, (_, i) => i + 1) : []
          }
          selected={pagina}
          setSelected={setPagina}
        />
        <div
          className={
            "pb-20 cont-contenido-clientes" +
            (!cargando ? " animar-entrada" : "")
          }
        >
          {clientesAux && clientesAux.length > 0
            ? clientesAux
                .slice(pagina * clientesPP, (pagina + 1) * clientesPP)
                .map((el) => {
                  return (
                    <motion.div layout key={el.idcliente} className="w-[340px]">
                      <CardCliente
                        datosCliente={el}
                        editar={editarCliente}
                        onHover={setMoreData}
                      />
                    </motion.div>
                  );
                })
            : !cargando && <h2>No existen clientes</h2>}
        </div>
        <div className="bg-white border-t-2 border-t-gray-300 fixed bottom-0 left-[50px] right-0 h-8 z-0 flex flex-row gap-4 items-center px-5">
          <span className="text-[var(--color-principal)] text-lg">
            {moreData[0]}
          </span>
          <span>|</span>
          <span>{moreData[1]}</span>
        </div>
      </div>
      <Modal activo={activoNuevoCliente} cerrar={cerrarModalCliente}>
        <FormNuevoCliente
          idCliente={idClienteEditar}
          cerrar={() => {
            cerrarModalCliente();
            obtenerClientes();
          }}
          clientes={clientes}
          seleccionarOrdenesAfectadas={seleccionarOrdenesAfectadas}
        />
      </Modal>
      <Modal activo={modalOrdenesAfectadas} temblar>
        <FormOrdenesAfectadas
          ordenesAfectadas={ordenesAfectadas}
          idcliente={clienteOA}
          cerrar={() => {
            setOrdenesAfectadas([]);
            setModalOrdenesAfectadas(false);
            setClienteOA(0);
          }}
        />
      </Modal>
    </>
  );
}

export default Clientes;
