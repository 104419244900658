import styles from "./MensajeCentro.module.css";

interface Props {
  mensaje: string;
}

function MensajeCentro({ mensaje }: Props) {
  return (
    <div className={`${styles.contMensajeCentro}`}>
      <p>{mensaje}</p>
    </div>
  );
}

export default MensajeCentro;
