import { useEffect, useState } from "react";
import Input from "../../../../../generic/Input";
import { IBalanza, IDetalleEquipo, IPesoPatron } from "../EquiposOrden";
import Button from "../../../../../generic/Button";
import { Tooltip } from "react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import Animations from "../../../../../../css/Animations";

export interface IRepuesto {
  repuesto: string;
  precio: string;
}

export interface IRepuestoExtended extends IRepuesto {
  idequipo: number;
  idrepuesto: number;
}

interface Props {
  idequipo: number;
  datosEquipoB?: IBalanza;
  datosEquipoPP?: IPesoPatron;
  anadir: (equipo: IDetalleEquipo) => void;
  eliminar: (idequipo: number) => void;
  detalleEquipo: IDetalleEquipo;
  index: number;
  activo: boolean;
  repuestos: IRepuestoExtended[];
  anadirRepuesto: (idequipo: number) => void;
  editarRepuesto: (
    idequipo: number,
    idrepuesto: number,
    repuesto: IRepuesto
  ) => void;
  eliminarRepuesto: (idrepuesto: number) => void;
}

// funcion que muestra un equipo de la pantalla de creación de orden de trabajo (nueva cotización)
function EquipoOrden({
  idequipo,
  datosEquipoB,
  datosEquipoPP,
  anadir,
  eliminar,
  detalleEquipo,
  index,
  activo,
  repuestos,
  anadirRepuesto,
  editarRepuesto,
  eliminarRepuesto,
}: Props) {
  // const findDetalle = useCallback((): IFormData => {
  //   const detalle = detalleEquipos.find((el1) => el1.idequipo === idequipo);
  //   return detalle
  //     ? {
  //         precioservicio: detalle.precioservicio,
  //         repuestoServicio: detalle.repuestoServicio,
  //       }
  //     : { precioservicio: "", repuestoServicio: "" };
  // }, [detalleEquipos, idequipo]);

  const [permitirEliminar, setPermitirEliminar] = useState(false);
  // const [formData, setFormData] = useState<IFormData>(findDetalle);
  const [respuestosOpen, setRespuestosOpen] = useState(repuestos.length > 0);

  useEffect(() => {
    if (!activo) {
      setRespuestosOpen(false);
      setPermitirEliminar(false);
    }
  }, [activo]);

  useEffect(() => {
    if (repuestos.length > 0) setRespuestosOpen(true);
  }, [repuestos]);

  useEffect(() => {
    if (Number(detalleEquipo.precioservicio) > 0) setPermitirEliminar(true);
    else setPermitirEliminar(false);
  }, [detalleEquipo.precioservicio]);

  // useEffect(() => {
  //   const detalle = findDetalle();
  //   if (
  //     detalle.precioservicio !== formData.precioservicio ||
  //     detalle.repuestoServicio !== formData.repuestoServicio
  //   ) {
  //     // esto se ejecuta para cargar los datos en el caso de editar una orden
  //     setFormData({
  //       precioservicio: detalle.precioservicio,
  //       repuestoServicio: detalle.repuestoServicio,
  //     });
  //     if (detalle.repuestoServicio && detalle.repuestoServicio.length > 0)
  //       setRespuestosOpen(true);
  //   }
  // }, [detalleEquipos]);

  // useEffect(() => {
  //   if (formData.precioservicio === "") {
  //     eliminar(idequipo);
  //     setPermitirEliminar(false);
  //   } else {
  //     anadir({ ...formData, idequipo: idequipo });
  //     setPermitirEliminar(true);
  //   }
  // }, [formData, idequipo]);

  const handleChange = (name: string, value: string) => {
    value = value.toString().replaceAll(",", ".");
    anadir({ ...detalleEquipo, idequipo: idequipo, [name]: value });
  };

  const limpiarCampos = () => {
    eliminar(idequipo);
    setPermitirEliminar(false);
  };

  const handleAnadirRepuesto = () => {
    anadirRepuesto(idequipo);
  };

  const handleChangeRepuesto = (
    idrepuesto: number,
    name: "precio" | "repuesto",
    value: string
  ) => {
    const repuestoData = repuestos.find((el) => el.idrepuesto === idrepuesto);
    editarRepuesto(idequipo, idrepuesto, {
      repuesto: repuestoData?.repuesto ?? "",
      precio: repuestoData?.precio ?? "",
      [name]: value,
    });
  };

  return (
    <div>
      <div
        className={`cont-fila-equipo-orden
      ${datosEquipoB ? "cont-fila-equipo-balanza" : ""}
      `}
      >
        {permitirEliminar ? (
          <div
            className="ico-cerrar format-ico-borrar-equipo"
            onClick={limpiarCampos}
          />
        ) : (
          <span />
        )}
        <span>{index + 1}</span>
        {/* contenido dinámico dependiendo de si es balanza o peso patrón */}
        {datosEquipoB ? (
          <div className="fila-orden-datos-equipo fila-orden-datos-equipo-balanza">
            <span>
              {datosEquipoB.capacidadacreditacion ? (
                <div className="ico-advertencia format-ico-estado" />
              ) : (
                <></>
              )}
              {datosEquipoB.tipo}{" "}
              {datosEquipoB.capacidadacreditacion ? (
                <>
                  {datosEquipoB.capacidad}
                  {datosEquipoB.capacidad1
                    ? "/" + datosEquipoB.capacidad1
                    : ""}{" "}
                  {datosEquipoB.abreviatura}
                </>
              ) : (
                <></>
              )}
            </span>
            <span>{datosEquipoB.marca}</span>
            <span>{datosEquipoB.modelo}</span>
            <span>{datosEquipoB.serie ?? "-"}</span>
            <span>{datosEquipoB.codigo ?? "-"}</span>
            <span>
              {datosEquipoB.capacidadacreditacion ? (
                <>{datosEquipoB.capacidadacreditacion} g</>
              ) : (
                <>
                  {datosEquipoB.capacidad}
                  {datosEquipoB.capacidad1
                    ? "/" + datosEquipoB.capacidad1
                    : ""}{" "}
                  {datosEquipoB.abreviatura}
                </>
              )}
            </span>
            <span>
              {datosEquipoB.divescalareal}{" "}
              {datosEquipoB.divescalareal1
                ? "/" + datosEquipoB.divescalareal1
                : ""}{" "}
              {datosEquipoB.capacidadacreditacion
                ? datosEquipoB.abreviatura
                : ""}
            </span>
            <span>{datosEquipoB.clase}</span>
            <span>{datosEquipoB.ubicacion}</span>
          </div>
        ) : datosEquipoPP ? (
          <div className="fila-orden-datos-equipo fila-orden-datos-equipo-pesopatron">
            <span>
              {(!datosEquipoPP.acreditado || !datosEquipoPP.cumpleoiml) && (
                <div className="ico-advertencia format-ico-estado" />
              )}
              {datosEquipoPP.marca}{" "}
            </span>
            <span>{datosEquipoPP.serie ?? "-"}</span>
            <span>{datosEquipoPP.codigo ?? "-"}</span>
            <span>
              {datosEquipoPP.valornominal} {datosEquipoPP.abreviatura}
            </span>
            <span>{datosEquipoPP.clase}</span>
            <span>{datosEquipoPP.material ?? "-"}</span>
            <span>{datosEquipoPP.ubicacion ?? "-"}</span>
          </div>
        ) : (
          <p />
        )}
        <Input
          placeholder="0"
          name={"precioservicio"}
          onChange={handleChange}
          value={detalleEquipo.precioservicio}
          tipo="number"
        />
        {datosEquipoB && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setRespuestosOpen((rO) => !rO);
            }}
          >
            <div
              className={`ico-arriba-simple w-7 h-7 transition-transform opacity-80 hover:bg-neutral-300 rounded-full p-1
            ${respuestosOpen ? "rotate-0" : "rotate-180"}`}
            />
          </button>
        )}
      </div>
      <AnimatePresence>
        {datosEquipoB && respuestosOpen && (
          <motion.div {...Animations.dropdown} className="overflow-hidden">
            <div className="flex flex-col grid-full-width bg-neutral-100 p-3 shadow-inner">
              <div className="grid grid-cols-[60px_3fr_1fr_40px] w-full gap-3 items-center">
                <span className="ml-2">Ítem</span>
                <span className="ml-2">Repuestos</span>
                <span className="ml-2">Precio</span>
                <div className="flex justify-end">
                  <Button
                    id="btn-anadir-repuesto"
                    data-tooltip-id="btn-anadir-repuesto"
                    onClick={handleAnadirRepuesto}
                    icono="ico-anadir"
                    nivel={2}
                  />
                  <Tooltip id="btn-anadir-repuesto" content="Añadir repuesto" />
                </div>
              </div>
              <AnimatePresence initial={false}>
                {repuestos.length === 0 ? (
                  <motion.p
                    {...Animations.dropdownFade}
                    layout
                    className="text-neutral-600 text-center"
                  >
                    No hay repuestos
                  </motion.p>
                ) : (
                  repuestos.map((el, i) => (
                    <motion.div
                      {...Animations.dropdownFade}
                      key={el.idrepuesto}
                    >
                      <div className="grid w-full gap-3 grid-cols-[60px_3fr_1fr_40px] pt-3">
                        <span className="ml-2">{i + 1}</span>
                        <Input
                          name="repuesto"
                          onChange={(name, value) =>
                            handleChangeRepuesto(el.idrepuesto, name, value)
                          }
                          value={el.repuesto}
                          placeholder="Repuesto"
                        />
                        <Input
                          name="precio"
                          onChange={(name, value) =>
                            handleChangeRepuesto(el.idrepuesto, name, value)
                          }
                          value={el.precio}
                          placeholder="Precio"
                          validacion={{
                            mensaje: "El precio debe ser un número",
                            validado: !isNaN(Number(el.precio)),
                          }}
                        />
                        <div className="flex justify-end">
                          <Button
                            id={"btn-eliminar-repuesto" + el.idrepuesto}
                            data-tooltip-id={
                              "btn-eliminar-repuesto" + el.idrepuesto
                            }
                            onClick={() => eliminarRepuesto(el.idrepuesto)}
                            icono="ico-eliminar"
                            color="ROJO"
                            nivel={2}
                          />
                          <Tooltip
                            id={"btn-eliminar-repuesto" + el.idrepuesto}
                            content="Eliminar repuesto"
                          />
                        </div>
                      </div>
                    </motion.div>
                  ))
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default EquipoOrden;
