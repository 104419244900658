import { useCallback, useContext, useEffect, useState, memo } from "react";
import $ from "jquery";
import { host, port } from "../../../../helpers/DbData";
import "./DetalleOrdenTrabajo.css";
import FormDatosServicio from "./detalle/FormDatosServicio";
import Tabs from "../../../generic/Tabs";
import ResumenDetalle from "./detalle/ResumenDetalle";
import VersionesCotizacion from "./detalle/VersionesCotizacion";
import PopupContext from "../../../../context/PopupContext";
import RecepcionItems from "./detalle/RecepcionItems";
import Loader from "../../../generic/Loader";
import Certificados from "./detalle/Certificados";
import { IDetalleData } from "./detalle/ResumenDetalle";
import HistorialCambios from "./detalle/HistorialCambios";

let initialDetalleData: IDetalleData = {
  idcliente: 0,
  idordentrabajo: 0,
  fechaserviciodesde: null,
  fechaserviciohasta: null,
  servicio: "",
  tiposequipos: [],
  costoextra: {
    cantidad: 1,
    descripcion: "Visita técnica",
    costounitario: 0,
  },
  contrato: {
    idformapago: 1,
    diascredito: 8,
    vigencia: 15,
    proveedor: "",
    observaciones: "",
    descuento: 0,
  },
  detalleequipos: [],
  tecnicosorden: [],
  descripcionestadoot: "",
  estado: 0,
  serviciosexternos: [],
  version: 0,
  codigocotizacion: "",
  num: 0,
  year: 0,
};

interface Props {
  activo: boolean;
  cerrar: () => void;
  idOrdenTrabajo: number;
  setHuboCambio?: (value: boolean) => void;
}

const DetalleOrdenTrabajo = memo(
  ({ activo, cerrar, idOrdenTrabajo, setHuboCambio }: Props) => {
    const { mostrarPopup } = useContext(PopupContext);
    const [detalleData, setDetalleData] = useState(initialDetalleData);
    const [editar, setEditar] = useState(false);
    const [tecnicos, setTecnicos] = useState([]);
    const [tabSelected, setTabSelected] = useState(0);
    const [cargando, setCargando] = useState(false);

    const obtenerOrden = useCallback(() => {
      $.ajax({
        url: `${host}:${port}/api/ordenTrabajo/ordenbyidOrden`,
        type: "get",
        dataType: "json",
        contentType: "application/json",
        data: { idordentrabajo: idOrdenTrabajo, ordencompleta: 1 },
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          // setDetalleData(formatearData(data));
          setDetalleData(data);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }, [idOrdenTrabajo, mostrarPopup]);

    useEffect(() => {
      if (!activo) {
        setEditar(false);
        setTabSelected(0);
        setDetalleData(initialDetalleData);
      }
    }, [activo]);

    useEffect(() => {
      if (idOrdenTrabajo && idOrdenTrabajo !== 0 && activo) {
        obtenerOrden();
      }
    }, [obtenerOrden, idOrdenTrabajo, activo]);

    useEffect(() => {
      obtenerTecnicos();
    }, []);

    const obtenerTecnicos = () => {
      $.ajax({
        url: `${host}:${port}/api/nuevaCotizacion/getTecnicos`,
        type: "get",
        dataType: "json",
        contentType: "application/json",
        beforeSend: function () {
          // setMostrarCargando(true);
        },
        success: function (data) {
          // setMostrarCargando(false);
          setTecnicos(data);
        },
        error: function (data) {
          // setMostrarCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    };

    const cerrarFDS = useCallback(() => {
      setEditar(false);
      obtenerOrden();
    }, [obtenerOrden]);

    const renderSwitch = () => {
      switch (tabSelected) {
        case 0:
          return (
            <>
              {editar ? (
                <FormDatosServicio
                  cerrar={cerrarFDS}
                  idordentrabajo={idOrdenTrabajo}
                  data={{
                    fechaserviciodesde: detalleData.fechaserviciodesde,
                    fechaserviciohasta: detalleData.fechaserviciohasta,
                    tecnicosorden: detalleData.tecnicosorden,
                  }}
                  setHuboCambio={setHuboCambio}
                  tecnicos={tecnicos}
                  setEditar={setEditar}
                />
              ) : (
                <ResumenDetalle
                  setEditar={setEditar}
                  detalleData={detalleData}
                  idordentrabajo={idOrdenTrabajo}
                  tecnicos={tecnicos}
                  cerrarModal={cerrar}
                />
              )}
            </>
          );
        case 1:
          return <RecepcionItems idordentrabajo={idOrdenTrabajo} />;
        case 2:
          return (
            <div
              className="cont-grid-equal-gap"
              style={{ gridTemplateColumns: "1fr 0.5fr" }}
            >
              <Certificados
                idordentrabajo={idOrdenTrabajo}
                idcliente={detalleData.idcliente}
              />
              <VersionesCotizacion
                idordentrabajo={idOrdenTrabajo}
                idcliente={detalleData.idcliente}
              />
            </div>
          );
        case 3:
          return <HistorialCambios idordentrabajo={idOrdenTrabajo} />;
        default:
          break;
      }
    };

    return (
      <div className="cont-detalle-ot" style={{ width: "750px" }}>
        {cargando ? (
          <Loader />
        ) : (
          <>
            <div className="titulo-accion">
              <h3>
                Orden N° {detalleData.year}-{detalleData.num}
                {detalleData.version ? `.${detalleData.version}` : ""}
              </h3>
              <Tabs
                lista={[
                  "Resumen",
                  "Recepción de ítems",
                  "Documentos",
                  "Historial",
                ]}
                selected={tabSelected}
                setSelected={setTabSelected}
                nivel={1}
              />
            </div>
            {renderSwitch()}
          </>
        )}
      </div>
    );
  }
);

export default DetalleOrdenTrabajo;
