import FilaFormato from "../../components/configuracion/formatos/FilaFormato";
import "./Formatos.css";

function Formatos() {
  return (
    <div className="animar-zoom-min-to-max">
      <h3 className="titulo-pagina">Formatos</h3>
      <div className="cont-listas-formatos">
        {/* <h3>Cotizaciones</h3> */}
        <div>
          <h3>Cotizaciones</h3>
          <div className="tabla-formatos">
            <FilaFormato label="Cotización" to="cotizacion" />
          </div>
        </div>
        <div>
          <h3>Certificados</h3>
          <div className="tabla-formatos">
            <FilaFormato label="Balanzas" to="balanzas" />
            <FilaFormato label="Pesos patrón" to="pesospatron" />
          </div>
        </div>
        <div>
          <h3>Informes</h3>
          <div className="tabla-formatos">
            <FilaFormato label="Mantenimiento preventivo" to="mantenimiento" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Formatos;
