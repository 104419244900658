import LabelText from "../../../../generic/LabelText";

export interface IMedicionesPP {
  identificacionlab: string;
  valornominal: number;
  densidad: number;
  incertidumbredensidad: number;
  masareal: number;
  masaconvencional: number;
  incertidumbremasa: number;
  clase: string;
  numdecimales: number;
}

interface Props {
  data: IMedicionesPP[];
  balanzas: string[];
}

function MedicionesPP({ data, balanzas }: Props) {
  return (
    <>
      <h3>Resultados obtenidos</h3>
      <div>
        <div className="table-row table-row-title">
          <span>N°</span>
          <span>ID/Código</span>
          <span>Valor nominal (g)</span>
          <span>Densidad (g/cm3)</span>
          <span>Incert. densidad (g/cm3)</span>
          <span>Masa real (g)</span>
          <span>Masa convencional vs. 8g/cm3 (g)</span>
          <span>Incert. masa k=2 (+-g)</span>
          <span>Clase</span>
        </div>
        {data.map((el, i) => (
          <div key={i} className="table-row row-equipo animar-entrada">
            <span>{i + 1}</span>
            <span>{el.identificacionlab}</span>
            <span>{el.valornominal}</span>
            <span>{Number(el.densidad).toFixed(2)}</span>
            <span>{Number(el.incertidumbredensidad).toFixed(2)}</span>
            <span>{Number(el.masareal).toFixed(el.numdecimales)}</span>
            <span>{Number(el.masaconvencional).toFixed(el.numdecimales)}</span>
            <span>{Number(el.incertidumbremasa).toPrecision(2)}</span>
            <span>{el.clase}</span>
          </div>
        ))}
      </div>
      <LabelText label={"Balanzas"} text="" />
      {balanzas.map((el, i) => (
        <p key={i}>{el}</p>
      ))}
    </>
  );
}

export default MedicionesPP;
