import { Tooltip } from "react-tooltip";

function EquipoBalanza({
  datosEquipo = {},
  detalles,
  editar,
  duplicar,
  eliminar,
}) {
  return (
    <>
      <div className="table-row row-equipo animar-entrada min-h-[3rem]">
        <span>{datosEquipo.marca}</span>
        <span>{datosEquipo.modelo}</span>
        <span>{datosEquipo.serie ?? "-"}</span>
        <span>{datosEquipo.codigo ?? "-"}</span>
        <span>
          {datosEquipo.capacidad}
          {datosEquipo.capacidad1 ? "/" + datosEquipo.capacidad1 : ""}{" "}
          {datosEquipo.abreviatura}
        </span>
        <span>
          {datosEquipo.divescalareal}
          {datosEquipo.divescalareal1 ? "/" + datosEquipo.divescalareal1 : ""}
        </span>
        <span>{datosEquipo.tipo}</span>
        {/* <span>{datosEquipo.empleo}</span> */}
        <div className="row-cont-acciones">
          <div
            id="btn-detalles"
            data-tooltip-id="btn-detalles"
            className="ico-documento h-7 row-accion p-1"
            onClick={() => detalles(datosEquipo.idequipo)}
          />
          <div
            id="btn-editar"
            data-tooltip-id="btn-editar"
            className="ico-lapiz h-7 row-accion p-1"
            onClick={() => editar(datosEquipo.idequipo)}
          />
          <div
            id="btn-duplicar"
            data-tooltip-id="btn-duplicar"
            className="ico-copiar h-7 row-accion p-1"
            onClick={() => duplicar(datosEquipo.idequipo)}
          />
          <div
            id="btn-eliminar"
            data-tooltip-id="btn-eliminar"
            className="ico-eliminar h-7 row-accion p-1"
            onClick={() => eliminar(datosEquipo.idequipo)}
          />
        </div>
      </div>
      <Tooltip id={"btn-detalles"} content="Detalles" />
      <Tooltip id={"btn-editar"} content="Editar" />
      <Tooltip id={"btn-duplicar"} content="Duplicar" />
      <Tooltip id={"btn-eliminar"} content="Eliminar" />
    </>
  );
}

export default EquipoBalanza;

// titulo de las filas de la tabla de equipos de balanza
function EquipoBalanzaTitulo() {
  return (
    <div className="table-row table-row-title">
      <span>Marca</span>
      <span>Modelo</span>
      <span>Serie</span>
      <span>Código</span>
      <span>Capacidad</span>
      <span>Resolución</span>
      <span>Tipo</span>
      {/* <span>Empleo</span> */}
      <span style={{ textAlign: "center" }}>Acciones</span>
    </div>
  );
}

export { EquipoBalanzaTitulo };
