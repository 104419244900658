import Button from "../../generic/Button";
import ContInput from "../../generic/ContInput";
import $ from "jquery";
import { useContext, useEffect, useRef, useState } from "react";
import { host, port } from "../../../helpers/DbData";
import { Validar } from "../../../helpers/Validar";

import "./FormUsuario.css";
import PopupContext from "../../../context/PopupContext";
import ConfirmContext from "../../../context/ConfirmContext";
import useForm from "../../../hooks/useForm";
import { IConfigValidacion } from "../../../hooks/useForm";
import Input from "../../generic/Input";
import Switch from "../../generic/Switch";
import Select from "../../generic/Select";
import Loader from "../../generic/Loader";

interface IFormData {
  nombre: string;
  cedula: string;
  idperfil: number;
  estado: 1 | 0;
  clavefirma: string;
}

let initialFormData: IFormData = {
  nombre: "",
  cedula: "",
  idperfil: 0,
  estado: 1,
  clavefirma: "",
};

let initialFormValidacion: IConfigValidacion = {
  nombre: { validar: "texto", opcional: false },
  cedula: { validar: "cedula", opcional: false },
  idperfil: { validar: "numerosPositivos", opcional: false },
  clavefirma: { validar: "general", opcional: true },
};

interface Props {
  idUsuario: number;
  cerrar: () => void;
}

function FormUsuario({ idUsuario, cerrar }: Props) {
  const [mounted, setMounted] = useState(true);
  const [cargando, setCargando] = useState(false);
  const {
    formData,
    setFormData,
    formValidacion,
    formValidado,
    handleChange,
    resetForm,
  } = useForm(initialFormData, initialFormValidacion);
  const [tempUsuario, setTempUsuario] = useState(initialFormData);
  const [perfiles, setPerfiles] = useState<
    { idperfil: number; perfil: string }[]
  >([]);
  const [editando, setEditando] = useState(false);
  const [nombreArchivoFirma, setNombreArchivoFirma] = useState("");

  const { mostrarPopup } = useContext(PopupContext);
  const { mostrarConfirm } = useContext(ConfirmContext);

  const refFirma = useRef<HTMLInputElement>(null);
  useEffect(() => {
    cargarPerfiles();
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (idUsuario === 0) {
      setEditando(true);
      setFormData(initialFormData);
      if (refFirma.current) {
        refFirma.current.value = "";
        refFirma.current.files = null;
        setNombreArchivoFirma("");
      }
    } else {
      setEditando(false);
      obtenerUsuario();
    }
    setTempUsuario(initialFormData);
  }, [idUsuario]);

  const guardarUsuario = () => {
    const validacion1 =
      (Number(formData.idperfil) !== 4 &&
        refFirma.current &&
        refFirma.current.files &&
        refFirma.current.files.length > 0 &&
        formData.clavefirma.length > 0) ||
      Number(formData.idperfil) === 4;

    if (formValidado && validacion1) {
      crearUsuario();
    } else {
      mostrarPopup(2, "Llena todos los datos");
    }
  };

  const editarUsuario = () => {
    setTempUsuario(formData);
    setEditando(true);
  };

  const cancelarEdicion = () => {
    setFormData(tempUsuario);
    setEditando(false);
  };

  const cargarPerfiles = () => {
    $.ajax({
      url: `${host}:${port}/api/perfil`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        if (mounted) {
          setCargando(false);
          setPerfiles(data);
        }
      },
      error: function (data) {
        setCargando(false);
        console.log(data.responseJSON.data);
        let mensaje = data.responseJSON.data;
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(2, mensaje);
      },
    });
  };

  const obtenerUsuario = () => {
    if (idUsuario && idUsuario > 0) {
      $.ajax({
        url: `${host}:${port}/api/usuario/${idUsuario}`,
        type: "get",
        dataType: "json",
        contentType: "application/json",
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          data = data[0];
          setCargando(false);
          let formattedData = {
            nombre: data.nombre,
            cedula: data.cedula,
            idperfil: parseInt(data.idperfil),
          };
          setFormData({ ...formData, ...formattedData, estado: data.estado });
        },
        error: function (data) {
          setCargando(false);
          console.log(data.responseJSON.data);
          let mensaje = data.responseJSON.data;
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(2, mensaje);
        },
      });
    } else {
      setFormData(initialFormData);
    }
  };

  const crearUsuario = () => {
    if (!cargando) {
      let fD = new FormData();
      fD.append("nombre", formData.nombre);
      fD.append("cedula", formData.cedula);
      fD.append("idperfil", formData.idperfil.toString());
      if (Number(formData.idperfil) !== 4) {
        fD.append("clavefirma", formData.clavefirma);
      }
      fD.append("estado", formData.estado.toString());
      if (
        Number(formData.idperfil) !== 4 &&
        refFirma.current &&
        refFirma.current.files &&
        refFirma.current.files.length > 0
      ) {
        fD.append("file", refFirma.current.files[0]);
      }
      fD.append("id", idUsuario.toString());
      setNombreArchivoFirma("");
      $.ajax({
        // url: `${host}:${port}/api/usuario/edit`,
        url: `${host}:${port}/api/siging/uploadSiging`,
        type: "post",
        contentType: false,
        processData: false,
        data: fD,
        // contentType: "application/json",
        // data: JSON.stringify({ ...usuario, imgfirma: "-", id: idUsuario }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data.data);
          cerrar();
        },
        error: function (data) {
          setCargando(false);
          console.log(data.responseText);
          let mensaje = data.responseText;
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(2, mensaje);
        },
      });
    }
  };

  const eliminarUsuario = async () => {
    if (
      (await mostrarConfirm("¿Seguro que deseas eliminar el usuario?")) &&
      !cargando
    )
      $.ajax({
        url: `${host}:${port}/api/usuario/edit`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ id: idUsuario, estado: 100 }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, "Usuario eliminado");
          cerrar();
        },
        error: function (data) {
          setCargando(false);
          console.log(data.responseJSON.data);
          let mensaje = data.responseJSON.data;
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(2, mensaje);
        },
      });
  };

  const restaurarContrasena = async () => {
    if (
      (await mostrarConfirm(
        "¿Seguro de restaurar la contraseña? La nueva contraseña será '1234'"
      )) &&
      !cargando
    )
      $.ajax({
        url: `${host}:${port}/api/usuario/resetearClaveUsuario`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({ id: idUsuario, hash: "12P_x21" }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data);
          cerrar();
        },
        error: function (data) {
          setCargando(false);
          console.log(data.responseJSON.data);
          let mensaje = data.responseJSON.data;
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(2, mensaje);
        },
      });
  };

  return (
    <div className="cont-form-usuario">
      {cargando ? (
        <Loader />
      ) : (
        <>
          <h3>{idUsuario === 0 ? "Nuevo usuario" : formData.nombre}</h3>
          <div className="form-usuario-acciones">
            {idUsuario && idUsuario !== 0 && !editando ? (
              <>
                <Button
                  label={"Editar"}
                  icono={"ico-lapiz"}
                  onClick={editarUsuario}
                  nivel={2}
                />
                <Button
                  label={"Restaurar clave"}
                  icono={"ico-restaurar"}
                  onClick={restaurarContrasena}
                  nivel={2}
                />
              </>
            ) : (
              ""
            )}
            {idUsuario && idUsuario !== 0 && editando ? (
              <Button
                label={"Cancelar"}
                onClick={cancelarEdicion}
                icono="ico-rechazar"
                color="ROJO"
                nivel={2}
              />
            ) : (
              ""
            )}
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input
              label="Nombre"
              icono={"ico-usuario"}
              value={formData.nombre}
              onChange={handleChange}
              name="nombre"
              disabled={!editando}
              placeholder="Juan Pérez"
              validacion={formValidacion.nombre}
            />
            <Input
              label="Cédula"
              icono={"ico-ruc"}
              value={formData.cedula}
              onChange={handleChange}
              name="cedula"
              disabled={!editando}
              placeholder="1700000001"
              validacion={formValidacion.cedula}
            />
            <Select
              name="idperfil"
              onChange={handleChange}
              options={perfiles.map((el) => ({
                label: el.perfil,
                value: el.idperfil,
              }))}
              value={formData.idperfil}
              label={"Privilegios"}
              validacion={formValidacion.idperfil}
            />
            <Switch
              label="Estado"
              name="estado"
              onChange={handleChange}
              value={formData.estado}
            />
            {Number(formData.idperfil) !== 4 && (
              <>
                <ContInput label="Firma" icono={"ico-huella"}>
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "200px",
                      overflow: "hidden",
                    }}
                  >
                    {nombreArchivoFirma ?? ""}
                  </span>
                  <input
                    ref={refFirma}
                    type={"file"}
                    style={{ display: "none" }}
                    accept=".p12"
                    onChange={(e) => {
                      if (
                        e.target.files &&
                        e.target.files.length > 0 &&
                        e.target.files[0].name
                      )
                        setNombreArchivoFirma(e.target.files[0].name);
                    }}
                  />
                  <Button
                    icono="ico-cargar"
                    onClick={() => {
                      if (refFirma.current) refFirma.current.click();
                    }}
                    disabled={!editando}
                    nivel={3}
                  />
                </ContInput>
                {refFirma.current &&
                  refFirma.current.files &&
                  refFirma.current?.files.length > 0 &&
                  refFirma.current.files[0].name.split(".")[1].toLowerCase() !==
                    "p12" && (
                    <p className="texto-validacion">
                      Solo archivos con extensión .p12
                    </p>
                  )}
                {editando && (
                  <Input
                    label="Clave firma"
                    icono={"ico-candado"}
                    placeholder="1234"
                    name="clavefirma"
                    onChange={handleChange}
                    value={formData.clavefirma}
                    validacion={formValidacion.clavefirma}
                    tipo={"password"}
                  />
                )}
              </>
            )}
            {editando && (
              <div className="form-usuario-acciones">
                <Button
                  label={"Guardar"}
                  icono={"ico-guardar"}
                  onClick={guardarUsuario}
                  cargando={cargando}
                />
                {idUsuario !== 0 && (
                  <Button
                    label={"Eliminar"}
                    icono={"ico-eliminar"}
                    onClick={eliminarUsuario}
                    color="ROJO"
                  />
                )}
              </div>
            )}
          </form>
        </>
      )}
    </div>
  );
}

export default FormUsuario;
