import { useEffect, useState, useContext } from "react";
import "./HojaDatosBalanza.css";
import { host, port } from "../../../../helpers/DbData";
import $ from "jquery";
import Button from "../../../generic/Button";
import IdentificacionCliente from "./hojaDatosBalanza/IdentificacionCliente";
import { IIdentificacionCliente } from "./hojaDatosBalanza/IdentificacionCliente";
import IdentificacionEquipo from "./hojaDatosBalanza/IdentificacionEquipo";
import PopupContext from "../../../../context/PopupContext";
import { IIdentificacionEquipo } from "./hojaDatosBalanza/IdentificacionEquipo";
import VerificacionesPrevias from "./hojaDatosBalanza/VerificacionesPrevias";
import { IVerificacionesPrevias } from "./hojaDatosBalanza/VerificacionesPrevias";
import Excentricidad from "./hojaDatosBalanza/Excentricidad";
import { IExcentricidad } from "./hojaDatosBalanza/Excentricidad";
import Linealidad from "./hojaDatosBalanza/Linealidad";
import { IRowLinealidad } from "./hojaDatosBalanza/Linealidad";
import Repetibilidad from "./hojaDatosBalanza/Repetibilidad";
import { IRepetibilidad } from "./hojaDatosBalanza/Repetibilidad";
import VerificacionNorma, {
  IVerificacionNorma,
} from "./hojaDatosBalanza/VerificacionNorma";
import UserContext from "../../../../context/UserContext";
import Loader from "../../../generic/Loader";
import MensajeCentro from "../../../generic/MensajeCentro";
import { IEquipoActivo } from "../Calibraciones";
import Problemas from "./hojaDatosBalanza/Problemas";
import { IProblema } from "./hojaDatosBalanza/Problemas";
import Fallas from "./hojaDatosBalanza/Fallas";
import Frecuencia from "./hojaDatosBalanza/Frecuencia";
import Observaciones from "./hojaDatosBalanza/Observaciones";

interface IDatosCertificado {
  identificacioncliente: IIdentificacionCliente;
  identificacionequipo: IIdentificacionEquipo;
  m_otros: { frecuencia: string };
  problemascausas: IProblema[];
  fallas: { falla: string }[];
  verificacionesprevias: IVerificacionesPrevias;
  excentricidad: IExcentricidad[];
  linealidad: IRowLinealidad[];
  repetibilidad: IRepetibilidad;
  verificacionnorma: IVerificacionNorma;
  observaciones: { observacion: string };
}

const initialDatosCertificado: IDatosCertificado = {
  identificacioncliente: {
    cliente: "",
    direccion: "",
    fechacalibracion: "",
    telefono: "",
  },
  identificacionequipo: {
    capacidad1: null,
    capacidad: 0,
    clase: 0,
    codigo: "",
    divescalareal1: null,
    divescalareal: 0,
    divescalaverif1: null,
    divescalaverif: 0,
    idtipoplataforma: 0,
    marca: "",
    modelo: "",
    responsable: "",
    serie: "",
    tipo: "",
    tipoequipo: "",
    ubicacion: "",
    unidadmedida: "",
    numdecimales1: 0,
    numdecimales2: null,
  },
  verificacionesprevias: {
    aptaproceso: 0,
    ceroinicial: 0,
    estado: "",
    lecturacargaprueba: 0,
    motivonoapta: "",
    nivelada: 0,
    otroestadoequipo: "",
    retornocero: 0,
    idnumdecimales: 1,
  },
  excentricidad: [],
  linealidad: [],
  repetibilidad: {
    carga: 0,
    difmax: 0,
    emp: 0,
    // idcertdata: 0,
    p1: 0,
    p2: 0,
    p3: 0,
    idnumdecimales: 1,
  },
  verificacionnorma: {
    excentricidad: 0,
    linealidad: 0,
    repetibilidad: 0,
  },
  fallas: [],
  m_otros: { frecuencia: "" },
  problemascausas: [],
  observaciones: { observacion: "" },
};

interface Props {
  data: IEquipoActivo;
  cerrar: () => void;
}

function HojaDatosMantenimiento({ data, cerrar }: Props) {
  const [datosCertificado, setDatosCertificado] = useState<IDatosCertificado>(
    initialDatosCertificado
  );
  const [cargando, setCargando] = useState(false);
  const [cargando1, setCargando1] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [cambios, setCambios] = useState(0);
  const [editando, setEditando] = useState({
    identificacioncliente: false,
    identificacionequipo: false,
    observaciones: false,
  });

  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    obtenerCalibracionEquipo();
  }, [data.iddetalleordentrabajo]);

  const obtenerCalibracionEquipo = () => {
    $.ajax({
      url: `${host}:${port}/api/certmantenimiento/calcular`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        iddetalleordentrabajo: data.iddetalleordentrabajo,
        revisado: 0,
        cambios: 0,
        idusuario: userData.idusuario,
      }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setDatosCertificado(data);
        setCargando(false);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const guardarAprobar = () => {
    $.ajax({
      url: `${host}:${port}/api/certmantenimiento/calcular`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        iddetalleordentrabajo: data.iddetalleordentrabajo,
        revisado: 1,
        cambios,
        data: {
          identificacioncliente: datosCertificado.identificacioncliente,
          identificacionequipo: datosCertificado.identificacionequipo,
        },
        idusuario: userData.idusuario,
      }),
      beforeSend: function () {
        setCargando1(true);
      },
      success: function (data) {
        setCambios(0);
        setCargando1(false);
        mostrarPopup(1, data.message);
        cerrar();
      },
      error: function (data) {
        setCargando1(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  return (
    <>
      {cargando ? (
        <Loader />
      ) : (
        <div className="cont-hoja-datos animar-entrada">
          {data.idcertdata === 0 ? (
            <MensajeCentro mensaje="Selecciona un equipo" />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {userData.idperfil === 2 && (
                  <Button
                    label={"Guardar y aprobar"}
                    icono={"ico-guardar"}
                    onClick={guardarAprobar}
                    cargando={cargando1}
                    disabled={
                      editando.identificacioncliente ||
                      editando.identificacionequipo ||
                      editando.observaciones
                    }
                  />
                )}
              </div>
              <div className="cont-hoja1 animar-entrada">
                <IdentificacionCliente
                  mantenimiento
                  data={datosCertificado.identificacioncliente}
                  permitirEdicion={userData.idperfil === 2}
                  setData={(data: IIdentificacionCliente) => {
                    setCambios(1);
                    setDatosCertificado({
                      ...datosCertificado,
                      identificacioncliente: data,
                    });
                  }}
                  setEditando={(editando1) =>
                    setEditando({
                      ...editando,
                      identificacioncliente: editando1,
                    })
                  }
                />
                <IdentificacionEquipo
                  data={datosCertificado.identificacionequipo}
                  permitirEdicion={userData.idperfil === 2}
                  setData={(data: IIdentificacionEquipo) => {
                    setCambios(1);
                    setDatosCertificado({
                      ...datosCertificado,
                      identificacionequipo: data,
                    });
                  }}
                  setEditando={(editando1) =>
                    setEditando({
                      ...editando,
                      identificacionequipo: editando1,
                    })
                  }
                />
                <Frecuencia data={datosCertificado.m_otros} />
                <div className="calibracion-2-column">
                  <Problemas data={datosCertificado.problemascausas} />
                  <Fallas data={datosCertificado.fallas} />
                </div>
                <VerificacionesPrevias
                  mantenimiento
                  data={datosCertificado.verificacionesprevias}
                  numdecimales={{
                    numdecimales1:
                      datosCertificado.identificacionequipo.numdecimales1,
                    numdecimales2:
                      datosCertificado.identificacionequipo.numdecimales2,
                  }}
                  unidadmedida={
                    datosCertificado.identificacionequipo.unidadmedida
                  }
                />
                <Excentricidad
                  data={datosCertificado.excentricidad}
                  numdecimales={{
                    numdecimales1:
                      datosCertificado.identificacionequipo.numdecimales1,
                    numdecimales2:
                      datosCertificado.identificacionequipo.numdecimales2,
                  }}
                  unidadmedida={
                    datosCertificado.identificacionequipo.unidadmedida
                  }
                />
                <Linealidad
                  mantenimiento
                  data={datosCertificado.linealidad}
                  numdecimales={{
                    numdecimales1:
                      datosCertificado.identificacionequipo.numdecimales1,
                    numdecimales2:
                      datosCertificado.identificacionequipo.numdecimales2,
                  }}
                  unidadmedida={
                    datosCertificado.identificacionequipo.unidadmedida
                  }
                />
                <Repetibilidad
                  data={datosCertificado.repetibilidad}
                  numdecimales={{
                    numdecimales1:
                      datosCertificado.identificacionequipo.numdecimales1,
                    numdecimales2:
                      datosCertificado.identificacionequipo.numdecimales2,
                  }}
                  unidadmedida={
                    datosCertificado.identificacionequipo.unidadmedida
                  }
                />
                <VerificacionNorma data={datosCertificado.verificacionnorma} />
                <Observaciones
                  data={datosCertificado.observaciones}
                  permitirEdicion={userData.idperfil === 2}
                  setData={(data: string) => {
                    setCambios(1);
                    setDatosCertificado({
                      ...datosCertificado,
                      observaciones: { observacion: data },
                    });
                  }}
                  setEditando={(editando1) =>
                    setEditando({ ...editando, observaciones: editando1 })
                  }
                />
                {/* <h3>Firmas de responsabilidad</h3>
              <div></div> */}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default HojaDatosMantenimiento;
