import LabelText from "../../../../generic/LabelText";
import { useState, useEffect, useContext } from "react";
import Button from "../../../../generic/Button";
import PopupContext from "../../../../../context/PopupContext";
import MensajesUI from "../../../../../helpers/MensajesUI";
import useForm from "../../../../../hooks/useForm";
import { IConfigValidacion } from "../../../../../hooks/useForm";
import Input from "../../../../generic/Input";

const initialFormValidacion: IConfigValidacion = {
  cliente: { validar: "general", opcional: false },
  telefono: { validar: "telefono", opcional: false },
  direccion: { validar: "general", opcional: false },
  lugarcalibracion: { validar: "general", opcional: false },
  fechacalibracion: { validar: "general", opcional: false },
  fechamantenimiento: { validar: "general", opcional: false },
};

export interface IIdentificacionCliente {
  cliente: string;
  telefono: string;
  direccion: string;
  lugarcalibracion?: string;
  fechacalibracion: string;
}

interface Props {
  mantenimiento?: boolean;
  data: IIdentificacionCliente;
  permitirEdicion: boolean;
  setData: (data: IIdentificacionCliente) => void;
  setEditando: (editando: boolean) => void;
}

function IdentificacionCliente({
  mantenimiento = false,
  data,
  permitirEdicion,
  setData,
  setEditando,
}: Props) {
  const { formData, formValidacion, formValidado, handleChange, setFormData } =
    useForm(data, initialFormValidacion);
  const [editar, setEditar] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setEditando(editar);
  }, [editar]);

  const cancelar = () => {
    setEditar(false);
    setFormData(data);
  };

  const guardar = () => {
    if (formValidado) {
      setEditar(false);
      setData(formData);
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  return (
    <div className="cont-card">
      <div className="calibracion-titulo-btn">
        <h3>Identificación del cliente</h3>
        {permitirEdicion && (
          <>
            {editar ? (
              <div className="cont-flex-horizontal-gap">
                <Button label="Guardar" onClick={guardar} nivel={2} />
                <Button
                  label="Cancelar"
                  onClick={cancelar}
                  nivel={2}
                  color="ROJO"
                />
              </div>
            ) : (
              <Button
                label="Editar"
                onClick={() => setEditar(true)}
                nivel={2}
              />
            )}
          </>
        )}
      </div>
      <div className="calibracion-2-column">
        <Input
          label={"Cliente"}
          name={"cliente"}
          onChange={handleChange}
          value={formData.cliente}
          validacion={formValidacion.cliente}
          disabled={!editar}
        />
        <Input
          label={"Teléfono"}
          name={"telefono"}
          onChange={handleChange}
          value={formData.telefono}
          validacion={formValidacion.telefono}
          disabled={!editar}
        />
        <Input
          label={"Dirección"}
          name={"direccion"}
          onChange={handleChange}
          value={formData.direccion}
          validacion={formValidacion.direccion}
          disabled={!editar}
        />
        {!mantenimiento && (
          <Input
            label={`Lugar de ${
              mantenimiento ? "mantenimiento" : "calibración"
            }`}
            name={"lugarcalibracion"}
            onChange={handleChange}
            value={formData.lugarcalibracion ?? "-"}
            validacion={
              formValidacion.lugarcalibracion ?? {
                mensaje: "",
                validado: false,
              }
            }
            disabled={!editar}
          />
        )}
      </div>
      <div className="calibracion-3-column mt10 divisor-top">
        <span />
        <LabelText
          label={`Fecha de ${mantenimiento ? "mantenimiento" : "calibración"}`}
          text={new Date(data.fechacalibracion).toLocaleDateString("sv")}
          centrar
        />
      </div>
    </div>
  );
}

export default IdentificacionCliente;
