import BarraBusqueda from "./BarraBusqueda";
import styles from "./ScrollableSelectList.module.css";
import { useState, useEffect } from "react";

function ScrollableSelectList<T extends Object>({
  list,
  keysToFilter,
  valueProperty,
  renderProperties,
  setValue,
}: {
  list: T[];
  keysToFilter: (keyof T)[];
  setValue: (value: any) => void;
  valueProperty: keyof T;
  renderProperties: (keyof T)[];
}) {
  const [filtered, setFiltered] = useState(list);
  const [selectedOwn, setSelectedOwn] = useState<any>();

  const handleClick = (value: any) => {
    setSelectedOwn(value);
  };

  useEffect(() => {
    setValue(selectedOwn);
  }, [selectedOwn]);

  return (
    <div className={styles.contPrincipal}>
      <BarraBusqueda
        data={list}
        keysToFilter={keysToFilter}
        setFilteredData={setFiltered}
        style={{ width: "100%" }}
      />
      <div className={styles.items}>
        {filtered.map((el, i) => (
          <p
            key={i}
            className={`${styles.item} ${
              selectedOwn === el[valueProperty] ? styles.selected : ""
            }`}
            onClick={() => handleClick(el[valueProperty])}
          >
            {renderProperties.map((el1) => el[el1]).join(" ")}
          </p>
        ))}
      </div>
    </div>
  );
}

export default ScrollableSelectList;
