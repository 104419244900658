import "./Switch.css";
import TypeIcon from "../../types/TypeIcon";
import { IValidacion } from "../../helpers/ValidarT";

interface Props {
  label?: string;
  icono?: TypeIcon;
  name: string;
  value: 0 | 1;
  validacion?: IValidacion;
  onChange: (name: any, value: 0 | 1) => void;
  labelColumn?: boolean;
  stateLabels?: { off: string; on: string };
  disabled?: boolean;
}

function Switch({
  label,
  icono,
  name,
  value,
  validacion,
  onChange,
  labelColumn = false,
  stateLabels,
  disabled = false,
}: Props) {
  const handleClick = () => {
    if (!disabled) {
      onChange(name, value === 1 ? 0 : 1);
    }
  };

  return (
    <div>
      <div className={"cont-principal-switch " + (labelColumn ? "column" : "")}>
        {label !== undefined && <p>{label}</p>}
        {icono !== undefined && <div className={`format-ico-form ${icono}`} />}
        <div className="cont-main-switch">
          {stateLabels !== undefined && (
            <p className="switch-state-label">{stateLabels.off}</p>
          )}
          <div className="cont-switch animar-hover" onClick={handleClick}>
            <div
              className={`ball-switch  ${value === 1 ? " active " : ""} ${
                disabled && "disabled"
              }`}
            />
          </div>
          {stateLabels !== undefined && (
            <p className="switch-state-label">{stateLabels.on}</p>
          )}
        </div>
      </div>
      {validacion && !validacion.validado ? (
        <div className="ico-advertencia  format-ico-form-validacion"></div>
      ) : (
        <></>
      )}
      {!validacion?.validado && (
        <p className="texto-validacion">{validacion?.mensaje}</p>
      )}
    </div>
  );
}

export default Switch;
