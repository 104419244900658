import { useState, useCallback, useContext, useEffect } from "react";
import PopupContext from "../../../../../context/PopupContext";
import { host, port } from "../../../../../helpers/DbData";
import $ from "jquery";
import styles from "./RecepcionItems.module.css";
import MensajeCentro from "../../../../generic/MensajeCentro";
import Loader from "../../../../generic/Loader";
import Button from "../../../../generic/Button";
import UserContext from "../../../../../context/UserContext";

interface IDetalle {
  iddetalleordentrabajo: number;
  idtablaequipo: number;
  marca: string;
  modelo: string;
  serie: string;
  codigo: string;
  capacidad?: number | null;
  divescalareal?: number | null;
  valornominal?: number | null;
  unidadmedida: string;
  fecharecepcion: string;
  editable: boolean;
}

interface Props {
  idordentrabajo: number;
}

// pantalla para mostrar los items de la orden de trabajo y poder ingresar la fecha de recepcion
function RecepcionItems({ idordentrabajo }: Props) {
  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [cargando3, setCargando3] = useState(false);
  const [detalles, setDetalles] = useState<IDetalle[]>([]);
  const [estadoOrden, setEstadoOrden] = useState(0);

  const obtenerEstado = useCallback(() => {
    // obtener el estado de la orden de trabajo para saber si se puede editar (estado 3 se puede editar)
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/estado/${idordentrabajo}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando3(true);
      },
      success: function (data) {
        setCargando3(false);
        setEstadoOrden(parseInt(data));
      },
      error: function (data) {
        setCargando3(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, idordentrabajo]);

  const consultarDetalles = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/fechasrecepcion`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idordentrabajo },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setDetalles(
          data.map((el: IDetalle) => ({
            ...el,
            fecharecepcion: !isNaN(Date.parse(el.fecharecepcion))
              ? new Date(el.fecharecepcion)
                  .toISOString()
                  .replace("T", " ")
                  .substring(0, 19)
              : "",
            editable: isNaN(Date.parse(el.fecharecepcion)) ? true : false,
          }))
        );
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, idordentrabajo]);

  useEffect(() => {
    if (idordentrabajo !== 0) {
      consultarDetalles();
      obtenerEstado();
    }
  }, [consultarDetalles, obtenerEstado, idordentrabajo]);

  const guardarBorrarFechaTodos = useCallback(
    (borrar = false) => {
      const fecharecepcion = new Date().toLocaleString("sv");
      setDetalles(
        detalles.map((el) => ({
          ...el,
          fecharecepcion:
            borrar && el.editable
              ? ""
              : el.editable
              ? fecharecepcion
              : el.fecharecepcion,
        }))
      );
    },
    [detalles]
  );

  const guardarBorrarFecha = useCallback(
    (data: IDetalle, borrar = false) => {
      const fecharecepcion = new Date().toLocaleString("sv");
      let tempFechas = [...detalles];
      tempFechas = tempFechas.filter(
        (el) => el.iddetalleordentrabajo !== data.iddetalleordentrabajo
      );
      tempFechas = [
        ...tempFechas,
        { ...data, fecharecepcion: borrar ? "" : fecharecepcion },
      ];
      setDetalles(tempFechas);
    },
    [detalles]
  );

  const guardarFechasRecepcion = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/fechasrecepcion`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        idordentrabajo,
        idmodificador: userData.idusuario,
        detalles: detalles.map((el) => ({
          iddetalleordentrabajo: el.iddetalleordentrabajo,
          fecharecepcion: el.fecharecepcion,
        })),
      }),
      beforeSend: function () {
        setCargando2(true);
      },
      success: function (data) {
        setCargando2(false);
        consultarDetalles();
        mostrarPopup(1, data.mensaje);
      },
      error: function (data) {
        setCargando2(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, detalles, consultarDetalles]);

  const renderItems = (el: IDetalle, i: number) => (
    <div key={i} className={`${styles.rowHorizontal} ${styles.equipos}`}>
      <p>
        {el.marca} {el.modelo} {el.serie}
      </p>
      <p>{el.fecharecepcion}</p>
      <Button
        icono="ico-reloj"
        onClick={() => guardarBorrarFecha(el)}
        nivel={3}
        disabled={!el.editable}
      />
      {el.editable ? (
        <div
          className="ico-cerrar format-ico-borrar-equipo"
          onClick={() => guardarBorrarFecha(el, true)}
        />
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <div className={styles.contPrincipal}>
      {cargando ? (
        <Loader />
      ) : (
        <>
          <div className={`${styles.tituloRow} ${styles.rowHorizontal}`}>
            <div className={styles.contTituloBtn}>
              <h5>Equipo</h5>
              {/* {!(
                detalles.filter(
                  (el) => !isNaN(Date.parse(el.fecharecepcion)) && el.editable
                ).length <= 0 || estadoOrden !== 4
              ) && ( */}
              <Button
                label="Guardar"
                onClick={guardarFechasRecepcion}
                disabled={
                  detalles.filter(
                    (el) => !isNaN(Date.parse(el.fecharecepcion)) && el.editable
                  ).length <= 0 || estadoOrden !== 4
                }
                cargando={cargando2 || cargando3}
                nivel={2}
              />
              {/* )} */}
            </div>
            <h5>Fecha recepción</h5>
            <Button
              icono="ico-reloj"
              onClick={guardarBorrarFechaTodos}
              color="ROJO"
              nivel={3}
            />
            <div
              className="ico-cerrar format-ico-borrar-equipo"
              onClick={() => guardarBorrarFechaTodos(true)}
            />
          </div>

          {detalles.filter((el) => el.idtablaequipo === 1).length > 0 && (
            <>
              <h5 className={styles.tipoEquipo}>Balanzas</h5>
              {detalles
                .filter((el) => el.idtablaequipo === 1)
                .sort(
                  (a, b) => a.iddetalleordentrabajo - b.iddetalleordentrabajo
                )
                .map(renderItems)}
            </>
          )}
          {detalles.filter((el) => el.idtablaequipo === 2).length > 0 && (
            <>
              <h5 className={styles.tipoEquipo}>Pesos patrón</h5>
              {detalles
                .filter((el) => el.idtablaequipo === 2)
                .sort(
                  (a, b) => a.iddetalleordentrabajo - b.iddetalleordentrabajo
                )
                .map(renderItems)}
            </>
          )}
          {detalles.length <= 0 && (
            <div className={styles.mensajeCentro}>
              <MensajeCentro mensaje="No hay equipos" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RecepcionItems;
