import LabelText from "../../../../generic/LabelText";
import { useState, useEffect, useContext } from "react";
import Button from "../../../../generic/Button";
import PopupContext from "../../../../../context/PopupContext";
import MensajesUI from "../../../../../helpers/MensajesUI";
import Input from "../../../../generic/Input";
import { IConfigValidacion } from "../../../../../hooks/useForm";
import useForm from "../../../../../hooks/useForm";

export interface IIdentificacionClientePP {
  direccion: string;
  empresa: string;
  telefono: string;
  fechacalibracion: string;
  fechaemision: string;
  fecharecepcion: string;
  lugarcalibracion: string;
}

const initialFormData: IIdentificacionClientePP = {
  direccion: "",
  empresa: "",
  telefono: "",
  fechacalibracion: "",
  fechaemision: "",
  fecharecepcion: "",
  lugarcalibracion: "",
};

const initialFormValidacion: IConfigValidacion = {
  direccion: { validar: "general", opcional: false },
  empresa: { validar: "general", opcional: false },
  telefono: { validar: "telefono", opcional: false },
  fechacalibracion: { validar: "general", opcional: true },
  fechaemision: { validar: "general", opcional: true },
  fecharecepcion: { validar: "general", opcional: true },
  lugarcalibracion: { validar: "general", opcional: true },
};

interface Props {
  data: IIdentificacionClientePP;
  permitirEdicion: boolean;
  setData: (data: IIdentificacionClientePP) => void;
  setEditando: (editando: boolean) => void;
}

function IdentificacionClientePP({
  data,
  permitirEdicion,
  setData,
  setEditando,
}: Props) {
  const [editar, setEditar] = useState(false);
  const { formData, formValidacion, formValidado, handleChange, setFormData } =
    useForm(initialFormData, initialFormValidacion);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setEditando(editar);
  }, [editar]);

  const cancelar = () => {
    setEditar(false);
    setFormData(data);
  };

  const guardar = () => {
    if (formValidado) {
      setEditar(false);
      setData(formData);
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  return (
    <>
      <div className="calibracion-titulo-btn">
        <h3>Identificación del cliente</h3>
        {permitirEdicion && (
          <>
            {editar ? (
              <div className="cont-flex-horizontal-gap">
                <Button label="Guardar" icono="ico-guardar" onClick={guardar} />
                <Button
                  label="Cancelar"
                  icono="ico-cerrar"
                  onClick={cancelar}
                  color="ROJO"
                />
              </div>
            ) : (
              <Button
                label="Editar"
                icono="ico-lapiz"
                onClick={() => setEditar(true)}
              />
            )}
          </>
        )}
      </div>
      <div className="calibracion-3-column separador-inferior">
        {editar ? (
          <>
            <Input
              label="Empresa"
              name="empresa"
              onChange={handleChange}
              value={formData.empresa}
              validacion={formValidacion.empresa}
            />
            <Input
              label="Dirección"
              name="direccion"
              onChange={handleChange}
              value={formData.direccion}
              validacion={formValidacion.direccion}
            />
            <Input
              label="Teléfono"
              name="telefono"
              onChange={handleChange}
              value={formData.telefono}
              validacion={formValidacion.telefono}
            />
          </>
        ) : (
          <>
            <LabelText label={"Empresa"} text={formData.empresa} />
            <LabelText label={"Dirección"} text={formData.direccion} />
            <LabelText label={"Teléfono"} text={formData.telefono} />
            <LabelText
              label={"Lugar calibración"}
              text={formData.lugarcalibracion}
            />
            <LabelText
              label={"Fecha calibración"}
              text={new Date(formData.fechacalibracion).toLocaleDateString(
                "sv"
              )}
            />
            <LabelText
              label={"Fecha recepción ítem"}
              text={formData.fecharecepcion}
            />
            {/* <LabelText
              label={"Fecha emisión certificado"}
              text={formData.fechaemision}
            /> */}
          </>
        )}
      </div>
    </>
  );
}

export default IdentificacionClientePP;
