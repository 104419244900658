import Button from "../../../../generic/Button";
import LabelText from "../../../../generic/LabelText";
import { useContext, useState, useEffect } from "react";
import UserContext from "../../../../../context/UserContext";
import PopupContext from "../../../../../context/PopupContext";
import { host, port, portPDF } from "../../../../../helpers/DbData";
import $ from "jquery";
import ConfirmContext from "../../../../../context/ConfirmContext";
import styles from "./ResumenDetalle.module.css";
import MensajeCentro from "../../../../generic/MensajeCentro";
import LabelNumeroUnidad from "../../../../generic/LabelNumeroUnidad";
import Loader from "../../../../generic/Loader";
import { IServicioExterno } from "../FormNuevaOrdenTrabajo";

let initialPrecio = {
  equipos: 0,
  visitatecnica: 0,
  subtotal: 0,
  iva: 0,
  total: 0,
  descuento: 0,
};

interface IDetalleEquipos {
  idequipo: number;
  precioservicio: number;
  servicio: string;
  idservicio: number;
  tipoequipo: string;
}

export interface IDetalleData {
  idcliente: number;
  idordentrabajo: number;
  version: number;
  servicio: string;
  tiposequipos: string[];
  fechaserviciodesde: Date | null;
  fechaserviciohasta: Date | null;
  costoextra: {
    cantidad: number;
    descripcion: string;
    costounitario: number;
  };
  contrato: {
    idformapago: number;
    diascredito: number;
    vigencia: number;
    proveedor: string;
    observaciones: string;
    descuento: number;
  };
  detalleequipos: IDetalleEquipos[];
  serviciosexternos: IServicioExterno[];
  tecnicosorden: { idusuario: number }[];
  estado: number;
  estadocotizacion?: number;
  descripcionestadoot: string;
  descripcionestadocotizacion?: string;
  year: number;
  num: number;
  codigocotizacion: string;
}

interface ITecnico {
  idusuario: number;
  nombre: string;
}

interface Props {
  setEditar: (data: boolean) => void;
  detalleData: IDetalleData;
  idordentrabajo: number;
  tecnicos: ITecnico[];
  cerrarModal: () => void;
}

function ResumenDetalle({
  setEditar,
  detalleData,
  idordentrabajo,
  cerrarModal,
  tecnicos,
}: Props) {
  const { userData } = useContext(UserContext);
  const { mostrarPopup } = useContext(PopupContext);
  const [precio, setPrecio] = useState(initialPrecio);
  const [estadoOrden, setEstadoOrden] = useState(0);
  const [puedeAprobar, setPuedeAprobar] = useState(false);
  const [puedeCompletar, setPuedeCompletar] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargando1, setCargando1] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const { mostrarConfirm } = useContext(ConfirmContext);

  useEffect(() => {
    if (estadoOrden !== 0) {
      let idperfil = userData.idperfil;
      let actualizar = false;
      switch (estadoOrden) {
        case 1:
          if (idperfil === 4) actualizar = true;
          break;
        case 2:
          if (idperfil === 2) actualizar = true;
          break;
        case 3:
          if (idperfil === 1000000) actualizar = true;
          break;
        case 4:
          if (idperfil === 3) actualizar = true;
          break;
        case 7:
          if (idperfil === 2) actualizar = true;
          break;
        default:
          break;
      }
      if (actualizar) {
        setPuedeAprobar(true);
      } else {
        setPuedeAprobar(false);
      }
    }
  }, [estadoOrden]);

  useEffect(() => {
    if (estadoOrden === 4) {
      setPuedeCompletar(true);
    } else {
      setPuedeCompletar(false);
    }
  }, [estadoOrden]);

  useEffect(() => {
    formatearPrecios();
  }, [detalleData]);

  useEffect(() => {
    if (idordentrabajo && idordentrabajo !== 0) {
      obtenerEstadoOrden();
    }
  }, [idordentrabajo]);

  const handleAprobar = () => {
    if (puedeAprobar) aprobar();
  };

  const handleCompletarServicio = () => {
    if (puedeCompletar) aprobar();
  };

  const aprobar = async () => {
    if (estadoOrden === 2) {
      if (
        !(await mostrarConfirm(
          "¿Deseas aprobar? Se notificará al cliente por correo electrónico."
        ))
      )
        return;
    }
    if (!cargando)
      $.ajax({
        url: `${host}:${port}/api/ordenTrabajo/actualizarEstado`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          idusuario: userData.idusuario,
          idordentrabajo,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data.message);
          setEstadoOrden(0);
          cerrarModal();
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const obtenerEstadoOrden = () => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/estado/${idordentrabajo}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        setEstadoOrden(parseInt(data));
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const formatearPrecios = () => {
    let equipos = 0;
    let el: IDetalleEquipos;
    for (el of detalleData.detalleequipos) {
      equipos += el.precioservicio;
    }
    const serviciosexternos = detalleData.serviciosexternos.reduce(
      (total: number, el) =>
        total + Number(el.cantidad) * Number(el.costounitario),
      0
    );
    equipos += serviciosexternos;
    let visitatecnica =
      detalleData.costoextra.costounitario * detalleData.costoextra.cantidad;

    const subtotal = equipos + visitatecnica;
    setPrecio({
      equipos,
      visitatecnica,
      subtotal,
      iva: (subtotal - detalleData.contrato.descuento) * 0.15,
      total: (subtotal - detalleData.contrato.descuento) * 1.15,
      descuento: detalleData.contrato.descuento,
    });
  };

  const verPdf = () => {
    window.open(
      `${host}:${portPDF}/api/download/pdf/cotizacion/?idot=${idordentrabajo}&idcliente=${detalleData.idcliente}`,
      "_blank"
    );
    // $.ajax({
    //   url: `${host}:${portPDF}/api/download/pdf/cotizacion/?idot=${idordentrabajo}`,
    //   type: "get",
    //   xhrFields: {
    //     responseType: "blob",
    //   },
    //   beforeSend: function () {
    //     setCargando2(true);
    //   },
    //   success: function (blob, status, xhr) {
    //     setCargando2(false);
    //     decodePdf(blob, xhr);
    //   },
    //   error: function (data) {
    //     setCargando2(false);
    //     console.log(data);
    //     if (data.status === 0)
    //       mostrarPopup(0, "No es posible conectarse al servidor");
    //     else mostrarPopup(0, data.responseText);
    //   },
    // });
  };

  return (
    <>
      <div className="animar-entrada cont-contenido-detalle">
        <div className={styles.contBtns}>
          {cargando1 || cargando || cargando2 ? (
            <Loader />
          ) : (
            <>
              {userData.idperfil === 4 && (
                <Button
                  label={"Completo"}
                  icono={"ico-check"}
                  onClick={handleCompletarServicio}
                  disabled={!puedeCompletar}
                  cargando={cargando1}
                  nivel={2}
                />
              )}
              <Button
                label={estadoOrden === 7 ? "Liberar certificados" : "Aprobar"}
                icono={"ico-check"}
                onClick={handleAprobar}
                disabled={!puedeAprobar}
                cargando={cargando}
                nivel={2}
              />
              <Button
                label={"PDF"}
                icono={"ico-documento"}
                cargando={cargando2}
                onClick={verPdf}
                nivel={2}
              />
              {userData.idperfil === 4 && (
                <Button
                  label="Asignar técnicos"
                  onClick={() => setEditar(true)}
                  nivel={2}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.layout}>
          <div className={styles.seccion}>
            <h3 className={styles.tituloSeccion}>Técnicos</h3>
            <div className={styles.contTecnicos}>
              {detalleData.tecnicosorden.length > 0 ? (
                detalleData.tecnicosorden
                  .map((el) => {
                    const tecnico = tecnicos.find(
                      (el1) => el.idusuario === el1.idusuario
                    );
                    return tecnico ? tecnico.nombre : "-";
                  })
                  .map((el, i) => (
                    <div key={i} className={`${styles.tecnico}`}>
                      <div className="ico-usuario format-ico-tecnico" />
                      {el}
                    </div>
                  ))
              ) : (
                <MensajeCentro mensaje="Sin técnicos asignados" />
              )}
            </div>
          </div>
          <div className={styles.seccion}>
            <h3 className={styles.tituloSeccion}>Detalle</h3>
            <div className="cont-detalle-fechas">
              <LabelText
                centrar
                label={"Desde"}
                text={
                  detalleData.fechaserviciodesde
                    ? new Date(detalleData.fechaserviciodesde)
                        .toISOString()
                        .substring(0, 10)
                    : "-"
                }
              />
              <LabelText
                centrar
                label={"Hasta"}
                text={
                  detalleData.fechaserviciohasta
                    ? new Date(detalleData.fechaserviciohasta)
                        .toISOString()
                        .substring(0, 10)
                    : "-"
                }
              />
            </div>
            <LabelText label={"Servicio"} text={detalleData.servicio} />
            <LabelText
              label={"Tipos de equipo"}
              text={detalleData.tiposequipos.join(", ")}
            />
            <div className={styles.contEstados}>
              <LabelText
                label={"Estado"}
                text={detalleData.descripcionestadoot}
              />
              {!!detalleData.descripcionestadocotizacion && (
                <LabelText
                  label={"Estado facturación"}
                  text={detalleData.descripcionestadocotizacion}
                />
              )}
            </div>
            <LabelNumeroUnidad
              label="Cant. equipos"
              numero={(
                detalleData.detalleequipos.length +
                detalleData.serviciosexternos.length
              ).toString()}
            />
          </div>
          <div className={styles.seccion}>
            <h3 className={styles.tituloSeccion}>Precios</h3>
            {precio.descuento <= 0 && (
              <>
                <LabelText
                  label={"Equipos"}
                  text={"$" + precio.equipos.toFixed(2)}
                />
                <LabelText
                  label={"Visita técnica"}
                  text={"$" + precio.visitatecnica.toFixed(2)}
                />
              </>
            )}
            <LabelText
              label={"Subtotal"}
              text={"$" + precio.subtotal.toFixed(2)}
            />
            {precio.descuento > 0 && (
              <>
                <LabelText
                  label={"Descuento"}
                  text={"$" + precio.descuento.toFixed(2)}
                  color="blue"
                />
                <LabelText
                  label={"Subtotal 2"}
                  text={"$" + (precio.subtotal - precio.descuento).toFixed(2)}
                  color="blue"
                />
              </>
            )}
            <LabelText label={"IVA"} text={"$ " + precio.iva.toFixed(2)} />
            <LabelNumeroUnidad
              label={"Total"}
              numero={"$" + precio.total.toFixed(2)}
            />
          </div>
        </div>
      </div>
      <div className="detalle-cont-btns"></div>
    </>
  );
}

export default ResumenDetalle;
