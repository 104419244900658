import { createContext, useEffect, useState } from "react";

interface IUserData {
  idusuario: 0;
  cedula: string;
  nombre: string;
  idperfil: number;
  perfil: string;
}

interface IUserContext {
  userData: IUserData;
  setUserData: (data: IUserData) => void;
  sesionActiva: boolean;
  cerrarSesion: () => void;
}

const UserContext = createContext({} as IUserContext);

const initialUserData: IUserData = {
  idusuario: 0,
  cedula: "",
  nombre: "",
  idperfil: 0,
  perfil: "",
};

interface Props {
  children: React.ReactNode;
}

const UserProvider = ({ children }: Props) => {
  const [userData, setUserData] = useState(() => {
    const temp1 = localStorage.getItem("userData");
    if (temp1) {
      const temp = JSON.parse(temp1) as IUserData;
      if (compareKeys(temp, initialUserData)) {
        let key: keyof typeof temp;
        for (key in temp) {
          if (Object.hasOwnProperty.call(temp, key)) {
            const el = temp[key].toString();
            if (el === "" || el === "0") {
              return initialUserData;
            }
          }
        }
        return temp;
      }
    }
    return initialUserData;
  });

  const [sesionActiva, setSesionActiva] = useState(() => {
    const temp1 = localStorage.getItem("userData");
    if (temp1) {
      const temp = JSON.parse(temp1) as IUserData;
      if (compareKeys(temp, initialUserData)) {
        let key: keyof typeof temp;
        for (key in temp) {
          if (Object.hasOwnProperty.call(temp, key)) {
            const el = temp[key].toString();
            if (el === "" || el === "0") {
              return false;
            }
          }
        }
        return true;
      }
    }
    return false;
  });

  useEffect(() => {
    if (compareKeys(initialUserData, userData)) {
      let key: keyof typeof userData;
      for (key in userData) {
        if (Object.hasOwnProperty.call(userData, key)) {
          const el = userData[key].toString();
          if (el === "" || el === "0") {
            setSesionActiva(false);
            return;
          }
        }
      }
      localStorage.setItem("userData", JSON.stringify(userData));
      setSesionActiva(true);
    } else {
      setSesionActiva(false);
    }
  }, [userData]);

  const cerrarSesion = () => {
    setUserData(initialUserData);
    localStorage.removeItem("userData");
  };

  return (
    <UserContext.Provider
      value={{ userData, setUserData, sesionActiva, cerrarSesion }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
export default UserContext;

function compareKeys(a: Object, b: Object) {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}
