import EquipoOrden, { IRepuesto, IRepuestoExtended } from "./items/EquipoOrden";
import { useEffect, useContext, useState } from "react";
import PopupContext from "../../../../../context/PopupContext";
import "../OrdenEquipo.css";
import Tabs from "../../../../generic/Tabs";
import styles from "./EquiposOrden.module.css";
import ConfirmContext from "../../../../../context/ConfirmContext";
import Button from "../../../../generic/Button";
import ContInput from "../../../../generic/ContInput";
import { Tooltip } from "react-tooltip";

interface IListaEquipos {
  label: string;
  key: keyof IEquipos;
}

const listaEquipos: IListaEquipos[] = [
  { label: "Balanzas", key: "balanzas" },
  { label: "Pesos patrón", key: "pesospatron" },
];

export interface IBalanza {
  idequipo: number;
  modelo: string;
  serie: string;
  codigo: string;
  ubicacion: string;
  marca: string;
  capacidad: number;
  capacidad1: number;
  divescalareal: number;
  divescalareal1: number;
  tipo: string;
  empleo: string;
  abreviatura: string;
  clase: string;
  tipoplataforma: string;
  capacidadacreditacion: number | null;
}

export interface IPesoPatron {
  abreviatura: string;
  clase: string;
  codigo: string;
  forma?: string;
  idclase: number;
  idequipo: number;
  idformapesopatron: number;
  idmaterial: number;
  marca: string;
  material: null;
  modelo: string;
  serie: string;
  ubicacion: string;
  valornominal: number;
  valornominalacreditacion: number;
  acreditado: number;
  cumpleoiml: number;
}

export interface ITermometro {
  idequipo: number;
}

export interface IEquipos {
  balanzas: IBalanza[];
  pesospatron: IPesoPatron[];
  termometros: ITermometro[];
}

export interface IDetalleEquipo {
  idequipo: number;
  precioservicio: string;
}

interface Props {
  detalleEquipos: IDetalleEquipo[];
  repuestos: IRepuestoExtended[];
  setDetalleEquipos: (data: IDetalleEquipo[]) => void;
  setRepuestos: (data: IRepuestoExtended[]) => void;
  visible?: boolean;
  equipos: IEquipos;
  activo: boolean;
}

// lista de todos los equipos del cliente para agregar a la orden
function EquiposOrden({
  detalleEquipos,
  repuestos,
  setDetalleEquipos,
  setRepuestos,
  equipos,
  visible,
  activo,
}: Props) {
  const { mostrarPopup } = useContext(PopupContext);
  const { mostrarConfirm } = useContext(ConfirmContext);

  const [pantallaTipoEquipo, setPantallaTipoEquipo] = useState(0);

  // la búsqueda se realiza ocultando los elementos que no coinciden con el término de búsqueda
  // no se filtraron los datos porque se pierde la referencia al elemento en el DOM
  // revisar si se puede hacer de otra forma
  const buscar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terminoBusqueda = e.target.value.toLowerCase();
    let elementos = document.querySelectorAll(".fila-orden-datos-equipo");

    for (const el of elementos as any) {
      if (el.innerText.toLowerCase().includes(terminoBusqueda))
        el.closest(".cont-fila-equipo-orden").style.display = "grid";
      else el.closest(".cont-fila-equipo-orden").style.display = "none";
    }
  };

  const anadirEquipo = (equipo: IDetalleEquipo) => {
    let tempDetalleEquipos = [...detalleEquipos];
    const index = tempDetalleEquipos.findIndex(
      (el) => el.idequipo === equipo.idequipo
    );
    if (index === -1) {
      tempDetalleEquipos.push(equipo);
    } else {
      tempDetalleEquipos[index] = {
        ...tempDetalleEquipos[index],
        precioservicio: equipo.precioservicio,
      };
    }
    setDetalleEquipos(tempDetalleEquipos);
  };

  const eliminarEquipo = (idequipo: number) => {
    let tempDetalleEquipos = [...detalleEquipos];
    const index = tempDetalleEquipos.findIndex(
      (el) => el.idequipo === idequipo
    );
    tempDetalleEquipos[index] = {
      ...tempDetalleEquipos[index],
      precioservicio: "",
    };
    setDetalleEquipos(tempDetalleEquipos);
  };

  // const anadirEquipo = (equipo: IDetalleEquipo) => {
  //   // si el equipo no está en la lista, se agrega
  //   let equiposMatch = detalleEquipos.filter(
  //     (el) => Number(el.idequipo) === Number(equipo.idequipo)
  //   );
  //   if (equiposMatch.length === 0) {
  //     let equiposTemp = [...detalleEquipos];
  //     equiposTemp.push(equipo);
  //     setDetalleEquipos(equiposTemp);
  //   } else {
  //     // si el equipo ya está en la lista, se actualiza el precio
  //     const equipoMatch = detalleEquipos.find(
  //       (el) => Number(el.idequipo) === Number(equipo.idequipo)
  //     );
  //     if (
  //       equipoMatch &&
  //       (Number(equipoMatch.precioservicio) !== Number(equipo.precioservicio) ||
  //         Number(equipoMatch.repuestoServicio) !==
  //           Number(equipo.repuestoServicio))
  //     ) {
  //       let equiposNotMatch = detalleEquipos.filter(
  //         (el) => Number(el.idequipo) !== Number(equipo.idequipo)
  //       );
  //       let equiposTemp = equiposNotMatch;
  //       equiposTemp.push(equipo);
  //       setDetalleEquipos(equiposTemp);
  //     }
  //   }
  // };

  // ELIMINAR
  // const eliminarEquipo = (idequipo: number) => {
  //   // se elimina el equipo de la lista
  //   let tempDetalleEquipos = [...detalleEquipos];
  //   const item = tempDetalleEquipos.find(
  //     (el) => Number(el.idequipo) === Number(idequipo)
  //   );
  //   if (item) {
  //     tempDetalleEquipos = tempDetalleEquipos.filter(
  //       (el) => Number(el.idequipo) !== Number(idequipo)
  //     );
  //     setDetalleEquipos(tempDetalleEquipos);
  //   }
  // };
  // END ELIMINAR

  // const anadirEquipo = (equipo: IDetalleEquipo) => {
  //   let equiposMatch = detalleEquipos.filter(
  //     (el) => Number(el.idequipo) === Number(equipo.idequipo)
  //   );
  //   if (equiposMatch.length === 0) {
  //     let equiposTemp = [...detalleEquipos];
  //     equiposTemp.push(equipo);
  //     setDetalleEquipos(equiposTemp);
  //   } else {
  //     const equipoMatch = detalleEquipos.find(
  //       (el) => Number(el.idequipo) === Number(equipo.idequipo)
  //     );
  //     if (
  //       equipoMatch &&
  //       (Number(equipoMatch.idservicio) !== Number(equipo.idservicio) ||
  //         Number(equipoMatch.precioservicio) !== Number(equipo.precioservicio))
  //     ) {
  //       let equiposNotMatch = detalleEquipos.filter(
  //         (el) => Number(el.idequipo) !== Number(equipo.idequipo)
  //       );
  //       let equiposTemp = equiposNotMatch;
  //       equiposTemp.push(equipo);
  //       setDetalleEquipos(equiposTemp);
  //     }
  //   }
  // };
  // const eliminarEquipo = (idequipo: number) => {
  //   let tempDetalleEquipos = [...detalleEquipos];
  //   const item = tempDetalleEquipos.find(
  //     (el) => Number(el.idequipo) === Number(idequipo)
  //   );
  //   if (item) {
  //     tempDetalleEquipos = tempDetalleEquipos.filter(
  //       (el) => Number(el.idequipo) !== Number(idequipo)
  //     );
  //     setDetalleEquipos(tempDetalleEquipos);
  //   }
  // };

  useEffect(() => {
    let tempDetalleEquipos = detalleEquipos.map((el) => ({
      ...el,
      idtablaequipo: 0,
    }));
    for (let i = 0; i < tempDetalleEquipos.length; i++) {
      const el = tempDetalleEquipos[i];
      // se asigna el id de la tabla de equipos para ordenarlos
      tempDetalleEquipos[i] = {
        ...el,
        idtablaequipo: equipos.balanzas.find(
          (el1) => el1.idequipo === el.idequipo
        )
          ? 1
          : equipos.pesospatron.find((el1) => el1.idequipo === el.idequipo)
          ? 2
          : equipos.termometros.find((el1) => el1.idequipo === el.idequipo)
          ? 3
          : 0,
      };
    }
    // se ordenan los equipos
    tempDetalleEquipos = tempDetalleEquipos.sort((a, b) =>
      a.idtablaequipo === b.idtablaequipo
        ? a.idequipo - b.idequipo
        : a.idtablaequipo - b.idtablaequipo
    );
    let iguales = true;
    for (let i = 0; i < detalleEquipos.length; i++) {
      const el = detalleEquipos[i];
      if (el.idequipo !== tempDetalleEquipos[i].idequipo) {
        iguales = false;
      }
    }
    if (!iguales) {
      setDetalleEquipos(
        tempDetalleEquipos.map((el) => ({
          idequipo: el.idequipo,
          precioservicio: el.precioservicio,
        }))
      );
    }
  }, [detalleEquipos]);

  const borrarEquipos = async () => {
    // se borran todos los equipos de la lista (de un tipo)
    const key =
      pantallaTipoEquipo === 0
        ? "balanzas"
        : pantallaTipoEquipo === 1
        ? "pesospatron"
        : "termometros";
    if (await mostrarConfirm(`¿Quieres borrar todos los equipos de ${key}?`)) {
      const tempData = detalleEquipos.filter(
        (el) => !equipos[key].find((el1) => el1.idequipo === el.idequipo)
      );
      setDetalleEquipos(tempData);
    }
  };

  const anadirRepuesto = (idequipo: number) => {
    // se agrega un repuesto a un equipo
    let nextIdRepuesto =
      repuestos.length > 0
        ? Math.max(...repuestos.map((el) => el.idrepuesto))
        : 0;
    nextIdRepuesto = nextIdRepuesto < 0 ? 0 : nextIdRepuesto;
    setRepuestos([
      ...repuestos,
      {
        idrepuesto: nextIdRepuesto + 1,
        idequipo: idequipo,
        repuesto: "",
        precio: "",
      },
    ]);
  };

  const editarRepuesto = (
    idequipo: number,
    idrepuesto: number,
    data: IRepuesto
  ) => {
    // se edita un repuesto de un equipo
    const tempRepuestos = [...repuestos];
    const index = tempRepuestos.findIndex(
      (el) => el.idequipo === idequipo && el.idrepuesto === idrepuesto
    );
    if (index !== -1) {
      tempRepuestos[index] = {
        ...tempRepuestos[index],
        ...data,
      };
      setRepuestos(tempRepuestos);
    }
  };

  const eliminarRepuesto = (idrepuesto: number) => {
    // se elimina un repuesto de un equipo
    const tempRepuestos = [...repuestos].filter(
      (el) => el.idrepuesto !== idrepuesto
    );
    setRepuestos(tempRepuestos);
  };

  const renderSwitchEncabezado = () => {
    switch (pantallaTipoEquipo) {
      case 0:
        return (
          <div className="fila-orden-datos-equipo-balanza">
            <span>Tipo</span>
            <span>Marca</span>
            <span>Modelo</span>
            <span>Serie</span>
            <span>Código</span>
            <span>Capacidad</span>
            <span>Resolución</span>
            <span>CL</span>
            <span>Ubicación</span>
          </div>
        );
      case 1:
        return (
          <div className="fila-orden-datos-equipo-pesopatron">
            <span>Marca</span>
            <span>Serie</span>
            <span>Código</span>
            <span>Valor nom.</span>
            <span>CL</span>
            <span>Material</span>
            <span>Ubicación</span>
          </div>
        );
      case 2:
        return (
          <div className="fila-orden-datos-equipo-termometro">
            <span>Tipo</span>
            <span>Marca</span>
            <span>Modelo</span>
            <span>Serie</span>
            <span>Capacidad</span>
            <span>Res.</span>
            <span>Ubicación</span>
          </div>
        );
      default:
        break;
    }
  };

  const renderSwitchEquipos = () => (
    <div>
      {equipos[pantallaTipoEquipo === 0 ? "balanzas" : "pesospatron"].map(
        (el, i) => (
          <EquipoOrden
            key={el.idequipo + "" + i}
            idequipo={el.idequipo}
            datosEquipoB={
              pantallaTipoEquipo === 0 ? (el as IBalanza) : undefined
            }
            datosEquipoPP={
              pantallaTipoEquipo === 1 ? (el as IPesoPatron) : undefined
            }
            anadir={anadirEquipo}
            eliminar={eliminarEquipo}
            detalleEquipo={
              detalleEquipos.find((el0) => el0.idequipo === el.idequipo) ?? {
                idequipo: el.idequipo,
                precioservicio: "",
              }
            }
            index={i}
            activo={activo}
            repuestos={
              repuestos.filter((el1) => el1.idequipo === el.idequipo) ?? []
            }
            anadirRepuesto={anadirRepuesto}
            editarRepuesto={editarRepuesto}
            eliminarRepuesto={eliminarRepuesto}
          />
        )
      )}
    </div>
  );

  const getCantEquipos = (key: keyof typeof equipos) =>
    detalleEquipos
      .filter((el) => Number(el.precioservicio) > 0)
      .filter((el) => equipos[key].find((el1) => el1.idequipo === el.idequipo))
      .length;
  // const getCantEquipos = (key: keyof typeof equipos) => {
  //   return detalleEquipos.filter((el) =>
  //     equipos[key].find((el1) => el1.idequipo === el.idequipo)
  //   ).length;
  // };

  const [precioAll, setPrecioAll] = useState("");
  const aplicarPrecio = async () => {
    // se aplica el precio a todos los equipos de un tipo
    const precioAll0 = Number(precioAll);
    if (isNaN(precioAll0) || precioAll.length === 0) {
      mostrarPopup(2, "El precio que has ingresado no es válido");
    } else {
      if (
        await mostrarConfirm(
          `¿Quieres aplicar el precio de $${precioAll0} a ${
            pantallaTipoEquipo === 0
              ? "todas las BALANZAS"
              : "todos los PESOS PATRÓN"
          }?`
        )
      ) {
        const equiposTemp =
          pantallaTipoEquipo === 0
            ? equipos.balanzas.map((el) => ({
                idequipo: el.idequipo,
                precioservicio: precioAll0.toString(),
              }))
            : equipos.pesospatron.map((el) => ({
                idequipo: el.idequipo,
                precioservicio: precioAll0.toString(),
              }));
        const detalleEquiposTemp = detalleEquipos.filter(
          (el) => !equiposTemp.find((el1) => el.idequipo === el1.idequipo)
        );
        setDetalleEquipos([...detalleEquiposTemp, ...equiposTemp]);
        setPrecioAll("");
      }
    }
  };

  return (
    <div
      className="cont-seccion2-equipos animar-entrada"
      style={{ display: visible ? "grid" : "none" }}
    >
      <div className={styles.contEncabezado}>
        <div style={{ justifySelf: "flex-start", alignSelf: "flex-end" }}>
          <Tabs
            lista={listaEquipos.map(
              (el, i) => `${el.label} (${getCantEquipos(el.key)})`
            )}
            selected={pantallaTipoEquipo}
            setSelected={setPantallaTipoEquipo}
            nivel={2}
          />
        </div>
        <div className={styles.contAcciones}>
          <div data-tooltip-id="aplicar-precio-tooltip-asdkjfern5">
            <ContInput>
              <input
                value={precioAll}
                onChange={(e) => setPrecioAll(e.target.value)}
                placeholder="0"
              />
              <Button
                onClick={aplicarPrecio}
                label="Aplicar a todos"
                nivel={3}
              />
            </ContInput>
          </div>
          <Tooltip
            id="aplicar-precio-tooltip-asdkjfern5"
            content="Aplicar este precio a todos los equipos"
          />
          <div className={`cont-input-ico ${styles.barraBusqueda}`}>
            <div className="ico-lupa format-ico-form"></div>
            <input onChange={buscar} placeholder="Buscar" />
          </div>
        </div>
      </div>

      <div className="cont-card orden-equipos">
        <div className="orden-equipos-lista">
          <div className="cont-fila-equipo-orden orden-titulo-lista">
            <div
              className="ico-cerrar format-ico-borrar-equipo"
              onClick={borrarEquipos}
            />
            <span>Ítem</span>
            {renderSwitchEncabezado()}
            <span>Precio</span>
          </div>
          {renderSwitchEquipos()}
        </div>
      </div>
    </div>
  );
}

export default EquiposOrden;
