import { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SocketContext } from "../../context/SocketContext";
import UserContext from "../../context/UserContext";

import logo from "../../media/ico/inicialPinprexat.svg";
import Hub from "../notificaciones/Hub";
import MenuSesion from "./MenuSesion";

import "./NavBar.css";
import { Tooltip } from "react-tooltip";

function NavBar() {
  const [hubActive, setHubActive] = useState(false);
  const { notificaciones } = useContext(SocketContext);
  const { userData } = useContext(UserContext);
  const location = useLocation();

  const handleHub = () => {
    setHubActive(true);
  };
  return (
    <>
      <div className="cont-navbar">
        <div className="cont-logo-navbar">
          <img src={logo} className="navbar-logo" alt="Logo Pinprexat" />
        </div>
        <ul className="navbar-list">
          <li data-tooltip-id="clientes-tooltip-f4t4gb5rg5b">
            <NavLink
              to="/"
              title="Clientes"
              className={({ isActive }) =>
                isActive ||
                (!!location.pathname.split("/")[1] &&
                  location.pathname.split("/")[1] === "clientes")
                  ? "active"
                  : ""
              }
            >
              <div className="ico-usuario format-ico-navbar" />
            </NavLink>
          </li>
          <li data-tooltip-id="ordenes-tooltip-sdg5h88jg">
            <NavLink
              to="/ordenes"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <div className="ico-capas format-ico-navbar" />
            </NavLink>
          </li>
          {userData.idperfil === 2 && (
            <li data-tooltip-id="monitoreo-tooltip-fdsfevbr4brg56">
              <NavLink
                to="/monitoreo"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <div className="ico-condicionesambientales format-ico-navbar" />
              </NavLink>
            </li>
          )}
          {/* {(userData.idperfil == 1 || userData.idperfil == 2) && ( */}
          <li data-tooltip-id="configuracion-tooltip-w46hrgopiu89">
            <NavLink
              to="/configuracion"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <div className="ico-engranaje format-ico-navbar" />
            </NavLink>
          </li>
          <li
            className="link"
            onClick={handleHub}
            data-tooltip-id="notificaciones-tooltip-dgj5j69kmh"
          >
            <a className={hubActive ? "active" : ""}>
              <div
                className={`ico-campana format-ico-navbar ${
                  notificaciones.find((el) => el.estado === 0)
                    ? "format-ico-notificaciones-pendientes"
                    : ""
                }`}
              />
              {notificaciones.filter((el) => el.estado === 0).length > 0 && (
                <p className="contador-notificaciones">
                  {notificaciones.filter((el) => el.estado === 0).length}
                </p>
              )}
            </a>
          </li>
          {/* )} */}
        </ul>
        <Tooltip
          id="clientes-tooltip-f4t4gb5rg5b"
          content="Clientes"
          place="right"
          className="z-10"
        />
        <Tooltip
          id="ordenes-tooltip-sdg5h88jg"
          content="Órdenes"
          place="right"
          className="z-10"
        />
        <Tooltip
          id="configuracion-tooltip-w46hrgopiu89"
          content="Configuración"
          place="right"
          className="z-10"
        />
        <Tooltip
          id="monitoreo-tooltip-fdsfevbr4brg56"
          content="Monitoreo"
          place="right"
          className="z-10"
        />
        <Tooltip
          id="notificaciones-tooltip-dgj5j69kmh"
          content="Notificaciones"
          place="right"
          className="z-10"
        />
        <div className="navbar-fondo-menu-sesion" />
        <MenuSesion />
      </div>
      <Hub active={hubActive} cerrar={() => setHubActive(false)} />
    </>
  );
}

export default NavBar;
