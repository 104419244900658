import styles from "./CondicionesAmbientales.module.css";

export interface ICondicionesAmbientales {
  humfinal: number;
  huminicial: number;
  tempfinal: number;
  tempinicial: number;
}

interface Props {
  data: ICondicionesAmbientales;
}

function CondicionesAmbientales({ data }: Props) {
  return (
    <div className="cont-card">
      <h3>Condiciones ambientales</h3>
      <div>
        <div className={`table-row ${styles.row}`}>
          <span />
          <h4 className={styles.label}>Temperatura (°C)</h4>
          <h4 className={styles.label}>Humedad (%HR)</h4>
        </div>
        <div className={`table-row ${styles.row}`}>
          <p className={styles.label}>Inicial</p>
          <p>{data.tempinicial.toFixed(1)}</p>
          <p>{data.huminicial.toFixed(1)}</p>
        </div>
        <div className={`table-row ${styles.row}`}>
          <p className={styles.label}>Final</p>
          <p>{data.tempfinal.toFixed(1)}</p>
          <p>{data.humfinal.toFixed(1)}</p>
        </div>
      </div>
    </div>
  );
}

export default CondicionesAmbientales;
