import MensajeCentro from "../../../../../generic/MensajeCentro";
import { IServicioExterno } from "../../FormNuevaOrdenTrabajo";
import styles from "./ServiciosExternos.module.css";
import Button from "../../../../../generic/Button";
import { useState, useCallback, useContext, useEffect } from "react";
import ServicioExterno from "./serviciosExternos/ServicioExterno";
import $ from "jquery";
import { host, port } from "../../../../../../helpers/DbData";
import PopupContext from "../../../../../../context/PopupContext";

interface ITipoEquipo {
  idtipoequipo: number;
  tipoequipo: string;
}

interface IServicio {
  idservicio: number;
  servicio: string;
}

interface IProveedor {
  idproveedor: number;
  proveedor: string;
}

export interface ICombosServicioExterno {
  tipoequipo: ITipoEquipo[];
  servicio: IServicio[];
  proveedores: IProveedor[];
}

interface Props {
  servicios: IServicioExterno[];
  setServicios: (servicios: IServicioExterno[]) => void;
  visible?: boolean;
}

function ServiciosExternos({ servicios, setServicios, visible }: Props) {
  const [nuevoServicio, setNuevoServicio] = useState(false);
  const [combos, setCombos] = useState<ICombosServicioExterno>({
    tipoequipo: [],
    servicio: [],
    proveedores: [],
  });
  const [cargando, setCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const obtenerCombos = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/combosSE`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setCombos(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  useEffect(() => {
    obtenerCombos();
  }, [obtenerCombos]);

  const guardarServicio = (data: IServicioExterno, i?: number) => {
    setNuevoServicio(false);
    if (i !== null && i !== undefined) {
      let tempServicios = [...servicios];
      tempServicios[i] = data;
      setServicios([...tempServicios]);
    } else {
      setServicios([...servicios, data]);
    }
  };

  const eliminarServicio = (i: number) => {
    let tempServicios = [...servicios];
    tempServicios.splice(i, 1);
    setServicios([...tempServicios]);
  };

  return (
    <div
      className={`${styles.contPrincipal} animar-entrada`}
      style={{ display: visible ? "flex" : "none" }}
    >
      {servicios.length <= 0 && !nuevoServicio ? (
        <div className={styles.contMensajeVacio}>
          <MensajeCentro mensaje="No existen servicios externos" />
        </div>
      ) : (
        servicios.map((el, i) => (
          <ServicioExterno
            key={i}
            servicio={el}
            guardar={(data) => guardarServicio(data, i)}
            eliminar={() => eliminarServicio(i)}
            combos={combos}
          />
        ))
      )}
      {nuevoServicio && (
        <ServicioExterno
          guardar={guardarServicio}
          nuevoServicio={{
            cancelarNuevo: () => setNuevoServicio(false),
            nuevo: true,
          }}
          combos={combos}
        />
      )}
      <div className={styles.contBtnAnadir}>
        {!nuevoServicio && (
          <Button
            icono="ico-anadir"
            onClick={() => setNuevoServicio(true)}
            label="Añadir"
            cargando={cargando}
          />
        )}
      </div>
    </div>
  );
}

export default ServiciosExternos;
