import Button from "../../../../generic/Button";
import "./EquipoHistorialCertificado.css";
import { host, portPDF } from "../../../../../helpers/DbData";

function EquipoHistorialCertificado({
  data,
  idactual,
  lastIdot = 0,
  idtablaequipo,
}) {
  const descargar = () => {
    window.open(
      `${host}:${portPDF}/api/download/pdf/certificado/${
        idtablaequipo === 1 ? "balanza" : "pesopatron"
      }?idcertdata=${data.idcertdata}`,
      "_blank"
    );
  };

  return (
    <div className="cont-equipo-historial-certificado">
      <p>{new Date(data.fechaemision).toLocaleDateString()}</p>
      <p className="equipo-historial-certificado-esta-orden">
        {Number(data.idordentrabajo) === Number(idactual) && (
          <span>Esta orden</span>
        )}
      </p>
      <Button icono="ico-descargar" onClick={descargar} />
    </div>
  );
}

export default EquipoHistorialCertificado;
