import { useContext, useEffect, useReducer, useState } from "react";
import $ from "jquery";
import { host, port } from "../../helpers/DbData";
import "./Usuarios.css";
import Button from "../../components/generic/Button";
import Loader from "../../components/generic/Loader";
import Usuario from "../../components/configuracion/usuarios/Usuario";
import Modal from "../../components/generic/Modal";
import FormUsuario from "../../components/configuracion/usuarios/FormUsuario";
import PopupContext from "../../context/PopupContext";
import BarraBusqueda from "../../components/generic/BarraBusqueda";
import MensajeCentro from "../../components/generic/MensajeCentro";

// let dataUsuario = {
//   id: 1,
//   nombre: "Nicolás Carvajal",
//   cargo: "ADMINISTRADOR",
//   estado: 1,
// };

// let dataUsuario2 = {
//   id: 2,
//   nombre: "Pedro Infante",
//   cargo: "OPERARIO",
//   estado: 1,
// };

// let dataUsuario3 = {
//   id: 3,
//   nombre: "Will Bernabé",
//   cargo: "OPERARIO",
//   estado: 1,
// };

let initialStateModal = {
  form: false,
};

let modalTypes = {
  OPEN_FORM: "OPEN_FORM",
  CLOSE_USUARIOS: "CLOSE_FORM",
};

function Usuarios() {
  const [stateModal, dispatchModal] = useReducer(
    reducerModal,
    initialStateModal
  );
  const [idUsuario, setIdUsuario] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [mostrarCargando, setMostrarCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);
  const [usuariosFiltered, setUsuariosFiltered] = useState([]);

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  function reducerModal(state, action) {
    switch (action.type) {
      case modalTypes.OPEN_FORM:
        return { ...state, form: true };
      case modalTypes.CLOSE_FORM:
        return { ...state, form: false };
      default:
        break;
    }
  }

  const abrirForm = (id) => {
    dispatchModal({ type: modalTypes.OPEN_FORM });
    setIdUsuario(id);
  };

  const cerrarForm = () => {
    dispatchModal({ type: modalTypes.CLOSE_FORM });
    setIdUsuario(0);
  };

  const obtenerUsuarios = () => {
    $.ajax({
      url: `${host}:${port}/api/usuario`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setMostrarCargando(true);
      },
      success: function (data) {
        let dataTemp = data.sort((a, b) =>
          a.nombre > b.nombre ? 1 : a.nombre < b.nombre ? -1 : 0
        );
        setUsuarios(dataTemp);
        setMostrarCargando(false);
        // if ("cedula" in data) {
        //   setUserData(data);
        // } else {
        //   mostrarPopup(2, data);
        // }
      },
      error: function (data) {
        setMostrarCargando(false);
        console.log(data.responseJSON.data);
        let mensaje = data.responseJSON.data;
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(2, mensaje);
      },
    });
  };

  return (
    <>
      <div className="cont-usuarios animar-zoom-min-to-max">
        <div className="encabezado-nombre-barra-buscar">
          <h3 className="titulo-pagina">Usuarios</h3>
          <BarraBusqueda
            data={usuarios}
            keysToFilter={["nombre"]}
            setFilteredData={setUsuariosFiltered}
          />
          <div style={{ width: "max-content", justifySelf: "end" }}>
            <Button
              label={"Nuevo"}
              icono="ico-anadir"
              onClick={() => abrirForm(0)}
            />
          </div>
        </div>
        {mostrarCargando ? (
          <Loader fullScreen />
        ) : (
          <div
            className={
              "cont-contenido-usuarios" +
              (!mostrarCargando ? " animar-entrada" : "")
            }
          >
            <h4>Administradores</h4>
            <div>
              {usuariosFiltered.filter((el) => el.idperfil === 1).length > 0 ? (
                usuariosFiltered
                  .filter((el) => el.idperfil === 1)
                  .map((el, i) => (
                    <Usuario
                      key={"usuario" + i}
                      datos={el}
                      abrirForm={() => abrirForm(el.idusuario)}
                    />
                  ))
              ) : (
                <div style={{ gridColumn: "1/-1" }}>
                  <MensajeCentro mensaje="No hay usuarios" />
                </div>
              )}
            </div>
            <h4>Gerencia técnica</h4>
            <div>
              {usuariosFiltered.filter((el) => el.idperfil === 5).length > 0 ? (
                usuariosFiltered
                  .filter((el) => el.idperfil === 5)
                  .map((el, i) => (
                    <Usuario
                      key={"usuario" + i}
                      datos={el}
                      abrirForm={() => abrirForm(el.idusuario)}
                    />
                  ))
              ) : (
                <div style={{ gridColumn: "1/-1" }}>
                  <MensajeCentro mensaje="No hay usuarios" />
                </div>
              )}
            </div>
            <h4>Calidad</h4>
            <div>
              {usuariosFiltered.filter((el) => el.idperfil === 2).length > 0 ? (
                usuariosFiltered
                  .filter((el) => el.idperfil === 2)
                  .map((el, i) => (
                    <Usuario
                      key={"usuario" + i}
                      datos={el}
                      abrirForm={() => abrirForm(el.idusuario)}
                    />
                  ))
              ) : (
                <div style={{ gridColumn: "1/-1" }}>
                  <MensajeCentro mensaje="No hay usuarios" />
                </div>
              )}
            </div>
            <h4>Ventas</h4>
            <div>
              {usuariosFiltered.filter((el) => el.idperfil === 4).length > 0 ? (
                usuariosFiltered
                  .filter((el) => el.idperfil === 4)
                  .map((el, i) => (
                    <Usuario
                      key={"usuario" + i}
                      datos={el}
                      abrirForm={() => abrirForm(el.idusuario)}
                    />
                  ))
              ) : (
                <div style={{ gridColumn: "1/-1" }}>
                  <MensajeCentro mensaje="No hay usuarios" />
                </div>
              )}
            </div>
            <h4>Técnicos</h4>
            <div>
              {usuariosFiltered.filter((el) => el.idperfil === 3).length > 0 ? (
                usuariosFiltered
                  .filter((el) => el.idperfil === 3)
                  .map((el, i) => (
                    <Usuario
                      key={"usuario" + i}
                      datos={el}
                      abrirForm={() => abrirForm(el.idusuario)}
                    />
                  ))
              ) : (
                <div style={{ gridColumn: "1/-1" }}>
                  <MensajeCentro mensaje="No hay usuarios" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal activo={stateModal.form} cerrar={cerrarForm}>
        <FormUsuario
          idUsuario={idUsuario}
          cerrar={() => {
            cerrarForm();
            obtenerUsuarios();
          }}
        />
      </Modal>
    </>
  );
}

export default Usuarios;
