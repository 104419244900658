import ContInput from "../../generic/ContInput";
import { useEffect, useState, useContext } from "react";
import $ from "jquery";
import { host, portPDF } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import { Validar } from "../../../helpers/Validar";
import styles from "./FormatoLayout.module.css";
import RichTextInput from "../../generic/RichTextInput";
import TypeMsgResponse from "../../../types/TypeMsgResponse";
import Loader from "../../generic/Loader";
import PdfContext from "../../../context/PdfContext";
import Button from "../../generic/Button";

const initialFormData = {
  nombreempresa: "",
  direccionempresa: "",
  telefonosempresa: "",
  ruc: "",
  procedimiento: "",
  trazabilidad: "",
  // regladecision: "",
};

const initialFormValidado = {
  nombreempresa: [false, ""],
  direccionempresa: [false, ""],
  telefonosempresa: [false, ""],
  ruc: [false, ""],
  procedimiento: [false, ""],
  trazabilidad: [false, ""],
  // regladecision: [false, ""],
};

function FormatoCertificadoPP() {
  const { mostrarPopup } = useContext(PopupContext);
  const [cargando, setCargando] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formValidado, setFormValidado] = useState(initialFormValidado);
  const { decodePdf } = useContext(PdfContext);
  const [cargando2, setCargando2] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    $.ajax({
      url: `${host}:${portPDF}/api/formatos/pdf/certificado`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      // consultar los tipos de certificado en la tabla tipocertificado
      data: { idtipocertificado: 2 },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        let tempData = data;
        delete tempData["idformato"];
        data = tempData as typeof formData;
        setFormData(data);
        validarTodo(data);
        setCargando(false);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const saveData = () => {
    $.ajax({
      url: `${host}:${portPDF}/api/formatos/pdf/certificado`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      // consultar los tipos de certificado en la tabla tipocertificado
      data: JSON.stringify({ ...formData, imgsae: "-", idtipocertificado: 2 }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data: TypeMsgResponse) {
        setCargando(false);
        mostrarPopup(1, data.message);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const validarTodo = (data: typeof formData) => {
    let tempFormValidado = formValidado;
    let key: keyof typeof formData;
    for (key in data) {
      if (Object.hasOwnProperty.call(initialFormData, key)) {
        const el = data[key];
        tempFormValidado = {
          ...tempFormValidado,
          [key]: el ? Validar.general(el) : [true, ""],
        };
      }
    }
    setFormValidado(tempFormValidado);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    actualizarValidacion(e.target.name, e.target.value);
  };

  const handleChangeRT = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    actualizarValidacion(name, value);
  };

  const actualizarValidacion = (name: string, value: string) => {
    let tempCampo = {};
    switch (name) {
      case "nombreempresa":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      case "direccionempresa":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      case "telefonosempresa":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      case "ruc":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      case "revision":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      case "trazabilidad":
        tempCampo = {
          [name]: Validar.general(value),
        };
        break;
      // case "regladecision":
      //   tempCampo = {
      //     [name]: Validar.general(value),
      //   };
      //   break;
      default:
        break;
    }
    setFormValidado({
      ...formValidado,
      ...tempCampo,
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name as keyof typeof formValidado;
    if (formValidado[name][0])
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
  };

  const getVistaPrevia = () => {
    window.open(
      `${host}:${portPDF}/api/formatos/pdf/certificado/vistaprevia?idtipocertificado=2`,
      "_blank"
    );
    // $.ajax({
    //   url: `${host}:${portPDF}/api/formatos/pdf/certificado/vistaprevia?idtipocertificado=2`,
    //   type: "get",
    //   xhrFields: {
    //     responseType: "blob",
    //   },
    //   beforeSend: function () {
    //     setCargando2(true);
    //   },
    //   success: function (blob, status, xhr) {
    //     setCargando2(false);
    //     decodePdf(blob, xhr);
    //   },
    //   error: function (data) {
    //     setCargando2(false);
    //     console.log(data);
    //     if (data.status === 0)
    //       mostrarPopup(0, "No es posible conectarse al servidor");
    //     else mostrarPopup(0, data.responseText);
    //   },
    // });
  };

  return (
    <>
      <div className={styles.contFormato + " animar-zoom-min-to-max"}>
        <div className="encabezado-nombre-barra-buscar">
          <h3 className="titulo-pagina">Formato certificado de pesos patrón</h3>
          <span />
          <div className="cont-flex-horizontal-gap">
            {!cargando && (
              <>
                <Button
                  label=""
                  icono="ico-documento"
                  onClick={getVistaPrevia}
                  disabled={false}
                  color="ROJO"
                  cargando={cargando2}
                />
                <Button
                  label="Guardar"
                  icono="ico-guardar"
                  onClick={saveData}
                />
              </>
            )}
          </div>
        </div>
        {cargando ? (
          <Loader fullScreen />
        ) : (
          <div className={styles.contFormatoFormulario + " animar-entrada"}>
            <div>
              <h3>Datos de la empresa</h3>
              <div className={styles.contCard + " cont-card"}>
                <div>
                  <ContInput label={"Nombre"}>
                    <input
                      type="text"
                      name="nombreempresa"
                      value={formData.nombreempresa}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.nombreempresa[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.nombreempresa[0] && (
                    <p className="texto-validacion">
                      {formValidado.nombreempresa[1]}
                    </p>
                  )}
                </div>
                <div>
                  <ContInput label={"Dirección"}>
                    <input
                      type="text"
                      name="direccionempresa"
                      value={formData.direccionempresa}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.direccionempresa[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.direccionempresa[0] && (
                    <p className="texto-validacion">
                      {formValidado.direccionempresa[1]}
                    </p>
                  )}
                </div>
                <div>
                  <ContInput label={"Teléfonos"}>
                    <input
                      type="text"
                      name="telefonosempresa"
                      value={formData.telefonosempresa}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.telefonosempresa[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.telefonosempresa[0] && (
                    <p className="texto-validacion">
                      {formValidado.telefonosempresa[1]}
                    </p>
                  )}
                </div>
                <div>
                  <ContInput label={"RUC"}>
                    <input
                      type="text"
                      name="ruc"
                      value={formData.ruc}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.ruc[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.ruc[0] && (
                    <p className="texto-validacion">{formValidado.ruc[1]}</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <h3>Formato</h3>
              <div className="cont-card">
                <div>
                  <ContInput label={"Formato"}>
                    <input
                      type="text"
                      name="revision"
                      value={formData.procedimiento}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.procedimiento[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.procedimiento[0] && (
                    <p className="texto-validacion">
                      {formValidado.procedimiento[1]}
                    </p>
                  )}
                </div>
                {/* <div>
                  <ContInput label={"Imagen SAE"} >
                    <input
                      type="text"
                      name="email"
                      value={formData.direccionempresa}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!formValidado.direccionempresa[0] ? (
                      <div className="ico-advertencia  format-ico-form-validacion"></div>
                    ) : (
                      <></>
                    )}
                  </ContInput>
                  {!formValidado.direccionempresa[0] && (
                    <p className="texto-validacion">
                      {formValidado.direccionempresa[1]}
                    </p>
                  )}
                </div> */}
              </div>
            </div>
            <div>
              <h3>Trazabilidad interna</h3>
              <div className="cont-card">
                {!formValidado.trazabilidad[0] && (
                  <p className="texto-validacion">
                    {formValidado.trazabilidad[1]}
                  </p>
                )}
                <RichTextInput
                  onChange={handleChangeRT}
                  name="trazabilidad"
                  // value={formData.trazabilidad}
                  value={formData.trazabilidad}
                />
              </div>
            </div>
            {/* <div>
              <h3>Regla de decisión</h3>
              <div className="cont-card">
                {!formValidado.regladecision[0] && (
                  <p className="texto-validacion">
                    {formValidado.regladecision[1]}
                  </p>
                )}
                <RichTextInput
                  onChange={handleChangeRT}
                  name="regladecision"
                  // value={formData.trazabilidad}
                  value={formData.regladecision}
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
      {/* <PdfViewer /> */}
    </>
  );
}

export default FormatoCertificadoPP;
