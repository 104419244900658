import LabelText from "../../../../generic/LabelText";
import { INumDecimales } from "./IdentificacionEquipo";
import DataHandler from "../../../../../helpers/DataHandler";
import MensajeCentro from "../../../../generic/MensajeCentro";

export interface IExcentricidad {
  punto: number;
  linicial: number;
  einicial: number;
  lfinal: number | null;
  efinal: number | null;
  emp: number;
  idnumdecimales: number;
}

interface Props {
  data: IExcentricidad[];
  numdecimales: INumDecimales;
  unidadmedida: string;
}

function Excentricidad({ data, numdecimales, unidadmedida }: Props) {
  const renderND = DataHandler.renderND(numdecimales);

  return (
    <div className="cont-card">
      <h3>Prueba de excentricidad</h3>
      {data.length > 0 ? (
        <div className="excentricidad">
          <div className="table-row titulo-excentricidad">
            <span />
            <div className="titulo-lectura-excentricidad lectura1">
              <h4>Inicial</h4>
              <LabelText
                label={`Carga de prueba (${unidadmedida})`}
                text={
                  data[0]
                    ? renderND(data[0].linicial, data[0].idnumdecimales)
                    : null
                }
                centrar
              />
            </div>
            <div className="titulo-lectura-excentricidad lectura2">
              <h4>Final</h4>
              <LabelText
                label={`Carga de prueba (${unidadmedida})`}
                text={
                  data[0]
                    ? data[0].lfinal
                      ? renderND(data[0].lfinal, data[0].idnumdecimales)
                      : null
                    : null
                }
                centrar
              />
            </div>
            <span />
          </div>
          <div>
            <div className="table-row table-row-title">
              <span>Punto</span>
              <span>Lectura ({unidadmedida})</span>
              <span>Error ({unidadmedida})</span>
              <span>Lectura ({unidadmedida})</span>
              <span>Error ({unidadmedida})</span>
              <span>e.m.p ({unidadmedida})</span>
            </div>
            {data.map((el, i) => (
              <div key={i} className="table-row">
                <span>{el.punto}</span>
                <span>{renderND(el.linicial, el.idnumdecimales)}</span>
                <span>{renderND(el.einicial, el.idnumdecimales)}</span>
                <span>
                  {el.lfinal ? renderND(el.lfinal, el.idnumdecimales) : "-"}
                </span>
                <span>
                  {el.efinal ? renderND(el.efinal, el.idnumdecimales) : "-"}
                </span>
                <span>{renderND(el.emp, el.idnumdecimales)}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <MensajeCentro mensaje="No se ha realizado la prueba de excentricidad" />
      )}
    </div>
  );
}

export default Excentricidad;
