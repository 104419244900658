import { useContext, useEffect, useState } from "react";
import $ from "jquery";
import "./Marcas.css";
import { host, port } from "../../../helpers/DbData";
import FormNuevaMarca from "./FormNuevaMarca";
import Button from "../../generic/Button";
import ConfirmContext from "../../../context/ConfirmContext";
import PopupContext from "../../../context/PopupContext";
import BarraBusqueda from "../../generic/BarraBusqueda";
import Tabs from "../../generic/Tabs";
import Loader from "../../generic/Loader";
import MensajeCentro from "../../generic/MensajeCentro";
import Modal from "../../generic/Modal";

let tabs = ["Balanzas", "Pesos patrón", "Termómetros"];

let initialMarcas = {
  balanzas: [],
  pesospatron: [],
  termometros: [],
};

function Marcas() {
  const { mostrarConfirm } = useContext(ConfirmContext);
  const { mostrarPopup } = useContext(PopupContext);

  const [pantallaActiva, setPantallaActiva] = useState(0);
  const [modalNuevaMarca, setModalNuevaMarca] = useState(false);
  const [marcas, setMarcas] = useState(initialMarcas);
  const [marcasAux, setMarcasAux] = useState(initialMarcas);
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    obtenerMarcas();
    return () => setMarcas(initialMarcas);
  }, []);

  const obtenerMarcas = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/marcas`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setMarcas(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const eliminarMarca = async (idmarca) => {
    let tempMarcas = [];
    for (const key in marcas) {
      if (Object.hasOwnProperty.call(marcas, key)) {
        const el = marcas[key];
        tempMarcas.push(...el);
      }
    }
    if (
      (await mostrarConfirm(
        `¿Seguro que deseas eliminar la marca ${
          tempMarcas.filter((el) => el.idmarca === idmarca)[0].marca
        }?`
      )) &&
      !cargando
    )
      $.ajax({
        url: `${host}:${port}/api/nuevaCotizacion/desactivarMarcas`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          id: idmarca,
          estado: 0,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, "Marca eliminada");
          obtenerMarcas();
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  return (
    <div className="animar-zoom-min-to-max">
      <div className="encabezado-nombre-barra-buscar mb-2">
        <h3>Ítems</h3>
        <BarraBusqueda
          data={marcas[Object.keys(marcas).at(pantallaActiva)]}
          keysToFilter={["marca"]}
          setFilteredData={(filteredData) =>
            setMarcasAux({
              ...marcasAux,
              [Object.keys(marcas).at(pantallaActiva)]: filteredData,
            })
          }
        />
        <Button icono={"ico-anadir"} onClick={() => setModalNuevaMarca(true)} />
      </div>
      <Tabs
        lista={tabs}
        selected={pantallaActiva}
        setSelected={setPantallaActiva}
      />
      {cargando ? (
        <Loader fullScreen />
      ) : (
        <>
          <div
            className={
              "cont-contenido-marcas" + (!cargando ? " animar-entrada" : "")
            }
          >
            {marcasAux[Object.keys(marcasAux).at(pantallaActiva)].length > 0 ? (
              marcasAux[Object.keys(marcasAux).at(pantallaActiva)]
                .sort((a, b) =>
                  a.marca > b.marca ? 1 : a.marca < b.marca ? -1 : 0
                )
                .map((el, i) => {
                  return (
                    <div
                      key={i}
                      className={"cont-marca animar-entrada"}
                      style={{ position: "relative" }}
                    >
                      <span>{el.marca}</span>
                      <div className="acciones-accion">
                        <div
                          className="ico-eliminar format-ico-row-table row-accion"
                          onClick={() => eliminarMarca(el.idmarca)}
                        ></div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <MensajeCentro mensaje="No hay marcas" />
            )}
          </div>
        </>
      )}
      <Modal activo={modalNuevaMarca} cerrar={() => setModalNuevaMarca(false)}>
        <FormNuevaMarca
          idtablaequipo={pantallaActiva}
          activo={modalNuevaMarca}
          nombreTab={tabs[pantallaActiva]}
          cerrar={() => {
            obtenerMarcas();
            setModalNuevaMarca(false);
          }}
          marcas={marcasAux[Object.keys(marcasAux).at(pantallaActiva)]}
        />
      </Modal>
    </div>
  );
}

export default Marcas;
