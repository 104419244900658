import ContInput from "../../generic/ContInput";
import { useEffect, useState, useContext } from "react";
import $ from "jquery";
import { host, portPDF } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import Button from "../../generic/Button";
import styles from "./FormatoLayout.module.css";
import RichTextInput from "../../generic/RichTextInput";
import TypeMsgResponse from "../../../types/TypeMsgResponse";
import Loader from "../../generic/Loader";
import useForm from "../../../hooks/useForm";
import { IConfigValidacion } from "../../../hooks/useForm";
import Input from "../../generic/Input";
import MensajesUI from "../../../helpers/MensajesUI";

const initialFormData = {
  nombreempresa: "",
  ruc: "",
  direccionempresa: "",
  telefonosempresa: "",
  procedimiento: "",
};

const initialFormValidacion: IConfigValidacion = {
  nombreempresa: { validar: "general", opcional: false },
  ruc: { validar: "cedula", opcional: false },
  direccionempresa: { validar: "general", opcional: false },
  telefonosempresa: { validar: "general", opcional: false },
  procedimiento: { validar: "general", opcional: false },
};

function FormatoMantenimiento() {
  const { mostrarPopup } = useContext(PopupContext);
  const [cargando, setCargando] = useState(false);
  const { formData, formValidacion, formValidado, handleChange, setFormData } =
    useForm(initialFormData, initialFormValidacion);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    $.ajax({
      url: `${host}:${portPDF}/api/formatos/pdf/certificado`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      // consultar los tipos de certificado en la tabla tipocertificado
      data: { idtipocertificado: 5 },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        if (data) {
          let tempData = data;
          delete tempData["idformato"];
          delete tempData["fechaactualizacion"];
          delete tempData["revision"];
          data = tempData as typeof formData;
          setFormData(data);
        }
        setCargando(false);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const handleGuardar = () => {
    if (formValidado) {
      saveData();
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.Incompleto);
    }
  };

  const saveData = () => {
    $.ajax({
      url: `${host}:${portPDF}/api/formatos/pdf/certificado`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      // consultar los tipos de certificado en la tabla tipocertificado
      data: JSON.stringify({ ...formData, idtipocertificado: 5 }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data: TypeMsgResponse) {
        setCargando(false);
        mostrarPopup(1, data.message);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  const getVistaPrevia = () => {
    window.open(
      `${host}:${portPDF}/api/formatos/pdf/certificado/vistaprevia?idtipocertificado=5`,
      "_blank"
    );
  };

  return (
    <div className={styles.contFormato + " animar-zoom-min-to-max"}>
      <div className="encabezado-nombre-barra-buscar">
        <h3 className="titulo-pagina">Formato de mantenimientos</h3>
        <span />
        <div className="cont-flex-horizontal-gap">
          {!cargando && (
            <>
              <Button
                label="Vista previa"
                icono="ico-documento"
                onClick={getVistaPrevia}
              />
              <Button
                label="Guardar"
                icono="ico-guardar"
                onClick={handleGuardar}
              />
            </>
          )}
        </div>
      </div>
      {cargando ? (
        <Loader fullScreen />
      ) : (
        <div className={styles.contFormatoFormulario + " animar-entrada"}>
          <div>
            <h3>Datos de la empresa</h3>
            <div className={styles.contCard + " cont-card"}>
              <Input
                label="Nombre de la empresa"
                name="nombreempresa"
                onChange={handleChange}
                value={formData.nombreempresa}
                validacion={formValidacion.nombreempresa}
              />
              <Input
                label="Ruc de la empresa"
                name="ruc"
                onChange={handleChange}
                value={formData.ruc}
                validacion={formValidacion.ruc}
              />
              <Input
                label="Dirección"
                name="direccionempresa"
                onChange={handleChange}
                value={formData.direccionempresa}
                validacion={formValidacion.direccionempresa}
              />
              <Input
                label="Teléfonos"
                name="telefonosempresa"
                onChange={handleChange}
                value={formData.telefonosempresa}
                validacion={formValidacion.telefonosempresa}
              />
            </div>
          </div>
          <div>
            <h3>Procedimiento</h3>
            <div className="cont-card">
              <Input
                label="Procedimiento"
                name="procedimiento"
                onChange={handleChange}
                value={formData.procedimiento}
                validacion={formValidacion.procedimiento}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormatoMantenimiento;
