import { useEffect } from "react";
import styles from "./FSModal.module.css";

interface Props {
  activo: boolean;
  cerrar: () => void;
  children: JSX.Element | JSX.Element[];
  zIndex?: number;
  temblar?: boolean;
  titulo?: string;
}

function FSModal({
  activo,
  cerrar,
  children,
  zIndex = 100,
  temblar = false,
  titulo,
}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activo]);

  return (
    <>
      <div
        style={{ zIndex }}
        className={[
          styles.contModal,
          activo ? styles.active : "",
          temblar ? " temblar " : "",
        ].join(" ")}
      >
        <div className={styles.contTitle}>
          <h3>{titulo ?? ""}</h3>
          <div
            style={{ zIndex: zIndex + 10 }}
            className={`${styles.btnCerrar}`}
            onClick={cerrar}
          >
            <div className={`ico-cerrar ${styles.icoCerrar}`} />
          </div>
        </div>
        <div>{children}</div>
      </div>
    </>
  );
}

export default FSModal;
