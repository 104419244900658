import { useState, useEffect } from "react";
import { useWindowSize } from "./useWindowSize";
function useItemsPP(itemWidth = 310, filas: number = 3) {
  const [itemsPP, setItemsPP] = useState(0);
  const windowSize = useWindowSize();

  useEffect(() => {
    setItemsPP(
      Math.round((windowSize.width ? windowSize.width : 0) / itemWidth) * filas
    );
  }, [windowSize]);
  return itemsPP;
}

export default useItemsPP;
