import React, { useCallback, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { io } from "socket.io-client";
import BrowserNotification from "../helpers/BrowserNotification";
import { host, portSocket } from "../helpers/DbData";
import UserContext from "./UserContext";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);
  const [notificaciones, setNotificaciones] = useState([]);

  useEffect(() => {
    if (userData.idusuario > 0) {
      conectarSocket();
    } else {
      desconectarSocket();
    }
  }, [userData]);

  useEffect(() => {
    // socket?.on("connect", () => setOnline(true));
    // socket?.on("disconnect", () => setOnline(false));
    setOnline(socket?.connected);
    socket?.on("listaUsuarios", (usuarios) => {
      // console.log(usuarios);
    });
    socket?.on("pendientes", (notificaciones) => {
      setNotificaciones(
        notificaciones.sort((a, b) => b.idnotificacion - a.idnotificacion)
      );
    });
    if (
      userData.idperfil === 2 ||
      userData.idperfil === 4 ||
      userData.idperfil === 5
    ) {
      socket?.on("notificacion", (data) => {
        BrowserNotification({
          titulo: "Cotización aprobada",
          mensaje: data.mensaje,
          ruta: data.ruta,
          onClick: () => aprobarNotificacion(data.idnotificacion),
        });
      });
    }
  }, [socket]);

  const desconectarSocket = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  const conectarSocket = useCallback(() => {
    desconectarSocket();
    const socketTemp = io.connect(`${host}:${portSocket}`, {
      transports: ["websocket"],
      autoConnect: true,
      forceNew: true, // crea una nueva conexión
      query: {
        idusuario: userData.idusuario,
        idperfil: userData.idperfil,
      },
    });
    setSocket(socketTemp);
  }, [userData.idusuario, userData.idperfil, desconectarSocket]);

  const aprobarNotificacion = (idnotificacion) => {
    socket?.emit("atender-notificacion", {
      idusuario: userData.idusuario,
      idperfil: userData.idperfil,
      idnotificacion,
    });
  };

  return (
    <SocketContext.Provider
      value={{ socket, online, notificaciones, aprobarNotificacion }}
    >
      {children}
    </SocketContext.Provider>
  );
};
