import { host, portPDF } from "../../../../../../helpers/DbData";
import Button from "../../../../../generic/Button";
import styles from "./ItemHistorial.module.css";

interface Props {
  idordentrabajo: number;
  idcliente: number;
  version: number;
}

function ItemHistorial({ idordentrabajo, idcliente, version }: Props) {
  const descargar = () => {
    window.open(
      // `localhost:${portPDF}/api/download/pdf/cotizacion?idot=${idordentrabajo}&version=${version}`,
      `${host}:${portPDF}/api/download/pdf/cotizacion?idot=${idordentrabajo}&version=${version}&idcliente=${idcliente}`,
      "_blank"
    );
  };

  return (
    <div className={`cont-item-historial ${styles.contItemHistorial}`}>
      <div>
        <p>{version}</p>
      </div>
      <Button icono="ico-descargar" onClick={descargar} nivel={3} />
    </div>
  );
}

export default ItemHistorial;
