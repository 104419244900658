import ContInput from "../../../../generic/ContInput";
import LabelText from "../../../../generic/LabelText";
import { useState, useEffect, useContext } from "react";
import Button from "../../../../generic/Button";
import { Validar } from "../../../../../helpers/Validar";
import MensajesUI from "../../../../../helpers/MensajesUI";
import PopupContext from "../../../../../context/PopupContext";

// interface IFormValidado {
//   marca: [number, string];
//   modelo: [number, string];
//   serie: [number, string];
//   codigo: [number, string];
//   tipo: [number, string];
//   capacidad: [number, string];
//   divescalaverif: [number, string];
//   divescalareal: [number, string];
//   unidadmedida: [number, string];
//   clase: [number, string];
//   responsable: [number, string];
//   ubicacion: [number, string];
//   idtipoplataforma: [number, string];
// }

interface IFormValidado {
  [key: string]: [number, string];
}

const initialFormValidado: IFormValidado = {
  marca: [1, ""],
  modelo: [1, ""],
  serie: [1, ""],
  codigo: [1, ""],
  tipo: [1, ""],
  tipoequipo: [1, ""],
  capacidad: [1, ""],
  divescalaverif: [1, ""],
  divescalareal: [1, ""],
  unidadmedida: [1, ""],
  clase: [1, ""],
  responsable: [1, ""],
  ubicacion: [1, ""],
  idtipoplataforma: [1, ""],
};

export interface IIdentificacionEquipo {
  marca: string;
  modelo: string;
  serie: string;
  codigo: string;
  tipo: string;
  tipoequipo: string;
  capacidad: number;
  capacidad1: number | null;
  divescalaverif: number;
  divescalaverif1: number | null;
  divescalareal: number;
  divescalareal1: number | null;
  unidadmedida: string;
  clase: number;
  responsable: string;
  ubicacion: string;
  idtipoplataforma: number;
  numdecimales1: number;
  numdecimales2: number | null;
}

export interface INumDecimales {
  numdecimales1: number;
  numdecimales2: number | null;
}

interface Props {
  data: IIdentificacionEquipo;
  permitirEdicion: boolean;
  setData: (data: IIdentificacionEquipo) => void;
  setEditando: (editando: boolean) => void;
}

function IdentificacionEquipo({
  data,
  permitirEdicion,
  setData,
  setEditando,
}: Props) {
  const [editar, setEditar] = useState(false);
  const [formData, setFormData] = useState(data);
  const [formValidado, setFormValidado] = useState(initialFormValidado);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setEditando(editar);
  }, [editar]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    actualizarValidacion(e);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formValidado[e.target.name][0])
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
  };

  const actualizarValidacion = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempCampo = {};
    const key = e.target.name as keyof typeof formValidado;
    switch (key) {
      case "capacidad":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "clase":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "codigo":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "divescalareal":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "divescalaverif":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "idtipoplataforma":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "marca":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "modelo":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "responsable":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "serie":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "tipo":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "ubicacion":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      default:
        break;
    }
    setFormValidado({
      ...formValidado,
      ...tempCampo,
    });
  };

  const cancelar = () => {
    setEditar(false);
    setFormData(data);
  };

  const guardar = () => {
    if (validarForm()) {
      setEditar(false);
      setData(formData);
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  const validarForm = () => {
    let key: keyof IFormValidado;
    for (key in formValidado) {
      if (Object.hasOwnProperty.call(formValidado, key)) {
        const el = formValidado[key];
        if (!el[0]) return false;
      }
    }
    return true;
  };

  return (
    <div className="cont-card">
      <div className="calibracion-titulo-btn">
        <h3>Identificación del equipo</h3>
        {permitirEdicion && (
          <>
            {editar ? (
              <div className="cont-flex-horizontal-gap">
                <Button label="Guardar" onClick={guardar} nivel={2} />
                <Button
                  label="Cancelar"
                  onClick={cancelar}
                  color="ROJO"
                  nivel={2}
                />
              </div>
            ) : (
              <Button
                label="Editar"
                onClick={() => setEditar(true)}
                nivel={2}
              />
            )}
          </>
        )}
      </div>
      <div className="calibracion-2-column">
        <div>
          <ContInput label={"Marca"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="marca"
              value={formData.marca}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.marca[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.marca[0] && (
            <p className="texto-validacion">{formValidado.marca[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Modelo"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="modelo"
              value={formData.modelo}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.modelo[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.modelo[0] && (
            <p className="texto-validacion">{formValidado.modelo[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Serie"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="serie"
              value={formData.serie}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.serie[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.serie[0] && (
            <p className="texto-validacion">{formValidado.serie[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Código"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="codigo"
              value={formData.codigo}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.codigo[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.codigo[0] && (
            <p className="texto-validacion">{formValidado.codigo[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Tipo"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="tipo"
              value={formData.tipo}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.tipo[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.tipo[0] && (
            <p className="texto-validacion">{formValidado.tipo[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Tipo de equipo"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="tipoequipo"
              value={formData.tipoequipo}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.tipoequipo[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.tipoequipo[0] && (
            <p className="texto-validacion">{formValidado.tipoequipo[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Ubicación"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="ubicacion"
              value={formData.ubicacion}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.ubicacion[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.ubicacion[0] && (
            <p className="texto-validacion">{formValidado.ubicacion[1]}</p>
          )}
        </div>
        <div>
          <ContInput label={"Responsable"}>
            <input
              type="text"
              pattern="[0-9]*"
              name="responsable"
              value={formData.responsable}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!editar}
            />
            {!formValidado.responsable[0] ? (
              <div className="ico-advertencia  format-ico-form-validacion" />
            ) : (
              <></>
            )}
          </ContInput>
          {!formValidado.responsable[0] && (
            <p className="texto-validacion">{formValidado.responsable[1]}</p>
          )}
        </div>
      </div>
      <div className="calibracion-2-column mt10 divisor-top">
        <LabelText
          label={"Capacidad"}
          text={`${data.capacidad}${
            data.capacidad1 ? " / " + data.capacidad1 : ""
          } ${data.unidadmedida}`}
          centrar
        />
        <LabelText
          label={"Div. escala verif."}
          text={`${data.divescalaverif}${
            data.divescalaverif1 ? " / " + data.divescalaverif1 : ""
          } ${data.unidadmedida}`}
          centrar
        />
        <LabelText
          label={"Div. escala real"}
          text={`${data.divescalareal}${
            data.divescalareal1 ? " / " + data.divescalareal1 : ""
          } ${data.unidadmedida}`}
          centrar
        />
        <LabelText label={"Clase"} text={data.clase} centrar />
      </div>
    </div>
  );
}

export default IdentificacionEquipo;
