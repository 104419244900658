import styles from "./Collapsable.module.css";
import { useState } from "react";

interface Props {
  children: React.ReactNode;
  title: string;
}

function Collapsable({ children, title }: Props) {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.contPrincipal}>
      <div className={styles.contTitulo} onClick={() => setActive(!active)}>
        <h4 className={styles.titulo}>{title}</h4>
        <div
          className={`ico-arriba-simple ${styles.chevron} ${
            active ? styles.active : ""
          }`}
        />
      </div>
      <div className={`${styles.contChildren} ${active ? styles.opened : ""}`}>
        {children}
      </div>
    </div>
  );
}

export default Collapsable;
