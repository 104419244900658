import ContInput from "./ContInput";
import TypeIcon from "../../types/TypeIcon";
import { useState, useEffect, useRef } from "react";
import styles from "./SelectSearch.module.css";
import { IValidacion } from "../../helpers/ValidarT";

interface IOption {
  value: string | number;
  label: string;
}

interface Props {
  label?: string;
  icono?: TypeIcon;
  disabled?: boolean;
  name: string;
  value: string | number;
  validacion?: IValidacion;
  onChange: (name: any, value: string) => void;
  options: IOption[];
}

function SelectSearch({
  label,
  icono,
  disabled = false,
  name,
  value,
  validacion,
  onChange,
  options,
}: Props) {
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [active, setActive] = useState(false);
  const [ownOptions, setOwnOptions] = useState<IOption[]>([]);
  const refInput = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerminoBusqueda(e.target.value);
  };

  const handleChangeSelect = (value: string | number) => {
    onChange(name, value.toString());
    setTerminoBusqueda(
      options.find((el) => el.value === value)
        ? options.find((el) => el.value === value)!.label
        : ""
    );
    setActive(false);
  };

  useEffect(() => {
    if (terminoBusqueda.length <= 0) {
      onChange(name, "0");
    }
  }, [terminoBusqueda]);

  const open = () => {
    if (!disabled) {
      setActive(true);
      setTerminoBusqueda("");
      refInput.current?.focus();
    }
  };

  const refCont = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (refCont.current && !refCont.current.contains(e.target as Node)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [refCont]);

  useEffect(() => {
    setOwnOptions(
      options.filter(
        (el) =>
          el.label
            ?.toString()
            .toLowerCase()
            .indexOf(terminoBusqueda.toLowerCase()) > -1
      )
    );
  }, [terminoBusqueda, options]);

  useEffect(() => {
    if (!active && Number(value) === 0) {
      setTerminoBusqueda("");
    }
  }, [active, value]);

  useEffect(() => {
    const option = options.find((el) => Number(el.value) === Number(value));
    if (Number(value) !== 0) {
      setTerminoBusqueda(option ? option.label : "");
    }
  }, [value, options]);

  return (
    <div ref={refCont} className={styles.contPrincipal}>
      <div>
        <ContInput label={label}>
          <input
            ref={refInput}
            name="terminoBusqueda"
            value={terminoBusqueda}
            onChange={handleChange}
            disabled={disabled}
          />
          {validacion && !validacion?.validado ? (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          ) : (
            <></>
          )}
        </ContInput>
        <div
          className={`cont-card ${styles.selector} ${
            active ? styles.active : ""
          }`}
        >
          {ownOptions.map((el, i) => (
            <p key={i} onClick={() => handleChangeSelect(el.value)}>
              {el.label}
            </p>
          ))}
        </div>
      </div>
      <div
        className={`${styles.capturador} ${active ? styles.active : ""}`}
        onClick={open}
      ></div>
    </div>
  );
}

export default SelectSearch;
