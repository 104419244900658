import { useContext, useState } from "react";
import UserContext from "../../../../context/UserContext";
import LabelText from "../../../generic/LabelText";
import "./OrdenTrabajo.css";
import ConfirmContext from "../../../../context/ConfirmContext";
import $ from "jquery";
import { host, port } from "../../../../helpers/DbData";
import PopupContext from "../../../../context/PopupContext";
import { IOrdenTrabajo } from "../../../../pages/ordenes/OrdenesPA";
import LabelNumeroUnidad from "../../../generic/LabelNumeroUnidad";
import Loader from "../../../generic/Loader";

export const maxEstadosOT = 9;

interface Props {
  datosOrden: IOrdenTrabajo;
  verDetalle: (idordentrabajo: number) => void;
  editar?: (idordentrabajo: number, estadocotizacion: number | null) => void;
  duplicar?: (idordentrabajo: number) => void;
  actualizarOrdenes?: () => void;
  verCertificados?: (idordentrabajo: number) => void;
}

function OrdenTrabajo({
  datosOrden,
  verDetalle,
  editar,
  duplicar,
  actualizarOrdenes,
  verCertificados,
}: Props) {
  const { userData } = useContext(UserContext);
  const { mostrarConfirm } = useContext(ConfirmContext);
  const { mostrarPopup } = useContext(PopupContext);
  const [cargando, setCargando] = useState(false);

  const renderEstado = () => {
    let htmlEstados = [];
    for (let i = 1; i < maxEstadosOT; i++) {
      // if (parseInt(orden.estado) == maxEstados + 1) {
      if (datosOrden.estado === maxEstadosOT) {
        htmlEstados[i] = <div key={i} className="color-estado-completo"></div>;
      } else {
        //   if (i < parseInt(orden.estado)) {
        if (i < datosOrden.estado) {
          htmlEstados[i] = <div key={i} className={"color-estado-" + i}></div>;
        } else {
          htmlEstados[i] = <div key={i} className="sin-color"></div>;
        }
      }
    }
    return htmlEstados;
  };

  const eliminarOrden = async () => {
    if (await mostrarConfirm("¿Borrar la orden de trabajo?")) {
      $.ajax({
        url: `${host}:${port}/api/ordenTrabajo/mostrarocultar`,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          idordentrabajo: datosOrden.idordentrabajo,
          ocultar: 1,
          idmodificador: userData.idusuario,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data.data);
          if (actualizarOrdenes) actualizarOrdenes();
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  return (
    <div className="cont-orden-trabajo cont-card animar-hover animar-entrada">
      <div className="orden-num-orden">
        <span>
          {datosOrden.codigocotizacion}
          {datosOrden.version ? `.${datosOrden.version}` : ""}
        </span>
        <span
          className={`orden-trabajo-indicador-servicio ${
            datosOrden.idservicio === 1
              ? "calibracion"
              : datosOrden.idservicio === 2
              ? "mantenimiento"
              : datosOrden.idservicio === 4
              ? "ensayo"
              : ""
          }`}
        >
          {datosOrden.servicio}
        </span>
      </div>
      <div className="cont-datos-resumen-orden-trabajo">
        <div className="datos-resumen-orden-trabajo">
          <LabelText label={"Técnicos"} text={datosOrden.tecnicos ?? "-"} />
          <LabelText
            label={"Fecha de servicio"}
            text={
              datosOrden.fechaserviciodesde
                ? `${new Date(datosOrden.fechaserviciodesde).toLocaleDateString(
                    "sv"
                  )} ${
                    datosOrden.fechaserviciohasta &&
                    datosOrden.fechaserviciodesde !==
                      datosOrden.fechaserviciohasta
                      ? ` / ${new Date(
                          datosOrden.fechaserviciohasta
                        ).toLocaleDateString("sv")}`
                      : ""
                  }`
                : "-"
            }
          />
          <LabelText
            label={"Tipos de equipo"}
            text={
              datosOrden.equipos
                ? datosOrden.equipos[0].toUpperCase() +
                  datosOrden.equipos.substring(1).toLowerCase()
                : "-"
            }
          />
        </div>
        <div>
          <LabelNumeroUnidad
            label="Cant. equipos"
            numero={datosOrden.cantequipos.toString()}
            derecha
          />
        </div>
      </div>
      <div className="cont-barra-estados">{renderEstado()}</div>
      <div
        className="orden-trabajo-clicker"
        onClick={() => verDetalle(datosOrden.idordentrabajo)}
      />
      <div className="cont-acciones">
        {/* los estados de la orden de trabajo pueden verse en la documentación del software */}
        {/* los perfiles se pueden ver en la tabla "perfil" */}
        {((datosOrden.estado === 5 && userData.idperfil === 2) ||
          (datosOrden.estado === 6 && userData.idperfil === 5)) && (
          <div
            className="acciones-accion"
            onClick={() =>
              !!verCertificados && verCertificados(datosOrden.idordentrabajo)
            }
          >
            <div className="ico-bandeja format-ico-accion"></div>
            <p>Revisar</p>
          </div>
        )}
        {datosOrden.estado < 4 && (
          <div className="acciones-accion" onClick={eliminarOrden}>
            {cargando ? (
              <Loader />
            ) : (
              <>
                <div className="ico-eliminar format-ico-accion"></div>
                <p>Eliminar</p>
              </>
            )}
          </div>
        )}
        {userData.idperfil === 4 && (
          <>
            <div
              className="acciones-accion"
              onClick={() => !!duplicar && duplicar(datosOrden.idordentrabajo)}
            >
              <div className="ico-copiar format-ico-accion"></div>
              <p>Duplicar</p>
            </div>
            {/* estado cotización viene de la tabla "estadocotizacion" para identificar el estado de facturación de la orden */}
            {(datosOrden.estado < 4 || datosOrden.estadocotizacion === 1) && (
              // {(datosOrden.estado < 4 || datosCotizacion.estado === 5) && (
              <div
                className="acciones-accion"
                onClick={() =>
                  !!editar &&
                  editar(datosOrden.idordentrabajo, datosOrden.estadocotizacion)
                }
              >
                <div className="ico-lapiz format-ico-accion"></div>
                <p>Editar</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default OrdenTrabajo;
