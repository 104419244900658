import { useContext } from "react";
import UserContext from "../../../../context/UserContext";
import MensajeCentro from "../../../generic/MensajeCentro";
import { IEquipoActivo } from "../Calibraciones";
import { IEquipoRevisionBalanzas } from "./ListaBalanzas";

export interface IOrdenRevisionPP {
  idordentrabajo: number;
  year: number;
  num: number;
}

interface Props {
  setEquipoActivo: (
    data: IEquipoRevisionBalanzas | IOrdenRevisionPP | undefined
  ) => void;
  equiposRevision: IOrdenRevisionPP[];
  equipoActivo: IEquipoActivo | undefined;
}

function ListaPesosPatron({
  setEquipoActivo,
  equiposRevision,
  equipoActivo,
}: Props) {
  const { userData } = useContext(UserContext);

  return (
    <>
      {equiposRevision.length > 0 ? (
        <>
          {equiposRevision.map((el, i) => {
            if (userData.idperfil === 5) {
              return (
                <p
                  key={i}
                  className={
                    "calibraciones-equipo-lista animar-entrada " +
                    (equipoActivo &&
                    equipoActivo.idordentrabajo === el.idordentrabajo
                      ? " active "
                      : "")
                  }
                  onClick={() => {
                    setEquipoActivo(el);
                  }}
                >
                  {el.year}-{el.num}
                </p>
              );
            } else {
              return (
                <p
                  key={i}
                  className={
                    "calibraciones-equipo-lista animar-entrada " +
                    (equipoActivo &&
                    equipoActivo.idordentrabajo === el.idordentrabajo
                      ? " active "
                      : "")
                  }
                  onClick={() => {
                    setEquipoActivo(el);
                  }}
                >
                  {el.year}-{el.num}
                </p>
              );
            }
          })}
        </>
      ) : (
        <div style={{ marginTop: "30px" }}>
          <MensajeCentro mensaje="No hay certificados por revisar" />
        </div>
      )}
    </>
  );
}

export default ListaPesosPatron;
