import LabelText from "../../../../generic/LabelText";

export interface ICondicionesAmbientalesPP {
  temperatura: string;
  presion: string;
  humedad: string;
  equipo: string;
}

interface Props {
  data: ICondicionesAmbientalesPP;
}

function CondicionesAmbientalesPP({ data }: Props) {
  return (
    <div className="separador-inferior">
      <h3>Condiciones ambientales</h3>
      <div>
        <div className="table-row table-row-title">
          <span>Temperatura</span>
          <span>Humedad</span>
          <span>Presión atmosférica</span>
        </div>
        <div className="table-row row-equipo animar-entrada">
          <span>{data.temperatura}</span>
          <span>{data.humedad}</span>
          <span>{data.presion}</span>
        </div>
      </div>
      <LabelText label={"Equipo"} text={data.equipo} />
    </div>
  );
}

export default CondicionesAmbientalesPP;
