import Input from "../../components/generic/Input";
import { useState, useCallback, useContext, useEffect, useRef } from "react";
import $ from "jquery";
import { host, port } from "../../helpers/DbData";
import PopupContext from "../../context/PopupContext";
import Modal from "../../components/generic/Modal";
import DetalleOrdenTrabajo from "../../components/clientes/seccionesDetalle/ordenestrabajo/DetalleOrdenTrabajo";
import styles from "./Buscar.module.css";
import OrdenTrabajo from "../../components/clientes/seccionesDetalle/ordenestrabajo/OrdenTrabajo";
import MensajeCentro from "../../components/generic/MensajeCentro";
import DataHandler from "../../helpers/DataHandler";
import { IOrdenTrabajo } from "./OrdenesPA";
import Loader from "../../components/generic/Loader";
import React from "react";
import Button from "../../components/generic/Button";

interface IOrden extends IOrdenTrabajo {
  empresa: string;
}

function Buscar() {
  const { mostrarPopup } = useContext(PopupContext);

  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [cargando, setCargando] = useState(false);
  const [modalActivo, setModalActivo] = useState(false);
  const [ordenes, setOrdenes] = useState<IOrden[]>([]);
  const [idOrdenActiva, setIdOrdenActiva] = useState(0);
  const [busquedaTerminada, setBusquedaTerminada] = useState(false);

  const handleChange = (name: string, value: string) => {
    setTerminoBusqueda(value);
  };

  const buscar = useCallback(() => {
    if (terminoBusqueda.length > 0)
      $.ajax({
        url: `${host}:${port}/api/usuario/buscarordenes`,
        type: "get",
        dataType: "json",
        contentType: "application/json",
        data: { terminobusqueda: terminoBusqueda },
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          setOrdenes(data);
          setBusquedaTerminada(true);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  }, [mostrarPopup, terminoBusqueda]);

  const borrarBusqueda = useCallback(() => {
    setBusquedaTerminada(false);
    setOrdenes([]);
    setTerminoBusqueda("");
  }, []);

  return (
    <>
      <div>
        <div className="encabezado-nombre-barra-buscar">
          <span />
          <div className="flex flex-row gap-1">
            <div className={styles.contBarraBusqueda}>
              <Input
                onChange={handleChange}
                name="terminobusqueda"
                value={terminoBusqueda}
                placeholder={"Buscar"}
                icono="ico-lupa"
              />
              {busquedaTerminada && (
                <div
                  className={`ico-cerrar animar-hover ${styles.btnBorrar}`}
                  onClick={borrarBusqueda}
                />
              )}
            </div>
            <Button label="Buscar" onClick={buscar} nivel={1} color="BLANCO" />
          </div>
          <span />
        </div>
        <div className={styles.contPrincipal}>
          {cargando ? (
            <Loader fullScreen />
          ) : ordenes.length > 0 ? (
            <>
              {Object.keys(DataHandler.groupBy(ordenes, "empresa")).map(
                (el, i) => (
                  <React.Fragment key={i}>
                    <p className={styles.empresa}>{el}</p>
                    <div className={styles.contOrdenes}>
                      {DataHandler.groupBy(ordenes, "empresa")[el].map(
                        (el1, i1) => (
                          <OrdenTrabajo
                            key={i1}
                            datosOrden={el1}
                            verDetalle={(idordentrabajo) => {
                              setIdOrdenActiva(idordentrabajo);
                              setModalActivo(true);
                            }}
                          />
                        )
                      )}
                    </div>
                  </React.Fragment>
                )
              )}
            </>
          ) : (
            <div style={{ marginTop: "20px" }}>
              <MensajeCentro mensaje="No se han encontrado órdenes" />
            </div>
          )}
        </div>
      </div>
      <Modal activo={modalActivo} cerrar={() => setModalActivo(false)}>
        <DetalleOrdenTrabajo
          idOrdenTrabajo={idOrdenActiva}
          activo={modalActivo}
          cerrar={() => {
            setModalActivo(false);
          }}
        />
      </Modal>
    </>
  );
}

export default Buscar;
