import { createContext, useState, useCallback } from "react";

interface IPdfContext {
  pdf: string | undefined;
  decodePdf: (file: BlobPart | undefined, headers: JQuery.jqXHR<any>) => void;
  error: boolean;
  modalActivo: boolean;
  cerrar: () => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  nombreArchivo: string;
  descargar: () => void;
}

const PdfContext = createContext({} as IPdfContext);

interface Props {
  children: React.ReactNode;
}

const PdfProvider = ({ children }: Props) => {
  const [pdf, setPdf] = useState<string>();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalActivo, setModalActivo] = useState(false);
  const [nombreArchivo, setNombreArchivo] = useState("");
  const [blob, setBlob] = useState<Blob>();

  const decodePdf = useCallback(
    (data: BlobPart | undefined, headers: JQuery.jqXHR<any>) => {
      const contentDisposition = headers.getResponseHeader(
        "Content-Disposition"
      );
      let filename = "Download.pdf";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match.length > 1) {
          filename = match[1] ?? "asdf";
        }
      }
      setNombreArchivo(filename);
      if (data) {
        const file = new Blob([data], { type: "application/pdf" });
        setBlob(file);
        const fileURL = URL.createObjectURL(file);
        setPdf(fileURL);
        setError(false);
        setModalActivo(true);
      } else {
        setError(true);
      }
    },
    []
  );

  const descargar = useCallback(() => {
    if (blob) {
      const link = document.createElement("a");
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = nombreArchivo;
      // some browser needs the anchor to be in the doc
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  }, [blob, nombreArchivo]);

  const inicializar = useCallback(() => {
    setModalActivo(false);
    setError(false);
    setErrorMessage("");
    setPdf(undefined);
    setNombreArchivo("");
  }, []);

  const cerrar = useCallback(() => {
    inicializar();
  }, [inicializar]);

  return (
    <PdfContext.Provider
      value={{
        pdf,
        decodePdf,
        error,
        modalActivo,
        cerrar,
        errorMessage,
        setErrorMessage,
        nombreArchivo,
        descargar,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};

export { PdfProvider };
export default PdfContext;
