// import { useEffect, useState } from "react";
import FormBalanza from "./tipoEquipoForm/FormBalanza";
import FormPesoPatron from "./tipoEquipoForm/FormPesoPatron";
import FormTermometro from "./tipoEquipoForm/FormTermometro";

import "./FormNuevoEquipo.css";

let detalleForm = [
  { nombreForm: "Nueva balanza", icono: "ico-balanza " },
  {
    nombreForm: "Nuevo peso patrón",
    icono: "ico-peso-patron color-peso-patron",
  },
  { nombreForm: "Nuevo termómetro", icono: "ico-termometro color-termometro " },
];
// let listaEquipos = ["Balanza", "Peso patrón", "Termómetro"];
// let listaEquipos = ["Balanza"];

// se muestra un solo modal para crear o editar un equipo de cualquier tipo como balanza, peso patron o termometro
function FormNuevoEquipo({
  activo,
  idcliente,
  idequipo,
  duplicar,
  idtablaequipo,
  cerrar,
  equipos,
  seleccionarOrdenesAfectadas,
}) {
  // const [pantallaTipoEquipo, setPantallaTipoEquipo] = useState(0);

  // useEffect(() => {
  //   // if (idequipo !== 0) {
  //   setPantallaTipoEquipo(idtablaequipo);
  //   // }
  // }, [idequipo, idtablaequipo]);
  // useEffect(() => {
  //   if (idequipo !== 0) {
  //     setPantallaTipoEquipo(idtablaequipo);
  //   }
  // }, [idequipo, pantallaTipoEquipo]);

  const renderSwitch = () => {
    switch (idtablaequipo) {
      case 0:
        return (
          <FormBalanza
            idcliente={idcliente}
            idequipo={idequipo}
            duplicar={duplicar}
            cerrar={cerrar}
            equipos={equipos.balanzas}
            activo={activo}
            seleccionarOrdenesAfectadas={seleccionarOrdenesAfectadas}
          />
        );
      case 1:
        return (
          <FormPesoPatron
            idcliente={idcliente}
            idequipo={idequipo}
            duplicar={duplicar}
            cerrar={cerrar}
            equipos={equipos.pesospatron}
            activo={activo}
            seleccionarOrdenesAfectadas={seleccionarOrdenesAfectadas}
          />
        );
      case 2:
        return (
          <FormTermometro
            idcliente={idcliente}
            idequipo={idequipo}
            cerrar={cerrar}
            equipos={equipos.termometros}
            activo={activo}
          />
        );
      default:
        break;
    }
  };

  // const cambiarPantallaTipoEquipo = (pantalla) => {
  //   if (idequipo === 0) setPantallaTipoEquipo(pantalla);
  // };

  return (
    <div style={{ width: "500px" }}>
      {/* <div style={{ display: "grid", justifyContent: "center" }}>
        <Tabs
          lista={listaEquipos}
          selected={pantallaTipoEquipo}
          setSelected={cambiarPantallaTipoEquipo}
        />
      </div> */}
      <div className="titulo-form-nuevo-equipo">
        <div
          className={
            detalleForm[idtablaequipo].icono + " format-ico-titulo-form "
          }
        ></div>
        <h3>
          {idequipo === 0
            ? detalleForm[idtablaequipo].nombreForm
            : "Edición de equipo"}
        </h3>
      </div>
      {renderSwitch()}
    </div>
  );
}

export default FormNuevoEquipo;
