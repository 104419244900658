import ContInput from "./ContInput";
import { IValidacion } from "../../helpers/ValidarT";
import TypeIcon from "../../types/TypeIcon";

interface Props {
  label?: string;
  icono?: TypeIcon;
  disabled?: boolean;
  name: string;
  value: string | number;
  validacion?: IValidacion;
  onChange: (name: any, value: string) => void;
  options: { value: string | number; label: string }[];
  initialLabel?: string;
}

function Select({
  label,
  icono,
  disabled = false,
  name,
  value,
  validacion,
  onChange,
  options,
  initialLabel,
}: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <div>
      <ContInput label={label} icono={icono}>
        <select
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          style={
            initialLabel && value.toString() === "0"
              ? {
                  textTransform: "uppercase",
                  fontWeight: "normal",
                  // color: "lightgray",
                }
              : {}
          }
        >
          {value.toString() === "0" && (
            <option
              value={0}
              style={{
                textTransform: "uppercase",
                // color: "lightgray",
              }}
            >
              {initialLabel ?? ""}
            </option>
          )}
          {options.map((el, i) => (
            <option key={i} value={el.value}>
              {el.label}
            </option>
          ))}
        </select>
        {validacion && !validacion?.validado ? (
          <div className="ico-advertencia  format-ico-form-validacion"></div>
        ) : (
          <></>
        )}
      </ContInput>
      {validacion && !validacion?.validado && (
        <p className="texto-validacion">{validacion?.mensaje}</p>
      )}
    </div>
  );
}

export default Select;
