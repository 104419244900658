import Button from "../../generic/Button";
import $ from "jquery";
import { useContext, useRef, useState } from "react";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import LabelText from "../../generic/LabelText";
// let host = "http://localhost";
// let port = "4003";
function JuegoPesas({ datosJuego, actualizar }) {
  const refInput = useRef();
  const [cargando, setCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const descargarExcel = () => {
    mostrarPopup(1, "Descargando excel");
    window.open(
      `${host}:${port}/api/excel/download/pesas/${datosJuego.idjuegopesa}`
    );
  };

  const cargarExcel = (e) => {
    if (!cargando) {
      var file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("idjuegopesa", datosJuego.idjuegopesa);
      e.target.value = null;
      $.ajax({
        url: `${host}:${port}/api/excel/upload`,
        type: "POST",
        contentType: false,
        processData: false,
        data: formData,
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data);
          actualizar();
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  return (
    <>
      <div
        className="cont-card cont-flex-vertical-gap"
        style={{ borderRadius: "15px" }}
      >
        <p className="titulo-card-trazabilidad">{datosJuego.juegopesa}</p>
        <div className="cont-grid-equal-gap">
          <LabelText label={"Cantidad"} text={datosJuego.cantidad} />
          <div className="max-content justify-self-end cont-flex-vertical-gap align-items-end">
            <Button
              icono="ico-cargar"
              onClick={() => refInput.current.click()}
              cargando={cargando}
              nivel={2}
            />
            <Button
              icono="ico-descargar"
              onClick={() => {
                descargarExcel(datosJuego.idjuegopesa);
              }}
              nivel={2}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <input
          ref={refInput}
          type={"file"}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={cargarExcel}
        />
      </div>
    </>
  );
}

export default JuegoPesas;
