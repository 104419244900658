import React, { useContext, useState } from "react";
import "./Login.css";
import { Validar } from "../helpers/Validar.js";

import $ from "jquery";
import ContInput from "../components/generic/ContInput";
import Button from "../components/generic/Button";
import PopupContext from "../context/PopupContext";
import UserContext from "../context/UserContext";
import { Navigate } from "react-router-dom";
import { host, port } from "../helpers/DbData";
import MensajesUI from "../helpers/MensajesUI";

const initialDatosUsuarioTemp = {
  cedula: "",
  clave: "",
};

const initialFormValidado = {
  cedula: [false, ""],
  clave: [false, ""],
};

function Login() {
  const { mostrarPopup } = useContext(PopupContext);
  const { sesionActiva, setUserData } = useContext(UserContext);

  const [datosUsuarioTemp, setDatosUsuarioTemp] = useState(
    initialDatosUsuarioTemp
  );
  const [formValidado, setFormValidado] = useState(initialFormValidado);
  const [cargando, setCargando] = useState(false);

  const handleChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
    setDatosUsuarioTemp({
      ...datosUsuarioTemp,
      [e.target.name]: e.target.value,
    });
    actualizarValidacion(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      verificarUsuario();
    }
  };

  const actualizarValidacion = (e) => {
    if (e.target.name === "cedula")
      setFormValidado({
        ...formValidado,
        cedula: Validar.cedula(e.target.value),
      });
    if (e.target.name === "clave")
      setFormValidado({
        ...formValidado,
        clave: Validar.general(e.target.value),
      });
  };

  async function verificarUsuario() {
    if (!cargando)
      if (validarForm()) {
        $.ajax({
          url: `${host}:${port}/api/usuario/login`,
          type: "post",
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify(datosUsuarioTemp),
          beforeSend: function () {
            setCargando(true);
          },
          success: function (data) {
            setCargando(false);
            setUserData(data);
          },
          error: function (data) {
            setCargando(false);
            console.log(data);
            if (data.status === 0)
              mostrarPopup(0, MensajesUI.error.servidor.sinConexion);
            else mostrarPopup(0, data.responseJSON);
          },
        });
      } else {
        mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
      }
  }

  const validarForm = () => {
    setFormValidado({
      cedula: Validar.cedula(datosUsuarioTemp.cedula),
      clave: Validar.general(datosUsuarioTemp.clave),
    });
    for (const key in formValidado) {
      if (Object.hasOwnProperty.call(formValidado, key)) {
        const el = formValidado[key];
        if (!el[0]) return false;
      }
    }
    return true;
  };

  return (
    <div className="cont-page-login">
      {sesionActiva && <Navigate to="/" replace />}
      <div className="cont-login  animar-zoom-min-to-max">
        <form className="cont-card">
          <div className="justify-content-center login-header">
            <div className="login-logo"></div>
            <div className="cont-ico-login-user">
              <div className="ico-usuario format-ico-login-user" />
            </div>
            <h4 className="text-align-center">ADMINISTRACIÓN</h4>
          </div>
          <div className="login-inputs">
            <ContInput icono={"ico-usuario"}>
              <input
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Cédula"
                name="cedula"
                autoFocus
                autoComplete="off"
                value={datosUsuarioTemp.cedula}
              ></input>
              {!formValidado.cedula[0] && (
                <div className="ico-advertencia format-ico-form-validacion"></div>
              )}
            </ContInput>
            <ContInput icono={"ico-candado"}>
              <input
                type="password"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Contraseña"
                name="clave"
                autoComplete="off"
                value={datosUsuarioTemp.clave}
              ></input>
              {!formValidado.clave[0] && (
                <div className="ico-advertencia format-ico-form-validacion"></div>
              )}
            </ContInput>
          </div>
          <div className="login-btn-login">
            <Button
              label={"Ingresar"}
              icono={"ico-login"}
              onClick={verificarUsuario}
              cargando={cargando}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
