import { Tooltip } from "react-tooltip";

function EquipoPesoPatron({
  datosEquipo = {},
  detalles,
  editar,
  duplicar,
  eliminar,
}) {
  return (
    <>
      <div className="table-row row-equipo animar-entrada">
        <span>{datosEquipo.marca}</span>
        <span>{datosEquipo.serie ?? "-"}</span>
        <span>{datosEquipo.codigo ?? "-"}</span>
        <span>
          {datosEquipo.valornominal} <b>{datosEquipo.abreviatura}</b>
        </span>
        <span>{datosEquipo.clase}</span>
        <span>{datosEquipo.forma ?? "-"}</span>
        <span>{datosEquipo.material ?? "-"}</span>
        <div className="row-cont-acciones">
          <div
            id="btn-detalles"
            data-tooltip-id="btn-detalles"
            className="ico-documento h-7 row-accion p-1"
            onClick={() => detalles(datosEquipo.idequipo)}
            title="Ver detalles del equipo"
          />
          <div
            id="btn-editar"
            data-tooltip-id="btn-editar"
            className="ico-lapiz h-7 row-accion p-1"
            onClick={() => editar(datosEquipo.idequipo)}
          />
          <div
            id="btn-duplicar"
            data-tooltip-id="btn-duplicar"
            className="ico-copiar h-7 row-accion p-1"
            onClick={() => duplicar(datosEquipo.idequipo)}
          />
          <div
            id="btn-eliminar"
            data-tooltip-id="btn-eliminar"
            className="ico-eliminar h-7 row-accion p-1"
            onClick={() => eliminar(datosEquipo.idequipo)}
          />
        </div>
      </div>
      <Tooltip id={"btn-detalles"} content="Detalles" />
      <Tooltip id={"btn-editar"} content="Editar" />
      <Tooltip id={"btn-duplicar"} content="Duplicar" />
      <Tooltip id={"btn-eliminar"} content="Eliminar" />
    </>
  );
}

export default EquipoPesoPatron;

// titulo de las filas de la tabla de equipos de peso patron
function EquipoPesoPatronTitulo() {
  return (
    <div className="table-row table-row-title">
      <span>Marca</span>
      <span>Serie</span>
      <span>Código</span>
      <span>Valor nominal</span>
      <span>Clase</span>
      <span>Forma</span>
      <span>Material</span>
      <span style={{ textAlign: "center" }}>Acciones</span>
    </div>
  );
}

export { EquipoPesoPatronTitulo };
