import useForm from "../../../hooks/useForm";
import { IConfigValidacion } from "../../../hooks/useForm";
import Input from "../../generic/Input";
import styles from "./LimitesCondicionesLab.module.css";
import { useCallback, useEffect, useState, useContext } from "react";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import MensajesUI from "../../../helpers/MensajesUI";
import * as math from "mathjs";
import Button from "../../generic/Button";
import Loader from "../../generic/Loader";

interface IFormData {
  temperatura: string;
  humedad: string;
  presion: string;
  iddispositivo: number;
}

const initialFormData: IFormData = {
  temperatura: "",
  humedad: "",
  presion: "",
  iddispositivo: 1,
};

const initialValidacion: IConfigValidacion = {
  temperatura: { validar: "ecuacion", opcional: false },
  humedad: { validar: "ecuacion", opcional: false },
  presion: { validar: "ecuacion", opcional: false },
  iddispositivo: { validar: "noCero", opcional: true },
};

function FCTermohigrometros() {
  const { formData, formValidacion, formValidado, handleChange, setFormData } =
    useForm(initialFormData, initialValidacion);
  const [cargando, setCargando] = useState(false);
  const [cargando1, setCargando1] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const getFC = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/fc_termohigrometros`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        // el iddispositivo es 1 porque solo hay un dispositivo en la tabla y corresponde al termohigrometro del laboratorio de masa
        // cuando se agreguen mas dispositivos se tendra que hacer dinámica la petición
        data.iddispositivo = 1;
        setFormData(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  useEffect(() => {
    getFC();
  }, [getFC]);

  const handleGuardar = () => {
    if (formValidado) {
      const mathParser = math.parser();
      const properties: (keyof typeof formData)[] = [
        "temperatura",
        "humedad",
        "presion",
      ];
      for (const el of properties) {
        mathParser.evaluate("f(x)=" + formData[el]);
        try {
          // valido si la ecuación es correcta evaluando en algunos puntos (pueden aumentarse)
          // la ecuación debe corregir la lectura directamente.
          // Ej: si la temperatura es 20°C pero en realidad debe marcar 20.1°C la ecuación deberá ser: x+0.1
          mathParser.evaluate("f(0)");
          mathParser.evaluate("f(1)");
          mathParser.evaluate("f(5)");
          mathParser.evaluate("f(10)");
        } catch (e) {
          mostrarPopup(2, "La ecuación de " + el + " no es correcta");
          return;
        }
      }
      guardar();
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.Incompleto);
    }
  };

  const guardar = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/fc_termohigrometros`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(formData),
      beforeSend: function () {
        setCargando1(true);
      },
      success: function (data) {
        setCargando1(false);
        mostrarPopup(1, data.mensaje);
      },
      error: function (data) {
        setCargando1(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [formData, mostrarPopup]);

  return (
    <div className={styles.cont}>
      <div>
        <h4>Factores de corrección de los termohigrómetros</h4>
        <Button
          label="Guardar"
          disabled={!formValidado}
          onClick={handleGuardar}
          icono="ico-guardar"
          cargando={cargando1}
        />
      </div>
      {cargando ? (
        <Loader />
      ) : (
        <form
          className={`cont-card ${styles.formFC}`}
          onSubmit={(e) => e.preventDefault()}
        >
          <Input
            label="Temperatura (°C)"
            name="temperatura"
            onChange={handleChange}
            value={formData.temperatura}
            placeholder="2x^2-x+1"
            validacion={formValidacion.temperatura}
          />
          <Input
            label="Humedad (%HR)"
            name="humedad"
            onChange={handleChange}
            value={formData.humedad}
            placeholder="2x^2-x+1"
            validacion={formValidacion.humedad}
          />
          <Input
            label="Presión (hPa)"
            name="presion"
            onChange={handleChange}
            value={formData.presion}
            placeholder="2x^2-x+1"
            validacion={formValidacion.presion}
          />
        </form>
      )}
    </div>
  );
}

export default FCTermohigrometros;
