import { INumDecimales } from "../components/clientes/seccionesDetalle/calibraciones/hojaDatosBalanza/IdentificacionEquipo";
const DataHandler = {
  compare: {
    objects(
      object1: Object,
      object2: Object,
      options: IOptionsDeepcomparison = { ignoreTypes: false } //falta implementar
    ) {
      if (checkIfHasSameProperties(object1, object2)) {
        if (compareObjectValues(object1, object2)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    arrays(array1: Array<object>, array2: Array<object>) {
      if (array1.length === array2.length) {
        for (let i = 0; i < array1.length; i++) {
          const el1 = array1[i];
          const el2 = array2[i];
          if (!DataHandler.compare.objects(el1, el2)) {
            return false;
          }
        }
      } else {
        return false;
      }
      return true;
    },
  },

  nullEmpty<T extends Object>(data: T) {
    let key: keyof T;
    for (key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const el = data[key];
        if (String(el) === "") {
          data[key] = null as any;
        }
      }
    }
    return data;
  },

  emptyNull<T extends Object>(data: T) {
    let key: keyof T;
    for (key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const el = data[key];
        if (el === null || el === undefined) {
          data[key] = "" as any;
        }
      }
    }
    return data;
  },

  groupBy: <T extends Object>(array: T[], key: keyof T) =>
    array.reduce((objectsByKey: { [key: string]: T[] }, obj) => {
      const value = obj[key] as any;
      objectsByKey[value] = (objectsByKey[value] || []).concat(obj);
      return objectsByKey;
    }, Object.create(null)),

  renderND:
    (numdecimales: INumDecimales) =>
    (value: number | null, idnumdecimales: number) => {
      if (value !== undefined && value !== null) {
        const numdecimales0 =
          idnumdecimales === 2
            ? numdecimales.numdecimales2 ?? numdecimales.numdecimales1
            : numdecimales.numdecimales1;
        let formattedValue = Number(value).toFixed(numdecimales0);

        if (Number(formattedValue) === 0) {
          formattedValue = "0";
          formattedValue = Number(formattedValue).toFixed(numdecimales0);
        }
        return formattedValue;
      } else {
        return null;
      }
    },
};

const checkIfIsObject = () => {};

const checkIfHasSameProperties = (obj1: Object, obj2: Object) => {
  let key: keyof typeof obj1;
  for (key in obj1) {
    if (obj2[key] === undefined) {
      return false;
    }
  }
  for (key in obj2) {
    if (obj1[key] === undefined) {
      return false;
    }
  }
  return true;
};

const compareObjectValues = (obj1: Object, obj2: Object) => {
  let key: keyof typeof obj1;
  for (key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      const el1 = obj1[key];
      const el2 = obj2[key];
      if (el1 != el2) {
        return false;
      }
    }
  }
  return true;
};

const compareArrays = (array1: [], array2: []) => {
  for (const key in array1) {
    if (Object.prototype.hasOwnProperty.call(array1, key)) {
      const el = array1[key];
      // checkIfHasSameProperties()
    }
  }
};

export default DataHandler;

interface IOptionsDeepcomparison {
  ignoreTypes?: boolean;
}
