import MensajeCentro from "../../../../generic/MensajeCentro";
export interface IProblema {
  problema: string;
  causa: string;
}

interface Props {
  data: IProblema[];
}

function Problemas({ data }: Props) {
  return (
    <div className="cont-card">
      <h3>Problemas</h3>
      {data.length > 0 ? (
        <>
          <div className="table-row table-row-title">
            <span>Problema</span>
            <span>Causa</span>
          </div>
          {data.map((el, i) => (
            <div key={i} className="table-row">
              <span>{el.problema}</span>
              <span>{el.causa}</span>
            </div>
          ))}
        </>
      ) : (
        <MensajeCentro mensaje="No se ha reportado" />
      )}
    </div>
  );
}

export default Problemas;
