import Button from "../../generic/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  to: string;
  label: string;
}

function FilaFormato({ label, to }: Props) {
  const navigate = useNavigate();
  return (
    <div
      className="cont-card fila-formatos animar-hover"
      onClick={() => navigate(to)}
    >
      <p>{label}</p>
    </div>
  );
}

export default FilaFormato;
