import { useEffect, useState, useContext } from "react";
import { host, port, portPDF } from "../../../../../helpers/DbData";
import Loader from "../../../../generic/Loader";
import PopupContext from "../../../../../context/PopupContext";
import $ from "jquery";
import styles from "./Certificados.module.css";
import MensajeCentro from "../../../../generic/MensajeCentro";
import Button from "../../../../generic/Button";
import UserContext from "../../../../../context/UserContext";

interface IBalanzas {
  idcertdata: number;
  idservicio: number;
  numcertificado: number;
  marca: string;
  modelo: string;
  serie: string;
  capacidad: number;
  capacidad1: number;
  divescalareal: number;
  divescalareal1: number;
  unidadmedida: string;
}

interface IPesosPatron {
  idcertdata: number;
  numcertificado: string;
  fechaemision: string;
}

interface ICertificados {
  balanzas: IBalanzas[];
  pesospatron?: IPesosPatron | null;
}

interface Props {
  idordentrabajo: number;
  idcliente: number;
}

// se obtienen los certificados de la orden de trabajo
function Certificados({ idordentrabajo, idcliente }: Props) {
  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);

  const [cargando, setCargando] = useState(false);
  const [certificados, setCertificados] = useState<ICertificados>({
    balanzas: [],
    pesospatron: null,
  });

  useEffect(() => {
    getCertificados();
  }, []);

  const getCertificados = () => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/certificados`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idordentrabajo },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setCertificados(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const descargar = (idcertdata: number, idtablaequipo: number) => {
    window.open(
      `${host}:${portPDF}/api/download/pdf/certificado/${
        idtablaequipo === 1 ? "balanza" : "pesopatron"
      }?idcertdata=${idcertdata}&idcliente=${idcliente}&idusuario=${
        userData.idusuario
      }`,
      "_blank"
    );
  };

  const renderItems = (el: IBalanzas, i: number) => (
    <div
      key={i}
      className={`${styles.rowHorizontal} ${styles.equipos} ${
        // se puede consultar los id de los servicios en la tabla servicio
        el.idservicio === 1
          ? styles.calibracion
          : el.idservicio === 2
          ? styles.mantenimiento
          : el.idservicio === 4
          ? styles.ensayo
          : ""
      }`}
    >
      <p>{el.numcertificado}</p>
      <p>
        {el.marca} {el.modelo} {el.serie} {el.capacidad}
        {el.capacidad1 ? ` / ${el.capacidad1}` : ""} {el.unidadmedida}{" "}
        {el.divescalareal}
        {el.divescalareal1 ? ` / ${el.divescalareal1}` : ""} {el.unidadmedida}
      </p>
      <Button
        icono="ico-descargar"
        onClick={() => descargar(el.idcertdata, 1)}
        nivel={3}
      />
    </div>
  );

  return (
    <div className="cont-card">
      <h3 className="titulo-seccion">Certificados</h3>
      {cargando ? (
        <Loader />
      ) : (
        <div>
          {certificados.balanzas.length > 0 && (
            <>
              <h5 className={styles.tipoEquipo}>Balanzas</h5>
              {certificados.balanzas
                .sort((a, b) => b.idservicio - a.idservicio)
                .map(renderItems)}
            </>
          )}
          {certificados.pesospatron && (
            <>
              <h5 className={styles.tipoEquipo}>Pesos patrón</h5>
              <div className={`${styles.rowHorizontal} ${styles.equipos}`}>
                <p>{certificados.pesospatron.numcertificado}</p>
                <p>{certificados.pesospatron.fechaemision}</p>
                <Button
                  icono="ico-descargar"
                  onClick={() =>
                    certificados.pesospatron
                      ? descargar(certificados.pesospatron.idcertdata, 2)
                      : () => {}
                  }
                  nivel={3}
                  disabled={!certificados.pesospatron}
                />
              </div>
            </>
          )}
          {certificados.balanzas.length <= 0 && !certificados.pesospatron && (
            <div className={styles.mensajeCentro}>
              <MensajeCentro mensaje="No hay certificados" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Certificados;
