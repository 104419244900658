let MensajesUI = {
  exito: {
    form: {
      guardado: "",
    },
  },
  advertencia: {
    form: {
      noValidado: "Revisa que todos los campos se hayan llenado correctamente",
      Incompleto: "Llena todos los campos",
    },
  },
  error: {
    servidor: {
      sinConexion: "No hay conexión con el servidor",
    },
  },
};
export default MensajesUI;
