import ItemHistorial from "./historial/ItemHistorial";
import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";
import { useState, useContext, useEffect } from "react";
import PopupContext from "../../../../../context/PopupContext";
import Loader from "../../../../generic/Loader";
import MensajeCentro from "../../../../generic/MensajeCentro";

interface Props {
  idordentrabajo: number;
  idcliente: number;
}

function VersionesCotizacion({ idordentrabajo, idcliente }: Props) {
  const [cargando, setCargando] = useState(false);
  const [versiones, setVersiones] = useState<number[]>([]);
  const { mostrarPopup } = useContext(PopupContext);

  useEffect(() => {
    getHistorial();
  }, []);

  const getHistorial = () => {
    $.ajax({
      url: `${host}:${port}/api/calculos/versionescotizaciones`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ idordentrabajo }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data: { version: number }[]) {
        setCargando(false);
        setVersiones(data.map((el) => el.version));
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  return (
    <div className={`animar-entrada cont-card`}>
      <h3 className="titulo-seccion">Versiones cotización</h3>
      {cargando ? (
        <Loader />
      ) : (
        <>
          {versiones.length > 0 ? (
            versiones.map((el, i) => (
              <ItemHistorial
                key={i}
                version={el}
                idordentrabajo={idordentrabajo}
                idcliente={idcliente}
              />
            ))
          ) : (
            <MensajeCentro mensaje="No hay versiones anteriores" />
          )}
        </>
      )}
    </div>
  );
}

export default VersionesCotizacion;
