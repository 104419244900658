import { Tooltip } from "react-tooltip";
import Button from "../generic/Button";
import { useContext, useEffect, useState } from "react";
import { host, port } from "../../helpers/DbData";
import PopupContext from "../../context/PopupContext";
import $ from "jquery";

export interface IOrdenAfectada {
  idordentrabajo: number;
  version: number;
  year: number;
  num: number;
  estado: string;
}

interface Props {
  ordenesAfectadas: IOrdenAfectada[];
  idcliente?: number;
  cerrar: () => void;
}

const FormOrdenesAfectadas = ({
  ordenesAfectadas,
  idcliente,
  cerrar,
}: Props) => {
  const [cargando, setCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);

  const [checkBoxes, setCheckBoxes] = useState<
    { id: number; label: string; estado: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    setCheckBoxes(
      ordenesAfectadas.map((el) => ({
        id: el.idordentrabajo,
        label: `${el.year}-${el.num}${el.version > 0 ? "." + el.version : ""}`,
        estado: `${el.estado}`,
        checked: false,
      }))
    );
  }, [ordenesAfectadas]);

  const handleChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxes((cB) =>
      cB.map((el) => ({ ...el, checked: e.target.checked }))
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckBoxes((cB) =>
      cB.map((el) => (el.id.toString() === name ? { ...el, checked } : el))
    );
  };

  const actualizar = () => {
    const idordenes = checkBoxes.filter((el) => el.checked).map((el) => el.id);
    // if idcliente doesnt exist, then it coming from "devices"
    $.ajax({
      url: idcliente
        ? `${host}:${port}/api/ordenTrabajo/clientData`
        : `${host}:${port}/api/ordenTrabajo/regenerateOrders`,
      type: idcliente ? "put" : "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ idordenes, idcliente }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        mostrarPopup(1, data.message);
        cerrar();
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  return (
    <div className="w-[30rem] flex flex-col gap-3">
      <h3>Órdenes afectadas</h3>
      <div className="text-gray-600">
        <p>
          Se han guardado los cambios del {idcliente ? "cliente" : "equipo"}.
        </p>
        <p className="mt-1">
          Selecciona las órdenes de trabajo que quieres actualizar.
        </p>
      </div>
      <div className="cont-card">
        <div className="flex flex-row items-center gap-2 py-2 px-2 border-b">
          <input
            id={"checkbox-all"}
            data-tooltip-id={"checkbox-all"}
            type="checkbox"
            className="w-5 h-5 cursor-pointer"
            onChange={handleChangeAll}
          />
          <Tooltip id={"checkbox-all"} content="Marcar todos" />
          <p className="font-bold">Orden de trabajo</p>
        </div>
        <div className="max-h-80 overflow-y-auto overflow-x-hidden">
          {checkBoxes.map((el, i) => (
            <div key={i}>
              <label
                htmlFor={"checkbox-" + el.id}
                className="flex flex-row items-center gap-2 h-10 cursor-pointer hover:bg-gray-200 px-2"
              >
                <input
                  id={"checkbox-" + el.id}
                  type="checkbox"
                  className="w-5 h-5 cursor-pointer"
                  onChange={handleChange}
                  checked={el.checked}
                  name={el.id.toString()}
                />
                <span>{el.label}</span>
                <span className="text-sm text-gray-600">{el.estado}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-28">
          <Button
            onClick={actualizar}
            icono="ico-check"
            label="Actualizar"
            nivel={2}
            cargando={cargando}
          />
        </div>
        <Button
          onClick={cerrar}
          icono="ico-cerrar"
          label="Cancelar"
          nivel={2}
          color="ROJO"
        />
      </div>
    </div>
  );
};

export default FormOrdenesAfectadas;
