import { useContext, useEffect, useState } from "react";
import Button from "../../../../generic/Button";
import ContInput from "../../../../generic/ContInput";
import PopupContext from "../../../../../context/PopupContext";
import { Validar } from "../../../../../helpers/Validar";
import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";

let initialDatosForm = {
  idmarca: 0,
  modelo: "",
  serie: "",
  codigo: "",
  liminferior: "",
  limsuperior: "",
  idunidadmedida: 0,
  resolucion: "",
  puntoscalibracion: "",
  idtipoequipo: 0,
  ubicacion: "",
  responsable: "",
};

let initialFormValidado = {
  idmarca: [false, ""],
  modelo: [false, ""],
  serie: [false, ""],
  codigo: [false, ""],
  liminferior: [false, ""],
  limsuperior: [false, ""],
  idunidadmedida: [false, ""],
  resolucion: [false, ""],
  puntoscalibracion: [false, ""],
  idtipoequipo: [false, ""],
  ubicacion: [false, ""],
  responsable: [false, ""],
};

let initialCombos = {
  marca: [],
  tipoEquipo: [],
  unidadMedida: [],
};

// función desactualizada. ACTUALIZAR!!!
function FormTermometro({ idcliente, idequipo, cerrar, equipos }) {
  const [mounted, setMounted] = useState(true);
  const { mostrarPopup } = useContext(PopupContext);

  const [datosForm, setDatosForm] = useState(initialDatosForm);
  const [formValidado, setFormValidado] = useState(initialFormValidado);
  const [combos, setCombos] = useState(initialCombos);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [equipoYaExiste, setEquipoYaExiste] = useState(false);
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    obtenerCombos();
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (idequipo !== 0) {
      obtenerEquipo();
      setModoEdicion(true);
    }
    if (idequipo === 0) inicializarForm();
    return () => {
      setDatosForm(initialDatosForm);
    };
  }, [idequipo]);

  useEffect(() => {
    if (
      equipos.filter((el) => {
        return (
          el.serie.toLowerCase() === datosForm.serie.toLowerCase() &&
          el.modelo.toLowerCase() === datosForm.modelo.toLowerCase()
        );
      }).length > 0 &&
      !modoEdicion
    ) {
      setEquipoYaExiste(true);
    } else {
      setEquipoYaExiste(false);
    }
  }, [datosForm]);

  const inicializarForm = () => {
    setDatosForm(initialDatosForm);
    setFormValidado(initialFormValidado);
    setEquipoYaExiste(false);
    setModoEdicion(false);
  };

  const obtenerCombos = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/comboId`,
      type: "put",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ id: 3 }),
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        if (mounted) setCombos(data);
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const obtenerEquipo = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/equiposId/${idequipo}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        setDatosForm(data[0]);
        validarTodo(data[0]);
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const validarTodo = (data) => {
    let tempFormValidado = formValidado;
    for (const key in data) {
      if (Object.hasOwnProperty.call(initialDatosForm, key)) {
        const el = data[key];
        tempFormValidado = { ...tempFormValidado, [key]: Validar.general(el) };
      }
    }
    setFormValidado(tempFormValidado);
  };

  const guardarEquipo = () => {
    if (!cargando)
      $.ajax({
        // url: `${host}:${port}/api/nuevaCotizacion/edit`,
        url: `${host}:${port}/api/ordenTrabajo/compararEquipo`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          id: idequipo,
          idtablaequipo: 3,
          idcliente,
          ...datosForm,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          // mostrarPopup(1, data);
          setDatosForm(initialDatosForm);
          cerrar();
          // setClientes(data);
          // if (data.length === 1) {
          //   if ("cedulausuario" in data[0]) {
          //     sessionStorage.setItem("datosUsuario", JSON.stringify(data[0]));
          //     setDatosUsuario(data[0]);
          //   } else {
          //     mostrarPopup(2, "No se ha encontrado el usuario");
          //   }
          // } else {
          //   mostrarPopup(2, "No se ha encontrado el usuario");
          // }
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const handleGuardarEquipo = () => {
    if (validarForm()) {
      guardarEquipo();
    } else {
      mostrarPopup(
        2,
        "Revisa que todos los campos se hayan llenado correctamente"
      );
    }
  };

  const handleChange = (e) => {
    setDatosForm({
      ...datosForm,
      [e.target.name]: e.target.value,
    });
    actualizarValidacion(e);
  };

  const actualizarValidacion = (e) => {
    let tempCampo = {};
    switch (e.target.name) {
      case "idmarca":
        tempCampo = {
          [e.target.name]: Validar.noCero(e.target.value),
        };
        break;
      case "modelo":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "serie":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "codigo":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "liminferior":
        tempCampo = {
          [e.target.name]: Validar.numeros(e.target.value),
        };
        break;
      case "limsuperior":
        tempCampo = {
          [e.target.name]: Validar.numeros(e.target.value),
        };
        break;
      case "idunidadmedida":
        tempCampo = {
          [e.target.name]: Validar.noCero(e.target.value),
        };
        break;
      case "resolucion":
        tempCampo = {
          [e.target.name]: Validar.numerosPositivos(e.target.value),
        };
        break;
      case "puntoscalibracion":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "idtipoequipo":
        tempCampo = {
          [e.target.name]: Validar.noCero(e.target.value),
        };
        break;
      case "ubicacion":
        tempCampo = {
          [e.target.name]: Validar.general(e.target.value),
        };
        break;
      case "responsable":
        tempCampo = {
          [e.target.name]: Validar.texto(e.target.value),
        };
        break;
      default:
        break;
    }
    setFormValidado({
      ...formValidado,
      ...tempCampo,
    });
  };

  const validarForm = () => {
    for (const key in formValidado) {
      if (Object.hasOwnProperty.call(formValidado, key)) {
        const el = formValidado[key];
        if (!el[0]) return false;
      }
    }
    return true;
  };

  const handleBlur = (e) => {
    if (formValidado[e.target.name][0])
      setDatosForm({
        ...datosForm,
        [e.target.name]: e.target.value.trim(),
      });
  };

  return (
    <form
      className="cont-2-columnas grid-gap-form animar-entrada"
      autoComplete={"off"}
    >
      <div>
        <ContInput label={"Marca"}>
          <select
            name="idmarca"
            value={datosForm.idmarca}
            onChange={handleChange}
          >
            {datosForm.idmarca === 0 && <option disabled value={0}></option>}
            {combos.marca
              .sort((a, b) =>
                a.marca > b.marca ? 1 : a.marca === b.marca ? 0 : -1
              )
              .map((el, i) => {
                return (
                  <option key={i} value={el.idmarca}>
                    {el.marca}
                  </option>
                );
              })}
          </select>
          {!formValidado.idmarca[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.idmarca[0] && (
          <p className="texto-validacion">{formValidado.idmarca[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Modelo"}>
          <input
            type="text"
            name="modelo"
            placeholder="M001"
            value={datosForm.modelo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.modelo[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.modelo[0] && (
          <p className="texto-validacion">{formValidado.modelo[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Serie"}>
          <input
            type="text"
            name="serie"
            placeholder="S001"
            value={datosForm.serie}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.serie[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.serie[0] && (
          <p className="texto-validacion">{formValidado.serie[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Código"}>
          <input
            type="text"
            name="codigo"
            placeholder="PCT-001"
            value={datosForm.codigo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.codigo[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.codigo[0] && (
          <p className="texto-validacion">{formValidado.codigo[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Límite inferior (rango)"}>
          <input
            type="number"
            name="liminferior"
            placeholder="-10"
            value={datosForm.liminferior}
            onChange={handleChange}
          />
          {!formValidado.liminferior[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.liminferior[0] && (
          <p className="texto-validacion">{formValidado.liminferior[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Límite superior (rango)"}>
          <input
            type="number"
            name="limsuperior"
            placeholder="150"
            value={datosForm.limsuperior}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.limsuperior[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.limsuperior[0] && (
          <p className="texto-validacion">{formValidado.limsuperior[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Unidad de medida"}>
          <select
            name="idunidadmedida"
            value={datosForm.idunidadmedida}
            onChange={handleChange}
          >
            {datosForm.idunidadmedida === 0 && (
              <option disabled value={0}></option>
            )}
            {combos.unidadMedida.map((el, i) => {
              return (
                <option key={i} value={el.idunidadmedida}>
                  {el.unidadmedida}
                </option>
              );
            })}
          </select>
          {!formValidado.idunidadmedida[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.idunidadmedida[0] && (
          <p className="texto-validacion">{formValidado.idunidadmedida[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Resolución"}>
          <input
            type="number"
            name="resolucion"
            placeholder="0.1"
            value={datosForm.resolucion}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.resolucion[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.resolucion[0] && (
          <p className="texto-validacion">{formValidado.resolucion[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Puntos de calibración"}>
          <input
            type="text"
            name="puntoscalibracion"
            placeholder="-10 80 150"
            value={datosForm.puntoscalibracion}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.puntoscalibracion[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.puntoscalibracion[0] && (
          <p className="texto-validacion">
            {formValidado.puntoscalibracion[1]}
          </p>
        )}
      </div>
      <div>
        <ContInput label={"Tipo de termómetro"}>
          <select
            name="idtipoequipo"
            value={datosForm.idtipoequipo}
            onChange={handleChange}
          >
            {datosForm.idtipoequipo === 0 && (
              <option disabled value={0}></option>
            )}
            {combos.tipoEquipo.map((el, i) => {
              return (
                <option key={i} value={el.idtipoequipo}>
                  {el.tipoequipo}
                </option>
              );
            })}
          </select>
          {!formValidado.idtipoequipo[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.idtipoequipo[0] && (
          <p className="texto-validacion">{formValidado.idtipoequipo[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Ubicación"}>
          <input
            type="text"
            name="ubicacion"
            placeholder="Planta"
            value={datosForm.ubicacion}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.ubicacion[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.ubicacion[0] && (
          <p className="texto-validacion">{formValidado.ubicacion[1]}</p>
        )}
      </div>
      <div>
        <ContInput label={"Responsable"}>
          <input
            type="text"
            name="responsable"
            placeholder="Juan Pérez"
            value={datosForm.responsable}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {!formValidado.responsable[0] && (
            <div className="ico-advertencia  format-ico-form-validacion"></div>
          )}
        </ContInput>
        {!formValidado.responsable[0] && (
          <p className="texto-validacion">{formValidado.responsable[1]}</p>
        )}
      </div>
      <div className="cont-btns grid-full-width">
        {!modoEdicion && equipoYaExiste && (
          <p className="texto-validacion">
            El equipo ya existe, verifica que quieres guardarlo
          </p>
        )}
        <Button
          label={"Guardar"}
          icono={"ico-guardar"}
          onClick={() => {
            handleGuardarEquipo();
          }}
        />
        <Button
          label={"Eliminar"}
          icono={"ico-eliminar"}
          onClick={() => {
            // guardarEquipo('balanza')
          }}
          color="ROJO"
        />
      </div>
    </form>
  );
}

export default FormTermometro;
