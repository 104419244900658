export interface IIdentificacionPesasPP {
  marca: string;
  forma: string;
  serie: string;
  codigo: string;
  identificacionlab: string;
  valornominal: string;
  unidadmedida: string;
  material: string;
  clase: string;
}

interface Props {
  data: IIdentificacionPesasPP[];
}

function IdentificacionPesasPP({ data }: Props) {
  return (
    <div className="separador-inferior">
      <h3>Información proporcionada por el cliente</h3>
      <div>
        <div className="table-row table-row-title">
          <span>N°</span>
          <span>Marca</span>
          <span>Forma</span>
          <span>Serie</span>
          <span>Código</span>
          <span>ID interna*</span>
          <span>Valor nominal</span>
          <span>Material</span>
          <span>Clase</span>
        </div>
        {data.map((el, i) => (
          <div key={i} className="table-row row-equipo animar-entrada">
            <span>{i + 1}</span>
            <span>{el.marca}</span>
            <span>{el.forma}</span>
            <span>{el.serie}</span>
            <span>{el.codigo}</span>
            <span>{el.identificacionlab}</span>
            <span>
              {el.valornominal} {el.unidadmedida}
            </span>
            <span>{el.material}</span>
            <span>{el.clase}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default IdentificacionPesasPP;

const data = {
  identificacioncliente: {
    direccion: "",
    empresa: "",
    telefono: "",
    fechacalibracion: "",
    fechaemision: "",
    fecharecepcion: "",
    lugarcalibracion: "",
  },
  pesas: [
    {
      marca: "",
      forma: "",
      serie: "",
      codigo: "",
      identificacionlab: "",
      valornominal: "",
      unidadmedida: "",
      material: "",
      clase: "",
    },
  ],
  resultados: [
    {
      identificacionlab: "",
      valornominal: 0,
      densidad: 0,
      incertidumbredensidad: 0,
      masareal: 0,
      masaconvencional: 0,
      incertidumbremasa: 0,
    },
  ],
  condicionesambientales: {
    temperatura: "",
    presion: "",
    humedad: "",
    equipo: "Codigo marca modelo serie certificado",
  },
  balanzas: [
    "balanza marca serie codigo certificado...",
    "balanza2 marca serie...",
  ],
};
