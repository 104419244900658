import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useCallback } from "react";
import styles from "./GraficaTHP.module.css";
import MensajeCentro from "../generic/MensajeCentro";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface ICondicionesAmbientales {
  idcondicionambiental: number;
  temperatura: number;
  humedad: number;
  presion: number;
  iddispositivo: number;
  fechacreacion: string;
  fechamodificacion: string;
}

const colores = {
  temperatura: "63, 209, 136",
  humedad: "48, 125, 227",
  presion: "245, 122, 51",
};

interface Props {
  data: ICondicionesAmbientales[];
}

function GraficaTHP({ data }: Props) {
  const getOptions = useCallback((titulo: string) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: titulo,
        },
      },
    };
  }, []);

  // comparar fechas inicial final
  const compararFechasIF = useCallback(
    (segundosDiferencia: number) => {
      return (
        new Date(data[data.length - 1].fechacreacion).getTime() / 1000 -
          new Date(data[0].fechacreacion).getTime() / 1000 >=
        segundosDiferencia
      );
    },
    [data]
  );

  const renderLabels = useCallback(
    () =>
      data.map((el) =>
        compararFechasIF(3600 * 24 * 3)
          ? new Date(el.fechacreacion).toLocaleString("sv").substring(0, 10)
          : new Date(el.fechacreacion).toLocaleString("sv").substring(11, 16)
      ),
    [compararFechasIF, data]
  );

  return (
    <div
      className={`cont-card ${styles.contPrincipal} ${
        data.length <= 0 && styles.vacio
      }`}
    >
      {data.length <= 0 ? (
        <MensajeCentro mensaje="No hay datos en ese rango de fechas" />
      ) : (
        <>
          <div>
            <Line
              options={getOptions("Temperatura")}
              data={{
                labels: renderLabels(),
                datasets: [
                  {
                    label: "Dataset 1",
                    data: data.map((el) => el.temperatura.toFixed(1)),
                    borderColor: "rgb(" + colores.temperatura + ")",
                    backgroundColor: "rgba(" + colores.temperatura + ", 0.5)",
                  },
                ],
              }}
            />
          </div>
          <div>
            <Line
              options={getOptions("Humedad")}
              data={{
                labels: renderLabels(),
                datasets: [
                  {
                    label: "Dataset 1",
                    data: data.map((el) => el.humedad.toFixed(1)),
                    borderColor: "rgb(" + colores.humedad + ")",
                    backgroundColor: "rgba(" + colores.humedad + ", 0.5)",
                  },
                ],
              }}
            />
          </div>
          <div>
            <Line
              options={getOptions("Presión")}
              data={{
                labels: renderLabels(),
                datasets: [
                  {
                    label: "Dataset 1",
                    data: data.map((el) => el.presion.toFixed(1)),
                    borderColor: "rgb(" + colores.presion + ")",
                    backgroundColor: "rgba(" + colores.presion + ", 0.5)",
                  },
                ],
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default GraficaTHP;
