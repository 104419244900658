import { INumDecimales } from "./IdentificacionEquipo";
import DataHandler from "../../../../../helpers/DataHandler";
export interface IRowLinealidad {
  efinaldecremento: null | number;
  efinalincremento: null | number;
  einicialdecremento: null | number;
  einicialincremento: number;
  emp: number;
  lfinaldecremento: null | number;
  lfinalincremento: null | number;
  linicialdecremento: null | number;
  linicialincremento: number;
  patron: number;
  punto: number;
  u: number;
  solocarga: number;
  idnumdecimales: number;
}

interface Props {
  mantenimiento?: boolean;
  data: IRowLinealidad[];
  numdecimales: INumDecimales;
  unidadmedida: string;
}

function Linealidad({
  mantenimiento = false,
  data,
  numdecimales,
  unidadmedida,
}: Props) {
  const renderND = DataHandler.renderND(numdecimales);

  return (
    <div className="cont-card">
      <h3>Prueba de linealidad e histéresis</h3>
      <div>
        <div className="titulo-lectura-excentricidad">
          <h4>Inicial</h4>
        </div>
        <div>
          <div className="table-row table-row-title center-hd">
            <span>Punto</span>
            <span>Patrón ({unidadmedida})</span>
            <span>Incremento ({unidadmedida})</span>
            <span>Decremento ({unidadmedida})</span>
            <span>Error Inc. ({unidadmedida})</span>
            <span>Error Dec. ({unidadmedida})</span>
            <span>e.m.p. ({unidadmedida})</span>
            {!mantenimiento && <span>u (k=2) ({unidadmedida})</span>}
          </div>
          {data.map((el, i) => {
            return (
              <div key={i} className="table-row center-hd">
                <span>{el.punto}</span>
                <span>{renderND(el.patron, el.idnumdecimales)}</span>
                <span>
                  {renderND(el.linicialincremento, el.idnumdecimales)}
                </span>
                <span>
                  {el.linicialdecremento
                    ? renderND(el.linicialdecremento, el.idnumdecimales)
                    : "-"}
                </span>
                <span>
                  {renderND(el.einicialincremento, el.idnumdecimales)}
                </span>
                <span>
                  {el.einicialdecremento
                    ? renderND(el.einicialdecremento, el.idnumdecimales)
                    : "-"}
                </span>
                <span>{renderND(el.emp, el.idnumdecimales)}</span>
                {!mantenimiento && <span>{el.u.toPrecision(2)}</span>}
              </div>
            );
          })}
        </div>
        {data[0] && data[0].efinalincremento && (
          <>
            <div className="titulo-lectura-excentricidad">
              <h4>Final</h4>
            </div>
            <div>
              <div className="table-row table-row-title">
                <span>Punto</span>
                <span>Patrón ({unidadmedida})</span>
                <span>Incremento ({unidadmedida})</span>
                <span>Decremento ({unidadmedida})</span>
                <span>Error Inc. ({unidadmedida})</span>
                <span>Error Dec. ({unidadmedida})</span>
                <span>e.m.p. ({unidadmedida})</span>
                {!mantenimiento && <span>u (k=2) ({unidadmedida})</span>}
              </div>
              {data.map((el, i) => {
                return (
                  <div key={"final" + i} className="table-row">
                    <span>{el.punto}</span>
                    <span>{renderND(el.patron, el.idnumdecimales)}</span>
                    <span>
                      {el.lfinalincremento
                        ? renderND(el.lfinalincremento, el.idnumdecimales)
                        : "-"}
                    </span>
                    <span>
                      {el.lfinaldecremento
                        ? renderND(el.lfinaldecremento, el.idnumdecimales)
                        : "-"}
                    </span>
                    <span>
                      {el.efinalincremento
                        ? renderND(el.efinalincremento, el.idnumdecimales)
                        : "-"}
                    </span>
                    <span>
                      {el.efinaldecremento
                        ? renderND(el.efinaldecremento, el.idnumdecimales)
                        : "-"}
                    </span>
                    <span>{renderND(el.emp, el.idnumdecimales)}</span>
                    {!mantenimiento && <span>{el.u.toPrecision(2)}</span>}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Linealidad;
