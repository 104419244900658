import { useContext, useEffect, useRef, useState } from "react";
import PopupContext from "../../../../../context/PopupContext";
import Button from "../../../../generic/Button";
import ContInput from "../../../../generic/ContInput";
import { Validar } from "../../../../../helpers/Validar";
import $ from "jquery";
import { host, port } from "../../../../../helpers/DbData";
import MensajesUI from "../../../../../helpers/MensajesUI";
import ConfirmContext from "../../../../../context/ConfirmContext";
import useForm, { IConfigValidacion } from "../../../../../hooks/useForm";
import Select from "../../../../generic/Select";
import Input from "../../../../generic/Input";
import LabelText from "../../../../generic/LabelText";
import _ from "lodash";
import { IOrdenAfectada } from "../../../FormOrdenesAfectadas";

interface IBalanza {
  idmarca: string;
  modelo: string;
  serie: string;
  codigo: string;
  capacidad: string;
  capacidad1: string;
  idunidadmedida: string;
  divescalareal: string;
  divescalareal1: string;
  divescalaverif: string;
  divescalaverif1: string;
  idtipoequipo: string;
  ubicacion: string;
  idclase: string;
}

let initialDatosForm: IBalanza = {
  idmarca: "0",
  modelo: "",
  serie: "",
  codigo: "",
  capacidad: "",
  capacidad1: "",
  idunidadmedida: "0",
  divescalareal: "",
  divescalareal1: "",
  divescalaverif: "",
  divescalaverif1: "",
  idtipoequipo: "0",
  ubicacion: "",
  idclase: "0",
};

let initialFormValidado: IConfigValidacion = {
  idmarca: { validar: "noCero", opcional: false },
  modelo: { validar: "general", opcional: false },
  serie: { validar: "general", opcional: true },
  codigo: { validar: "general", opcional: true },
  capacidad: { validar: "numerosPositivos", opcional: false },
  capacidad1: { validar: "numerosPositivos", opcional: true },
  idunidadmedida: { validar: "noCero", opcional: false },
  divescalareal: { validar: "numerosPositivos", opcional: false },
  divescalareal1: { validar: "numerosPositivos", opcional: true },
  divescalaverif: { validar: "numerosPositivos", opcional: true },
  divescalaverif1: { validar: "numerosPositivos", opcional: true },
  idtipoequipo: { validar: "noCero", opcional: false },
  ubicacion: { validar: "general", opcional: true },
  idclase: { validar: "noCero", opcional: true },
};

interface ICombos {
  clase: { idclase: number; clase: string }[];
  marca: { idmarca: number; marca: string }[];
  plataforma: { idtipoplataforma: number; tipoplataforma: string }[];
  tipo: { idtipo: number; tipo: string }[];
  tipoEquipo: { idtipoequipo: number; tipoequipo: string }[];
  unidadMedida: { idunidadmedida: number; unidadmedida: string }[];
}

let initialCombos: ICombos = {
  clase: [],
  marca: [],
  plataforma: [],
  tipo: [],
  tipoEquipo: [],
  unidadMedida: [],
};

const initialAcreditado = {
  cumplecapacidad: false,
  cumpledivescalareal: false,
  rango: 0,
  divescalareal: 0,
};

interface Props {
  activo: boolean;
  idcliente: number;
  idequipo: number;
  cerrar: () => void;
  equipos: IBalanza[];
  seleccionarOrdenesAfectadas: (ordenesAfectadas: IOrdenAfectada[]) => void;
  duplicar: boolean;
}

function FormBalanza({
  activo,
  idcliente,
  idequipo,
  cerrar,
  equipos,
  seleccionarOrdenesAfectadas,
  duplicar,
}: Props) {
  const [mounted, setMounted] = useState(true);
  const { mostrarPopup } = useContext(PopupContext);

  const {
    formData,
    formValidacion,
    formValidado,
    handleChange,
    resetForm,
    setFormData,
  } = useForm(initialDatosForm, initialFormValidado);

  const [combos, setCombos] = useState(initialCombos);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [equipoYaExiste, setEquipoYaExiste] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [cargando2, setCargando2] = useState(false);
  const [cargando3, setCargando3] = useState(false);
  const [acreditado, setAcreditado] = useState(initialAcreditado);

  const originalFormData = useRef(initialDatosForm);

  useEffect(() => {
    obtenerCombos();
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    // si el idequipo es diferente de 0, es porque se esta editando un equipo
    if (idequipo !== 0) {
      obtenerEquipo();
      if (!duplicar) setModoEdicion(true);
    }
  }, [idequipo]);

  useEffect(() => {
    if (!activo) {
      inicializarForm();
    }
  }, [activo]);

  useEffect(() => {
    // si el equipo ya existe, se muestra un mensaje de error
    if (
      (equipos.filter((el) => {
        if (el.serie && el.modelo)
          return (
            el.serie.toLowerCase() === formData.serie.toLowerCase() &&
            el.modelo.toLowerCase() === formData.modelo.toLowerCase()
          );
        else return false;
      }).length > 0 ||
        equipos.find((el) => el.codigo && el.codigo === formData.codigo)) &&
      !modoEdicion
    ) {
      setEquipoYaExiste(true);
    } else {
      setEquipoYaExiste(false);
    }
  }, [formData]);

  const timeoutClase = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    // si todos los campos estan validados, se calcula la clase de la balanza
    if (
      formValidacion.capacidad.validado &&
      formValidacion.divescalareal.validado &&
      formValidacion.divescalaverif.validado &&
      parseFloat(formData.divescalareal) !== 0 &&
      !isNaN(parseFloat(formData.divescalareal)) &&
      parseFloat(formData.capacidad) !== 0 &&
      !isNaN(parseFloat(formData.capacidad))
    ) {
      setCargando(true);
      if (timeoutClase.current) clearTimeout(timeoutClase.current);
      // se espera medio segundo para que el usuario termine de escribir
      timeoutClase.current = setTimeout(() => {
        calcularClaseBalanza();
      }, 500);
    } else {
      if (timeoutClase.current) clearTimeout(timeoutClase.current);
      setCargando(false);
    }
    return () => {
      if (timeoutClase.current) clearTimeout(timeoutClase.current);
    };
  }, [formData.capacidad, formData.divescalareal, formData.divescalaverif]);

  const calcularClaseBalanza = async () => {
    if (
      formValidacion.capacidad.validado &&
      formValidacion.divescalareal.validado &&
      formValidacion.divescalaverif.validado &&
      parseFloat(formData.divescalareal) !== 0 &&
      !isNaN(parseFloat(formData.divescalareal)) &&
      parseFloat(formData.capacidad) !== 0 &&
      !isNaN(parseFloat(formData.capacidad))
    ) {
      await $.ajax({
        url: `${host}:${port}/api/pesas/calcularClaseBalanza`,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          capacidad: formData.capacidad,
          divescalaverif:
            formData.divescalaverif.toString().length > 0
              ? formData.divescalaverif
              : formData.divescalareal, // en realidad todo debería ser divescalaverif
        }),
        beforeSend: function () {
          // setMostrarCargando(true);
        },
        success: function (data) {
          setCargando(false);
          // console.log(data);
          if (data.idclase) handleChange("idclase", data.idclase);
          else handleChange("idclase", "1");
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  const timeoutAcreditacion = useRef<ReturnType<typeof setTimeout> | null>();
  useEffect(() => {
    // si todos los campos estan validados, se calcula la clase de la balanza
    if (
      Number(formData.divescalareal) > 0 &&
      (Number(formData.capacidad) > 0 || Number(formData.capacidad1) > 0) &&
      Number(formData.idtipoequipo) > 0 &&
      Number(formData.idunidadmedida) > 0
    ) {
      setCargando2(true);
      if (timeoutAcreditacion.current)
        clearTimeout(timeoutAcreditacion.current);
      // se espera medio segundo para que el usuario termine de escribir
      timeoutAcreditacion.current = setTimeout(() => {
        cumpleAcreditacion();
      }, 500);
    } else {
      if (timeoutAcreditacion.current)
        clearTimeout(timeoutAcreditacion.current);
      setCargando2(false);
    }
    return () => {
      if (timeoutAcreditacion.current)
        clearTimeout(timeoutAcreditacion.current);
    };
  }, [
    formData.capacidad,
    formData.divescalareal,
    formData.idtipoequipo,
    formData.capacidad1,
    formData.idunidadmedida,
  ]);

  const cumpleAcreditacion = async () => {
    if (
      Number(formData.divescalareal) > 0 &&
      (Number(formData.capacidad) > 0 || Number(formData.capacidad1) > 0) &&
      Number(formData.idtipoequipo) > 0 &&
      Number(formData.idunidadmedida) > 0
    ) {
      await $.ajax({
        url: `${host}:${port}/api/acreditacion/cumpleacreditacion`,
        type: "post",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          capacidad:
            Number(formData.capacidad1) > 0
              ? formData.capacidad1
              : formData.capacidad,
          divescalareal: formData.divescalareal, // en realidad todo debería ser divescalaverif
          idtipoequipo: formData.idtipoequipo,
          idunidadmedida: formData.idunidadmedida,
        }),
        beforeSend: function () {
          // setMostrarCargando(true);
        },
        success: function (data) {
          setAcreditado(data);
          setCargando2(false);
          // console.log(data);
          // if (data.idclase)
          //   setDatosForm({ ...datosForm, idclase: data.idclase });
          // else setDatosForm({ ...datosForm, idclase: 1 });
        },
        error: function (data) {
          setCargando2(false);
          setAcreditado(initialAcreditado);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  };

  const inicializarForm = () => {
    resetForm();
    setEquipoYaExiste(false);
    setModoEdicion(false);
    setAcreditado(initialAcreditado);
    originalFormData.current = initialDatosForm;
  };

  const obtenerCombos = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/comboId`,
      type: "put",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({ id: 1 }),
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        // console.log(data);
        if (mounted) setCombos(data);
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const obtenerEquipo = () => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/equiposId/${idequipo}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        const formattedData = formatearDatos(data[0]);
        console.log(formattedData);
        originalFormData.current = formattedData;
        if (mounted) setFormData(formattedData);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        // if (data.status === 0)
        //   mostrarPopup(0, "No es posible conectarse al servidor");
        // else mostrarPopup(0, data.responseText);
      },
    });
  };

  const formatearDatos = (data: any) => {
    let data0 = { ...data };
    data0.serie = data0.serie ?? "";
    data0.codigo = data0.codigo ?? "";
    data0.ubicacion = data0.ubicacion ?? "";
    data0.capacidad1 = data0.capacidad1 ?? "";
    data0.divescalareal1 = data0.divescalareal1 ?? "";
    data0.divescalaverif = data0.divescalaverif ?? "";
    data0.divescalaverif1 = data0.divescalaverif1 ?? "";
    data0.idtipo = data0.idtipo ?? "";
    data0.responsable = data0.responsable ?? "";
    delete data0.idtipoplataforma;
    delete data0.capacidadacreditacion;
    return data0;
  };

  const guardarEquipo = () => {
    const editado = !_.isEqual(originalFormData.current, formData);
    console.log(originalFormData.current, formData, editado, modoEdicion);
    if (!editado && modoEdicion) {
      mostrarPopup(2, "No se ha realizado ningún cambio");
      return;
    }
    if (!cargando)
      $.ajax({
        // url: `${host}:${port}/api/ordenTrabajo/compararEquipo`,
        url: `${host}:${port}/api/nuevaCotizacion/edit`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          editado,
          id: duplicar ? 0 : idequipo,
          idtablaequipo: 1,
          idcliente,
          capacidadacreditacion:
            acreditado.cumplecapacidad === false ? acreditado.rango : null,
          ...formData,
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          // console.log(data);
          mostrarPopup(1, data.data);
          inicializarForm();
          cerrar();
          if (data.affectedOrders && data.affectedOrders.length > 0)
            seleccionarOrdenesAfectadas(data.affectedOrders);
          // setClientes(data);
          // if (data.length === 1) {
          //   if ("cedulausuario" in data[0]) {
          //     sessionStorage.setItem("datosUsuario", JSON.stringify(data[0]));
          //     setDatosUsuario(data[0]);
          //   } else {
          //     mostrarPopup(2, "No se ha encontrado el usuario");
          //   }
          // } else {
          //   mostrarPopup(2, "No se ha encontrado el usuario");
          // }
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const handleGuardarEquipo = () => {
    if (formValidado) {
      guardarEquipo();
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  return (
    <form
      className="cont-2-columnas grid-gap-form animar-entrada"
      autoComplete={"off"}
    >
      <Select
        label={"Marca"}
        name="idmarca"
        value={formData.idmarca}
        onChange={handleChange}
        options={combos.marca.map((el) => ({
          label: el.marca,
          value: el.idmarca,
        }))}
        validacion={formValidacion.idmarca}
      />
      <Input
        label={"Modelo"}
        name="modelo"
        placeholder="M001"
        value={formData.modelo}
        onChange={handleChange}
        validacion={formValidacion.modelo}
      />
      <Input
        label={"Capacidad"}
        name="capacidad"
        placeholder="1000"
        value={formData.capacidad}
        onChange={handleChange}
        validacion={formValidacion.capacidad}
      />
      <Select
        label={"Unidad de medida"}
        name="idunidadmedida"
        value={formData.idunidadmedida}
        onChange={handleChange}
        options={combos.unidadMedida.map((el) => ({
          label: el.unidadmedida,
          value: el.idunidadmedida,
        }))}
        validacion={formValidacion.idunidadmedida}
      />
      <Input
        label={"Resolución"}
        // label={"Div. esc. verif (e)"}
        name="divescalareal"
        placeholder="0.1"
        value={formData.divescalareal}
        onChange={handleChange}
        validacion={formValidacion.divescalareal}
      />
      <Select
        label={"Tipo de equipo"}
        name="idtipoequipo"
        value={formData.idtipoequipo}
        onChange={handleChange}
        options={combos.tipoEquipo.map((el) => ({
          label: el.tipoequipo,
          value: el.idtipoequipo,
        }))}
        validacion={formValidacion.idtipoequipo}
      />
      <div className="divisor" />
      <Input
        label={"Serie"}
        name="serie"
        placeholder="S001"
        value={formData.serie}
        onChange={handleChange}
        validacion={formValidacion.serie}
      />
      <Input
        label={"Código"}
        name="codigo"
        placeholder="PCT-001"
        value={formData.codigo}
        onChange={handleChange}
        validacion={formValidacion.codigo}
      />
      <Input
        label={"Ubicación"}
        name="ubicacion"
        placeholder="Sala 1"
        value={formData.ubicacion}
        onChange={handleChange}
        validacion={formValidacion.ubicacion}
      />
      <Input
        label="Div. esc. verif (e)"
        placeholder="1000"
        name={"divescalaverif"}
        value={formData.divescalaverif}
        onChange={handleChange}
        validacion={formValidacion.divescalaverif}
      />
      <Input
        label="Capacidad (Rango 2)"
        placeholder="1000"
        name={"capacidad1"}
        value={formData.capacidad1}
        onChange={handleChange}
        validacion={formValidacion.capacidad1}
      />
      <Input
        label="Resolución (Rango 2)"
        placeholder="0.1"
        name={"divescalareal1"}
        value={formData.divescalareal1}
        onChange={handleChange}
        validacion={formValidacion.divescalareal1}
      />
      <Input
        label="Div. esc. verif (e) (R. 2)"
        placeholder="1000"
        name={"divescalaverif1"}
        value={formData.divescalaverif1}
        onChange={handleChange}
        validacion={formValidacion.divescalaverif1}
      />
      <LabelText
        label={"Clase"}
        text={
          combos.clase.find(
            (el) => Number(el.idclase) === Number(formData.idclase)
          )?.clase ?? "-"
        }
      />
      <div className="cont-btns grid-full-width">
        {!modoEdicion && equipoYaExiste && (
          <p className="texto-validacion">
            El equipo ya existe, verifica que quieres guardarlo
          </p>
        )}
        {(Number(formData.capacidad) > 0 || Number(formData.capacidad1) > 0) &&
          Number(formData.divescalareal) > 0 &&
          Number(formData.idtipoequipo) > 0 &&
          !cargando2 &&
          (!acreditado.cumplecapacidad || !acreditado.cumpledivescalareal) && (
            <p className="texto-validacion">
              {!acreditado.cumplecapacidad && !acreditado.cumpledivescalareal
                ? "Capacidad y resolución"
                : ""}
              {acreditado.cumplecapacidad && !acreditado.cumpledivescalareal
                ? "Resolución"
                : ""}
              {!acreditado.cumplecapacidad && acreditado.cumpledivescalareal
                ? "Capacidad"
                : ""}{" "}
              fuera del alcance de acreditación
            </p>
          )}
        <Button
          label={"Guardar"}
          icono={"ico-guardar"}
          onClick={() => {
            handleGuardarEquipo();
          }}
          cargando={cargando}
        />
      </div>
    </form>
  );
}

export default FormBalanza;
