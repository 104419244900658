import GraficaTHP, {
  ICondicionesAmbientales,
} from "../components/monitoreo/GraficaTHP";
import { useState, useEffect, useCallback, useContext } from "react";
import styles from "./Monitoreo.module.css";
import ContInput from "../components/generic/ContInput";
import $ from "jquery";
import { host, port } from "../helpers/DbData";
import PopupContext from "../context/PopupContext";
import LabelText from "../components/generic/LabelText";
import CondicionesAmbientalesContext from "../context/CondicionesAmbientalesContext";
import Button from "../components/generic/Button";
import Loader from "../components/generic/Loader";

const initialRangoFechas = {
  fechainicial: new Date().toLocaleDateString("sv"),
  fechafinal: new Date().toLocaleDateString("sv"),
};

// condiciones ambientales del laboratorio de masa, gráficos y descarga de archivo excel
function Monitoreo() {
  const [rangoFechas, setRangoFechas] = useState(initialRangoFechas);
  const [data, setData] = useState<ICondicionesAmbientales[]>([]);
  const [cargando, setCargando] = useState(false);
  const { mostrarPopup } = useContext(PopupContext);
  const [mensaje, setMensaje] = useState("");
  const { condicionesAmbientales } = useContext(CondicionesAmbientalesContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRangoFechas({ ...rangoFechas, [e.target.name]: e.target.value });
  };

  const getData = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/condicionesambientales`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        ...rangoFechas,
        iddispositivo: 1, // es el iddispositivo que se encuentra en la tabla dispositivos.dispositivos de la BD
        numdatos: 70, // cantidad de datos que se van a retornar desde el servidor
      }),
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data) {
        setCargando(false);
        setData(data);
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, rangoFechas]);

  const descargar = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/descargar`,
      type: "get",
      contentType: "application/json",
      xhrFields: {
        responseType: "blob",
      },
      data: {
        ...rangoFechas,
        iddispositivo: 1,
      },
      beforeSend: function () {
        setCargando(true);
      },
      success: function (data, _, responseData) {
        setCargando(false);
        const indice = responseData
          .getResponseHeader("Content-Disposition")
          ?.indexOf("filename=");

        const filename =
          responseData.getResponseHeader("Content-Disposition") &&
          indice &&
          indice > -1
            ? responseData
                .getResponseHeader("Content-Disposition")!
                .substring(indice + 9)
            : "Archivo.xlsx";

        var blob = data;
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      },
      error: function (data) {
        setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, rangoFechas]);

  useEffect(() => {
    // validación de que la fecha inicial tiene que ser menor a la final
    const fechaImenorF =
      new Date(rangoFechas.fechainicial).getTime() <=
      new Date(rangoFechas.fechafinal).getTime();
    if (
      fechaImenorF &&
      rangoFechas.fechainicial !== "" &&
      rangoFechas.fechafinal !== ""
    ) {
      getData();
    }
    if (fechaImenorF) {
      setMensaje("");
    } else {
      setMensaje("La fecha final debe ser mayor");
    }
  }, [getData, rangoFechas]);

  return (
    <div className={styles.contPrincipal}>
      <div className={styles.encabezado}>
        <h3 className="titulo-pagina">Monitoreo</h3>
        <div className={styles.contIndicadoresActuales}>
          <LabelText
            label="Temperatura"
            text={
              Number(condicionesAmbientales.temperatura) > 0
                ? `${condicionesAmbientales.temperatura} °C`
                : "-"
            }
            row
          />
          <LabelText
            label="Humedad"
            text={
              Number(condicionesAmbientales.humedad) > 0
                ? `${condicionesAmbientales.humedad} %HR`
                : "-"
            }
            row
          />
          <LabelText
            label="Presión"
            text={
              Number(condicionesAmbientales.presion) > 0
                ? `${condicionesAmbientales.presion} hPa`
                : "-"
            }
            row
          />
        </div>
        <div className={styles.contInputsFechas}>
          <Button
            icono="ico-descargar"
            onClick={descargar}
            disabled={data.length <= 0 || cargando}
          />
          <ContInput style={{ background: "white" }}>
            <input
              name="fechafinal"
              type={"date"}
              value={rangoFechas.fechafinal}
              onChange={handleChange}
            />
          </ContInput>
          <ContInput style={{ background: "white" }}>
            <input
              name="fechainicial"
              type={"date"}
              value={rangoFechas.fechainicial}
              onChange={handleChange}
            />
          </ContInput>
          {cargando ? (
            <div className={styles.contLoader}>
              <Loader />
            </div>
          ) : (
            <p className={styles.mensaje}>{mensaje}</p>
          )}
        </div>
      </div>
      <GraficaTHP data={data} />
    </div>
  );
}

export default Monitoreo;
