import "./App.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";

import "./css/iconos.css";
import "./css/contenedores.css";
import "./css/normalize.css";
import "./css/animaciones.css";
import "./css/tablas.css";
import "./css/alineaciones.css";
import "./css/general.css";
import "./css/textos.css";
import Login from "./pages/Login";
import { useContext } from "react";
import DetalleCliente from "./pages/DetalleCliente";
import Popup from "./components/popup/Popup";
import PopupContext from "./context/PopupContext";
import UserContext from "./context/UserContext";
import Usuarios from "./pages/configuracion/Usuarios";
import InicioConfiguracion from "./pages/configuracion/InicioConfiguracion";
import Confirm from "./components/generic/Confirm";
import LayoutClientes from "./components/clientes/LayoutClientes";
import Pesos from "./pages/configuracion/Pesos";
import Formatos from "./pages/configuracion/Formatos";
import FormatoCertificadoBalanzas from "./components/configuracion/formatos/FormatoCertificadoBalanzas";
import Listas from "./pages/configuracion/Listas";
import OrdenesDeTrabajo from "./components/clientes/seccionesDetalle/OrdenesDeTrabajo";
import Equipos from "./components/clientes/seccionesDetalle/Equipos";
import FormatoCotizacion from "./components/configuracion/formatos/FormatoCotizacion";
import FormatoCertificadoPP from "./components/configuracion/formatos/FormatoCertificadoPP";
import OrdenesPA from "./pages/ordenes/OrdenesPA";
import Monitoreo from "./pages/Monitoreo";
import CondicionesAmbientales from "./pages/configuracion/CondicionesAmbientales";
import Buscar from "./pages/ordenes/Buscar";
import Ordenes from "./pages/Ordenes";
import FormatoMantenimiento from "./components/configuracion/formatos/FormatoMantenimiento";
import Home from "./pages/Home";

// estructura de las rutas
function App() {
  const { sesionActiva } = useContext(UserContext);
  if (!("Notification" in window)) {
    console.log("Este navegador no soporta las notificaciones de escritorio");
  } else {
    Notification.requestPermission();
  }

  return (
    <div className="cont-app">
      {sesionActiva ? <NavBar /> : <></>}
      <div className="cont-contenido-app">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <LayoutClientes />
              </ProtectedRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="/clientes/:idcliente" element={<DetalleCliente />}>
              <Route index element={<OrdenesDeTrabajo />} />
              <Route path="equipos" element={<Equipos />} />
            </Route>
          </Route>
          <Route
            path="/ordenes"
            element={
              <ProtectedRoute>
                <Ordenes />
              </ProtectedRoute>
            }
          >
            <Route index element={<OrdenesPA />} />
            <Route path="buscar" element={<Buscar />} />
          </Route>
          <Route
            path="/monitoreo"
            element={
              <ProtectedRoute>
                <AdminRoute>
                  <Monitoreo />
                </AdminRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path="/configuracion"
            element={
              <ProtectedRoute>
                <div
                  className="animar-zoom-max-to-min"
                  style={{ height: "100%" }}
                >
                  <Outlet />
                </div>
              </ProtectedRoute>
            }
          >
            <Route index element={<InicioConfiguracion />} />
            <Route
              path="usuarios"
              element={
                <AdminRoute to="/configuracion">
                  <Usuarios />
                </AdminRoute>
              }
            ></Route>
            <Route path="listas" element={<Listas />}></Route>
            <Route
              path="pesos"
              element={
                <AdminRoute to="/configuracion">
                  <Pesos />
                </AdminRoute>
              }
            ></Route>
            <Route
              path="formatos"
              element={
                <AdminRoute to="/configuracion">
                  <Outlet />
                </AdminRoute>
              }
            >
              <Route index element={<Formatos />} />
              <Route path="balanzas" element={<FormatoCertificadoBalanzas />} />
              <Route path="pesospatron" element={<FormatoCertificadoPP />} />
              <Route path="mantenimiento" element={<FormatoMantenimiento />} />
              <Route path="cotizacion" element={<FormatoCotizacion />} />
            </Route>
            <Route
              path="condicionesambientales"
              element={
                <AdminRoute>
                  <CondicionesAmbientales />
                </AdminRoute>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <h2>Error 404</h2>
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      <Popup />
      <Confirm />
    </div>
  );
}

export default App;

interface RoutesProps {
  children: React.ReactNode;
  to?: string;
}

// ruta a la que solo se puede acceder si se ha iniciado sesión
const ProtectedRoute = ({ children }: RoutesProps) => {
  const { sesionActiva } = useContext(UserContext);
  if (!sesionActiva) {
    return <Navigate to="/login" replace />;
  } else {
    return <>{children}</>;
  }
};

// ruta a la que solo se puede acceder si has iniciado sesión como administrador
const AdminRoute = ({ children, to = "/" }: RoutesProps) => {
  const { userData } = useContext(UserContext);
  const { mostrarPopup } = useContext(PopupContext);
  // const [retorno, setRetorno] = useState(true);
  // useEffect(() => {
  // idperfil === 1 es administrador
  // idperfil === 2 es calidad
  if (Number(userData.idperfil) === 1 || Number(userData.idperfil) === 2) {
    // setRetorno(false);
    return <>{children}</>;
  } else {
    mostrarPopup(2, "No tienes privilegios para ingresar a esta sección");
    // setRetorno(true);
    return <Navigate to={to} replace />;
  }
  // }, [userData.idperfil]);
  // console.log(retorno);
  // if (retorno) {

  // } else {

  // }
};
