import { useContext, useEffect, useState } from "react";
import Button from "../../generic/Button";
import ContInput from "../../generic/ContInput";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import { Validar } from "../../../helpers/Validar";
import PopupContext from "../../../context/PopupContext";
import MensajesUI from "../../../helpers/MensajesUI";

let detalleForm = [
  { nombreForm: "Nueva marca de balanzas", icono: "ico-balanza " },
  {
    nombreForm: "Nueva marca de pesos patrón",
    icono: "ico-peso-patron color-peso-patron",
  },
  {
    nombreForm: "Nueva marca de termómetros",
    icono: "ico-termometro color-termometro ",
  },
];

function FormNuevaMarca({ idtablaequipo, activo, cerrar, marcas }) {
  const { mostrarPopup } = useContext(PopupContext);
  const [marca, setMarca] = useState("");
  const [validarMarca, setValidarMarca] = useState([false, ""]);
  const [cargando, setCargando] = useState(false);
  const [marcaExiste, setMarcaExiste] = useState(false);

  useEffect(() => {
    setMarca("");
  }, [activo]);

  const guardarMarca = () => {
    if (!cargando)
      if (validarMarca[0])
        $.ajax({
          url: `${host}:${port}/api/nuevaCotizacion/marcaAgregar`,
          type: "POST",
          dataType: "json",
          contentType: "application/json",
          // los detalles de los idtablaequipo se pueden ver en la tabla "tablaequipo"
          data: JSON.stringify({ marca, idtablaequipo: idtablaequipo + 1 }),
          beforeSend: function () {
            setCargando(true);
          },
          success: function (data) {
            setCargando(false);
            mostrarPopup(1, data);
            cerrar();
          },
          error: function (data) {
            setCargando(false);
            console.log(data);
            if (data.status === 0)
              mostrarPopup(0, "No es posible conectarse al servidor");
            else mostrarPopup(0, data.responseText);
          },
        });
      else mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
  };

  const actualizarValidacion = (e) => {
    let tempCampo = [];
    switch (e.target.name) {
      case "marca":
        tempCampo = Validar.noEspacios(e.target.value);
        break;
      default:
        break;
    }
    setValidarMarca(tempCampo);
  };

  useEffect(() => {
    if (marcas.find((el) => el.marca === marca)) {
      setMarcaExiste(true);
    } else {
      setMarcaExiste(false);
    }
  }, [marca]);

  return (
    <form
      style={{
        width: "350px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        guardarMarca();
      }}
    >
      <div className="titulo-form-nuevo-equipo">
        <div
          className={
            detalleForm[idtablaequipo].icono + " format-ico-titulo-form "
          }
        ></div>
        <h3>{detalleForm[idtablaequipo].nombreForm}</h3>
      </div>
      <ContInput>
        <input
          name="marca"
          value={marca}
          onChange={(e) => {
            setMarca(e.target.value.toUpperCase());
            actualizarValidacion(e);
          }}
        />
        {!validarMarca[0] && (
          <div className="ico-advertencia  format-ico-form-validacion"></div>
        )}
      </ContInput>
      {!validarMarca[0] && (
        <p className="texto-validacion">{validarMarca[1]}</p>
      )}
      {marcaExiste && <p className="texto-validacion">Ya existe la marca</p>}
      <div className="max-content" style={{ alignSelf: "end" }}>
        <Button
          label={"Guardar"}
          icono={"ico-guardar"}
          onClick={guardarMarca}
          cargando={cargando}
          disabled={marcaExiste || marca === ""}
        />
      </div>
    </form>
  );
}

export default FormNuevaMarca;
