import { useNavigate } from "react-router-dom";
import "./CardCliente.css";

export interface IDatosCliente {
  idcliente: number;
  empresa: string;
  nombres: string;
  email: string;
  telefono: string;
  celular: string;
  identificacion: string;
  ciudad: string;
  direccion: string;
}

interface Props {
  datosCliente: IDatosCliente;
  editar: (id: number) => void;
  onHover: (data: [string, string]) => void;
}

function CardCliente({ datosCliente, editar, onHover }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className={"animar-hover cont-card-cliente cont-card "}
      onMouseEnter={() => onHover([datosCliente.empresa, datosCliente.nombres])}
    >
      <div className="cont-ico-usuario-card">
        <div className="ico-usuario format-ico-usuario-card"></div>
      </div>
      <div>
        <div className="cliente-datos-principales">
          <p>{datosCliente.empresa}</p>
          <p>{datosCliente.nombres}</p>
          <p>{datosCliente.email}</p>
        </div>
        <div className="cliente-datos-detalles">
          <div>
            <div className="ico-telefono format-ico-tarjeta"></div>
            {datosCliente.telefono || datosCliente.celular
              ? [datosCliente.telefono, datosCliente.celular]
                  .filter((el) => el)
                  .join(" / ")
              : "Sin registro"}
          </div>
          <div>
            <div className="ico-ruc format-ico-tarjeta"></div>
            <span>{datosCliente.identificacion}</span>
          </div>
          <div>
            <div className="ico-ciudad format-ico-tarjeta"></div>
            {datosCliente.ciudad}, {datosCliente.direccion}
          </div>
        </div>
      </div>
      <div
        className="cliente-clicker"
        onClick={() => navigate(`/clientes/${datosCliente.idcliente}`)}
      />
      <div className="cont-acciones">
        <div
          className="acciones-accion"
          onClick={() => editar(datosCliente.idcliente)}
        >
          <div className="ico-lapiz format-ico-accion"></div>
          <p>Editar</p>
        </div>
      </div>
    </div>
  );
}

export default CardCliente;
