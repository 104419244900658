import ContInput from "./ContInput";
import TypeIcon from "../../types/TypeIcon";
import { IValidacion } from "../../helpers/ValidarT";

interface Props {
  label?: string;
  placeholder?: string;
  icono?: TypeIcon;
  disabled?: boolean;
  tipo?: "email" | "password" | "tel" | "text" | "time" | "url" | "number";
  name: string;
  value: string;
  validacion?: IValidacion;
  onChange: (name: any, value: string) => void;
  onEnter?: (name: any, value: string) => void;
  autoFocus?: boolean;
  datalist?: { label: string; value: string }[];
}

function Input({
  label,
  placeholder,
  icono,
  disabled = false,
  tipo = "text",
  name,
  value,
  validacion,
  onChange,
  onEnter,
  autoFocus = false,
  datalist,
}: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name, e.target.value);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name, e.target.value.trim());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(e.currentTarget.name, e.currentTarget.value);
    }
  };

  return (
    <div>
      <ContInput label={label} icono={icono}>
        <input
          type={tipo}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          autoFocus={autoFocus}
          list={"datalist-" + name}
        />
        {!!datalist && (
          <datalist id={"datalist-" + name}>
            {datalist.map((el, i) => (
              <option key={i} value={el.value}>
                {el.label}
              </option>
            ))}
          </datalist>
        )}
        {validacion && !validacion?.validado ? (
          <div className="ico-advertencia  format-ico-form-validacion"></div>
        ) : (
          <></>
        )}
      </ContInput>
      {validacion && !validacion?.validado && (
        <p className="texto-validacion">{validacion?.mensaje}</p>
      )}
    </div>
  );
}

export default Input;
