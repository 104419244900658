import styles from "./Listas.module.css";
import { useState } from "react";
import ScrollableSelectList from "../../components/generic/ScrollableSelectList";
import TiposDeIndustria from "../../components/configuracion/listas/TiposDeIndustria";
import Proveedores from "../../components/configuracion/listas/Proveedores";
import TiposDeEquipo from "../../components/configuracion/listas/TiposDeEquipo";
import Marcas from "../../components/configuracion/listas/Marcas";
import MensajeCentro from "../../components/generic/MensajeCentro";
import Magnitudes from "../../components/configuracion/listas/Magnitudes";

export interface IListas {
  iddesplegable: number;
  nombre: string;
}

export const listasDesplegables: IListas[] = [
  { iddesplegable: 1, nombre: "Tipos de industria" },
  { iddesplegable: 2, nombre: "Proveedores" },
  { iddesplegable: 3, nombre: "Tipos de equipo" },
  { iddesplegable: 4, nombre: "Marcas" },
  { iddesplegable: 5, nombre: "Magnitudes" },
];

function Listas() {
  const [idDesplegable, setIdDesplegable] = useState(0);

  return (
    <div className={`${styles.contListas}`}>
      <div>
        <h3 className={`${styles.tituloLista} titulo-pagina`}>
          Listas desplegables
        </h3>
        <div className="cont-card">
          <ScrollableSelectList
            list={listasDesplegables.sort((a, b) =>
              a.nombre.localeCompare(b.nombre)
            )}
            keysToFilter={["nombre"]}
            renderProperties={["nombre"]}
            setValue={setIdDesplegable}
            valueProperty={"iddesplegable"}
          />
        </div>
      </div>
      {idDesplegable === 0 ? (
        <MensajeCentro mensaje="Selecciona una lista" />
      ) : idDesplegable === 1 ? (
        <TiposDeIndustria />
      ) : idDesplegable === 2 ? (
        <Proveedores />
      ) : idDesplegable === 3 ? (
        <TiposDeEquipo />
      ) : idDesplegable === 4 ? (
        <Marcas />
      ) : idDesplegable === 5 ? (
        <Magnitudes />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Listas;
