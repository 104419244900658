import { useState } from "react";
import Tabs from "../components/generic/Tabs";
import Clientes from "./clientes/Clientes";
import Grupos from "./clientes/Grupos";

// experimento para mostrar los grupos de clientes - SIN TERMINAR
function Home() {
  const [pantallaActiva, setPantallaActiva] = useState(0);

  return (
    <div className="cont-clientes animar-zoom-max-to-min">
      {/* <div className="encabezado-nombre-barra-buscar">
        <h3 className="titulo-pagina">
          {pantallaActiva === 0
            ? "Clientes"
            : pantallaActiva === 1
            ? "Grupos"
            : "-"}
        </h3>
        <span />
        <Tabs
          lista={["Clientes", "Grupos"]}
          selected={pantallaActiva}
          setSelected={setPantallaActiva}
        />
      </div> */}
      {pantallaActiva === 0 ? <Clientes /> : <Grupos />}
    </div>
  );
}

export default Home;
