import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "../components/generic/Tabs";
import $ from "jquery";
import { host, port } from "../helpers/DbData";

import "./DetalleCliente.css";
import PopupContext from "../context/PopupContext";
import UserContext from "../context/UserContext";

let initialTabs = ["Órdenes de trabajo", "Equipos"];
// let initialTabs = ["Órdenes de trabajo", "Equipos"];
// let tabs = [1,2,3,4];

// este es el layout de la página que se muestra después de hacer click en un cliente.
function DetalleCliente() {
  const { mostrarPopup } = useContext(PopupContext);
  const [pantallaActiva, setPantallaActiva] = useState(0);
  const [nombreCliente, setNombreCliente] = useState("");
  const { userData } = useContext(UserContext);
  const [tabs, setTabs] = useState(initialTabs);
  const location = useLocation();
  const navigate = useNavigate();

  const { idcliente } = useParams();
  useEffect(() => {
    obtenerNombreCliente(idcliente);
    setPantallaActiva(0);
    return () => setNombreCliente("");
  }, [idcliente]);

  // considerar borrar, no tiene sentido
  useEffect(() => {
    // idperfil 2 es calidad
    // idperfil 5 es gerencia tecnica
    if (Number(userData.idperfil) === 2 || Number(userData.idperfil) === 5) {
      setTabs(["Órdenes de trabajo", "Equipos"]);
    } else {
      setTabs(["Órdenes de trabajo", "Equipos"]);
    }
  }, [userData.idperfil]);

  useEffect(() => {
    let location2 = location.pathname.split("/");
    location2 = location2.slice(3);
    switch (location2.join("/")) {
      case "":
        setPantallaActiva(0);
        break;
      case "equipos":
        setPantallaActiva(1);
        break;
      case "calibraciones":
        setPantallaActiva(2);
        break;
      default:
        break;
    }
  }, [location]);

  const navegar = (selectedOption) => {
    setPantallaActiva(selectedOption);
    switch (selectedOption) {
      case 0:
        navigate("");
        break;
      case 1:
        navigate("equipos");
        break;
      case 2:
        navigate("calibraciones");
        break;
      default:
        break;
    }
  };

  const obtenerNombreCliente = () => {
    $.ajax({
      url: `${host}:${port}/api/cliente/${idcliente}`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setMostrarCargando(true);
      },
      success: function (data) {
        // setMostrarCargando(false);
        setNombreCliente(data[0].empresa);
      },
      error: function (data) {
        // setMostrarCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  };

  return (
    <div className="cont-detalle-cliente animar-zoom-min-to-max">
      <div className="encabezado-nombre-barra-buscar">
        <h3
          className="titulo-pagina"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          {nombreCliente}
        </h3>
        <span />
        <div className="justify-self-end">
          <Tabs lista={tabs} selected={pantallaActiva} setSelected={navegar} />
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default DetalleCliente;
