let validado = [true, ""];

export const Validar = {
  general(texto, opcional = false) {
    return opcional ? validado : validarVacio(texto);
  },

  texto(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
      return expression.test(texto) ? validado : [false, "Solo letras"];
    }
  },

  numeros(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[-+]?[0-9]{1,10000}([.]?[0-9]{1,10000})?$/;
      return expression.test(texto)
        ? validado
        : [false, "Solo números con punto"];
    }
  },

  numerosPositivos(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[+]?[0-9]{1,10000}([.]?[0-9]{1,10000})?$/;
      return expression.test(texto)
        ? validado
        : [false, "Solo números positivos con punto"];
    }
  },

  numerosNegativos(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[-+]?[0-9]{1,10000}([.]?[0-9]{1,10000})?$/;
      return expression.test(texto)
        ? validado
        : [false, "Solo números negativos con punto"];
    }
  },

  cedula(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[0-9]{10,13}?$/;
      return expression.test(texto)
        ? validado
        : [false, "Ingresa una cédula válida"];
    }
  },

  direccion(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^.{1,100}$/;
      return expression.test(texto)
        ? validado
        : [false, "Máximo 100 caracteres"];
    }
  },

  email(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      return expression.test(texto)
        ? validado
        : [false, "Ingresa un email válido"];
    }
  },

  telefono(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression =
        /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;
      return expression.test(texto)
        ? validado
        : [false, "Ingresa un teléfono válido"];
    }
  },

  noCero(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      return parseInt(texto) !== 0
        ? validado
        : [false, "Selecciona una opción"];
    }
  },

  noEspacios(texto, opcional = false) {
    let val = validarVacio(texto);
    if (!val[0]) {
      return opcional ? validado : val;
    } else {
      var expression = /^[^\s]+(\s+[^\s]+)*$/;
      return expression.test(texto)
        ? validado
        : [false, "No espacios al inicio ni al final"];
    }
  },
};

function validarVacio(texto) {
  if (texto !== undefined && texto !== null && texto.toString().length === 0)
    return [false, "No puede estar vacío"];
  else {
    let format = /['´`"]/;
    if (format.test(texto)) return [false, "Sin caracteres especiales"];
    else return validado;
  }
}
