import { Link } from "react-router-dom";
import IcoApp from "../../components/generic/IcoApp";
import "./InicioConfiguracion.css";
import TypeIconColor from "../../types/TypeIconColor";
import { useContext } from "react";
import UserContext from "../../context/UserContext";

interface ILink {
  label: string;
  ico: TypeIconColor;
  to: import("react-router-dom").To;
  admin: boolean;
}

const links: ILink[] = [
  {
    label: "Usuarios",
    ico: "ico-usuarios",
    to: "usuarios",
    admin: true,
  },
  {
    label: "Listas",
    ico: "ico-lista-color",
    to: "listas",
    admin: false,
  },
  {
    label: "Trazabilidad",
    ico: "ico-peso-patron-color",
    to: "pesos",
    admin: true,
  },
  {
    label: "Formatos",
    ico: "ico-documento-color",
    to: "formatos",
    admin: true,
  },
  {
    label: "Condiciones Ambientales",
    ico: "ico-condicionesambientales-color",
    to: "condicionesambientales",
    admin: true,
  },
];

function InicioConfiguracion() {
  const { userData } = useContext(UserContext);

  return (
    <div className="cont-inicio-configuracion animar-zoom-max-to-min">
      <div className="encabezado-nombre-barra-buscar">
        <h3 className="titulo-pagina">Configuración</h3>
      </div>
      <div className="configuracion-seccion">
        <div className="configuracion-aplicaciones-seccion">
          {links
            .filter(
              (el) =>
                userData.idperfil === 2 ||
                (!el.admin && userData.idperfil !== 2)
            )
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((el, i) => (
              <div className="max-content" key={i}>
                <Link to={el.to}>
                  <IcoApp icono={el.ico} label={el.label} />
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default InicioConfiguracion;
