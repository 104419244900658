import MensajeCentro from "../../../../generic/MensajeCentro";

export interface ITrazabilidad {
  idpesa: number;
  certificado: string;
  idtipotrazabilidad: number;
}

interface Props {
  data: ITrazabilidad[];
}

function Trazabilidad({ data }: Props) {
  return (
    <div className="cont-card">
      <h3>Trazabilidad</h3>
      {data.length === 0 && (
        <MensajeCentro mensaje="Sin datos de trazabilidad" />
      )}
      {data.filter((el) => el.idtipotrazabilidad === 1).length > 0 && (
        <>
          <h4>Interna</h4>
          <p>
            {data
              .filter((el) => el.idtipotrazabilidad === 1)
              .map((el) => el.certificado)
              .join(", ")}
          </p>
        </>
      )}
      {data.filter((el) => el.idtipotrazabilidad === 2).length > 0 && (
        <>
          <h4>Externa</h4>
          <p>
            {data
              .filter((el) => el.idtipotrazabilidad === 2)
              .map((el) => el.certificado)
              .join(", ")}
          </p>
        </>
      )}
    </div>
  );
}

export default Trazabilidad;
