import HistorialEquipo from "./certificados/HistorialEquipo";

function DetalleEquipo({ activo, datosEquipo, idtablaequipo }) {
  return (
    <div
      style={{
        width: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {datosEquipo && datosEquipo.idequipo !== 0 && (
        <>
          <h3>
            Certificados de {datosEquipo.marca} {datosEquipo.modelo}{" "}
            {datosEquipo.serie}
          </h3>
          <div className="cont-card">
            <HistorialEquipo
              activo={activo}
              idequipo={datosEquipo.idequipo}
              idtablaequipo={idtablaequipo}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DetalleEquipo;
