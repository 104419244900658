import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { createRoot } from "react-dom/client";
import { SocketProvider } from "./context/SocketContext.js";
import { PopupProvider } from "./context/PopupContext";
import { ConfirmProvider } from "./context/ConfirmContext";
import { CondicionesAmbientalesProvider } from "./context/CondicionesAmbientalesContext";

const container = document.getElementById("root");

// en este archivo se llaman a todos los proveedores de contexto
const root = createRoot(container!);
root.render(
  <HashRouter>
    <UserProvider>
      <SocketProvider>
        <PopupProvider>
          <ConfirmProvider>
            <CondicionesAmbientalesProvider>
              <App />
            </CondicionesAmbientalesProvider>
          </ConfirmProvider>
        </PopupProvider>
      </SocketProvider>
    </UserProvider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
