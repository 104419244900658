import LabelText from "../../../../generic/LabelText";
import { INumDecimales } from "./IdentificacionEquipo";
import DataHandler from "../../../../../helpers/DataHandler";

export interface IRepetibilidad {
  carga: number;
  difmax: number;
  emp: number;
  p1: number;
  p2: number;
  p3: number;
  p4?: number | null;
  p5?: number | null;
  p6?: number | null;
  idnumdecimales: number;
}

interface Props {
  data: IRepetibilidad;
  numdecimales: INumDecimales;
  unidadmedida: string;
}

function Repetibilidad({ data, numdecimales, unidadmedida }: Props) {
  const renderND = DataHandler.renderND(numdecimales);
  return (
    <div className="cont-card">
      <h3>Prueba de repetibilidad</h3>
      <div>
        <LabelText
          label={`Carga (${unidadmedida})`}
          text={renderND(data.p1, data.idnumdecimales)}
          centrar
        />
        <div>
          <div className="table-row table-row-title center-hd">
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <span>6</span>
            <span>Dif. Máx.</span>
            <span>e.m.p.</span>
          </div>
          <div className="table-row center-hd">
            <span>{renderND(data.p1, data.idnumdecimales)}</span>
            <span>{renderND(data.p2, data.idnumdecimales)}</span>
            <span>{renderND(data.p3, data.idnumdecimales)}</span>
            <span>
              {data.p4 ? renderND(data.p4, data.idnumdecimales) : "-"}
            </span>
            <span>
              {data.p5 ? renderND(data.p5, data.idnumdecimales) : "-"}
            </span>
            <span>
              {data.p6 ? renderND(data.p6, data.idnumdecimales) : "-"}
            </span>
            <span>{renderND(data.difmax, data.idnumdecimales)}</span>
            <span>±{renderND(data.emp, data.idnumdecimales)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Repetibilidad;
