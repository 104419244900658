import $ from "jquery";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ConfirmContext from "../../../../context/ConfirmContext";
import PopupContext from "../../../../context/PopupContext";
import UserContext from "../../../../context/UserContext";
import DataHandler from "../../../../helpers/DataHandler";
import { host, port } from "../../../../helpers/DbData";
import MensajesUI from "../../../../helpers/MensajesUI";
import {
  ValidarT,
  objValidado,
  objValidadoInicial,
  validarCamposLlenos,
  validarForm,
} from "../../../../helpers/ValidarT";
import Button from "../../../generic/Button";
import Select from "../../../generic/Select";
import Tabs from "../../../generic/Tabs";
import "./FormNuevaOrden.css";
import "./OrdenEquipo.css";
import EquiposOrden, {
  IBalanza,
  IDetalleEquipo,
  IEquipos,
  IPesoPatron,
  ITermometro,
} from "./equiposOrden/EquiposOrden";
import DatosGenerales from "./equiposOrden/formOT/DatosGenerales";
import IndicadorPrecios from "./equiposOrden/formOT/IndicadorPrecios";
import ServiciosExternos from "./equiposOrden/formOT/ServiciosExternos";
import { IRepuestoExtended } from "./equiposOrden/items/EquipoOrden";
import _ from "lodash";

const fetchRepuestos = async (idordentrabajo: number) =>
  fetch(`${host}:${port}/api/repuestos/${idordentrabajo}`);

export interface ICotizacionData {
  idcliente: number;
  idordentrabajo: number;
  idservicio: number;
  costoextra: {
    cantidad: number;
    descripcion: string;
    costounitario: string;
  };
  contrato: {
    idformapago: number;
    diascredito: number;
    vigencia: number;
    proveedor: string;
    observaciones: string;
    descuento: string;
  };
  detalleequipos: IDetalleEquipo[];
  serviciosexternos: IServicioExterno[];
  datoscliente: { informe_direccion: string; informe_ciudad: string };
}

export interface IServicioExterno {
  idtipoequipo: number;
  idservicio: number;
  idproveedor: number;
  cantidad: string;
  costounitario: string;
  acreditado: 0 | 1;
  descripcion: string;
  marca: string;
  modelo: string;
  identificacion: string;
  capacidad: string;
  resolucion: string;
}

export interface ICostos {
  visitatecnica: number;
  equipos: number;
  repuestos: number;
  serviciosexternos: number;
  subtotal: number;
  descuento: number | null;
  descuentoP: number | null;
  subtotal2: number | null;
  iva: number;
  total: number;
}

const initialCostos: ICostos = {
  visitatecnica: 0,
  equipos: 0,
  repuestos: 0,
  serviciosexternos: 0,
  subtotal: 0,
  descuento: null,
  descuentoP: null,
  subtotal2: null,
  iva: 0,
  total: 0,
};

const initialCotizacionData: ICotizacionData = {
  idcliente: 0,
  idordentrabajo: 0,
  idservicio: 0,
  costoextra: {
    cantidad: 1,
    descripcion: "Visita técnica",
    costounitario: "",
  },
  contrato: {
    idformapago: 1,
    diascredito: 8,
    vigencia: 15,
    proveedor: "",
    observaciones: "",
    descuento: "",
  },
  detalleequipos: [],
  serviciosexternos: [],
  datoscliente: { informe_direccion: "", informe_ciudad: "" },
};

const initialFormValidacion = {
  cantidad: objValidado,
  costounitario: objValidadoInicial,
  diascredito: objValidado,
  vigencia: objValidado,
  informe_ciudad: objValidado,
  informe_direccion: objValidado,
};

const initialDatosCliente = {
  identificacion: "",
  empresa: "",
  nombres: "",
  ciudad: "",
  direccion: "",
  telefono: "",
  celular: "",
  email: "",
};

let initialEquipos: IEquipos = {
  balanzas: [],
  pesospatron: [],
  termometros: [],
};

interface Props {
  activo: boolean;
  cerrar: () => void;
  idOrdenTrabajo: number;
  idOrdenDuplicar: number;
  estadoCotizacion?: number;
}

function FormNuevaOrdenTrabajo({
  activo,
  cerrar,
  idOrdenTrabajo,
  idOrdenDuplicar,
  estadoCotizacion = 0,
}: Props) {
  const { mostrarPopup } = useContext(PopupContext);
  const { userData } = useContext(UserContext);
  const [formasPago, setFormasPago] = useState([]);

  // const [auxEquipos, setAuxEquipos] = useState(initialEquipos);
  const [formCotizacionData, setFormCotizacionData] = useState(
    initialCotizacionData
  );
  const [cargando, setCargando] = useState(false);
  const formValidacion = useRef(initialFormValidacion);
  const { mostrarConfirm } = useContext(ConfirmContext);
  const [pantallaActiva, setPantallaActiva] = useState(0);
  const [servicios, setServicios] = useState<
    { idservicio: number; abreviatura: string }[]
  >([]);
  const [repuestos, setRepuestos] = useState<IRepuestoExtended[]>([]);
  const [datosCliente, setDatosCliente] = useState(initialDatosCliente);
  const [costos, setCostos] = useState(initialCostos);
  const [pantallaProveedor, setPantallaProveedor] = useState(0);
  const [equipos, setEquipos] = useState<IEquipos>(initialEquipos);

  // para validar si se han hecho cambios en la orden de trabajo y no actualizar la versión de la cotización
  const ordenTrabajoAntesCambios = useRef<ICotizacionData | null>(null);
  const repuestosAntesCambios = useRef<IRepuestoExtended[]>([]);

  const { idcliente } = useParams();

  useEffect(() => {
    if (activo && idOrdenTrabajo > 0) {
      const a = async () => {
        const response = await fetchRepuestos(idOrdenTrabajo);
        if (!response.ok) setRepuestos([]);
        const data = await response.json();
        setRepuestos(data ?? []);
        repuestosAntesCambios.current = data ?? [];
      };
      a();
    }
  }, [activo, idOrdenTrabajo]);

  useEffect(() => {
    if (!activo) {
      setFormCotizacionData((fCD) => ({
        ...initialCotizacionData,
        detalleequipos: fCD.detalleequipos.map((el) => ({
          idequipo: el.idequipo,
          precioservicio: "",
        })),
        datoscliente: {
          informe_ciudad: datosCliente.ciudad,
          informe_direccion: datosCliente.direccion,
        },
      }));
      setRepuestos([]);
      formValidacion.current = initialFormValidacion;
    }
  }, [activo, datosCliente]);

  useEffect(() => {
    if (!activo) {
      setPantallaActiva(0);
    }
  }, [activo]);

  useEffect(() => {
    // se calculan los costos
    // el descuento se aplica al subtotal
    const visitatecnica =
      Number(formCotizacionData.costoextra.cantidad) *
      Number(formCotizacionData.costoextra.costounitario);
    const equipos = formCotizacionData.detalleequipos
      .filter((el) => Number(el.precioservicio) > 0)
      .reduce(
        (total: number, el: { precioservicio: string }) =>
          total + Number(el.precioservicio),
        0
      );
    const precioRepuestos = repuestos.reduce(
      (total, el) =>
        total + (isNaN(Number(el?.precio)) ? 0 : Number(el?.precio)),
      0
    );
    const serviciosexternos = formCotizacionData.serviciosexternos.reduce(
      (total: number, el) =>
        total + Number(el.cantidad) * Number(el.costounitario),
      0
    );
    const subtotal =
      visitatecnica + equipos + serviciosexternos + precioRepuestos;
    const descuento =
      Number(formCotizacionData.contrato.descuento) > 0
        ? Number(formCotizacionData.contrato.descuento)
        : null;
    const descuentoP =
      descuento && subtotal ? (descuento / subtotal) * 100 : null;
    const subtotal2 =
      descuento !== null && descuento !== undefined
        ? subtotal - descuento
        : null;
    const iva =
      subtotal2 !== null && subtotal2 !== undefined
        ? subtotal2 * 0.15
        : subtotal * 0.15;
    const total =
      subtotal2 !== null && subtotal2 !== undefined
        ? subtotal2 * 1.15
        : subtotal * 1.15;

    setCostos({
      visitatecnica,
      equipos,
      repuestos: precioRepuestos,
      serviciosexternos,
      subtotal,
      descuento,
      descuentoP,
      subtotal2,
      iva,
      total,
    });
  }, [
    formCotizacionData.costoextra.cantidad,
    formCotizacionData.costoextra.costounitario,
    formCotizacionData.detalleequipos,
    formCotizacionData.contrato.descuento,
    formCotizacionData.serviciosexternos,
    repuestos,
  ]);

  const getServicios = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/getServicios`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {},
      success: function (data) {
        setServicios(data);
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  const obtenerFormasPago = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/getFormaPago`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {},
      success: function (data) {
        setFormasPago(data);
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  const obtenerServiciosEquipos = useCallback(async () => {
    await $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/getEquipos`,
      type: "post",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        idcliente,
      }),
      beforeSend: function () {},
      success: function (data) {
        setEquipos(data);
        setFormCotizacionData((fCD) => ({
          ...fCD,
          detalleequipos: [
            ...data.balanzas.map((el: IBalanza) => ({
              idequipo: el.idequipo,
              precioservicio: "",
            })),
            ...data.pesospatron.map((el: IPesoPatron) => ({
              idequipo: el.idequipo,
              precioservicio: "",
            })),
            ...data.termometros.map((el: ITermometro) => ({
              idequipo: el.idequipo,
              precioservicio: "",
            })),
          ],
        }));
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup, idcliente]);

  useEffect(() => {
    obtenerFormasPago();
    obtenerServiciosEquipos();
    getServicios();
  }, [obtenerFormasPago, obtenerServiciosEquipos, getServicios]);

  const handleChangeCostoExtra = (name: string, value: string) => {
    setFormCotizacionData((fCD) => ({
      ...fCD,
      costoextra: {
        ...fCD.costoextra,
        [name]: value,
      },
    }));
    actualizarValidacion(name, value);
  };

  const handleChangeContrato = (name: string, value: string) => {
    setFormCotizacionData((fCD) => ({
      ...fCD,
      contrato: {
        ...fCD.contrato,
        [name]: value,
      },
    }));
    actualizarValidacion(name, value);
  };

  const handleChangeDatosCliente = (name: string, value: string) => {
    setFormCotizacionData((fCD) => ({
      ...fCD,
      datoscliente: {
        ...fCD.datoscliente,
        [name]: value,
      },
    }));
    actualizarValidacion(name, value);
  };

  const actualizarValidacion = (name: string, value: string) => {
    let tempCampo = {};
    switch (name) {
      case "cantidad":
        tempCampo = {
          [name]: ValidarT.numerosPositivos(value),
        };
        break;
      case "costounitario":
        tempCampo = {
          [name]: ValidarT.numerosPositivos(value),
        };
        break;
      case "diascredito":
        tempCampo = {
          [name]: ValidarT.numerosPositivos(value),
        };
        break;
      case "vigencia":
        tempCampo = {
          [name]: ValidarT.numerosPositivos(value),
        };
        break;
      case "informe_ciudad":
        tempCampo = {
          [name]: ValidarT.general(value),
        };
        break;
      case "informe_direccion":
        tempCampo = {
          [name]: ValidarT.general(value),
        };
        break;
      default:
        break;
    }
    formValidacion.current = {
      ...formValidacion.current,
      ...tempCampo,
    };
  };

  const validarEquipoTienePrecioRepuestos = (): boolean => {
    const equiposConRepuestos = [
      ...new Set(repuestos.map((el) => el.idequipo)),
    ];
    for (const el of equiposConRepuestos) {
      const found = formCotizacionData.detalleequipos.find(
        (el2) => el2.idequipo === el && el2.precioservicio !== ""
      );
      if (!found) {
        return false;
      }
    }
    return true;
  };

  const handleGuardarOrdenTrabajo = async () => {
    if (
      repuestos.some((el) => el?.precio === "" || isNaN(Number(el?.precio)))
    ) {
      mostrarPopup(2, "El precio de uno de los repuestos no es válido");
      return;
    }

    if (
      repuestos.some(
        (el) =>
          (el.repuesto === "" || el.repuesto === null) &&
          !isNaN(Number(el?.precio))
      )
    ) {
      mostrarPopup(2, "Uno de los repuestos no tiene nombre");
      return;
    }

    if (!validarEquipoTienePrecioRepuestos()) {
      mostrarPopup(2, "Hay equipos sin precio que tienen repuestos añadidos");
      return;
    }

    if (
      formCotizacionData.detalleequipos.filter(
        (el) => Number(el.precioservicio) > 0
      ).length <= 0 &&
      formCotizacionData.serviciosexternos.length <= 0
    ) {
      mostrarPopup(2, "Selecciona al menos un equipo");
    } else if (!validarDetalleEquipos()) {
      mostrarPopup(2, "El precio de uno de los equipos no es válido");
    } else if (
      Number(formCotizacionData.idservicio) === 0 &&
      formCotizacionData.detalleequipos.filter(
        (el) => Number(el.precioservicio) > 0
      ).length > 0
    ) {
      mostrarPopup(2, "Selecciona un servicio");
    } else if (validarForm(formValidacion.current)) {
      if (estadoCotizacion === 1) {
        if (
          await mostrarConfirm("¿Seguro que deseas actualizar la cotización?")
        ) {
          guardarOrdenTrabajo();
        } else {
          return;
        }
      } else {
        guardarOrdenTrabajo();
      }
    } else {
      mostrarPopup(2, MensajesUI.advertencia.form.noValidado);
    }
  };

  const validarDetalleEquipos = () => {
    for (const el of formCotizacionData.detalleequipos.filter(
      (el) => Number(el.precioservicio) > 0
    )) {
      if (el.precioservicio === "" || isNaN(Number(el.precioservicio))) {
        return false;
      }
    }
    return true;
  };

  const guardarOrdenTrabajo = () => {
    let tempFormCotizacionData = {
      ...formCotizacionData,
      detalleequipos: formCotizacionData.detalleequipos.filter(
        (el) => el.precioservicio !== "" && !isNaN(Number(el.precioservicio))
      ),
    };
    if (idOrdenTrabajo !== 0 && ordenTrabajoAntesCambios.current) {
      ordenTrabajoAntesCambios.current.detalleequipos
        .filter(
          (el) => el.precioservicio !== "" && !isNaN(Number(el.precioservicio))
        )
        .sort((a, b) => a.idequipo - b.idequipo);
    }

    repuestosAntesCambios.current.sort((a, b) => a.idequipo - b.idequipo);

    const repuestosIguales = _.isEqual(
      [...repuestos].sort((a, b) => a.idequipo - b.idequipo),
      repuestosAntesCambios.current.sort((a, b) => a.idequipo - b.idequipo)
    );

    let editado = 0;
    if (idOrdenTrabajo !== 0 && ordenTrabajoAntesCambios.current) {
      editado =
        (!compararCotizaciones(
          tempFormCotizacionData,
          ordenTrabajoAntesCambios.current
        ) ||
          !repuestosIguales) &&
        estadoCotizacion === 1
          ? 1
          : 0;
    }

    if (!cargando)
      $.ajax({
        url: `${host}:${port}/api/ordenTrabajo/actualizar`,
        type: "put",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          ...tempFormCotizacionData,
          idcliente,
          idmodificador: userData.idusuario,
          idordentrabajo: idOrdenTrabajo,
          editado,
          repuestos: repuestos.map((el) => ({
            idequipo: el.idequipo,
            repuesto: el.repuesto,
            precio: el.precio,
          })),
        }),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          cerrar();
          mostrarPopup(1, data);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  const getDatosCliente = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/nuevaCotizacion/datoscliente`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idcliente },
      beforeSend: function () {},
      success: function (data) {
        setDatosCliente(data);
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [idcliente, mostrarPopup]);

  const anadirDetallesEquipos = (data: ICotizacionData) => {
    let temp = { ...data };
    temp.detalleequipos = [
      ...equipos.balanzas.map((el) => {
        const temp = data.detalleequipos.find(
          (el2) => el2.idequipo === el.idequipo
        );
        return temp ? temp : { idequipo: el.idequipo, precioservicio: "" };
      }),
      ...equipos.pesospatron.map((el) => {
        const temp = data.detalleequipos.find(
          (el2) => el2.idequipo === el.idequipo
        );
        return temp ? temp : { idequipo: el.idequipo, precioservicio: "" };
      }),
      ...equipos.termometros.map((el) => {
        const temp = data.detalleequipos.find(
          (el2) => el2.idequipo === el.idequipo
        );
        return temp ? temp : { idequipo: el.idequipo, precioservicio: "" };
      }),
    ];
    return temp;
  };

  const obtenerOrdenTrabajo = useCallback(() => {
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/ordenbyidOrden`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idordentrabajo: idOrdenTrabajo, ordencompleta: 0 },
      beforeSend: function () {},
      success: function (data) {
        setFormCotizacionData(anadirDetallesEquipos(data));
        formValidacion.current = {
          ...formValidacion.current,
          ...validarCamposLlenos({
            cantidad: data.costoextra.cantidad,
            costounitario: data.costoextra.costounitario,
            diascredito: data.contrato.diascredito,
            vigencia: data.contrato.vigencia,
          }),
        };
        ordenTrabajoAntesCambios.current = data;
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [idOrdenTrabajo, mostrarPopup]);

  const obtenerOrdenTrabajoDuplicar = useCallback(() => {
    // cuando se duplica una orden de trabajo solo se duplican el servicio, los equipos, los servicios externos y los datos del cliente
    $.ajax({
      url: `${host}:${port}/api/ordenTrabajo/ordenbyidOrden`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      data: { idordentrabajo: idOrdenDuplicar, ordencompleta: 0 },
      beforeSend: function () {},
      success: function (data) {
        const tempDetallesEquipos = anadirDetallesEquipos(data);
        const tempData = {
          ...initialCotizacionData,
          idservicio: data.idservicio,
          detalleequipos: tempDetallesEquipos.detalleequipos,
          serviciosexternos: data.serviciosexternos,
          datoscliente: {
            informe_ciudad: datosCliente.ciudad,
            informe_direccion: datosCliente.direccion,
          },
        };
        setFormCotizacionData(tempData);
      },
      error: function (data) {
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [
    datosCliente.ciudad,
    datosCliente.direccion,
    idOrdenDuplicar,
    mostrarPopup,
  ]);

  useEffect(() => {
    getDatosCliente();
  }, [getDatosCliente]);

  useEffect(() => {
    if (idOrdenTrabajo && idOrdenTrabajo !== 0 && activo) {
      obtenerOrdenTrabajo();
    }
  }, [idOrdenTrabajo, obtenerOrdenTrabajo, activo]);

  useEffect(() => {
    if (idOrdenDuplicar && idOrdenDuplicar !== 0) obtenerOrdenTrabajoDuplicar();
  }, [idOrdenDuplicar, obtenerOrdenTrabajoDuplicar]);

  const compararCotizaciones = (
    obj1: ICotizacionData,
    obj2: ICotizacionData
  ) => {
    let iguales = true;

    if (
      Number(obj1.idservicio) !== Number(obj2.idservicio) ||
      !DataHandler.compare.objects(obj1.contrato, obj2.contrato) ||
      !DataHandler.compare.objects(obj1.costoextra, obj2.costoextra) ||
      !DataHandler.compare.objects(obj1.datoscliente, obj2.datoscliente) ||
      !DataHandler.compare.arrays(obj1.detalleequipos, obj2.detalleequipos) ||
      !DataHandler.compare.arrays(
        obj1.serviciosexternos,
        obj2.serviciosexternos
      )
    ) {
      iguales = false;
    }
    return iguales;
  };

  const setDetalleEquipos = (
    detalleequipos: ICotizacionData["detalleequipos"]
  ) => {
    setFormCotizacionData((fCD) => {
      return { ...fCD, detalleequipos };
    });
  };

  const handleChangeServicio = (_: string, value: string) => {
    setFormCotizacionData((fCD) => ({ ...fCD, idservicio: Number(value) }));
  };

  return (
    <div className="cont-form-nueva-orden">
      <div className="cotizacion-titulo-form">
        {pantallaActiva === 1 && !cargando ? (
          <Button
            icono="ico-atras-simple"
            onClick={() => setPantallaActiva(0)}
            nivel={3}
          />
        ) : (
          <div className="cont-tipo-servicio">
            <Select
              name="idservicio"
              onChange={handleChangeServicio}
              options={servicios.map((el) => ({
                value: el.idservicio,
                label: el.abreviatura,
              }))}
              value={formCotizacionData.idservicio}
              initialLabel="Servicio"
            />
          </div>
        )}
        <IndicadorPrecios costos={costos} />
        {pantallaActiva === 0 && (
          <Button
            icono="ico-adelante-simple"
            onClick={() => setPantallaActiva(1)}
            nivel={3}
          />
        )}
        {pantallaActiva === 1 && (
          <Button
            label={"Guardar"}
            icono="ico-guardar"
            onClick={handleGuardarOrdenTrabajo}
            cargando={cargando}
          />
        )}
      </div>
      {pantallaActiva === 0 && (
        <Tabs
          lista={["Equipos", "Servicios externos"]}
          selected={pantallaProveedor}
          setSelected={setPantallaProveedor}
        />
      )}
      <form className="form-nueva-orden" autoComplete="off">
        <EquiposOrden
          detalleEquipos={formCotizacionData.detalleequipos}
          repuestos={repuestos}
          setRepuestos={setRepuestos}
          setDetalleEquipos={setDetalleEquipos}
          visible={pantallaProveedor === 0 && pantallaActiva === 0}
          equipos={equipos}
          activo={activo}
        />
        <ServiciosExternos
          servicios={formCotizacionData.serviciosexternos}
          setServicios={(servicios: IServicioExterno[]) =>
            setFormCotizacionData((fCD) => ({
              ...fCD,
              serviciosexternos: servicios,
            }))
          }
          visible={pantallaProveedor !== 0 && pantallaActiva === 0}
        />
        <DatosGenerales
          formCotizacionData={formCotizacionData}
          formasPago={formasPago}
          handleChangeContrato={handleChangeContrato}
          handleChangeCostoExtra={handleChangeCostoExtra}
          handleChangeDatosCliente={handleChangeDatosCliente}
          visible={pantallaActiva === 1}
          costos={costos}
        />
      </form>
    </div>
  );
}

export default FormNuevaOrdenTrabajo;
