// @ts-ignore
import mqtt from "mqtt/dist/mqtt";
import {
  createContext,
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { host, port } from "../helpers/DbData";
import warningSound from "../media/sounds/warning.wav";
import PopupContext from "./PopupContext";
import $ from "jquery";
import BrowserNotification from "../helpers/BrowserNotification";
import UserContext from "./UserContext";
import * as math from "mathjs";

const initialCondicionesAmbientales: ICondicionesAmbientalesData = {
  temperatura: "",
  humedad: "",
  presion: "",
  iddispositivo: 1,
};

const initialFueraRango = {
  temperatura: false,
  humedad: false,
  presion: false,
};

const initialLimites = {
  temperaturamin: -1,
  temperaturamax: 100,
  humedadmin: -1,
  humedadmax: 100,
  presionmin: -1,
  presionmax: 1000,
};

interface ICondicionesAmbientalesContext {
  cargando: boolean;
  condicionesAmbientales: ICondicionesAmbientalesData;
  fueraRango: typeof initialFueraRango;
  connected: boolean;
}

export interface ICondicionesAmbientalesData {
  temperatura: string;
  humedad: string;
  presion: string;
  iddispositivo: number;
}

const CondicionesAmbientalesContext = createContext(
  {} as ICondicionesAmbientalesContext
);

interface Props {
  children: React.ReactNode;
}

const initialFactoresCorreccion = {
  temperatura: "x",
  humedad: "x",
  presion: "x",
};

const CondicionesAmbientalesProvider = ({ children }: Props) => {
  const [cargando, setCargando] = useState(true);
  const [cliente] = useState(() =>
    mqtt.connect("wss://admin.pinprexat.com:8084/mqtt", {
      username: "laboratorio",
      password: "lab.mqtt",
    })
  );
  // const [cliente] = useState(() =>
  //   mqtt.connect("ws://admin.pinprexat.com:8083/mqtt", {
  //     username: "laboratorio",
  //     password: "lab.mqtt",
  //   })
  // );
  const audioRef = useRef(new Audio(warningSound));
  const [condicionesAmbientales, setCondicionesAmbientales] = useState(
    initialCondicionesAmbientales
  );
  const [fueraRango, setFueraRango] = useState(initialFueraRango);
  const [limitesCondicionesLab, setLimitesCondicionesLab] =
    useState(initialLimites);
  const { mostrarPopup } = useContext(PopupContext);
  const [connected, setConnected] = useState(false);
  const lastDataDate = useRef<Date>(new Date(0));
  const [time, setTime] = useState(0);
  const [time2, setTime2] = useState(0);
  const { userData } = useContext(UserContext);
  const [factoresCorreccion, setFactoresCorreccion] = useState(
    initialFactoresCorreccion
  );

  useEffect(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/limitescondicioneslab`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setCargando(true);
      },
      success: function (data) {
        // setCargando(false);
        setLimitesCondicionesLab(data);
      },
      error: function (data) {
        // setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  useEffect(() => {
    $.ajax({
      url: `${host}:${port}/api/monitoreo/fc_termohigrometros`,
      type: "get",
      dataType: "json",
      contentType: "application/json",
      beforeSend: function () {
        // setCargando(true);
      },
      success: function (data) {
        // setCargando(false);
        setFactoresCorreccion({
          temperatura: data.temperatura,
          humedad: data.humedad,
          presion: data.presion,
        });
      },
      error: function (data) {
        // setCargando(false);
        console.log(data);
        if (data.status === 0)
          mostrarPopup(0, "No es posible conectarse al servidor");
        else mostrarPopup(0, data.responseText);
      },
    });
  }, [mostrarPopup]);

  const comprobarCondicionesAmbientales = useCallback(() => {
    if (fueraRango.humedad || fueraRango.presion || fueraRango.temperatura)
      try {
        audioRef.current.play();
        BrowserNotification({
          titulo: "Laboratorio de masa",
          mensaje: "Condiciones ambientales fuera de rango",
          ruta: "/monitoreo",
        });
      } catch (e) {
        console.log(e);
      }
  }, [fueraRango]);

  const timer1Ref = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    if (Number(userData.idperfil) === 2) {
      comprobarCondicionesAmbientales();
      timer1Ref.current = setInterval(() => setTime2((t) => t + 1000), 1000);
      return () => {
        if (timer1Ref.current) clearInterval(timer1Ref.current);
      };
    }
  }, [userData.idperfil]);

  useEffect(() => {
    const intervaloNotificaciones = 60;
    if (time2 / 1000 >= intervaloNotificaciones) {
      comprobarCondicionesAmbientales();
      setTime2(0);
    }
  }, [time2]);

  useEffect(() => {
    if (cliente.connected) {
      cliente.end();
      cliente.reconnect();
    }
    cliente.on("message", (topic: string, message: Object) => {
      try {
        const data = JSON.parse(message.toString()) as {
          Temperatura: number;
          Humedad: number;
          Presion: number;
          iddispositivo: number;
        };
        if (!(data.Temperatura && data.Humedad && data.Presion)) {
          return;
        }
        // la ecuación debe corregir la lectura directamente.
        // Ej: si la temperatura es 20°C pero en realidad debe marcar 20.1°C la ecuación deberá ser: x+0.1
        const mathParser = math.parser();
        mathParser.evaluate("f(x)=" + factoresCorreccion.temperatura);
        const temperatura = mathParser
          .evaluate("f(" + Number(data.Temperatura) + ")")
          .toFixed(1);
        mathParser.evaluate("f(x)=" + factoresCorreccion.humedad);
        const humedad = mathParser
          .evaluate("f(" + Number(data.Humedad) + ")")
          .toFixed(1);
        mathParser.evaluate("f(x)=" + factoresCorreccion.presion);
        const presion = mathParser
          .evaluate("f(" + Number(data.Presion) + ")")
          .toFixed(1);

        setCondicionesAmbientales({
          temperatura,
          humedad,
          presion,
          iddispositivo: 1,
        });
        setCargando(false);
        lastDataDate.current = new Date();
      } catch (e) {
        console.error(e);
      }
    });

    return () => {
      if (cliente.connected) {
        cliente.end();
      }
    };
  }, [cliente, factoresCorreccion]);

  useEffect(() => {
    cliente.subscribe("/zona1/PTHB-002");
  }, [cliente]);

  useEffect(() => {
    if (cargando) return;
    if (
      Number(condicionesAmbientales.temperatura) >=
        limitesCondicionesLab.temperaturamin &&
      Number(condicionesAmbientales.temperatura) <=
        limitesCondicionesLab.temperaturamax
    ) {
      setFueraRango((fR) => ({ ...fR, temperatura: false }));
    } else {
      setFueraRango((fR) => ({ ...fR, temperatura: true }));
    }
    if (
      Number(condicionesAmbientales.humedad) >=
        limitesCondicionesLab.humedadmin &&
      Number(condicionesAmbientales.humedad) <= limitesCondicionesLab.humedadmax
    ) {
      setFueraRango((fR) => ({ ...fR, humedad: false }));
    } else {
      setFueraRango((fR) => ({ ...fR, humedad: true }));
    }
    if (
      Number(condicionesAmbientales.presion) >=
        limitesCondicionesLab.presionmin &&
      Number(condicionesAmbientales.presion) <= limitesCondicionesLab.presionmax
    ) {
      setFueraRango((fR) => ({ ...fR, presion: false }));
    } else {
      setFueraRango((fR) => ({ ...fR, presion: true }));
    }
  }, [condicionesAmbientales, limitesCondicionesLab, cargando]);

  const contRef = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    contRef.current = setInterval(() => setTime((t) => t + 5000), 5000);
    return () => {
      if (contRef.current) clearInterval(contRef.current);
    };
  }, []);

  useEffect(() => {
    const segundosMaximosEntreLecturas = 8;
    if (
      (new Date().getTime() - lastDataDate.current.getTime()) / 1000 >
      segundosMaximosEntreLecturas
    ) {
      setConnected(false);
    } else {
      setConnected(true);
    }
  }, [time]);

  return (
    <CondicionesAmbientalesContext.Provider
      value={{
        cargando,
        condicionesAmbientales,
        fueraRango,
        connected,
      }}
    >
      {children}
    </CondicionesAmbientalesContext.Provider>
  );
};

export { CondicionesAmbientalesProvider };
export default CondicionesAmbientalesContext;
