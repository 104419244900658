const Animations = {
  dropdown: {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
  },
  fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  dropdownFade: {
    initial: { height: 0, opacity: 0 },
    animate: { height: "auto", opacity: 1 },
    exit: { height: 0, opacity: 0 },
  },
};

export default Animations;
