import { useState, useContext, useEffect, useCallback } from "react";
import "./Calibraciones.css";
import HojaDatosBalanza from "./calibraciones/HojaDatosBalanza";
import UserContext from "../../../context/UserContext";
import HojaDatosPesosPatron from "./calibraciones/HojaDatosPesosPatron";
import MensajeCentro from "../../generic/MensajeCentro";
import Tabs from "../../generic/Tabs";
import ListaBalanzas from "./calibraciones/ListaBalanzas";
import ListaPesosPatron from "./calibraciones/ListaPesosPatron";
import { IEquipoRevisionBalanzas } from "./calibraciones/ListaBalanzas";
import { IOrdenRevisionPP } from "./calibraciones/ListaPesosPatron";
import $ from "jquery";
import { host, port } from "../../../helpers/DbData";
import PopupContext from "../../../context/PopupContext";
import { useParams } from "react-router-dom";
import HojaDatosMantenimiento from "./calibraciones/HojaDatosMantenimiento";
import Loader from "../../generic/Loader";
import Collapsable from "../../generic/Collapsable";

interface IEquiposRevision {
  balanzas: IEquipoRevisionBalanzas[];
  pesospatron: IOrdenRevisionPP[];
}

const initialEquiposRevision: IEquiposRevision = {
  balanzas: [],
  pesospatron: [],
};

export interface IEquipoActivo {
  idordentrabajo: number;
  idservicio?: number;
  idtipoequipo?: number;
  iddetalleordentrabajo?: number;
  idcertdata?: number;
  marca?: string;
  modelo?: string;
  serie?: string;
  year?: number;
  num?: number;
  idtablaequipo?: number;
}

interface Props {
  idordentrabajo: number;
}

function Calibraciones({ idordentrabajo }: Props) {
  const [equipoActivo, setEquipoActivo] = useState<IEquipoActivo>();
  const { userData } = useContext(UserContext);
  const [cargando, setCargando] = useState(false);
  const [equiposRevision, setEquiposRevision] = useState<IEquiposRevision>(
    initialEquiposRevision
  );
  const { idcliente } = useParams();
  const { mostrarPopup } = useContext(PopupContext);

  const getCertDatas = useCallback(() => {
    if (idordentrabajo > 0) {
      $.ajax({
        url: `${host}:${port}/api/cert/porrevisar`,
        type: "get",
        dataType: "json",
        contentType: "application/json",
        data: { idcliente, idusuario: userData.idusuario, idordentrabajo },
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          setEquiposRevision(data);
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
    }
  }, [idcliente, mostrarPopup, userData.idperfil, idordentrabajo]);

  const cerrarHojaDatos = useCallback(() => {
    setEquipoActivo(undefined);
    getCertDatas();
  }, [getCertDatas]);

  useEffect(() => {
    cerrarHojaDatos();
  }, [cerrarHojaDatos]);

  return (
    // {userData.idperfil===2 ||userData.idperfil===5 &&
    <div className="animar-entrada cont-calibraciones">
      <div className="calibraciones-lista-equipos">
        {cargando ? (
          <Loader />
        ) : (
          <div style={{ marginRight: "10px" }}>
            {equiposRevision.balanzas.length > 0 ||
            equiposRevision.pesospatron.length > 0 ? (
              <>
                {equiposRevision.balanzas.length > 0 && (
                  <Collapsable title="Balanzas">
                    <ListaBalanzas
                      setEquipoActivo={setEquipoActivo}
                      equipoActivo={equipoActivo}
                      equiposRevision={equiposRevision.balanzas}
                    />
                  </Collapsable>
                )}
                {equiposRevision.pesospatron.length > 0 && (
                  <Collapsable title="Pesos patrón">
                    <ListaPesosPatron
                      setEquipoActivo={setEquipoActivo}
                      equipoActivo={equipoActivo}
                      equiposRevision={equiposRevision.pesospatron}
                    />
                  </Collapsable>
                )}
              </>
            ) : (
              <MensajeCentro mensaje="No hay equipos para revisar" />
            )}
          </div>
        )}
      </div>
      {equipoActivo === undefined ? (
        <MensajeCentro mensaje="Selecciona un equipo" />
      ) : (
        <div style={{ marginLeft: "10px", overflowY: "scroll" }}>
          {equipoActivo.idservicio === 1 &&
            equipoActivo.idtablaequipo === 1 && (
              <HojaDatosBalanza data={equipoActivo} cerrar={cerrarHojaDatos} />
            )}
          {equipoActivo.idservicio === 2 &&
            equipoActivo.idtablaequipo === 1 && (
              <HojaDatosMantenimiento
                data={equipoActivo}
                cerrar={cerrarHojaDatos}
              />
            )}
          {equipoActivo.idtablaequipo === 2 && (
            <HojaDatosPesosPatron
              data={equipoActivo}
              cerrar={cerrarHojaDatos}
            />
          )}
        </div>
      )}
    </div>
    // }
  );
}

export default Calibraciones;
