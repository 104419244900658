import $ from "jquery";
import { useContext, useRef, useState } from "react";
import PopupContext from "../../../context/PopupContext";
import { host, port } from "../../../helpers/DbData";
import { objValidadoInicial, validarForm } from "../../../helpers/ValidarT";
// import useForm from "../../../hooks/useForm";
import Button from "../../generic/Button";
// import ContInput from "../../generic/ContInput";
import Input from "../../generic/Input";

let initialFormData = {
  juegopesa: "",
};

const initialFormValidado = {
  juegopesa: objValidadoInicial,
};

function FormNuevoJuego({ cerrar }) {
  const [formData, setFormData] = useState(initialFormData);
  const formValidado = useRef(initialFormValidado);
  const { mostrarPopup } = useContext(PopupContext);
  const [cargando, setCargando] = useState(false);
  // const [formData,handleChange,formValidado]=useForm(initialFormData)

  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormValidado = (name, value) => {
    formValidado.current = { ...formValidado.current, [name]: value };
  };

  const guardarJuego = () => {
    if (!cargando && validarForm(formValidado.current))
      $.ajax({
        url: `${host}:${port}/api/pesas/agregarJuegosPesas`,
        type: "POST",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(formData),
        beforeSend: function () {
          setCargando(true);
        },
        success: function (data) {
          setCargando(false);
          mostrarPopup(1, data);
          cerrar();
        },
        error: function (data) {
          setCargando(false);
          console.log(data);
          if (data.status === 0)
            mostrarPopup(0, "No es posible conectarse al servidor");
          else mostrarPopup(0, data.responseText);
        },
      });
  };

  return (
    <form
      style={{
        width: "300px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        guardarJuego();
      }}
    >
      <h3>Nuevo juego de pesas</h3>
      <Input
        name="juegopesa"
        value={formData.juegopesa}
        onChange={handleChange}
        icono={"ico-peso-patron"}
      />
      <div className="max-content" style={{ alignSelf: "end" }}>
        <Button
          label={"Guardar"}
          icono={"ico-guardar"}
          onClick={guardarJuego}
        />
      </div>
    </form>
  );
}

export default FormNuevoJuego;
